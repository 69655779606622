import { useState, SyntheticEvent } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import { Project } from '@urbanmix-tech/shared-js';

import { Autocomplete } from '@/components/shared/Autocomplete/Autocomplete';
import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';

export type NewProjectFormProps = {
  projects: Project[];
  teamProjectIds: string[];
  onAdd(teamProjectId: string): void;
};

export function NewProjectForm({
  projects,
  teamProjectIds,
  onAdd,
}: NewProjectFormProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const handleChangeProject = (
    _event: SyntheticEvent<Element, Event>,
    value: Project | null
  ) => {
    setProject(value);
  };

  const handleSetEditing = () => {
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setProject(null);
  };

  const handleAddProject = () => {
    if (!project) {
      return;
    }

    onAdd(project.id);

    handleCancelEditing();
  };

  const isReadyToSubmit = () => {
    if (project === null) {
      return true;
    }

    const exists = teamProjectIds.find((id) => id === project.id);

    return Boolean(exists);
  };

  return !isEditing ? (
    <Button variant="contained" onClick={handleSetEditing}>
      Assign a project
    </Button>
  ) : (
    <Stack gap="5px" className="w-full py-10">
      <Typography variant="caption2" className="text-white">
        Choose Project to add
      </Typography>

      <Autocomplete
        value={project}
        onChange={handleChangeProject}
        options={projects}
        getOptionLabel={(item) => `${item.name}`}
        fullWidth
      />

      <ConfirmButtons
        confirm={{
          label: 'Confirm',
          onClick: handleAddProject,
          disabled: isReadyToSubmit(),
        }}
        cancel={{
          label: 'Cancel',
          onClick: handleCancelEditing,
        }}
      />
    </Stack>
  );
}
