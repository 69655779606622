import { useState, SyntheticEvent, useEffect } from 'react';

import { Stack, Typography } from '@mui/material';

import { ActionItemCategory, ActionItemStatus } from '@urbanmix-tech/shared-js';

import { Autocomplete } from '@/components/shared/Autocomplete/Autocomplete';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { useAuth } from '@/shared/context/AuthProvider';
import { UserRole } from '@/types/enums';
import { getAllTeams } from '@urbanmix-tech/shared-js/data';
import { ModalContent } from '../../containers/ModalContent';

export type AssignInspectorModalContentProps = {
  category: ActionItemCategory;
  readonly?: boolean;
  isReinspection?: boolean;
  projectId: string;
  inspectorId: string | null;
  status: ActionItemStatus;
  onAssign: (inspectorId: string, inspectorName: string | null) => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function AssignInspectorModalContent({
  category,
  readonly,
  isReinspection,
  projectId,
  inspectorId,
  status,
  onAssign,
  onChangeModalOpen,
}: AssignInspectorModalContentProps) {
  const [inputValue, setInputValue] = useState('');
  const [inspector, setInspector] = useState<string | null>(inspectorId);
  const [inspectors, setInspectors] = useState<
    { value: string; label: string }[]
  >([]);
  const { userData } = useAuth();

  useEffect(() => {
    if (!userData) {
      return;
    }

    const getInspectors = async () => {
      const isAdmin =
        UserRole[userData.role as keyof typeof UserRole] === UserRole.ADMIN;
      const allTeams = isAdmin
        ? await getAllTeams()
        : (await getAllTeams())?.filter((t) =>
            t.users.filter((u) => u.email === userData.email)
          );

      const projectTeams =
        allTeams?.filter((t) => t.projectIds.includes(projectId)) ?? [];

      const uniqueInspectors: Record<string, string> = {};

      projectTeams.forEach((team) => {
        team.users?.forEach((user) => {
          uniqueInspectors[user.email] = user.name;
        });
      });

      setInspectors(
        Object.keys(uniqueInspectors).map((key) => ({
          value: key,
          label: uniqueInspectors[key],
        }))
      );
    };

    getInspectors();
  }, [projectId, userData]);

  const handleChangeInspector = (
    _event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setInspector(value);
  };

  const handleChangeFilter = (
    _event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleAssign = () => {
    if (inspector) {
      onAssign(
        inspector,
        inspectors.find((item) => item.value === inspector)?.label || null
      );
    }
  };

  const headerTitle = isReinspection
    ? `Assign ${category === 'inspect' ? 'Inspector' : 'a Team'} ${
        category === 'inspect' ? 'for Reinspection' : 'for Make Ready'
      }`
    : `Assign ${
        category === 'inspect' ? 'Inspector' : 'a Team for Make Ready'
      }`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Assign',
            disabled:
              !['active', 'future'].includes(status) ||
              inspector === null ||
              readonly,
            onClick: handleAssign,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: () => {},
            hidden: true,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    >
      <Stack direction="column" gap="12px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Type or choose from the list below:
        </Typography>

        <Autocomplete
          value={inspector}
          onChange={handleChangeInspector}
          inputValue={inputValue}
          onInputChange={handleChangeFilter}
          options={inspectors.map((item) => item.value)}
          fullWidth
          className="w-[263px]"
        />
      </Stack>
    </ModalContent>
  );
}
