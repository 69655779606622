import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { SearchMixTypeItem } from './SearchMixTypeItem';

type ResultRowProps = {
  index: number;
  keyItem: string;
  searchKeys: string[];
  parts: Record<string, { text: string; highlight: boolean }[]>;
  option: SearchMixTypeItem;
};

const ResultRow = ({
  index,
  keyItem,
  searchKeys,
  parts,
  option,
}: ResultRowProps) => {
  return (
    <>
      {index > 0 && (
        <span className="my-3 w-1 bg-[#6F79A6]" key={`divider-${index}`} />
      )}
      <div className="flex-1 truncate text-center " key={`row-${index}`}>
        {searchKeys.includes(keyItem) &&
          parts[keyItem].map((part, idx) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`${keyItem}-${idx}`}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        {!searchKeys.includes(keyItem) &&
          option[keyItem as keyof SearchMixTypeItem]}
      </div>
      {keyItem === 'stage' && (
        <Link
          to={`/projects/${option.projectId}/${option.buildingId}/units/${option.unitId}`}
        >
          <ArrowCircleRightOutlinedIcon sx={{ color: 'white' }} />
        </Link>
      )}
    </>
  );
};

export default ResultRow;
