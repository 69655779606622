import { ActionItem } from '@urbanmix-tech/shared-js';

import {
  ButtonType,
  ConfirmButtons,
} from '@/components/shared/ConfirmButtons/ConfirmButtons';

import Markdown from 'react-markdown';
import { saveActionItem } from '@urbanmix-tech/shared-js/data';

import useUsersData from '@/shared/hooks/useUsersData';
import { useState } from 'react';
import { useAuth } from '@/shared/context/AuthProvider';
import { convertPseudoDateToRealDate } from '@/helpers/common';
import { ModalContent } from '../../containers/ModalContent';

export type ActionItemModalProps = {
  actionItem: ActionItem;
  actionTitle?: string;
  onChangeModalOpen: (val: boolean) => void;
};

export function ActionItemModal({
  actionItem,
  actionTitle,
  onChangeModalOpen,
}: ActionItemModalProps) {
  const [formContent, setFormContent] = useState<any>(actionItem.response);
  const [formError, setFormError] = useState<any>(undefined);
  

  const cancelAction: ButtonType = {
    label: 'Cancel',
    hidden: true,
    onClick: () => {
      onChangeModalOpen(false);
    },
    // disabled: !['active', 'future'].includes(status),
    // confirmDialog: {
    //   title: 'Confirm',
    //   description: 'Are you sure?',
    // },
  };

 
  const usersData = useUsersData();
  let owner = '-';
  let supervisor = '-';
  let followers: string[] = [];
  if (actionItem.owner?.userId) {
    owner = usersData[actionItem.owner?.userId]?.fullName ?? '-';
  }
  if (actionItem.supervisor?.userId) {
    supervisor = usersData[actionItem.supervisor?.userId]?.fullName ?? '-';
  }

  if (actionItem.followers && actionItem.followers.length > 0) {
    followers = actionItem.followers.map((f) => usersData[f.userId]?.fullName ?? '-');
  }

  const auth = useAuth();

  const userAllowed = !actionItem.owner?.userId && !actionItem.supervisor?.userId ? true :
    auth.user?.uid == actionItem.owner?.userId ||
    auth.user?.uid == actionItem.supervisor?.userId || false;

   // The default acionts are approve and skip
   const defaultActions = (
    <ConfirmButtons
      alignItems="left"
      confirm={{
        label: actionTitle || 'Approve',
        onClick: async () => {
          // set the status of the action item to 'done' and save any form responses
          actionItem.status = 'done';
          actionItem.completedOn = new Date();
          if (actionItem.response || actionItem.form_scheme) {
            actionItem.response = formContent;
          }
          await saveActionItem(actionItem);
          onChangeModalOpen(false);
        },
        disabled: formError ? (formError.length > 0 || !userAllowed) : !userAllowed,
        tooltip: !userAllowed
          ? 'Only the assigned person or a supervisor can complete this action'
          : undefined,
      }}
      cancel={cancelAction}
    />
  );

  const completedOnDate = actionItem.completedOn
    ? convertPseudoDateToRealDate(actionItem.completedOn)?.toString()
    : undefined;

  return (
    <ModalContent
      actionCategory={actionItem.category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={actionItem.title}
      description={<Markdown className="popup-markdown">{actionItem.description}</Markdown>}
      owner={owner}
      supervisor={supervisor}
      followers={followers}
      moreInfo={completedOnDate}
      actionComponent={defaultActions}
      form_content={actionItem.response}
      form_scheme={actionItem.form_scheme}
      form_ui_scheme={actionItem.form_uischeme}
      on_content_change={({data, errors}) => {
        setFormContent(data);
        console.log('form errors', errors);
        setFormError(errors);
      }}
      actionItem={actionItem}
      readonly={!userAllowed}
    />
  );
}
