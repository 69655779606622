import { SvgIcon, type SvgIconProps } from '@mui/material';

export function Net(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.369141L0 17.6307L8.85396 8.9999L17.7079 17.6307L17.7079 0.369141L8.85396 8.9999L0 0.369141ZM11.4121 8.9999L15.9223 13.3963L15.9223 4.60349L11.4121 8.9999ZM6.29578 8.9999L1.78567 4.60349L1.78567 13.3963L6.29578 8.9999Z"
        fill="currentColor"
        stroke="none"
      />
    </SvgIcon>
  );
}
