import { useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Modal,
  IconButton,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import { ConditionReportItem } from '@urbanmix-tech/shared-js';

import { Picture } from '@/components/shared/Icons/Picture';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

export type InspectionAccordionItemAccordionProps = {
  disableConditionSatistic?: boolean;
  conditionReport: ConditionReportItem;
};

export function InspectionAccordionItemAccordion({
  disableConditionSatistic,
  conditionReport,
}: InspectionAccordionItemAccordionProps) {
  const [image, setImage] = useState<{ note: string; image: string } | null>(
    null
  );

  const handleSetNote = (value: { note: string; image: string }) => {
    setImage(value);
  };

  const handleCloseNote = () => {
    setImage(null);
  };

  return (
    <>
      <Accordion
        className="!m-0 rounded-[6px] bg-white bg-opacity-5 text-white"
        sx={{
          background: '#ffffff0d !important',
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: '24px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            !disableConditionSatistic && conditionReport?.images?.length ? (
              <ExpandMoreIcon className="text-[#9099BF]" />
            ) : null
          }
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8.5px 12px',
            minHeight: '37px !important',
            width: '100%',
            '& .MuiAccordionSummary-content': {
              margin: '0 !important',
            },
          }}
        >
          <Typography variant="caption" className="w-[200px] text-white">
            {conditionReport.name}
          </Typography>
          <Stack
            className="w-[100px] pl-[24px]"
            alignItems="flex-start"
            justifyContent="center"
          >
            {!disableConditionSatistic ? (
              <AppIcon
                name={
                  conditionReport.condition === true
                    ? 'good'
                    : conditionReport.condition === false
                      ? 'bad'
                      : 'noCond'
                }
              />
            ) : null}
          </Stack>
          <Stack
            className="w-[150px]"
            alignItems="flex-start"
            justifyContent="start"
          >
            <Typography variant="caption" className="w-[150px] text-white">
              {conditionReport.group}
            </Typography>
          </Stack>
          <Stack
            gap="8px"
            direction="row"
            alignItems="center"
            className="flex-1"
          >
            {conditionReport.images.length > 0 ? (
              <>
                <Picture
                  className="text-[20px] text-white"
                  viewBox="0 0 20 20"
                />
                <Typography variant="caption2" className="text-white">
                  {conditionReport.images.length} file attached
                </Typography>
              </>
            ) : null}
          </Stack>
        </AccordionSummary>
        <AccordionDetails className="flex justify-end p-0 pt-[5px]">
          <Stack gap="5px" className="mr-[42px] w-[228px]">
            {conditionReport.images
              .map((imageItem, index) => ({
                key: `${imageItem.note || ''} ${index}`,
                image: imageItem,
              }))
              .map((item) => (
                <Stack
                  direction="row"
                  gap="9px"
                  key={item.key}
                  className="w-[200px]"
                >
                  <Button
                    onClick={() => handleSetNote(item.image)}
                    className="h-[37px] min-w-[55px] p-0"
                  >
                    <img
                      src={item.image.image}
                      alt="Not found"
                      className="h-[37px] w-[55px] rounded-[4px] hover:border-[2px] hover:border-[#9099BF]"
                      onError={(e) => {
                        e.currentTarget.style.display = 'none';
                      }}
                    />
                  </Button>

                  <Typography variant="caption2" className="text-white">
                    {item.image.note || ''}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Modal open={Boolean(image)} onClose={handleCloseNote}>
        <Stack
          direction="row"
          gap="24px"
          className="-translation absolute left-1/2 top-1/2 h-[80%] w-[80%] -translate-x-1/2 -translate-y-1/2 items-start rounded-[30px] border border-primary bg-black p-[32px]"
        >
          {image ? (
            <>
              <img
                src={image.image || ''}
                className="object-fit h-full w-2/3"
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                }}
              />
              <Typography variant="body1" className="flex-1 text-white">
                {image.note}
              </Typography>
              <IconButton onClick={handleCloseNote}>
                <ClearIcon className="font-[30px] text-white" />
              </IconButton>
            </>
          ) : null}
        </Stack>
      </Modal>
    </>
  );
}
