import { useMemo } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';

import {
  ActionItemCategory,
  ConditionReportItem,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { useUIContext } from '@/shared/context/UIContextProvider';

import { ModalContent } from '../../containers/ModalContent';
import { InspectionAccordion } from '../InspectionAccordion';

export type ReviewInspectionReportModalContentProps = {
  readonly?: boolean;
  unitName: string;
  category: ActionItemCategory;
  isReinspection?: boolean;
  conditionReports: ConditionReportItem[];
  inspectorId: string;
  submittedDate: Date;
  status: ActionItemStatus;
  onApprove: () => void;
  onAskToReinspect?: () => void;
  onSkip: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function ReviewInspectionReportModalContent({
  readonly,
  unitName,
  category,
  isReinspection,
  conditionReports,
  inspectorId,
  submittedDate,
  status,
  onApprove,
  onAskToReinspect,
  onSkip,
  onChangeModalOpen,
}: ReviewInspectionReportModalContentProps) {
  const { openConfirm } = useUIContext();

  const handleSkip = () => {
    openConfirm({
      title: 'Confirm',
      description: 'Are you sure?',
      onAction: (value: boolean) => {
        if (value) {
          onSkip();
        }
      },
    });
  };

  const conditionReportsByRoomType = useMemo(() => {
    const conditionMap: Record<string, ConditionReportItem[]> = {};

    conditionReports.forEach((report) => {
      const arr = conditionMap[report.roomType];

      if (arr) {
        arr.push(report);
      } else {
        conditionMap[report.roomType] = [report];
      }
    });

    return Object.keys(conditionMap)
      .sort()
      .map((key) => ({
        roomType: key,
        conditionReport: conditionMap[key],
      }));
  }, [conditionReports]);

  const headerTitle = isReinspection
    ? `${category === 'inspect' ? 'Reinspection' : 'Make Ready'} Review`
    : `${category === 'inspect' ? 'Inspection' : 'Make Ready'} Review`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <Stack
          direction="row"
          gap="24px"
          alignItems="center"
          className="h-[28px]"
        >
          <Button
            variant="contained"
            onClick={onApprove}
            color="primary"
            disabled={!['active', 'future'].includes(status) || readonly}
            className="h-full min-w-[92px] px-16"
          >
            Approve
          </Button>

          {onAskToReinspect && (
            <Button
              variant="outlined"
              onClick={onAskToReinspect}
              color="primary"
              disabled={!['active', 'future'].includes(status) || readonly}
              className="h-full min-w-[92px]"
            >
              Ask to Reinspect
            </Button>
          )}

          <Button
            variant="text"
            onClick={handleSkip}
            color="primary"
            disabled={!['active', 'future'].includes(status) || readonly}
            className="h-full min-w-0 !px-0 underline"
          >
            Skip Task
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="16px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Review and approve the inspection list for Unit {unitName} below:
        </Typography>

        <Stack direction="column" gap="24px">
          <Stack
            direction="row"
            justifyContent="space-between"
            className="px-4"
          >
            <Stack direction="column" className="w-1/2">
              <Typography variant="captionMedium" className="text-stone-200">
                Submitted
              </Typography>
              <Typography
                variant="caption"
                className="font-thin text-stone-200 text-opacity-80"
              >
                {dayjs(submittedDate).format('dddd, MMM DD, YYYY at h:mm A')}
              </Typography>
            </Stack>

            <Stack direction="column" className="w-1/2">
              <Typography variant="captionMedium" className="text-stone-200">
                Assigned Inspector
              </Typography>
              <Typography
                variant="caption"
                className="font-thin text-stone-200 text-opacity-80"
              >
                {inspectorId}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            gap="4px"
            className="h-[395px] max-h-[400px] overflow-y-auto"
          >
            {conditionReportsByRoomType.map(({ roomType, conditionReport }) => (
              <InspectionAccordion
                key={roomType}
                roomType={roomType}
                conditionReports={conditionReport}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </ModalContent>
  );
}
