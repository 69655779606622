/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useMemo, forwardRef, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Stack,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

import { ITableCell, ITableRow } from './Table';

export type VirtualizedTableProps = {
  rows: ITableRow[];
  columns: ITableCell[];
  hoveredRowId?: string;
  onClickRow?: (rowId: string) => void;
  onChangeHover?: (rowId: string | null) => void;
  height?: string;
  headerHeight?: string;
  rowHeight?: number;
  px?: number;
  classNames?: {
    container?: string;
  };
  showHeader?: boolean;
};

export function VirtualizedTable({
  rows,
  columns,
  headerHeight,
  height,
  rowHeight = 40,
  px = 32,
  onClickRow,
  hoveredRowId,
  onChangeHover,
  classNames,
  showHeader=true,
}: VirtualizedTableProps) {
  const [sortKey, setSortKey] = useState<string>('');
  const [ascOrder, setAscOrder] = useState<boolean | null>(null);

  const tblRows = useMemo(() => {
    if (!sortKey) return rows;

    return rows.sort((a, b) => {
      const first = a.data[sortKey].value;
      const second = b.data[sortKey].value;
      const direction = ascOrder || ascOrder === null ? 1 : -1;

      if (first === undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === undefined && second !== undefined) {
        return -1 * direction;
      }

      if (first !== undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === second) {
        return 0;
      }

      if (first! > second!) {
        return 1 * direction;
      }

      if (first! < second!) {
        return -1 * direction;
      }

      return 0;
    });
  }, [sortKey, rows, ascOrder]);

  const handleDir = useCallback(
    (label: string) => {
      if (ascOrder === null) {
        setAscOrder(true);
        return;
      }

      if (sortKey === label) {
        setAscOrder((prevOrder) => !prevOrder);
      } else {
        setSortKey(label);
      }
    },
    [ascOrder, sortKey]
  );

  const components = useMemo(() => {
    return {
      Scroller: forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer
          component="div"
          ref={ref}
          className={twMerge(
            'rounded-xl border border-zinc-600',
            classNames?.container
          )}
          sx={{ height }}
          {...props}
        />
      )),
      Table: (props) => <Table {...props} className="relative h-auto w-full" />,
      TableHead,
      TableRow: ({ item, ...props }) => (
        <TableRow
          sx={{
            cursor: onClickRow ? 'pointer' : '',
            height: rowHeight,
          }}
          {...props}
        />
      ),
      TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
      )),
      EmptyPlaceholder: () => (
        <Typography variant="caption" className="pl-10 text-stone-200">
          No data to display
        </Typography>
      ),
    } as TableComponents<ITableRow>;
  }, [classNames?.container, height, onClickRow, rowHeight]);

  const headerContent = () => (
    <TableRow>
      {columns.map(
        (
          { sortable, label, children, value, className, ...rest1 },
          index
        ) => (
          <TableCell
            key={label}
            {...rest1}
            className="group cursor-pointer p-0"
            onClick={() => handleDir(label)}
          >
            <Stack
              direction="row"
              alignItems="center"
              className={twMerge(
                'h-40 border-b border-zinc-600',
                className
              )}
              sx={{
                paddingLeft: index === 0 ? `${px}px` || '32px' : '0',
                paddingRight:
                  index === columns.length - 1 ? `${px}px` || '32px' : 0,
                fontSize: headerHeight ? '12px' : '16px',
              }}
            >
              {children || label}
              {sortable ? (
                <AppIcon
                  name={
                    label === sortKey && ascOrder !== null
                      ? ascOrder
                        ? 'sortAsc'
                        : 'sortDesc'
                      : 'sort'
                  }
                />
              ) : null}
            </Stack>
          </TableCell>
        )
      )}
    </TableRow>
  );

  return (
    <TableVirtuoso
      data={tblRows}
      components={components}
      fixedHeaderContent={showHeader ? headerContent : undefined}
      itemContent={(_index: number, row: ITableRow) => (
        <>
          {columns.map((column, index) => (
            <TableCell
              key={column.label}
              {...row.data[column.label]}
              className={twMerge(
                'p-0',
                row.id === hoveredRowId && 'MuiTableCell-hover'
              )}
              onClick={() => onClickRow && onClickRow(row.id)}
              onMouseEnter={() => onChangeHover && onChangeHover(row.id)}
              onMouseLeave={() => onChangeHover && onChangeHover(null)}
              sx={{
                '&.MuiTableCell-hover': {
                  background: onClickRow ? 'rgba(255,255,255,0.12)' : '',
                },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                className={twMerge(
                  'h-40 text-sm text-zinc-400',
                  row.data[column.label] && row.data[column.label].className
                )}
                sx={{
                  paddingLeft: index === 0 ? `${px}px` || '32px' : '0',
                  paddingRight:
                    index === columns.length - 1 ? `${px}px` || '32px' : 0,
                  fontSize: headerHeight ? '12px' : '16px',
                  height: rowHeight,
                }}
              >
                {row.data[column.label] && row.data[column.label].children
                  ? row.data[column.label].children
                  : row.data[column.label].label}
              </Stack>
            </TableCell>
          ))}
        </>
      )}
      defaultItemHeight={rowHeight}
    />
  );
}
