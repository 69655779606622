import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { matchSorter } from 'match-sorter';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { getUnitsByProjectId } from '@urbanmix-tech/shared-js/data';
import { Project, Unit, Building } from '@urbanmix-tech/shared-js';
import { SearchMixTypeItem } from '@/components/GlobalSearchBar/SearchMixTypeItem';
import { convertPseudoDateToRealDate } from '@/helpers/common';
import { useProjects } from '@/shared/hooks/useProjects';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { twMerge } from 'tailwind-merge';
import ResultRow from './ResultRow';
import AppIcon from '../shared/AppIcon/AppIcon';

const GlobalSearchBar = () => {
  const [list, setList] = useState<SearchMixTypeItem[]>([]);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const allowedProjects = useProjects();

  const mapProjectsToSearchMixType = (
    projects: Project[],
    units: Unit[]
  ): SearchMixTypeItem[] => {
    const searchMixItems: SearchMixTypeItem[] = [];

    const projectMap = new Map<string, Project>();
    const buildingMap = new Map<string, Building>();

    projects.forEach((project) => {
      projectMap.set(project.id, project);

      project.buildings.forEach((building) => {
        buildingMap.set(
          JSON.stringify({ projectId: project.id, buildingId: building.id }),
          building
        );
      });
    });

    units.forEach((unit) => {
      const project = projectMap.get(unit.projectId);
      const building = buildingMap.get(
        JSON.stringify({
          projectId: unit.projectId,
          buildingId: unit.buildingId,
        })
      );

      if (!project || !building) {
        return;
      }

      searchMixItems.push({
        id: unit.id,
        project: project.name,
        projectId: project.id,
        building: building.name,
        buildingId: building.id,
        unit: unit.name || unit.id,
        unitId: unit.id,
        br: unit.numBedrooms,
        baths: unit.numBathrooms,
        sqft: unit.sqft,
        leaseEndDate: dayjs(
          convertPseudoDateToRealDate(unit.leaseEndDate)
        ).format('MM/DD/YYYY'),
        marketRent: unit.marketRent,
        stage: Array.isArray(unit.currentState)
          ? unit.currentState.length > 0
            ? unit.currentState[unit.currentState.length - 1]
            : LeaseLifecycle.NOTICE
          : (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE,
      });
    });

    return searchMixItems;
  };

  useEffect(() => {
    const fetcheData = async () => {
      try {
        const units: Unit[] = [];

        const promises = allowedProjects.map(async (project) =>
          getUnitsByProjectId(project.id)
        );

        await Promise.all(promises).then((values) => {
          units.push(...values.reduce((acc, item) => [...acc, ...item], []));
        });

        if (allowedProjects) {
          const result: SearchMixTypeItem[] = mapProjectsToSearchMixType(
            allowedProjects,
            units
          );
          setList(result);
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetcheData();
  }, [allowedProjects]);

  const searchKeys = ['project', 'building', 'unit'];
  const gridHeader = [
    'Project',
    // 'Building',
    'Unit ID',
    // 'BR',
    // 'Baths',
    // 'SqFt',
    // 'Lease End',
    // 'Rent ($)',
    'Stage',
  ];

  const gridRows = [
    'project',
    // 'building',
    'unit',
    // 'br',
    // 'baths',
    // 'sqft',
    // 'leaseEndDate',
    // 'marketRent',
    'stage',
  ];

  function queryMatches(value: string, inputValue: string) {
    const matches = match(value, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(value, matches);
  }

  return (
    <Autocomplete
      id="input-query"
      forcePopupIcon={false}
      clearOnEscape
      disablePortal
      filterOptions={(options, { inputValue }) => {
        const ret = matchSorter(options, inputValue || searchText, {
          keys: searchKeys,
        });
        return ret;
      }}
      options={list}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.buildingId === value.buildingId
      }
      groupBy={() => '1'}
      renderGroup={(params) => (
        <ul
          className="flex min-w-[875px] flex-col gap-5 bg-black "
          key={`group-${params.group}`}
        >
          <div
            key={`heade-${params.group}`}
            className="flex justify-between rounded-[100px] bg-white pl-17 pr-43 text-sm"
          >
            {gridHeader.map((item, key) => (
              <>
                {key > 0 && (
                  <span
                    className="my-3 w-1 bg-[#6F79A6]"
                    key={`head-divider${item}`}
                  />
                )}
                <div
                  className="flex-1 text-center text-[#6F79A6] "
                  key={`head-${item}`}
                >
                  {item}
                </div>
              </>
            ))}
          </div>
          {params.children}
        </ul>
      )}
      getOptionLabel={(option: SearchMixTypeItem) => option.unitId}
      componentsProps={{
        popper: {
          placement: 'bottom-end',
          className: 'min-w-[875px] bg-black',
          style: {
            width: 'fit-content',
          },
        },
      }}
      onChange={(_event, _value, reason) => {
        if (reason === 'clear') {
          setSearchText('');
        }
      }}
      renderOption={(props, option, { inputValue }) => {
        const parts: Record<
          string,
          Array<{ text: string; highlight: boolean }>
        > = {};

        const handleClick = () => {
          navigate(
            `/projects/${option.projectId}/${option.buildingId}/units/${option.unitId}`
          );
        };
        searchKeys.forEach((key) => {
          const value = option[key as keyof SearchMixTypeItem];
          if (value != null && typeof value === 'string') {
            parts[key] = queryMatches(value, inputValue);
          }
        });
        return (
          <li
            {...props}
            className="bg-black "
            key={`option-${option.projectId}-${option.id}`}
          >
            <div
              className="flex cursor-pointer justify-between rounded-[100px] bg-[#4A4E6B] pl-19 pr-20 text-sm hover:bg-[#b5b5b5]"
              onClick={handleClick}
            >
              {gridRows.map((keyItem, index) => (
                <ResultRow
                  key={`keyItem-${keyItem}`}
                  index={index}
                  keyItem={keyItem}
                  searchKeys={searchKeys}
                  parts={parts}
                  option={option}
                />
              ))}
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          variant="standard"
          placeholder="Search for projects, units, teams"
          className={twMerge(
            'flex h-36 w-[300px] items-center gap-16 rounded-lg px-12 leading-[14px] tracking-tight'
          )}
          size="small"
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          onReset={() => setSearchText('')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <AppIcon
                  name="search"
                  width={16}
                  className="text-white text-opacity-40"
                />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
      slotProps={{
        paper: {
          sx: {
            background: '#22212C',
            padding: '0',
          },
        },
      }}
      sx={{
        borderRadius: '8px',
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0A0E15 !important`,
        '& .MuiFormControl-root': { justifyContent: 'center' },
        '& input, & input::placeholder': {
          fontSize: '14px',
          color: '#6C6E73',
          fontWeight: 400,
          lineHeight: '14px',
          letterSpacing: '0.35px',
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0A0E15 !important`,
        },
        '& input': {
          color: 'rgba(255,255,255,0.6)',
        },
      }}
    />
  );
};

export default GlobalSearchBar;
