import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { Table, ITableCell, ITableRow } from '@/components/shared/Table';
import { FilterSelect } from '@/components/shared/Selects/FilterSelect';

import OverviewStatus from './OverviewStatus';

export type ProjectOverviewProps = {
  marketUnits: number;
  affordableUnits: number;
  data: {
    type: string;
    units: number;
    avgMarketRent: number | null;
    avgAffordableRent: number | null;
  }[];
  unitTypes: Record<string, string>;
  unitFilter?: string[];
  onChangeUnitFilter?: (selected: string[]) => void;
};

export default function ProjectOverview({
  marketUnits,
  affordableUnits,
  data,
  unitTypes,
  unitFilter,
  onChangeUnitFilter,
}: ProjectOverviewProps) {
  const columns: ITableCell[] = useMemo(() => {
    return [
      {
        label: 'Type',
        children:
          unitFilter && !!onChangeUnitFilter ? (
            <div className="relative -top-5">
              <FilterSelect
                label="Unit"
                items={unitTypes}
                multiple
                selected={unitFilter}
                onSelect={onChangeUnitFilter}
                className=""
                renderValue={() => (
                  <Typography className="pt-2 text-xs text-stone-200">
                    Type
                  </Typography>
                )}
              />
            </div>
          ) : undefined,
        sortable: false,
      },
      { label: 'Units', sortable: true },
      { label: 'Market', sortable: true },
      { label: 'Affordable', sortable: true },
    ];
  }, [onChangeUnitFilter, unitFilter, unitTypes]);

  const rows: ITableRow[] = useMemo(() => {
    return data
      .filter((item) => item.units)
      .map((item) => {
        return {
          id: `${item.type}`,
          data: {
            Type: {
              label: item.type === '0' ? 'Studio' : `${item.type} BR`,
              value: +item.type,
            },
            Units: {
              label: `${item.units}`,
              value: item.units,
            },
            Market: {
              label:
                item.avgMarketRent !== null
                  ? `$${Math.round(item.avgMarketRent)}`
                  : '-',
              value: item.avgMarketRent || 0,
            },
            Affordable: {
              label:
                item.avgAffordableRent !== null
                  ? `$${Math.round(item.avgAffordableRent)}`
                  : '-',
              value: item.avgAffordableRent || 0,
            },
          } as Record<string, ITableCell>,
        } as ITableRow;
      });
  }, [data]);

  return (
    <Stack direction="row" gap="16px" className="w-full">
      <Table
        rows={rows}
        columns={columns}
        stickyHeader
        height="156px"
        headerHeight="30px"
        rowHeight="28.5px"
        px={8}
      />

      <Stack
        direction="column"
        className="w-[160px]"
        gap="16px"
        justifyContent="space-between"
      >
        <OverviewStatus value={marketUnits} label="Market Units" />
        <OverviewStatus value={affordableUnits} label="Affordable Units" />
      </Stack>
    </Stack>
  );
}
