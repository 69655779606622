export const actionTitles = {
  sendRenewalNotice: 'Send a renewal notice',
  checkRenewalStatus: 'Check renewal status',
  extendLeaseOrScheduleMoveOutDate: 'Extend lease or schedule a move-out date',

  considerNewInspeciton: 'Consider a new inspection',
  assignInspector: 'Assign an inspector',
  scheduleInspection: 'Schedule an inspection',
  planInspectionItems: 'Plan inspection items',
  sendInspectionRequest: 'Send an inspection request',
  reviewInspectionReport: 'Review inspection report',
  planReinspection: 'Plan reinspection(?)',
  checkInOnInspectionProgress: 'Check in on inspection progress',

  startMakeReady: 'Start make ready',
  assignTeam: 'Assign a team',
  scheduleMakeReady: 'Schedule a make Ready',
  generateMakeReadyPlan: 'Generate Make Ready Plan',
  sendMakeReadyRequest: 'Send Make Ready Request',
  checkInOnMakeReadyProgress: 'Check in on make ready progress',
  reviewMakeReadyReport: 'Review Make Ready Report',

  startLease: 'Start lease',
  generateMarketingPackage: 'Generate marketing package',
  sendNoticeToLeaseAgency: 'Send notice to lease agency',
  checkLeaseAgencyForSignedContract: 'Check lease agency for signed contract',
  setNewTenant: 'Set a new tenant',

  occupied: 'Occupied',
};
