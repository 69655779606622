import { Chip, Stack, Typography } from '@mui/material';
import { getActionColorByCategory } from '@/helpers/common';
import { TodoTabListItem } from './TodoTabListItem';

type AgendaProps = {
  processes: TodoTabListItem[];
};

export function Agenda({ processes }: AgendaProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Typography variant="caption" className="pl-10 text-stone-300">
        Processes:
      </Typography>
      {processes.map((process) => (
        <Chip
          key={process.tabId}
          label={process.label}
          variant="outlined"
          sx={{
            color: getActionColorByCategory(process.action!),
            borderColor: getActionColorByCategory(process.action!),
          }}
          color="primary"
          size="small"
        />
      ))}
    </Stack>
  );
}
