import { useMemo } from 'react';
import { Checkbox, Stack } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import { ActionItem } from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import AppIcon from '@/components/shared/AppIcon/AppIcon';

import { StylessTable } from '@/components/shared/Table/StylessTable';
import { ITableCell, ITableRow } from '@/components/shared/Table/Table';

import {
  TodoAccordion,
  getBgColorClassByCategory,
  borderColorClassByCategory,
} from './TodoAccordion';

export type CompletedAccordionProps = {
  isUnit?: boolean;
  actions: ActionItem[];
  onClickActionItem(id: string): void;
};

export function CompletedAccordion({
  isUnit,
  actions,
  onClickActionItem,
}: CompletedAccordionProps) {
  const columns: ITableCell[] = useMemo(
    () => [
      {
        label: 'Unit',
        sortable: true,
        className: twMerge(
          'bg-gray-950 !pl-20 text-xs text-stone-200',
          !isUnit && 'hidden'
        ),
      },
      {
        label: 'Action',
        sortable: true,
        className: 'bg-gray-950 !pl-20 text-xs text-stone-200',
      },
      {
        label: 'Date',
        sortable: false,
        className: 'bg-gray-950 text-xs text-stone-200',
      },
      {
        label: 'Done',
        sortable: false,
        className: 'bg-gray-950 !pr-20 text-xs text-stone-200',
      },
    ],
    [isUnit]
  );

  const rows: ITableRow[] = useMemo(() => {
    return actions.map((actionItem) => {
      const updateDate = convertPseudoDateToRealDate(actionItem.updateDate);

      const data = {
        Unit: {
          label: actionItem.unitId,
          value: actionItem.unitId,
          className: twMerge(
            '!p-0 !border-solid !border !border-r-0',
            borderColorClassByCategory[actionItem.category],
            !isUnit && 'hidden'
          ),
          children: (
            <Stack direction="row" gap="9px" alignItems="center">
              <div
                className={twMerge(
                  '!my-1 h-30 w-10 rounded-r-lg',
                  getBgColorClassByCategory(actionItem.category)
                )}
              />
              <p className="text-[10px] text-stone-200">{actionItem.unitId}</p>
            </Stack>
          ),
        },
        Action: {
          label: actionItem.title,
          value: actionItem.title,
          children: (
            <Stack direction="row" gap="9px" alignItems="center">
              <div
                className={twMerge(
                  '!my-1 h-30 w-10 rounded-r-lg',
                  getBgColorClassByCategory(actionItem.category),
                  isUnit && 'hidden'
                )}
              />
              <p className="text-[10px] text-stone-200">{actionItem.title}</p>
            </Stack>
          ),
          className: twMerge(
            '!p-0 !border-solid !border !border-r-0',
            borderColorClassByCategory[actionItem.category],
            isUnit && '!border-l-0'
          ),
        },
        Date: {
          label: updateDate
            ? new Intl.DateTimeFormat(navigator.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(new Date(updateDate))
            : '-',
          className: twMerge(
            '!text-[10px] text-stone-200 !p-0 !border-solid !border !border-l-0 !border-r-0',
            borderColorClassByCategory[actionItem.category]
          ),
        },
        Done: {
          label: actionItem.status,
          children: (
            <Checkbox
              disabled
              size="small"
              defaultChecked={actionItem.status === 'done'}
              className="!p-0 !text-[14px] !text-stone-200"
            />
          ),
          className: twMerge(
            '!text-[10px] text-stone-200 !p-0 !border-solid !border !border-l-0',
            borderColorClassByCategory[actionItem.category]
          ),
        },
      } as Record<string, ITableCell>;

      return {
        id: actionItem.id,
        data,
      } as ITableRow;
    });
  }, [actions, isUnit]);

  return (
    <TodoAccordion
      summary={
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          justifyContent="space-between"
          className="mr-20 w-full"
        >
          <Stack direction="row" gap="13px" alignItems="center">
            <p className="text-sm font-medium">Completed</p>
            <AppIcon name="success" />
          </Stack>

          <span className="flex min-w-[16px] rounded-sm bg-zinc-400 px-5 py-2 text-[10px] font-semibold leading-[14px] tracking-tight text-neutral-800">
            {actions.length}
          </span>
        </Stack>
      }
      details={
        <StylessTable
          rows={rows}
          columns={columns}
          height={`${Math.min(
            Math.max(2 * 40, (rows.length + 1) * 40),
            360
          )}px`}
          onClickRow={onClickActionItem}
        />
      }
    />
  );
}
