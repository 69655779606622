import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import { Unit } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import ProjectOverview from './ProjectOverview/ProjectOverview';
import { LeaseStatusBar } from '../LeaseStatusBar/LeaseStatusBar';

export type HighlightProps = {
  units: Unit[];
  statusFilter: LeaseLifecycle[];
  onChangeStatusFilter(selected: string[]): void;
  unitFilter?: string[];
  onChangeUnitFilter?: (selected: string[]) => void;
};

export function Highlight({
  units,
  statusFilter,
  onChangeStatusFilter,
  unitFilter,
  onChangeUnitFilter,
}: HighlightProps) {
  const overviews = useMemo(() => {
    const marketRentUnits = units.filter((unit) => !unit.affordable).length;
    const affordableUnits = units.filter((unit) => unit.affordable).length;
    const currentDate = dayjs();

    const unitTypes: Record<string, string> = {};

    units.forEach((unit) => {
      if (!Number.isNaN(unit.numBedrooms)) {
        unitTypes[`${unit.numBedrooms}`] = `${unit.numBedrooms} BR`;
      }
    });

    unitTypes[`0`] = 'Studio';

    const data = Object.keys(unitTypes).map((unitType) => {
      const filteredUnits = units.filter(
        (unit) => unitType === (unit.numBedrooms ? `${unit.numBedrooms}` : '0')
      );
      const filteredMarketUnits = filteredUnits.filter(
        (unit) => !unit.affordable
      );
      const filteredAffordableUnits = filteredUnits.filter(
        (unit) => unit.affordable
      );

      return {
        type: unitType,
        units: filteredUnits.length,
        avgMarketRent: filteredMarketUnits.length
          ? filteredMarketUnits.reduce(
              (acc, item) => acc + (item.actualRent || 0),
              0
            ) / filteredMarketUnits.length
          : null,
        avgAffordableRent:
          filteredAffordableUnits.length > 0
            ? filteredAffordableUnits.reduce(
                (acc, item) => acc + (item.actualRent || 0),
                0
              ) / filteredAffordableUnits.length
            : null,
      };
    });

    return [
      {
        id: 0,
        content: (
          <Stack gap="8px" className="w-full">
            {[
              LeaseLifecycle.NOTICE,
              LeaseLifecycle.INSPECT,
              LeaseLifecycle.TURNOVER,
              LeaseLifecycle.LEASE,
            ].map((status) => (
              <Stack
                key={status}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LeaseStatusBar status={status} />
                <Stack direction="row" alignItems="center" gap="16px">
                  <Typography
                    variant="caption2"
                    className="min-w-[80px] text-stone-200/80"
                  >
                    {`${
                      units.filter((unit) =>
                        Array.isArray(unit.currentState)
                          ? unit.currentState.length > 0
                            ? unit.currentState.includes(status)
                            : LeaseLifecycle.NOTICE === status
                          : ((unit.currentState as LeaseLifecycle) ||
                              LeaseLifecycle.NOTICE) === status
                      ).length
                    } units`}
                  </Typography>
                  <Checkbox
                    checked={statusFilter.includes(status)}
                    onChange={(_e, checked) => {
                      if (checked) {
                        onChangeStatusFilter([
                          ...statusFilter.filter((item) => item !== status),
                          status,
                        ]);
                      } else {
                        onChangeStatusFilter([
                          ...statusFilter.filter((item) => item !== status),
                        ]);
                      }
                    }}
                    className="p-0"
                    sx={{ '& .MuiSvgIcon-root': { color: 'white' } }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        ),
        className: 'w-1/4 min-w-[340px] px-[30px]',
      },
      {
        id: 1,
        content: (
          <Stack className="w-full" gap="16px">
            <Stack gap="4px" className="w-fit">
              <Typography
                variant="h3"
                className="leading-[25px] tracking-wide text-white"
              >
                {`${units.reduce((total, u) => {
                  if (u.leaseEndDate) {
                    const leaseEndDate = convertPseudoDateToRealDate(
                      u.leaseEndDate
                    );

                    const actualMoveInDate = convertPseudoDateToRealDate(
                      u.actualMoveInDate
                    );

                    if (
                      dayjs(leaseEndDate).isBefore(currentDate) &&
                      (!actualMoveInDate ||
                        dayjs(actualMoveInDate).isBefore(dayjs(leaseEndDate)))
                    ) {
                      const vacancyDays = currentDate.diff(
                        dayjs(leaseEndDate),
                        'day'
                      );
                      return total + vacancyDays;
                    }
                  }

                  return total;
                }, 0)}`}
              </Typography>
              <Divider className="border-zinc-400/50" />
              <Typography
                variant="caption"
                className="font-medium leading-[14px] text-white"
              >
                Days of Vacancy
              </Typography>

              {/* <Typography
                variant="caption2"
                className="pt-[4px] text-xs leading-[12px] text-stone-300"
              >
                ~-8% compare to last year
              </Typography> */}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <LeaseStatusBar status={LeaseLifecycle.DONE} />
              <Stack direction="row" alignItems="center" gap="16px">
                <Typography
                  variant="caption2"
                  className="min-w-[80px] text-stone-200/80"
                >
                  {`${
                    units.filter((unit) =>
                      Array.isArray(unit.currentState) &&
                      unit.currentState.length > 0
                        ? unit.currentState.includes(LeaseLifecycle.DONE)
                        : ((unit.currentState as LeaseLifecycle) ||
                            LeaseLifecycle.NOTICE) === LeaseLifecycle.DONE
                    ).length
                  } units`}
                </Typography>
                <Checkbox
                  checked={statusFilter.includes(LeaseLifecycle.DONE)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      onChangeStatusFilter([
                        ...statusFilter.filter(
                          (item) => item !== LeaseLifecycle.DONE
                        ),
                        LeaseLifecycle.DONE,
                      ]);
                    } else {
                      onChangeStatusFilter([
                        ...statusFilter.filter(
                          (item) => item !== LeaseLifecycle.DONE
                        ),
                      ]);
                    }
                  }}
                  className="p-0"
                  sx={{ '& .MuiSvgIcon-root': { color: 'white' } }}
                />
              </Stack>
            </Stack>
          </Stack>
        ),
        className: 'w-1/4 min-w-[340px]',
      },
      {
        id: 2,
        content: (
          <ProjectOverview
            marketUnits={marketRentUnits}
            affordableUnits={affordableUnits}
            data={data}
            unitTypes={unitTypes}
            onChangeUnitFilter={onChangeUnitFilter}
            unitFilter={unitFilter}
          />
        ),
        className: 'w-1/2 min-w-[570px]',
      },
    ];
  }, [
    onChangeStatusFilter,
    statusFilter,
    units,
    onChangeUnitFilter,
    unitFilter,
  ]);

  return (
    <Stack direction="row" gap="16px">
      {overviews.map((overview) => (
        <Stack
          key={overview.id}
          gap="16px"
          className={twMerge(
            'rounded-[10px] border border-stone-200 bg-zinc-900 px-20 py-6',
            overview.className
          )}
          alignItems="center"
          justifyContent="center"
        >
          {overview.content}
        </Stack>
      ))}
    </Stack>
  );
}
