import { useParams, Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { LeaseStatusCircle } from '../LeaseStatusBar/LeaseStatusCircle';

export type BuildingUnitPopupProps = {
  unitId: string;
  numBedrooms: number;
  numBathrooms: number;
  rent: number;
  statusList: LeaseLifecycle[];
};

export function BuildingUnitPopup({
  unitId,
  numBathrooms,
  numBedrooms,
  rent,
  statusList,
}: BuildingUnitPopupProps) {
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  return (
    <Link to={`/projects/${projectId}/${buildingId}/units/${unitId}`}>
      <Stack
        gap="7px"
        className="rounded-md bg-cyan-100/60 px-16 py-20"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          className="font-medium capitalize tracking-[5.88px] text-white"
        >
          Unit {unitId}
        </Typography>
        <Stack gap="4px">
          <Typography
            variant="caption2"
            className="font-medium capitalize tracking-tight text-white"
          >
            {`${numBedrooms} Bed | ${numBathrooms} Bath`}
          </Typography>
          <Typography
            variant="caption2"
            className="font-medium capitalize tracking-tight text-white"
          >
            $ {rent}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          className="flex-wrap"
        >
          {statusList.map((status) => (
            <LeaseStatusCircle key={status} status={status} />
          ))}
        </Stack>
      </Stack>
    </Link>
  );
}
