import { convertPseudoDateToRealDate } from '@/helpers/common';
import { actionTitles } from '@/shared/constants';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { ActionItem, ActionItemCategory } from '@urbanmix-tech/shared-js';

export const statusByCategory = {
  notice: LeaseLifecycle.NOTICE,
  inspect: LeaseLifecycle.INSPECT,
  makeReady: LeaseLifecycle.TURNOVER,
  lease: LeaseLifecycle.LEASE,
};

export const FIRST_ACTION_TITLE_OF_CATEGORY: Partial<
  Record<ActionItemCategory, string>
> = {
  notice: actionTitles.sendRenewalNotice,
  inspect: actionTitles.considerNewInspeciton,
  makeReady: actionTitles.startMakeReady,
  lease: actionTitles.startLease,
};

export const LAST_ACTION_TITLE_OF_CATEGORY: Partial<
  Record<ActionItemCategory, string>
> = {
  notice: actionTitles.extendLeaseOrScheduleMoveOutDate,
  inspect: actionTitles.reviewInspectionReport,
  makeReady: actionTitles.reviewMakeReadyReport,
  lease: actionTitles.setNewTenant,
};

const PROGRESS_PERCENTAGES = {
  notice: {
    [actionTitles.sendRenewalNotice]: {
      percentage: {
        start: 0,
        finish: 30,
      },
      group: 0,
      count: 1,
    },
    [actionTitles.checkRenewalStatus]: {
      percentage: {
        start: 30,
        finish: 60,
      },
      group: 1,
      count: 1,
    },
    [actionTitles.extendLeaseOrScheduleMoveOutDate]: {
      percentage: {
        start: 60,
        finish: 100,
      },
      group: 2,
      count: 1,
    },
  },
  inspect: {
    [actionTitles.considerNewInspeciton]: {
      percentage: {
        start: 0,
        finish: 12,
      },
      group: 0,
      count: 1,
    },
    [actionTitles.assignInspector]: {
      percentage: {
        start: 12,
        finish: 47,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.scheduleInspection]: {
      percentage: {
        start: 12,
        finish: 47,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.planInspectionItems]: {
      percentage: {
        start: 12,
        finish: 47,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.planReinspection]: {
      percentage: {
        start: 12,
        finish: 47,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.sendInspectionRequest]: {
      percentage: {
        start: 47,
        finish: 60,
      },
      group: 2,
      count: 1,
    },
    [actionTitles.checkInOnInspectionProgress]: {
      percentage: {
        start: 60,
        finish: 75,
      },
      group: 3,
      count: 1,
    },
    [actionTitles.reviewInspectionReport]: {
      percentage: {
        start: 75,
        finish: 100,
      },
      group: 4,
      count: 1,
    },
  },
  makeReady: {
    [actionTitles.startMakeReady]: {
      percentage: {
        start: 0,
        finish: 15,
      },
      group: 0,
      count: 1,
    },
    [actionTitles.assignTeam]: {
      percentage: {
        start: 15,
        finish: 40,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.scheduleMakeReady]: {
      percentage: {
        start: 15,
        finish: 40,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.generateMakeReadyPlan]: {
      percentage: {
        start: 15,
        finish: 40,
      },
      group: 1,
      count: 3,
    },
    [actionTitles.sendMakeReadyRequest]: {
      percentage: {
        start: 40,
        finish: 67,
      },
      group: 2,
      count: 1,
    },
    [actionTitles.checkInOnMakeReadyProgress]: {
      percentage: {
        start: 67,
        finish: 83,
      },
      group: 3,
      count: 1,
    },
    [actionTitles.reviewMakeReadyReport]: {
      percentage: {
        start: 83,
        finish: 100,
      },
      group: 4,
      count: 1,
    },
  },
  lease: {
    [actionTitles.startLease]: {
      percentage: {
        start: 0,
        finish: 12,
      },
      group: 0,
      count: 1,
    },
    [actionTitles.generateMarketingPackage]: {
      percentage: {
        start: 12,
        finish: 25,
      },
      group: 1,
      count: 1,
    },
    [actionTitles.sendNoticeToLeaseAgency]: {
      percentage: {
        start: 25,
        finish: 50,
      },
      group: 2,
      count: 1,
    },
    [actionTitles.checkLeaseAgencyForSignedContract]: {
      percentage: {
        start: 50,
        finish: 75,
      },
      group: 3,
      count: 1,
    },
    [actionTitles.setNewTenant]: {
      percentage: {
        start: 75,
        finish: 100,
      },
      group: 4,
      count: 1,
    },
  },
};

const sortActionItems = (items: ActionItem[]): ActionItem[] => {
  return [...items].sort((a, b) => {
    const createDateA = convertPseudoDateToRealDate(a.createDate);
    const createDateB = convertPseudoDateToRealDate(b.createDate);

    if (createDateA !== null && createDateB !== null) {
      return createDateB.getTime() - createDateA.getTime();
    }

    if (createDateA === null && createDateB !== null) {
      return 1;
    }

    if (createDateA !== null && createDateB === null) {
      return -1;
    }

    return 0;
  });
};

const getLastRoundActionItems = (
  category: ActionItemCategory,
  items: ActionItem[]
): ActionItem[] => {
  const sortedItems = sortActionItems(items);

  const startIndex = sortedItems.findIndex(
    (item) =>
      item.title === FIRST_ACTION_TITLE_OF_CATEGORY[category] &&
      item.status === 'done'
  );

  if (startIndex === -1) {
    return sortedItems.reverse();
  }

  return sortedItems.slice(0, startIndex + 1).reverse();
};

export type LastActionStats = {
  activePercentage: number;
  completedPercentage: number;
  ignored?: boolean;
};

export const getLatestActionStats = (
  category: 'notice' | 'inspect' | 'makeReady' | 'lease',
  items: ActionItem[]
): LastActionStats => {
  const actionItems = getLastRoundActionItems(category, items);

  if (
    actionItems.length === 0 ||
    actionItems.find((item) => item.title === actionTitles.occupied)
  ) {
    return {
      activePercentage: 0,
      completedPercentage: 0,
    };
  }

  const lastActions: ActionItem[] = [];

  const lastActionItem = actionItems[actionItems.length - 1];

  const lastActionProperty =
    PROGRESS_PERCENTAGES[category][lastActionItem.title];

  if (!lastActionProperty) {
    return {
      activePercentage: 0,
      completedPercentage: 0,
    };
  }

  let count = 0;
  let i = actionItems.length - 1;

  // find same order actions
  while (i >= 0 && count < lastActionProperty.count) {
    const actionProperty = PROGRESS_PERCENTAGES[category][actionItems[i].title];

    if (actionProperty) {
      if (actionProperty.group === lastActionProperty.group) {
        count += 1;
        lastActions.push(actionItems[i]);
      }
    }

    i -= 1;
  }

  const lastIgnoreItem = lastActions.find((x) => x.status === 'ignored');

  if (lastIgnoreItem) {
    const property = PROGRESS_PERCENTAGES[category][lastIgnoreItem.title];

    return {
      ignored: true,
      activePercentage: property.percentage.finish,
      completedPercentage: property.percentage.start,
    };
  }

  const doneItems = lastActions.filter((x) => x.status === 'done');

  const { start, finish } = lastActionProperty.percentage;

  return {
    activePercentage: finish,
    completedPercentage: Math.floor(
      start + ((finish - start) * doneItems.length) / lastActions.length
    ),
  };
};
