import { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Tooltip, Modal, Box, Button } from '@mui/material';

import { Unit } from '@urbanmix-tech/shared-js';
import { getUnitsByProjectId } from '@urbanmix-tech/shared-js/data';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { ProjectCardList } from '@/components/ProjectCard';
import { Highlight } from '@/components/Highlights';
import { Table, ITableCell, ITableRow } from '@/components/shared/Table';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { useProjects } from '@/shared/hooks/useProjects';
import { useAuth } from '@/shared/context/AuthProvider';

import { NewProjectModalContent } from '@/components/NewProjectModalContent/NewProjectModalContent';
import { ErrorBoundary } from '@sentry/react';
import { GoogleConnectModalContent } from '@/components/GoogleConnectModalContent/GoogleConnectModalContent';

function getNumber(num: number | null) {
  return num && !Number.isNaN(num) ? num : 0;
}

interface PortfolioPageProps {
  modal?: PortfolioModals | undefined;
}

export type PortfolioModals = 'action' | 'project' | 'google_connect' | null;

const Portfolio = ({ modal }: PortfolioPageProps) => {
  const navigate = useNavigate();
  const projects = useProjects();
  const { userData, selectedOption, isEditMode } = useAuth();

  const [viewMode, setViewMode] = useState<'card-list' | 'table'>('card-list');
  const [statusFilter, setStatusFilters] = useState<LeaseLifecycle[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<PortfolioModals>(
    null
  );
  const [unitsByProjectId, setUnitsByProjectId] = useState<
    Record<string, Unit[]>
  >({});

  useEffect(() => {
    if (modal) {
      setIsModalOpen(modal);
    }
  }, [modal]);

  useEffect(() => {
    projects.forEach((project) => {
      getUnitsByProjectId(project.id).then((value) => {
        setUnitsByProjectId((prev) => {
          return {
            ...prev,
            [project.id]: value,
          };
        });
      });
    });
  }, [projects]);

  const handleClickRow = (id: string) => {
    navigate(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(null);
  };

  const handleOpenProjectModal = () => {
    setIsModalOpen('project');
  };

  const filteredUnits = useMemo(() => {
    const obj: Record<string, Unit[]> = {};

    projects.forEach((project) => {
      if (!unitsByProjectId[project.id]) {
        obj[project.id] = [];
        return;
      }

      const tempUnits = unitsByProjectId[project.id].filter((unit) => {
        if (statusFilter.length === 0) {
          return true;
        }

        return Array.isArray(unit.currentState)
          ? unit.currentState.length > 0
            ? statusFilter.some((item) => unit.currentState.includes(item))
            : statusFilter.includes(LeaseLifecycle.NOTICE)
          : statusFilter.includes(
              (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE
            );
      });

      obj[project.id] = tempUnits;
    });

    return obj;
  }, [projects, statusFilter, unitsByProjectId]);

  const rows: ITableRow[] = useMemo(() => {
    return projects.map((project) => {
      const units = filteredUnits[project.id];

      const affordableRent = units.reduce(
        (acc, unit) =>
          unit.affordable
            ? {
                sumOfAffordableRent:
                  acc.sumOfAffordableRent + getNumber(unit.actualRent),
                sumOfSQFT: acc.sumOfSQFT + getNumber(unit.sqft),
                units: acc.units + 1,
              }
            : { ...acc },
        { sumOfAffordableRent: 0, sumOfSQFT: 0, units: 0 }
      );
      const marketRent = units.reduce(
        (acc, unit) =>
          !unit.affordable
            ? {
                sumOfMarketRent:
                  acc.sumOfMarketRent + getNumber(unit.actualRent),
                sumOfSQFT: acc.sumOfSQFT + (unit.sqft || 0),
                units: acc.units + 1,
              }
            : { ...acc },
        { sumOfMarketRent: 0, sumOfSQFT: 0, units: 0 }
      );

      return {
        id: `/projects/${project.id}/${project.buildings[0].id}`,
        data: {
          Projects: {
            label: project.name,
          },
          Buildings: {
            label: `${project.buildings.length}`,
          },
          'Affordable Rate Units': {
            label:
              affordableRent.sumOfSQFT > 0
                ? `$${(
                    affordableRent.sumOfAffordableRent /
                    affordableRent.sumOfSQFT
                  ).toFixed(2)}/sqft`
                : '-',
          },
          'Market Rate Units': {
            label:
              marketRent.sumOfSQFT > 0
                ? `$${(
                    marketRent.sumOfMarketRent / marketRent.sumOfSQFT
                  ).toFixed(2)}/sqft`
                : '-',
          },
          Floors: {
            label: `${project.buildings[0].floors || 0}`,
          },
          'Total units': {
            label: `${units.length}`,
          },
        },
      } as ITableRow;
    });
  }, [projects, filteredUnits]);

  const columns: ITableCell[] = [
    { label: 'Projects' },
    { label: 'Buildings' },
    {
      label: 'Affordable Rate Units',
      children: (
        <Stack direction="row" alignItems="center" gap="4px">
          Affordable Rate Units
          <Tooltip
            title="Avg. price per sqft of affordable units in the portfolio"
            slotProps={{ tooltip: { className: '!max-w-[500px]' } }}
            placement="top"
          >
            <div>
              <AppIcon name="info" fontSize={14} />
            </div>
          </Tooltip>
        </Stack>
      ),
    },
    {
      label: 'Market Rate Units',
      children: (
        <Stack direction="row" alignItems="center" gap="4px">
          Market Rate Units
          <Tooltip
            title="Avg. price per sqft of market units in the portfolio"
            placement="top"
            slotProps={{ tooltip: { className: '!max-w-[500px]' } }}
          >
            <div>
              <AppIcon name="info" fontSize={14} />
            </div>
          </Tooltip>
        </Stack>
      ),
    },
    { label: 'Floors' },
    { label: 'Total units' },
  ];

  const handleChangeStatusFilter = useCallback((selected: LeaseLifecycle[]) => {
    setStatusFilters(selected);
  }, []);

  return (
    <Stack gap="16px" className="w-full min-w-[470px] p-24">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" className="text-stone-200">
          {selectedOption.value !== 'all'
            ? selectedOption.label
            : 'Portfolio Homepage'}
        </Typography>

        <Stack direction="row" gap="12px" alignItems="center">
          <Typography variant="body2Medium" className="text-stone-200/80">
            {`${projects.length} Projects`}
          </Typography>
          <Typography variant="body2Medium" className="text-stone-200/80">
            |
          </Typography>
          <Typography variant="body2Medium" className="text-stone-200/80">
            {`${
              Object.values(filteredUnits).reduce(
                (acc, item) => [...acc, ...item],
                []
              ).length
            } Units`}
          </Typography>
        </Stack>
      </Stack>

      <Highlight
        units={Object.values(filteredUnits).reduce(
          (acc, item) => [...acc, ...item],
          []
        )}
        statusFilter={statusFilter}
        onChangeStatusFilter={handleChangeStatusFilter}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="24px"
      >
        <Stack direction="row" alignItems="center" gap="24px">
          {userData?.isDev && isEditMode && (
            <Button
              onClick={handleOpenProjectModal}
              variant="outlined"
              className="rounded-lg bg-primary"
              type="submit"
              size="small"
            >
              + Add Building
            </Button>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap="8px">
          {viewMode === 'table' ? (
            <Button
              variant="text"
              onClick={() => setViewMode('card-list')}
              className="text-base font-normal text-white !underline !outline-none"
            >
              Grid View
            </Button>
          ) : (
            <Button
              onClick={() => setViewMode('table')}
              className="text-base font-normal text-white !underline !outline-none"
            >
              List View
            </Button>
          )}
        </Stack>
      </Stack>

      {viewMode === 'table' ? (
        <Table rows={rows} columns={columns} onClickRow={handleClickRow} />
      ) : (
        <ErrorBoundary fallback={<p>Error Loading Projects</p>}>
          <ProjectCardList
            projects={projects}
            unitsByProjectId={filteredUnits}
            statusFilter={statusFilter}
          />
        </ErrorBoundary>
      )}

      <Modal open={!!isModalOpen} onClose={handleCloseModal}>
        <Box
          component="div"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
        >
          {isModalOpen === 'project' && (
            <NewProjectModalContent onClose={handleCloseModal} />
          )}
          {isModalOpen === 'google_connect' && (
            <GoogleConnectModalContent onClose={handleCloseModal} />
          )}
        </Box>
      </Modal>
    </Stack>
  );
};

export default Portfolio;
