import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/shared/context/AuthProvider';
import ResetPasswordDialog from '@/containers/Auth/Login/ResetPasswordDialog';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { useLoading } from '@/shared/context/UIContextProvider';
import { Input } from '@/components/shared/Inputs/Input';
import GoogleIcon from '@mui/icons-material/Google';

export default function Login() {
  const { signIn, signInWithGoogle, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const { showAlert } = useAlert();
  const location = useLocation();
  const { createLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const from = location.state?.from || '/';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const loader = createLoading();
    try {
      loader.startLoading({ title: 'Launching Your Workspace...' });
      // For demonstration, we'll use a setTimeout to show loading animation at least 2s
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await signIn(email, password);
      navigate(from as string, { replace: true });
    } catch (err) {
      setError(true);
    }

    loader.stopLoading();
  };

  const handleResetPassword = (emailToReset: string) => {
    resetPassword(emailToReset).then(() => {
      showAlert('Password reset email was sent!', 'success');
    });
  };

  const handleGoogleSignIn = async () => {
    const loader = createLoading();
    try {
      loader.startLoading({ title: 'Launching Your Workspace...' });
      await signInWithGoogle();
      navigate(from as string, { replace: true });
    } catch (err) {
      setError(true);
    } finally {
      loader.stopLoading();
    }
  };

  return (
    <Box
      component="form"
      className="flex w-full flex-col items-center"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h4"
        className="mb-[40px] text-base font-semibold text-white sm:mb-[40px] sm:text-[25px]"
      >
        Welcome Back! Login to your workspace
      </Typography>
      <Stack gap="12px" className="w-full px-16">
        <Input
          onChange={(e) => setEmail(e.target.value)}
          type="username"
          fullWidth
          placeholder="Email*"
          className="border border-primary text-stone-200"
          required
        />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          fullWidth
          placeholder="Password*"
          className="border border-primary text-stone-200"
          required
        />

        {error && (
          <Typography variant="subtitle2" color="red">
            Invalid login details
          </Typography>
        )}
        <Button
          variant="contained"
          className="rounded-lg bg-primary"
          fullWidth
          type="submit"
        >
          Login
        </Button>
        <Button
          variant="contained"
          className="rounded-lg bg-primary"
          fullWidth
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
      </Stack>
      <ResetPasswordDialog
        open={openResetDialog}
        onClose={() => setOpenResetDialog(false)}
        resetPassword={handleResetPassword}
      />
      <Stack direction="row" gap={1} marginTop={1}>
        <Typography
          variant="subtitle2"
          className="cursor-pointer text-info-400 underline hover:no-underline"
          onClick={() => setOpenResetDialog(true)}
        >
          Forgot Password?
        </Typography>
      </Stack>
      <Stack direction="row" gap={1} marginTop={5}>
        <Typography variant="subtitle2" className="text-white">
          Don&apos;t have an account?
        </Typography>
        <Typography variant="subtitle2">
          <Link to="/register" className="text-info-400 hover:no-underline">
            Sign up
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
