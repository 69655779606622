import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { Typography, Button } from '@mui/material';

import animationData from '../assets/city.json';

export default function AccessDeniedPage() {
  return (
    <div className="relative flex h-screen w-full items-center justify-center">
      <Lottie
        style={{
          height: '100vh',
          width: '100%',
          cursor: 'auto',
          position: 'absolute',
          left: '0',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        animationData={animationData}
        autoPlay
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        loop
      />
      <div className="relative flex flex-col items-center rounded-3xl bg-black bg-opacity-70 p-20">
        <Typography
          variant="h1"
          className="pb-20 align-middle text-primary sm:text-[70px]"
          sx={{ textShadow: '2px 2px 10px rgba(255,0,0,0.5)' }}
        >
          Access Denied
        </Typography>
        <Typography variant="h3" className="text-white">
          You don't have access to this section
        </Typography>
        <Link to="/portfolio">
          <Button className="underline">Go To Home</Button>
        </Link>
      </div>
    </div>
  );
}
