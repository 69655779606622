import React, { createContext, useState } from 'react';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';

export const AppContextProvider = createContext({
  inspectMode: undefined as LeaseLifecycle | undefined,
  setInspectMode: (() => {}) as React.Dispatch<
    React.SetStateAction<LeaseLifecycle | undefined>
  >,
  lastSelectedProjectPage: null as string | null,
  setLastSelectedProjectPage: (() => {}) as React.Dispatch<
    React.SetStateAction<string | null>
  >,
  lastSelectedUnitPage: null as {
    projectId: string;
    unitId: string;
  } | null,
  setLastSelectedUnitPage: (() => {}) as React.Dispatch<
    React.SetStateAction<{
      projectId: string;
      unitId: string;
    } | null>
  >,
});

export const AppContext = () => {
  const [inspectMode, setInspectMode] = useState<LeaseLifecycle | undefined>();
  const [lastSelectedProjectPage, setLastSelectedProjectPage] = useState<
    string | null
  >(null);
  const [lastSelectedUnitPage, setLastSelectedUnitPage] = useState<{
    projectId: string;
    unitId: string;
  } | null>(null);

  return {
    value: {
      inspectMode,
      setInspectMode,
      lastSelectedProjectPage,
      setLastSelectedProjectPage,
      lastSelectedUnitPage,
      setLastSelectedUnitPage,
    },
  };
};
