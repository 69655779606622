import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';
import { ModalContent } from '../../containers/ModalContent';

export type SendInspectionRequestModalContentProps = {
  readonly?: boolean;
  unitName: string;
  category: ActionItemCategory;
  isReinspection?: boolean;
  inspectorId: string;
  scheduledDate: Date;
  status: ActionItemStatus;
  onSubmit: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function SendInspectionRequestModalContent({
  readonly,
  unitName,
  category,
  isReinspection,
  inspectorId,
  scheduledDate,
  status,
  onSubmit,
  onChangeModalOpen,
}: SendInspectionRequestModalContentProps) {
  const headerTitle = isReinspection
    ? `Send ${category === 'inspect' ? 'Reinspection' : 'Make Ready'} Details`
    : `Send ${category === 'inspect' ? 'Inspection' : 'Make Ready'} Details`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Submit',
            disabled:
              !['active', 'future'].includes(status) ||
              !scheduledDate ||
              new Date(scheduledDate.toLocaleDateString()) <
                new Date(new Date().toLocaleDateString()) ||
              readonly,
            onClick: onSubmit,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: () => {},
            hidden: true,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <Stack direction="column" gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Inspection for Unit {unitName} is scheduled for
        </Typography>

        <Stack direction="column" gap="16px">
          <Stack direction="row">
            <Stack direction="column" className="w-2/3">
              <Typography variant="captionMedium" className="text-stone-200">
                Date
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {dayjs(scheduledDate).format('dddd')}
                <br />
                {dayjs(scheduledDate).format('MMM DD, YYYY')}
              </Typography>
            </Stack>

            <Stack direction="column" className="w-1/3">
              <Typography variant="captionMedium" className="text-stone-200">
                Time
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {dayjs(scheduledDate).format('h:mm A')}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row">
            <Stack direction="column">
              <Typography variant="captionMedium" className="text-stone-200">
                Assigned Inspector
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {inspectorId}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalContent>
  );
}
