import React from 'react';
import { Tab, Tabs as MuiTabs } from '@mui/material';

import { BadgeTab } from './BadgeTab';
import AppIcon, { iconTypes } from '../AppIcon/AppIcon';

export type TabListItem = {
  tabId: string;
  label: string;
  iconName?: string;
  status?: number;
};

export type TabsProps = {
  items: TabListItem[];
  tabKey: string;
  setTabKey: (newValue: string) => void;
};

export function Tabs({ items, tabKey, setTabKey }: TabsProps) {
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabKey(newValue);
  };

  return (
    <MuiTabs
      value={tabKey}
      onChange={handleChange}
      TabIndicatorProps={{ className: 'bg-white' }}
      className="!min-h-[30px]"
    >
      {items.map((list) => (
        <Tab
          icon={
            list.iconName && (
              <AppIcon
                name={list.iconName as keyof typeof iconTypes}
                className="text-[16px]"
              />
            )
          }
          iconPosition="start"
          sx={{
            '&.Mui-selected': {
              color: '#fff',
            },
            borderBottom: '2px solid #25272D',
            alignItems: 'center',
          }}
          className="!min-h-[30px] !min-w-[216px] !p-0 text-base normal-case leading-[14px] tracking-tight text-stone-300"
          label={
            list.status ? (
              <BadgeTab label={list.label} status={list.status} />
            ) : (
              list.label
            )
          }
          value={list.tabId}
          key={list.tabId}
        />
      ))}
    </MuiTabs>
  );
}
