import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import {
  ActionItemCategory,
  ConditionReportItem,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';
import { InspectionAccordion } from '../InspectionAccordion';

export type PlanInspectionItemsModalContentProps = {
  readonly?: boolean;
  unitName: string;
  category: ActionItemCategory;
  isReinspection?: boolean;
  data: ConditionReportItem[];
  status: ActionItemStatus;
  onApprove: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function PlanInspectionItemsModalContent({
  readonly,
  unitName,
  category,
  isReinspection,
  data,
  status,
  onApprove,
  onChangeModalOpen,
}: PlanInspectionItemsModalContentProps) {
  const conditionReportsByRoomType = useMemo(() => {
    const conditionMap: Record<string, ConditionReportItem[]> = {};

    data.forEach((report) => {
      const arr = conditionMap[report.roomType];

      if (arr) {
        arr.push(report);
      } else {
        conditionMap[report.roomType] = [report];
      }
    });

    return Object.keys(conditionMap)
      .sort()
      .map((key) => ({
        roomType: key,
        conditionReport: conditionMap[key],
      }));
  }, [data]);

  const headerTitle = isReinspection
    ? `Plan ${category === 'inspect' ? 'Reinspection' : 'Make Ready'} Items`
    : `Plan ${category === 'inspect' ? 'Inspection' : 'Make Ready'} Items`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Approve',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onApprove,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: () => {},
            hidden: true,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <Stack direction="column" gap="16px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Review and approve the inspection list for Unit {unitName} below:
        </Typography>

        <Stack
          direction="column"
          gap="4px"
          className="h-[395px] max-h-[400px] overflow-y-auto"
        >
          {conditionReportsByRoomType.map(({ roomType, conditionReport }) => (
            <InspectionAccordion
              key={roomType}
              roomType={roomType}
              conditionReports={conditionReport}
              disableConditionSatistic
            />
          ))}
        </Stack>
      </Stack>
    </ModalContent>
  );
}
