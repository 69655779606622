import { Stack, Typography } from '@mui/material';

import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';

export type GoogleConnectModalContentProps = {
  onClose(): void;
};

export function GoogleConnectModalContent({
  onClose,
}: GoogleConnectModalContentProps) {
  return (
    <Stack
      gap="24px"
      className="relative w-[500px] rounded-[10px] bg-neutral-950 p-24"
    >
      <Typography variant="h4" className="!text-white">
        Google Connect
      </Typography>

      <Typography variant="body2Medium" className="text-stone-200/80">
        You successfuly connected your Google account to UrbanMix
      </Typography>

      <ConfirmButtons
        confirm={{
          label: 'Close',
          onClick: onClose,
        }}
        cancel={{
          label: 'Cancel',
          onClick: onClose,
          hidden: true,
        }}
      />
    </Stack>
  );
}
