import { useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useRadioGroup,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { twMerge } from 'tailwind-merge';

import {
  ActionItem,
  ActionItemCategory,
  UserData,
  UserRef,
} from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import { ITableCell, ITableRow } from '@/components/shared/Table/Table';
import { VirtualizedTable } from '@/components/shared/Table';

import { useAuth } from '@/shared/context/AuthProvider';
import {
  borderColorClassByCategory,
  labelByCategory,
} from '../UnitTabs/Todo/TodoAccordion';

import { UsersAvatars } from '../shared/UserAvatar/UserAvatars';

import { ActionCategoryCircle } from '../UnitTabs/Todo/ActionCategoryCircle';
import { useBuildingModel } from '../../contexts/Building/BuildingContext';

const oneDayGap = 1 * 24 * 60 * 60 * 1000;

export type ActionGroupProps = {
  category: ActionItemCategory;
  actionItems: ActionItem[];
  expanded?: boolean;
  // onChangeExpanded: (category: ActionItemCategory, expanded: boolean) => void;
  onClickActionItem(id: string): void;
  unitId: string;
};

export function ActionGroup({
  category,
  actionItems,
  expanded,
  // onChangeExpanded,
  onClickActionItem,
  unitId,
}: ActionGroupProps) {
  const columns: ITableCell[] = useMemo(
    () => [
      {
        label: 'Task',
        sortable: true,
        className: 'bg-gray-950 border-zinc-600 pl-[24px]',
      },
      {
        label: 'Owner',
        sortable: false,
        className: 'bg-gray-950 border-zinc-600',
      },
      {
        label: 'Due on',
        sortable: false,
        className: 'bg-gray-950 border-zinc-600',
      },
    ],
    []
  );

  const authContext = useAuth();
  const { usersData, usersColors, usersColorClasses } = authContext;
  const currentUserId = authContext.auth.currentUser?.uid;
  const currentUser: UserData | null = currentUserId
    ? usersData[currentUserId]
    : null;

  const { selectUnit,unitsOverlayData,setUnitOverlayData } = useBuildingModel();

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  // need to decide if a group should be collapsed on expanded based
  // on the action items. If includes actions for me, then show them
  // if not, show them only if they are late
  const filteredActionItems = useMemo(() => {
    const today = new Date();
    return actionItems.filter((item) => {
      if (!item.owner) {
        console.log('item without owner', item.title);
      }
      if (item.dueDate >= today) {
        return true;
      }
      if ((item.owner?.userId == currentUserId && item.status !== 'done') || !item.owner) {
        return true;
      }
      return false;
    });
  }, [actionItems, currentUserId]);

  const userGroupTasksTypes = useMemo(() => {
    const result = {
      hasOwnerActiveTasks: false,
      hasSupervisorActiveTasks: false,
      hasSupervisorLateTasks: false,
      hasFollowersActiveTasks: false,
      hasAnyActiveItems: false,
      hasLateTasks: false,
    };
    actionItems.forEach((item) => {
      if (item.owner?.userId === currentUserId && item.status !== 'done') {
        result.hasOwnerActiveTasks = true;
      }      
      if (item.supervisor?.userId === currentUserId && item.status !== 'done') {
        result.hasSupervisorActiveTasks = true;
      }
      const completedOn = convertPseudoDateToRealDate(item.completedOn);
      const dueDate = convertPseudoDateToRealDate(item.dueDate) || new Date();
      const now = new Date().getTime();

      if (completedOn && completedOn.getTime() > now - 3600) {
        result.hasOwnerActiveTasks = true;
        result.hasAnyActiveItems = true
      }
      if (
        item.supervisor?.userId === currentUserId &&
        item.status !== 'done' &&
        dueDate < new Date()
      ) {
        result.hasSupervisorLateTasks = true;
      }
      if (
        item.followers &&
        item.followers.find((f) => f.userId === currentUserId) &&
        item.status !== 'done'
      ) {
        result.hasFollowersActiveTasks = true;
      }
      if (item.status !== 'done') {
        result.hasAnyActiveItems = true;
      }
      if (dueDate < new Date() && item.status === 'active') {
        result.hasLateTasks = true;
      }
    });
    return result;
  }, [actionItems, currentUserId]);

  const activeGroupUsers = useMemo(() => {
    const users = new Set<string>();
    actionItems.forEach((item) => {
      if (item.owner?.userId && item.status !== 'done') {
        users.add(item.owner.userId);
      }
    });
    return Array.from(users).map((userId) => {
      return {
        userId,
        email: usersData[userId].email,
      };
    }) as UserRef[];
  }, [actionItems, usersData]);

  const [showExpanded, setShowExpanded] = useState<boolean>(expanded ?? false);
  const [hasActiveItems, setHasActiveItems] = useState<boolean>(false);

  useEffect(() => {
    const expandedGroup =
      userGroupTasksTypes.hasOwnerActiveTasks ||
      userGroupTasksTypes.hasSupervisorLateTasks;
    setShowExpanded(expandedGroup);
    setHasActiveItems(userGroupTasksTypes.hasAnyActiveItems);
    selectUnit(expandedGroup ? unitId : null);
  }, [selectUnit, unitId, userGroupTasksTypes]);

  const rows: ITableRow[] = useMemo(() => {
    return actionItems
      .sort((a: ActionItem, b: ActionItem) => {
        // sort ascendign by due date
        if (a.dueDate < b.dueDate) {
          return -1;
        }
        return 0;
      })
      .map((actionItem) => {
        const dueDate = convertPseudoDateToRealDate(actionItem.dueDate);

        let priority: number = 2;

        if (dueDate) {
          const adayAgo = new Date(dueDate.getTime() - oneDayGap);
          const adayLater = new Date(dueDate.getTime() + oneDayGap);
          const now = new Date();

          priority = now > adayLater ? 0 : priority;
          priority = now <= adayAgo ? 1 : priority;
        }

        const user =
          actionItem.owner?.userId && usersData
            ? usersData[actionItem.owner?.userId]
            : null;

        // console.log('user', user);

        const data = {
          Task: {
            label: actionItem.title,
            value: actionItem.title,
            className: twMerge(
              'text-sm border-zinc-600 text-overflow-ellipsis max-w-[280px] truncate',
              'whitespace-normal',
              borderColorClassByCategory[category],
              actionItem.status === 'done' ? 'line-through text-zinc-400' : '',
              actionItem.owner?.userId === currentUserId && actionItem.status != 'done'
                ? 'text-white'
                : 'text-zinc-400'
            ),
          },
          Priority: {
            label:
              priority === 0
                ? 'Overdue'
                : priority === 1
                  ? 'Due Now'
                  : 'Due Soon',
            value: priority * 1e12 + (dueDate?.getTime() || 0),
            className: twMerge(
              'text-sm text-zinc-400 border-zinc-600',
              actionItem.status === 'done' ? 'line-through' : ''
            ),
          },
          'Due on': {
            label: dueDate
              ? new Intl.DateTimeFormat(navigator.language, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(new Date(dueDate))
              : '-',
            className: twMerge(
              'text-sm text-zinc-400 border-zinc-600 justify-end',
              actionItem.status === 'done' ? 'line-through' : '',
              dueDate && dueDate < new Date() && actionItem.status === 'active' ? 'text-red-500' : ''
            ),
          },
          Owner: {
            label: user ? user.fullName ?? user.email : '-',
            className: twMerge(
              'text-sm text-zinc-400 border-zinc-600 max-w-[50px] ',
              actionItem.status === 'done' ? 'line-through' : ''
            ),
            children: (
              <UsersAvatars
                users={user ? [{ userId: user?.id, email: user.email }] : []}
              />
            ),
          },
        } as Record<string, ITableCell>;

        return {
          id: actionItem.id,
          data,
        } as ITableRow;
      });
  }, [actionItems, category, usersData, currentUserId]);

  const { groupColor, groupIcon } = useMemo(() => {
    let color: any = {};
    let icon : string = '';
    if (userGroupTasksTypes.hasOwnerActiveTasks && currentUser) {
      color = {
        class: usersColorClasses[currentUser.email],
        color: usersColors[currentUser.email],
      };
      icon = 'owner';
    }
    if (activeGroupUsers.length > 0) {
      color = {
        class: usersColorClasses[activeGroupUsers[0].email],
        color: usersColors[activeGroupUsers[0].email],
      };      
    }

    if (userGroupTasksTypes.hasLateTasks) {
      icon = 'late';
    }
    if (!userGroupTasksTypes.hasAnyActiveItems) {
      icon = 'done';
    }

    if (icon === '') {
      icon = 'waiting';
    }

    return {
      groupColor: color,
      groupIcon: icon,
    };
  }, [
    activeGroupUsers,
    currentUser,
    userGroupTasksTypes.hasOwnerActiveTasks,
    usersColorClasses,
    usersColors,
  ]);

  useEffect(() => {
    // if (unitsOverlayData[unitId]?.colorOverlay !== groupColor.class) {
      setUnitOverlayData({
        unitId,
        colorOverlay: groupColor.color,
      });
    // }
  }, [groupColor, setUnitOverlayData, unitId]);

  return (
    <Accordion
      className={twMerge(
        '!m-0 rounded-xl border-[0.5px] border-solid border-gray-700 bg-transparent py-16',
        
      )}
      expanded={showExpanded}
      onChange={() => {
        setShowExpanded(!showExpanded);
        // expanded = !expanded;
        // onChangeExpanded(key as string, !expanded);
      }}
    >
      <AccordionSummary
        className={twMerge(
          '!m-0 !min-h-0  !px-32',
          !hasActiveItems && activeGroupUsers.length === 0 ? 'line-through text-zinc-400' : 'text-white',
          borderColorClassByCategory[category]
        )}
        expandIcon={<ExpandMoreIcon />}
        sx={{ '& .MuiAccordionSummary-content': { margin: '0 !important' } }}
      >
        <Stack
          direction="row"
          gap="22px"
          alignItems="center"
          className="flex pb-10"
          sx={{ width: '100%' }}
        >
          <ActionCategoryCircle
            category={category}
            icon={groupIcon}
            colorClass={groupColor.class}
            color={groupColor.color}
            grayed={!userGroupTasksTypes.hasOwnerActiveTasks}
          />

          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            className="flex-1"
          >
            <span className="text-sm font-semibold capitalize tracking-tight">
              {unitId}
            </span>

            <span className="text-sm capitalize tracking-tight">
              {labelByCategory(category)}
            </span>

            {!showExpanded && <UsersAvatars users={activeGroupUsers} />}
            {/* <Divider orientation="vertical" className="h-16 border-white" />
            <span className="text-sm tracking-tight">Overdue</span> */}
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        className="p-0"
        sx={{
          height: `${Math.max(40, rows.length * 41)}px`,
        }}
      >
        <VirtualizedTable
          showHeader={false}
          rows={rows}
          columns={columns}
          height={`${Math.max(40, rows.length * 40)}px`}
          px={8}
          onClickRow={onClickActionItem}
          hoveredRowId={hoveredRowId || undefined}
          onChangeHover={(rowId) => {
            setHoveredRowId(rowId);
            selectUnit(rowId ? unitId : null);
          }}
          classNames={{
            container: 'rounded-none border-0 ',
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
