import {
  ActionItem,
  ActionItemCategory,
  createUnitAction,
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';


import { LeaseGeneralModalContent } from './LeaseGeneralModalContent/LeaseGeneralModalContent';
import { SetNewTenantModalContent } from './SetNewTenantModalContent/SetNewTenantModalContent';

export type UnitLeaseModalContentProps = {
  actionItem: ActionItem;
  onChangeModalOpen: (val: boolean) => void;
  onApproveAction: (payload?: unknown) => Promise<void>;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
  onSkipActions: (category: ActionItemCategory) => void;
  onSetNewTenant: (payload: { scheduledDate: Date; rent: number }) => void;
};

export function UnitLeaseModalContent({
  actionItem,
  onChangeModalOpen,
  onApproveAction,
  onSaveAction,
  onSkipActions,
  onSetNewTenant,
}: UnitLeaseModalContentProps) {
  const handleSkip = () => {
    onSkipActions('lease');
    onChangeModalOpen(false);
  };

  const handleApproveGenerateMakeReadyPlan = () => {
    const sendNoticeAction = createUnitAction({
      projectId: actionItem.projectId,
      buildingId: actionItem.buildingId,
      unitId: actionItem.unitId,
      title: actionTitles.sendNoticeToLeaseAgency,
      description: '',
      category: 'lease',
      action_type: {
        inputType: 'text',
        reminder: false,
        type: 'approve',
      },
    });

    onSaveAction(sendNoticeAction);

    onApproveAction();
    onChangeModalOpen(false);
  };

  const handleApproveSendNotice = () => {
    const checkLeaseAgency = createUnitAction({
      projectId: actionItem.projectId,
      buildingId: actionItem.buildingId,
      unitId: actionItem.unitId,
      title: actionTitles.checkLeaseAgencyForSignedContract,
      description: '',
      category: 'lease',
      action_type: {
        inputType: 'text',
        reminder: false,
        type: 'approve',
      },
    });

    onSaveAction(checkLeaseAgency);

    onApproveAction();
    onChangeModalOpen(false);
  };

  const handleApproveCheckLeaseAgency = () => {
    const setNewTenantAction = createUnitAction({
      projectId: actionItem.projectId,
      buildingId: actionItem.buildingId,
      unitId: actionItem.unitId,
      title: actionTitles.setNewTenant,
      description: '',
      category: 'lease',
      action_type: {
        inputType: 'text',
        reminder: false,
        type: 'approve',
      },
    });

    onSaveAction(setNewTenantAction);

    onApproveAction();
    onChangeModalOpen(false);
  };

  const handleNewTenant = (scheduledDate: Date, rent: number) => {
    onSetNewTenant({
      scheduledDate,
      rent,
    });

    onApproveAction();
    onChangeModalOpen(false);
  };

  if (actionItem.title === actionTitles.generateMarketingPackage) {
    return (
      <LeaseGeneralModalContent
        category={actionItem.category}
        title={actionTitles.generateMakeReadyPlan}
        status={actionItem.status}
        onSkip={handleSkip}
        onApprove={handleApproveGenerateMakeReadyPlan}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.sendNoticeToLeaseAgency) {
    return (
      <LeaseGeneralModalContent
        category={actionItem.category}
        title={actionTitles.sendNoticeToLeaseAgency}
        status={actionItem.status}
        onSkip={handleSkip}
        onApprove={handleApproveSendNotice}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.checkLeaseAgencyForSignedContract) {
    return (
      <LeaseGeneralModalContent
        category={actionItem.category}
        title={actionTitles.checkLeaseAgencyForSignedContract}
        status={actionItem.status}
        onSkip={handleSkip}
        onApprove={handleApproveCheckLeaseAgency}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.setNewTenant) {
    return (
      <SetNewTenantModalContent
        category={actionItem.category}
        title={actionTitles.setNewTenant}
        status={actionItem.status}
        onSkip={handleSkip}
        onSubmit={handleNewTenant}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  return null;
}
