import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  resetPassword: (email: string) => void;
};

export default function ResetPasswordDialog({
  open,
  onClose,
  resetPassword,
}: Props) {
  const [email, setEmail] = React.useState('');
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Forgot Password?</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter your email address</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            resetPassword(email);
            onClose();
            setEmail('');
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
