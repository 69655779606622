import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material/';
import { twMerge } from 'tailwind-merge';

import { SelectStatus } from './SelectStatus';
import { DropArrowIcon } from '../Icons/DropArrowIcon';
import AppIcon from '../AppIcon/AppIcon';

export type FilterSelectProps = {
  label: string;
  multiple?: boolean;
  items: Record<string, string>;
  selected: string[];
  onSelect(filter: string[]): void;
  className?: string;
  renderValue?: (sel: string[]) => JSX.Element;
};

export function FilterSelect({
  label,
  items,
  multiple = true,
  selected,
  onSelect,
  className,
  renderValue,
}: FilterSelectProps) {
  const handleRenderer = (sel: string[]) => {
    if (sel.includes('any'))
      return <SelectStatus label={`${label}: `} status="any" />;
    if (sel.length === 1)
      return <SelectStatus label={`${label}: `} status={items[sel[0]]} />;
    return (
      <SelectStatus label={`${label}: `} status={` ${sel.length} selected`} />
    );
  };

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;

    if (value.constructor === Array) {
      onSelect(value.filter((item) => item !== 'any'));
    } else if (value.constructor === String) {
      onSelect([value]);
    }
  };

  return (
    <Select
      multiple={multiple}
      value={selected.length > 0 ? selected : ['any']}
      onChange={handleChange}
      renderValue={renderValue || ((value) => handleRenderer(value))}
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent={(props) => (
        <DropArrowIcon {...props} className={className || 'text-stone-200'} />
      )}
      className={twMerge(
        'h-[26px] w-fit border border-white/10 bg-white bg-opacity-10 p-0 text-sm text-stone-200',
        className
      )}
      MenuProps={{
        PaperProps: {
          className:
            '!rounded-md max-w-[250px] !min-w-0 !w-fit border !border-white/40 mt-[8px] !p-16 flex flex-col gap-16',
          sx: {
            width: 'fit-content',
            minWidth: 'unset',
            '& .MuiList-root': {
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              padding: 0,
              width: 'fit-content',
              minWidth: 'unset',
            },
            background:
              'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #0A0E15;',
            opacity: 1,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      sx={{
        cursor: 'pointer',
        '&.Mui-focused': {
          bgcolor: '#ffffff33 !important',
        },
        '& .MuiSelect-select': {
          paddingLeft: '10px',
        },
        '& .MuiSelect-icon': {
          right: '0px !important',
        },
        '& fieldset': {
          border: 'none',
        },
      }}
    >
      {Object.entries(items).map(([key, value]) => (
        <MenuItem
          key={key}
          value={key}
          className="bg-transparent text-stone-200 hover:bg-white/10"
          sx={{
            padding: 0,
            width: 'fit-content',
            minWidth: 0,
            '&.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent !important',
            },
          }}
        >
          <Checkbox
            checked={selected.includes(key)}
            icon={<AppIcon name="check" />}
            checkedIcon={<AppIcon name="checked" />}
          />
          <ListItemText
            primary={value}
            sx={{
              '& .MuiTypography-root': { fontSize: '12px' },
              '& .MuiTypography-body1': { color: '#E6E3E3' },
              '& .MuiListItemText-root': { minWidth: 0 },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
}
