import React, { useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { AuthErrorCodes } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/shared/context/AuthProvider';
import { reportAppError } from '@/shared/monitoring';
import { Input } from '@/components/shared/Inputs/Input';
import { UserData, UserRole } from '@urbanmix-tech/shared-js/data';

import { useLoading } from '@/shared/context/UIContextProvider';
import GoogleIcon from '@mui/icons-material/Google';

interface RegisterError extends Error {
  code: string;
}

export default function Register() {
  const { createLoading } = useLoading();

  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<Partial<UserData>>({
    fullName: '',
    email: '',
    authProvider: 'email',
    profileImageUrl: '',
    role: '',
  });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    const loader = createLoading();

    try {
      loader.startLoading({ title: 'Launching Your Workspace...' });
      // For demonstration, we'll use a setTimeout to show loading animation at least 2s
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (userData.email && password && userData.fullName) {
        await signUp(userData.email, password, userData);
        navigate('/');
      } else {
        setError('Invalid registration details');
      }
    } catch (err) {
      let errMessage = 'Invalid registration';

      if ((err as RegisterError).code) {
        switch ((err as RegisterError).code) {
          case AuthErrorCodes.EMAIL_EXISTS:
            errMessage = 'Email address already exists';
            break;
          case AuthErrorCodes.INVALID_EMAIL:
            errMessage = 'Invalid email address';
            break;
          case AuthErrorCodes.WEAK_PASSWORD:
            errMessage = 'Weak password (at least 6 characters)';
            break;
          default:
            errMessage = 'Invalid registration';
            break;
        }
      }

      setError(errMessage);
      reportAppError(err as RegisterError, errMessage);
    }

    loader.stopLoading();
  };

  const handleGoogleSignIn = async () => {
    const loader = createLoading();
    try {
      loader.startLoading({ title: 'Launching Your Workspace...' });
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await signUp('', '', {
        ...userData,
        authProvider: 'google',
      });
      navigate('/');
    } catch (err) {
      console.error(err);
      setError('Google Sign-In Failed');
      reportAppError(err as Error, 'Google Sign-In Failed');
    } finally {
      loader.stopLoading();
    }
  };

  return (
    <Box
      component="form"
      className="flex w-full flex-col items-center sm:min-w-[550px]"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h4"
        className="mb-[40px] text-lg font-semibold text-white sm:mb-[32px] sm:text-[25px]"
      >
        Create an account
      </Typography>
      <Stack gap="12px" className="w-full px-16">
        <Input
          onChange={(e) =>
            setUserData((prevUserData) => {
              return { ...prevUserData, fullName: e.target.value };
            })
          }
          type="name"
          fullWidth
          placeholder="Full Name*"
          className="border border-primary text-stone-200"
          required
        />
        <Input
          onChange={(e) =>
            setUserData((prevUserData) => {
              return { ...prevUserData, email: e.target.value };
            })
          }
          type="email"
          fullWidth
          placeholder="Email*"
          className="border border-primary text-stone-200"
          required
        />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          fullWidth
          placeholder="Password*"
          className="border border-primary text-stone-200"
          required
        />
        <Input
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          fullWidth
          placeholder="Confirm Password*"
          className="border border-primary text-stone-200"
          required
        />

        <Select
          displayEmpty
          required
          onChange={(e) =>
            setUserData((prevUserData) => {
              return {
                ...prevUserData,
                role: (e.target.value as UserRole | null) ?? '',
              };
            })
          }
          type="role"
          input={
            <Input
              className="border border-primary text-stone-200"
              placeholder="Role*"
            />
          }
          renderValue={(selected?: string) => {
            if (!selected) {
              return <em className="text-zinc-700">Role*</em>;
            }

            return selected;
          }}
          fullWidth
          sx={{
            '& .MuiSelect-select': {
              fontSize: 14,
              lineHeight: '20px',
              color: 'rgba(255,255,255,0.6)',
            },
          }}
        >
          {Object.keys(UserRole).map((roleKey) => (
            <MenuItem key={roleKey} value={roleKey}>
              {UserRole[roleKey as keyof typeof UserRole]}
            </MenuItem>
          ))}
        </Select>

        {error && (
          <Typography variant="subtitle2" color="red">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          className="rounded-lg bg-primary"
          type="submit"
        >
          Sign up
        </Button>
        <Button
          variant="contained"
          className="rounded-lg bg-primary"
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
        >
          Sign up with Google
        </Button>
      </Stack>

      <Stack direction="row" gap={1} marginTop={5}>
        <Typography variant="subtitle2" className="text-white">
          Already have an account?
        </Typography>
        <Typography variant="subtitle2">
          <Link to="/login" className="text-info-400 hover:no-underline">
            Log in
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
