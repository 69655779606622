import { useState, ChangeEventHandler } from 'react';
import { Stack, Typography, InputBase } from '@mui/material';

import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { Calendar } from '@/components/shared/Calendar/Calendar';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type SetNewTenantModalContentProps = {
  category: ActionItemCategory;
  title: string;
  status: ActionItemStatus;
  onSubmit: (scheduledDate: Date, rent: number) => void;
  onSkip: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function SetNewTenantModalContent({
  category,
  title,
  status,
  onSubmit,
  onSkip,
  onChangeModalOpen,
}: SetNewTenantModalContentProps) {
  const [date, setDate] = useState<Date>(new Date());
  const [rent, setRent] = useState<number | null>(null);

  const handleChangeDate = (value: Date | null) => {
    const today = new Date();

    if (
      !value ||
      new Date(value.toDateString()) <= new Date(today.toDateString())
    ) {
      setDate(new Date());
    } else {
      setDate(value);
    }
  };

  const handleChangeRent: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRent(!Number.isNaN(e.currentTarget.value) ? +e.currentTarget.value : 0);
  };

  const handleSubmit = () => {
    if (!date || rent === null) {
      return;
    }

    onSubmit(date, rent);
  };

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={title}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Submit',
            disabled:
              Boolean(date === null || rent === null) ||
              !['active', 'future'].includes(status),
            onClick: handleSubmit,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status),
            onClick: onSkip,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    >
      <Stack gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Choose a date:
        </Typography>

        <Calendar value={date} onChange={handleChangeDate} />
      </Stack>

      <Stack gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Rent:
        </Typography>

        <InputBase
          type="number"
          value={rent}
          onChange={handleChangeRent}
          className="rounded-md border border-white p-4 text-[13px] text-white"
          fullWidth
        />
      </Stack>
    </ModalContent>
  );
}
