import { Stack, Typography } from '@mui/material';

import {
  ActionItemStatus,
  ActionItemCategory,
} from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';
import { actionTitles } from '@/shared/constants';

import { ModalContent } from '../../containers/ModalContent';

export type CheckRenewalStatusModalContentProps = {
  readonly?: boolean;
  category: ActionItemCategory;
  status: ActionItemStatus;
  onDone: () => void;
  onSnooze: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function CheckRenewalStatusModalContent({
  readonly,
  category,
  status,
  onDone,
  onSnooze,
  onChangeModalOpen,
}: CheckRenewalStatusModalContentProps) {
  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={actionTitles.checkRenewalStatus}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Tenant Responded',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onDone,
          }}
          cancel={{
            label: 'Snooze',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onSnooze,
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <Stack direction="column" gap="24px" className="mt-[-8px]">
        <Typography
          variant="caption"
          className="w-[265px] !font-thin text-stone-200 text-opacity-80"
        >
          Kindly remind the tenants to answer their notice
        </Typography>
      </Stack>
    </ModalContent>
  );
}
