import { Typography, Stack } from '@mui/material';

type OverViewStatusProps = {
  value: number;
  label: string;
};

export default function OverviewStatus({ value, label }: OverViewStatusProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap="12px"
      className="flex-1 rounded-lg bg-white/[0.08] p-10"
    >
      <Typography
        variant="h3"
        className="text-center leading-none text-stone-200"
      >
        {value}
      </Typography>
      <Typography
        variant="caption2"
        className="text-center leading-none text-stone-200/80"
        align="center"
      >
        {label}
      </Typography>
    </Stack>
  );
}
