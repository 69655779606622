/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useMemo, forwardRef, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Stack,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

import { ITableCell, ITableRow } from './Table';

export type StylessTableProps = {
  rows: ITableRow[];
  columns: ITableCell[];
  hoveredRowId?: string;
  onClickRow?: (rowId: string) => void;
  onChangeHover?: (rowId: string | null) => void;
  height?: string;
  rowHeight?: number;
};

export function StylessTable({
  rows,
  columns,
  height,
  rowHeight = 40,
  onClickRow,
  hoveredRowId,
  onChangeHover,
}: StylessTableProps) {
  const [sortKey, setSortKey] = useState<string>('');
  const [ascOrder, setAscOrder] = useState<boolean | null>(null);

  const tblRows = useMemo(() => {
    if (!sortKey) return rows;

    return rows.sort((a, b) => {
      const first = a.data[sortKey].value;
      const second = b.data[sortKey].value;
      const direction = ascOrder || ascOrder === null ? 1 : -1;

      if (first === undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === undefined && second !== undefined) {
        return -1 * direction;
      }

      if (first !== undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === second) {
        return 0;
      }

      if (first! > second!) {
        return 1 * direction;
      }

      if (first! < second!) {
        return -1 * direction;
      }

      return 0;
    });
  }, [sortKey, rows, ascOrder]);

  const handleDir = useCallback(
    (label: string) => {
      if (ascOrder === null) {
        setAscOrder(true);
        return;
      }

      if (sortKey === label) {
        setAscOrder((prevOrder) => !prevOrder);
      } else {
        setSortKey(label);
      }
    },
    [ascOrder, sortKey]
  );

  const components = useMemo(() => {
    return {
      Scroller: forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer
          component="div"
          ref={ref}
          sx={{ height: height && `${height} !important` }}
          {...props}
        />
      )),
      Table: (props) => <Table {...props} className="relative h-auto w-full" />,
      TableHead,
      TableRow: ({ item, ...props }) => (
        <TableRow
          sx={{
            cursor: onClickRow ? 'pointer' : '',
          }}
          {...props}
        />
      ),
      TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
      )),
      EmptyPlaceholder: () => (
        <p className="pl-10 text-xs text-stone-200">No data to display</p>
      ),
    } as TableComponents<ITableRow>;
  }, [height, onClickRow]);

  return (
    <TableVirtuoso
      data={tblRows}
      components={components}
      fixedHeaderContent={() => (
        <TableRow>
          {columns.map(
            ({ sortable, label, children, value, className, ...rest1 }) => (
              <TableCell
                key={label}
                {...rest1}
                className={twMerge('group cursor-pointer', className)}
                onClick={() => handleDir(label)}
              >
                <Stack direction="row" alignItems="center">
                  {children || label}
                  {sortable ? (
                    <AppIcon
                      name={
                        label === sortKey && ascOrder !== null
                          ? ascOrder
                            ? 'sortAsc'
                            : 'sortDesc'
                          : 'sort'
                      }
                      className="opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 "
                    />
                  ) : null}
                </Stack>
              </TableCell>
            )
          )}
        </TableRow>
      )}
      itemContent={(_index: number, row: ITableRow) => (
        <>
          {columns.map((column) => (
            <TableCell
              key={column.label}
              {...row.data[column.label]}
              className={twMerge(
                row.id === hoveredRowId && 'MuiTableCell-hover',
                row.data[column.label].className
              )}
              onClick={() => onClickRow && onClickRow(row.id)}
              onMouseEnter={() => onChangeHover && onChangeHover(row.id)}
              sx={{
                '&.MuiTableCell-hover': {
                  background: onClickRow ? 'rgba(255,255,255,0.12)' : '',
                },
              }}
            >
              {row.data[column.label] && row.data[column.label].children
                ? row.data[column.label].children
                : row.data[column.label].label}
            </TableCell>
          ))}
        </>
      )}
      defaultItemHeight={rowHeight}
    />
  );
}
