import {
  ReactNode,
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';
import {
  Modal,
  Box,
  Backdrop,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { Loader } from '@/components/Loader/Loader';

export interface UIContextProviderProps {
  children: ReactNode | null;
}

type LeaseLifecycleMode = {
  mode: 'standard' | 'none' | 'component';
  component?: ReactNode;
};

type LoadingStack = {
  id: string;
  title?: string;
  description?: string;
  loader?: ReactNode;
  onCancel?: () => void;
};

type ModalStack = {
  id: string;
  component: ReactNode;
  onClose: () => void;
};

type ConfirmProps = {
  title: string;
  description: string;
  onAction(value: boolean): void;
};

export interface UIContextModel {
  leaseLifecycle: LeaseLifecycleMode;
  setLeaseLifecycle: (component: LeaseLifecycleMode) => void;
  createLoading: (props: LoadingStack) => void;
  removeLoading: (id: string) => void;
  openConfirm: (confirm: ConfirmProps) => void;
  createModal: (props: ModalStack) => void;
  removeModal: (id: string) => void;
}

export const UIContext = createContext<UIContextModel>({
  setLeaseLifecycle: () => {},
  leaseLifecycle: {
    mode: 'standard',
  },
  createLoading: (_props: LoadingStack) => {},
  removeLoading: (_id: string) => {},
  openConfirm: (_confirm: ConfirmProps) => {},
  createModal: (_props: ModalStack) => {},
  removeModal: (_id: string) => {},
});

export function useUIContext(): UIContextModel {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error('useUIContext must be within UIContextProvider');
  }

  return context;
}

export function useLoading() {
  const { createLoading, removeLoading } = useUIContext();

  const create = useCallback(() => {
    const id = uuidv4();

    return {
      startLoading: (props: Omit<LoadingStack, 'id'>) => {
        createLoading({ id, ...props });
      },
      stopLoading: () => {
        removeLoading(id);
      },
    };
  }, [createLoading, removeLoading]);

  return { createLoading: create };
}

export function useModal() {
  const { createModal, removeModal } = useUIContext();

  const create = useCallback(() => {
    const id = uuidv4();

    return {
      openModal: (component: ReactNode) => {
        createModal({ id, component, onClose: () => removeModal(id) });
      },
      closeModal: () => removeModal(id),
    };
  }, [createModal, removeModal]);

  return { createModal: create };
}

export function UIContextProvider({ children }: UIContextProviderProps) {
  const [leaseLifecycle, setLeaseLifecycle] = useState<LeaseLifecycleMode>({
    mode: 'standard',
  });
  const [loadingStack, setLoadingStack] = useState<LoadingStack[]>([]);
  const [modalStack, setModalStack] = useState<ModalStack[]>([]);
  const [confirm, setConfirm] = useState<ConfirmProps | null>(null);

  const handleSetLeaseLifecycle = useCallback(
    (component: LeaseLifecycleMode) => {
      setLeaseLifecycle(component);
    },
    []
  );

  const createLoading = useCallback((loading: LoadingStack) => {
    setLoadingStack((prevStack) => [
      ...prevStack.filter((item) => item.id !== loading.id),
      loading,
    ]);
  }, []);

  const removeLoading = useCallback((id: string) => {
    setLoadingStack((prevStack) => [
      ...prevStack.filter((item) => item.id !== id),
    ]);
  }, []);

  const createModal = useCallback((modal: ModalStack) => {
    setModalStack((prevStack) => [
      ...prevStack.filter((item) => item.id !== modal.id),
      modal,
    ]);
  }, []);

  const removeModal = useCallback((id: string) => {
    setModalStack((prevStack) => [
      ...prevStack.filter((item) => item.id !== id),
    ]);
  }, []);

  const openConfirm = useCallback((props: ConfirmProps) => {
    setConfirm(props);
  }, []);

  const handleClickAgree = () => {
    confirm?.onAction(true);
    setConfirm(null);
  };

  const handleClickDisagree = () => {
    confirm?.onAction(false);
    setConfirm(null);
  };

  const values = useMemo(() => {
    return {
      leaseLifecycle,
      setLeaseLifecycle: handleSetLeaseLifecycle,
      createLoading,
      removeLoading,
      openConfirm,
      createModal,
      removeModal,
    };
  }, [
    createLoading,
    createModal,
    handleSetLeaseLifecycle,
    leaseLifecycle,
    openConfirm,
    removeLoading,
    removeModal,
  ]);

  return (
    <UIContext.Provider value={values}>
      {children}

      <Backdrop sx={{ zIndex: 2000 }} open={loadingStack.length > 0}>
        <Loader {...loadingStack[loadingStack.length - 1]} center />
      </Backdrop>

      {modalStack.length > 0 ? (
        <Modal open onClose={modalStack[modalStack.length - 1].onClose}>
          <Box
            component="div"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
          >
            {modalStack[modalStack.length - 1].component}
          </Box>
        </Modal>
      ) : null}

      <Dialog open={Boolean(confirm)} PaperProps={{ className: 'bg-zinc-900' }}>
        {confirm ? (
          <>
            <DialogTitle color="white">{confirm.title}</DialogTitle>
            <DialogContent>
              <DialogContentText color="white">
                {confirm.description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickDisagree} className="text-white">
                CANCEL
              </Button>
              <Button
                onClick={handleClickAgree}
                autoFocus
                className="text-white"
              >
                OK
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    </UIContext.Provider>
  );
}
