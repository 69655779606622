import { Stack, Typography } from '@mui/material';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import AppIcon, { iconTypes } from '@/components/shared/AppIcon/AppIcon';

export const colorsByLeaseLifecycle: Record<LeaseLifecycle, string> = {
  [LeaseLifecycle.NOTICE]: '#B67BFF',
  [LeaseLifecycle.INSPECT]: '#FF7295',
  [LeaseLifecycle.TURNOVER]: '#FFA760',
  [LeaseLifecycle.LEASE]: '#8FD3CD',
  [LeaseLifecycle.DONE]: '#FFFFFF',
};

export const labelsByLeaseLifecycle: Record<LeaseLifecycle, string> = {
  [LeaseLifecycle.NOTICE]: 'Notice',
  [LeaseLifecycle.INSPECT]: 'Inspect',
  [LeaseLifecycle.TURNOVER]: 'Make Ready',
  [LeaseLifecycle.LEASE]: 'Lease',
  [LeaseLifecycle.DONE]: 'Occupied',
};

export const iconNamesByLeaseLifecycle: Record<
  LeaseLifecycle,
  keyof typeof iconTypes
> = {
  [LeaseLifecycle.NOTICE]: 'emailMarkAsUnread',
  [LeaseLifecycle.INSPECT]: 'eye',
  [LeaseLifecycle.TURNOVER]: 'tool',
  [LeaseLifecycle.LEASE]: 'key',
  [LeaseLifecycle.DONE]: 'forbbiden',
};

export type LeaseStatusBarProps = {
  status: LeaseLifecycle;
};

export function LeaseStatusBar({ status }: LeaseStatusBarProps) {
  return (
    <Stack
      gap="16px"
      direction="row"
      className="h-20 w-[150px] rounded pl-25"
      alignItems="center"
      sx={{ backgroundColor: colorsByLeaseLifecycle[status] }}
    >
      <AppIcon
        name={iconNamesByLeaseLifecycle[status]}
        width={16}
        height={16}
        className="text-gray-800"
      />
      <Typography
        variant="caption2"
        className="font-medium tracking-tight text-gray-800"
      >
        {labelsByLeaseLifecycle[status]}
      </Typography>
    </Stack>
  );
}
