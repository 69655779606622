import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import {
  ActionItem,
  LeaseLifecycle,
  Unit,
  UserData,
} from '@urbanmix-tech/shared-js';

import {
  convertPseudoDateToRealDate,
  getLongNumberStr,
} from '@/helpers/common';

import {
  ITableCell,
  ITableRow,
  VirtualizedTable,
} from '@/components/shared/Table';

import { LeaseStatusCircle } from '../LeaseStatusBar/LeaseStatusCircle';
import { useAuth } from '@/shared/context/AuthProvider';

type UnitMixTabContentProps = {
  units: Unit[];
  hoveredUnitId: string | null;
  onSelectUnit(unitId: string | null): void;
};

type UnitTasksProperties = {
  hasOwnerActiveTasks: boolean;
  hasSupervisorActiveTasks: boolean;
  hasSupervisorLateTasks: boolean;
  hasFollowersActiveTasks: boolean;
  hasAnyActiveItems: boolean;
  hasLateTasks: boolean;
  activeUsers: UserData[];
  groupColor: string;
  groupColorClass: string;
  groupIcon: string;
};

export function getUnitTasksProperties(
  actionItems: ActionItem[],
  usersData: Record<string, UserData>,
  currentUser: UserData,
  usersColorClasses: Record<string, string>,
  usersColors: Record<string, string>
): UnitTasksProperties {
  const result: UnitTasksProperties = {
    hasOwnerActiveTasks: false,
    hasSupervisorActiveTasks: false,
    hasSupervisorLateTasks: false,
    hasFollowersActiveTasks: false,
    hasAnyActiveItems: false,
    hasLateTasks: false,
    activeUsers: [],
    groupColor: '',
    groupColorClass: '',
    groupIcon: '',
  };
  const users = new Set<string>();

  actionItems.forEach((item) => {
    if (item.owner?.userId && item.status !== 'done') {
      users.add(item.owner.userId);
    }

    if (item.owner?.userId === currentUser.id && item.status !== 'done') {
      result.hasOwnerActiveTasks = true;
    }
    if (item.supervisor?.userId === currentUser.id && item.status !== 'done') {
      result.hasSupervisorActiveTasks = true;
    }
    const dueDate = convertPseudoDateToRealDate(item.dueDate) || new Date();
    if (
      item.supervisor?.userId === currentUser.id &&
      item.status !== 'done' &&
      dueDate < new Date()
    ) {
      result.hasSupervisorLateTasks = true;
    }
    if (
      item.followers &&
      item.followers.find((f) => f.userId === currentUser.id) &&
      item.status !== 'done'
    ) {
      result.hasFollowersActiveTasks = true;
    }
    if (item.status !== 'done') {
      result.hasAnyActiveItems = true;
    }
    if (dueDate < new Date()) {
      result.hasLateTasks = true;
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  result.activeUsers = Array.from(users).map(
    (userId: string) => usersData[userId]
  );
  return result;
}

export function UnitMixTabContent({
  units,
  hoveredUnitId,
  onSelectUnit,
}: UnitMixTabContentProps) {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHight] = useState(250);

  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  const { usersData, userData, usersColorClasses, usersColors } = useAuth();

  useEffect(() => {
    if (ref.current) {
      const elem = ref.current;

      new ResizeObserver(() => {
        setHight(elem.clientHeight);
      }).observe(elem);
    }
  }, []);

  const handleClickRow = useCallback(
    (id: string) => {
      navigate(`/projects/${projectId}/${buildingId}/units/${id}`);
    },
    [buildingId, navigate, projectId]
  );

  const columns: ITableCell[] = useMemo(
    () => [
      { label: 'Status', sortable: true, className: 'bg-gray-950' },
      {
        label: 'Unit',
        sortable: true,
        className: 'bg-gray-950',
      },
      {
        label: 'Type',
        sortable: true,
        className: 'bg-gray-950',
      },
      {
        label: 'Sq.Ft',
        sortable: true,
        className: 'bg-gray-950',
      },
      {
        label: 'Lease',
        sortable: true,
        className: 'bg-gray-950',
      },
      {
        label: 'Rent',
        sortable: true,
        className: 'bg-gray-950',
      },
    ],
    []
  );

  const rows: ITableRow[] = useMemo(() => {
    return units.map((unit) => {
      const date = convertPseudoDateToRealDate(unit.leaseEndDate);

      const data = {
        Status: {
          label: unit.currentState,
          value: unit.currentState,
          children:
            Array.isArray(unit.currentState) && unit.currentState.length > 0 ? (
              <Stack direction="row" gap="4px" alignItems="center">
                {unit.currentState.map((currentStatus) => (
                  <LeaseStatusCircle
                    key={currentStatus}
                    status={currentStatus}
                  />
                ))}
              </Stack>
            ) : (
              <LeaseStatusCircle
                status={
                  Array.isArray(unit.currentState)
                    ? LeaseLifecycle.NOTICE
                    : (unit.currentState as LeaseLifecycle) ||
                      LeaseLifecycle.NOTICE
                }
              />
            ),
          className: 'border-l border-zinc-600',
        },
        Unit: {
          label: unit?.name || unit.id,
          value: unit?.name || unit.id,
        },
        Type: {
          value:
            (Number.isNaN(unit.numBedrooms) ? 0 : +unit.numBedrooms) * 1000000 +
            (Number.isNaN(unit.numBathrooms) ? 0 : +unit.numBathrooms),
          label:
            Number.isNaN(unit.numBedrooms) || +unit.numBedrooms === 0
              ? 'Studio'
              : `${unit.numBedrooms}B${
                  Number.isNaN(unit.numBathrooms)
                    ? ''
                    : `/${unit.numBathrooms}B`
                }`,
        },
        'Sq.Ft': {
          label: `${Number.isNaN(unit.sqft) ? '-' : unit.sqft}`,
          value: unit.sqft,
        },
        Lease: {
          label: date ? dayjs(date).format('MM/DD/YYYY') : '',
          value: date?.getTime(),
        },
        Rent: {
          label: `$${getLongNumberStr(unit.actualRent || 0)}`,
          value: unit.actualRent || 0,
          className: 'border-r border-zinc-600',
        },
      } as Record<string, ITableCell>;

      return {
        id: unit.id,
        data,
      } as ITableRow;
    });
  }, [units]);

  return (
    <div
      className="unit-mix h-[calc(100vh-20rem)] min-h-[30vh] min-w-[318px] bg-zinc-900"
      
      ref={ref}
    >
      <VirtualizedTable
        rows={rows}
        columns={columns}
        height={`${height - 32}px`}
        px={8}
        onClickRow={handleClickRow}
        hoveredRowId={hoveredUnitId || undefined}
        onChangeHover={onSelectUnit}
        classNames={{
          container:
            'rounded-none border-0 border-b border-b-solid border-zinc-600',
        }}
      />
    </div>
  );
}
