import { useState, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Card,
  CardMedia,
  Stack,
  Tooltip,
  IconButton,
  Modal,
  Box,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import { Building, Unit } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';

import { useUIContext } from '@/shared/context/UIContextProvider';

import { useAuth } from '@/shared/context/AuthProvider';

import { deleteProject } from '@urbanmix-tech/shared-js/data';
import BuildingDisplayModel from '@/components/BuildingDisplayModel/BuildingDisplayModel';
import { NewProjectModalContent } from '@/components/NewProjectModalContent/NewProjectModalContent';

import { ErrorBoundary } from '@sentry/react';
import { StatusBar } from './StatusBar';

export type ProjectCardProps = {
  hasActiveAction?: boolean;
  projectId: string;
  buildingId: number;
  building: Building;
  units: Unit[];
  name: string;
  address: string;
  stats: Record<
    LeaseLifecycle,
    {
      units: number;
      percentage: number;
    }
  >;
  width: number;
  statusFilter: string[];
};

export function ProjectCard({
  hasActiveAction,
  projectId,
  buildingId,
  building,
  units,
  name,
  address,
  stats,
  width,
  statusFilter,
}: ProjectCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { openConfirm } = useUIContext();
  const { userData, isEditMode } = useAuth();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditProject: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleDeleteProject: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    openConfirm({
      title: 'Archive Project',
      description: 'Are you sure?',
      onAction: async (value: boolean) => {
        if (value) {
          await deleteProject(projectId, true);
          window.location.reload();
        }
      },
    });
  };

  return (
    <ErrorBoundary fallback={<p>Error loading project card</p>}>
      <Card
        variant="elevation"
        elevation={0}
        className="min-w-[318px] bg-white/5 p-18 hover:bg-white/10"
        style={{ width: `${width}px` }}
      >
        <Stack gap="16px" className="relative">
          <Stack
            direction="row"
            alignItems="center"
            className="absolute right-8 top-8 z-10"
          >
            {userData?.isDev && isEditMode ? (
              <>
                <IconButton color="primary" onClick={handleEditProject}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={handleDeleteProject}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </>
            ) : null}
            {hasActiveAction && (
              <ErrorOutlinedIcon className="text-[44px] text-white" />
            )}
          </Stack>

          <Link to={`/projects/${projectId}/${buildingId}/`}>
            <CardMedia
              component="div"
              className="center flex h-[294px] w-full cursor-pointer rounded-md bg-white bg-opacity-10 object-fill"
            >
              {building && (
                <Stack alignItems="center" width="100%">
                  <BuildingDisplayModel
                    building={building}
                    units={units}
                    statusFilter={statusFilter as LeaseLifecycle[]}
                  />
                </Stack>
              )}
            </CardMedia>
          </Link>
        </Stack>

        <Stack gap="4px" margin="8px 0">
          <Typography variant="h4" className="font-semibold text-stone-200">
            {name}
          </Typography>

          <Typography variant="body2" className="truncate text-blue-500">
            <Tooltip placement="bottom" className="z-[20]" title={address}>
              <span>{address}</span>
            </Tooltip>
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" gap="4px" className="w-full">
          {Object.values(LeaseLifecycle).map((status) =>
            stats[status].units ? (
              <StatusBar
                key={status}
                status={status}
                units={stats[status].units}
                percentage={stats[status].percentage}
              />
            ) : undefined
          )}
        </Stack>

        <Modal open={!!isModalOpen} onClose={handleCloseModal}>
          <Box
            component="div"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
          >
            <NewProjectModalContent
              onClose={handleCloseModal}
              projectId={projectId}
            />
          </Box>
        </Modal>
      </Card>
    </ErrorBoundary>
  );
}
