import { Stack, Typography } from '@mui/material';

import { ActionItem } from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type InspectionApproveModalContentProps = {
  readonly?: boolean;
  unitName: string;
  action: ActionItem;
  onStartMakeReady: () => void;
  onStartLeaseAction: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function InspectionApproveModalContent({
  readonly,
  unitName,
  action,
  onStartMakeReady,
  onStartLeaseAction,
  onChangeModalOpen,
}: InspectionApproveModalContentProps) {
  const headerTitle = `${
    action.category === 'inspect' ? 'Inspection' : 'Make Ready'
  } Approved`;

  return (
    <ModalContent
      actionCategory={action.category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label:
              action.category === 'inspect'
                ? 'Start Make Ready'
                : 'Start Lease',
            onClick:
              action.category === 'inspect'
                ? onStartMakeReady
                : onStartLeaseAction,
            disabled: readonly,
          }}
          cancel={{
            label: action.category === 'inspect' ? 'Start Lease' : 'Skip Task',
            onClick:
              action.category === 'inspect' ? onStartLeaseAction : () => {},
            disabled: readonly,
            hidden: action.category !== 'inspect',
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <Stack direction="column" gap="24px">
        <Typography
          variant="caption"
          className="w-[265px] !font-thin text-stone-200 text-opacity-80"
        >
          Inspection for Unit {unitName} is approved.
          <br />
          How would you like to proceed?
        </Typography>
      </Stack>
    </ModalContent>
  );
}
