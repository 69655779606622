import { ChangeEventHandler, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';
import { Calendar } from '@/components/shared/Calendar/Calendar';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { Input } from '@/components/shared/Inputs/Input';

export type CustomActionGenerationModalContentProps = {
  onGenerate: (title: string, description: string, dueDate: Date) => void;
  onClose: () => void;
};

export function CustomActionGenerationModalContent({
  onGenerate,
  onClose,
}: CustomActionGenerationModalContentProps) {
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleChangeDueDate = (value: Date | null) => {
    const today = new Date();

    if (
      !value ||
      new Date(value.toDateString()) <= new Date(today.toDateString())
    ) {
      setDueDate(new Date());
    } else {
      setDueDate(value);
    }
  };

  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTitle(e.currentTarget.value);
  };

  const handleChangeDescription: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDescription(e.currentTarget.value);
  };

  const handleSubmit = () => {
    if (title.trim() === '') {
      return;
    }

    onGenerate(title.trim(), description.trim(), dueDate);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Stack
      gap="24px"
      className="relative rounded-[10px]"
      sx={{
        background: '#0A0E15',
        padding: '32px',
      }}
    >
      <AppIcon
        name="close"
        className="absolute right-16 top-16 cursor-pointer text-stone-300"
        onClick={onClose}
      />

      <Typography variant="h4" className="!text-white">
        Custom Action
      </Typography>

      <Stack gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Title:
        </Typography>

        <Input
          onChange={handleChangeTitle}
          value={title}
          type="text"
          fullWidth
          placeholder="Input action title*"
          className="border border-primary text-stone-200"
          required
        />
      </Stack>

      <Stack gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Description:
        </Typography>

        <Input
          onChange={handleChangeDescription}
          value={description}
          type="text"
          fullWidth
          placeholder="Input action description"
          className="border border-primary text-stone-200"
          required
        />
      </Stack>

      <Stack gap="8px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Due Date:
        </Typography>

        <Calendar value={dueDate} onChange={handleChangeDueDate} />
      </Stack>

      <ConfirmButtons
        confirm={{
          label: 'Generate',
          disabled: Boolean(title.trim() === ''),
          onClick: handleSubmit,
        }}
        cancel={{
          label: 'Cancel',
          onClick: handleCancel,
        }}
        justifyContent="flex-start"
        gap="24px"
      />
    </Stack>
  );
}
