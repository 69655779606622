import { Link } from 'react-router-dom';

import logo from '@/assets/images/logo/logo.svg';

export function MainAppLogo() {
  return (
    <Link to="/portfolio">
      <img
        src={logo}
        alt="Logo"
        width={105}
        height={16}
        className="h-16 object-fill"
      />
    </Link>
  );
}
