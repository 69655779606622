import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { Snackbar, Alert, AlertProps } from '@mui/material';

interface AlertContextProps {
  showAlert: (message: string, severity: AlertProps['severity']) => void;
  hideAlert: () => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertData, setAlertData] = useState({
    isOpen: false,
    message: '',
    severity: 'info' as AlertProps['severity'], // Default severity
  });

  // Function to show an alert
  const showAlert = useCallback(
    (message: string, severity: AlertProps['severity']) => {
      setAlertData({
        isOpen: true,
        message,
        severity,
      });
    },
    []
  );

  // Function to hide the alert
  const hideAlert = useCallback(() => {
    setAlertData({
      isOpen: false,
      message: '',
      severity: 'info',
    });
  }, []);

  const value = useMemo(
    () => ({
      showAlert,
      hideAlert,
    }),
    [hideAlert, showAlert]
  );

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertData.isOpen}
        autoHideDuration={3000}
        onClose={hideAlert}
      >
        <Alert
          onClose={hideAlert}
          severity={alertData.severity}
          sx={{ width: '100%' }}
        >
          {alertData.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
