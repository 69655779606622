import { useState, useEffect } from 'react';
import {
  getActionItem,
  getActionItemPayload,
  getInspectionById,
} from '@urbanmix-tech/shared-js/data';
import {
  ActionItem,
  ActionItemPayload,
  Inspection,
  AssignTeamPayload,
  InspectionActionPayload,
  ScheduleMakeReadyActionPayload,
  ReportMakeReadyPlanActionPayload,
  SendMakeReadyRequestActionPayload,
  CheckInOnMakeReadyProgressActionPayload,
  ActionItemCategory,
  createUnitAction,
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import { AssignInspectorModalContent } from './AssignInspectorModalContent/AssignInspectorModalContent';
import { ScheduleInspectionModalContent } from './ScheduleInspectionModalContent/ScheduleInspectionModalContent';
import { PlanInspectionItemsModalContent } from './PlanInspectionItemsModalContent/PlanInspectionItemsModalContent';
import { SendInspectionRequestModalContent } from './SendInspectionRequestModalContent/SendInspectionRequestModalContent';
import { CheckInOnInspectionProgressModalContent } from './CheckInOnInspectionProgressModalContent/CheckInOnInspectionProgressModalContent';
import { ReviewInspectionReportModalContent } from './ReviewInspectionReportModalContent/ReviewInspectionReportModalContent';

export type UnitMakeReadyModalContentProps = {
  readonly?: boolean;
  unitName: string;
  actionItem: ActionItem;
  actionItemPayload: ActionItemPayload;
  onChangeModalOpen: (val: boolean) => void;
  onSkipActions: (category: ActionItemCategory) => void;
  onApproveAction: (payload?: unknown) => Promise<void>;
  onOpenApproveModal: () => void;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
};

export function UnitMakeReadyModalContent({
  readonly,
  unitName,
  actionItem,
  actionItemPayload,
  onChangeModalOpen,
  onSkipActions,
  onApproveAction,
  onOpenApproveModal,
  onSaveAction,
}: UnitMakeReadyModalContentProps) {
  const [inspection, setInspection] = useState<Inspection | null>(null);

  useEffect(() => {
    if ((actionItemPayload.payload as InspectionActionPayload).inspectionId) {
      getInspectionById(
        actionItem.projectId,
        actionItem.unitId,
        (actionItemPayload.payload as InspectionActionPayload).inspectionId
      ).then((value) => setInspection(value));
    }
  }, [actionItem, actionItemPayload]);

  const handleMakeReadyReadyToSend = async (ids: string[]) => {
    const actionPromises = ids.map((id: string) => {
      return getActionItem(id);
    });

    const actions = await Promise.all(actionPromises);

    if (actions.find((action) => action === null || action.status !== 'done')) {
      return;
    }

    const assignTeamAction = actions.find(
      (action) => action?.title === actionTitles.assignTeam
    );
    const generateMakeReadyPlanAction = actions.find(
      (action) => action?.title === actionTitles.generateMakeReadyPlan
    );
    const scheduleMakeReadyAction = actions.find(
      (action) => action?.title === actionTitles.scheduleMakeReady
    );

    if (
      !assignTeamAction ||
      !generateMakeReadyPlanAction ||
      !scheduleMakeReadyAction
    ) {
      return;
    }

    const assignTeamPayload = await getActionItemPayload(assignTeamAction.id);
    const schedulePayload = await getActionItemPayload(
      scheduleMakeReadyAction.id
    );

    if (!assignTeamPayload || !schedulePayload) {
      return;
    }

    const sendMakeReadyAction = createUnitAction({
      projectId: actionItem.projectId,
      buildingId: actionItem.buildingId,
      unitId: actionItem.unitId,
      title: actionTitles.sendMakeReadyRequest,
      description: '',
      category: 'makeReady',
      action_type: {
        inputType: 'text',
        reminder: false,
        type: 'approve',
      },
    });

    const checkPayload: SendMakeReadyRequestActionPayload = {
      teamId: (assignTeamPayload.payload as AssignTeamPayload).teamId!,
      scheduledDate: (
        schedulePayload?.payload as ScheduleMakeReadyActionPayload
      ).scheduledDate!,
    };

    onSaveAction(sendMakeReadyAction, checkPayload);
  };

  const handleSkip = () => {
    onSkipActions('makeReady');
    onChangeModalOpen(false);
  };

  const handlePrepareMakeReady = async ({
    team,
    scheduledDate,
  }: {
    team?: string;
    scheduledDate?: Date;
  }) => {
    if (team && !scheduledDate) {
      await onApproveAction({
        ...(actionItemPayload.payload as AssignTeamPayload),
        teamId: team,
      });
    } else if (!team && scheduledDate) {
      await onApproveAction({
        ...(actionItemPayload.payload as ScheduleMakeReadyActionPayload),
        scheduledDate,
      });
    } else if (!team && !scheduledDate) {
      onApproveAction();
    } else {
      return;
    }

    handleMakeReadyReadyToSend(
      (
        actionItemPayload.payload as {
          relatedActionIds: string[];
        }
      ).relatedActionIds
    );

    onChangeModalOpen(false);
  };

  const handleApprove = () => {
    onApproveAction();
    onChangeModalOpen(false);
  };

  if (actionItem.title === actionTitles.assignTeam) {
    return (
      <AssignInspectorModalContent
        readonly={readonly}
        category={actionItem.category}
        inspectorId={(actionItemPayload.payload as AssignTeamPayload).teamId}
        projectId={actionItem.projectId}
        status={actionItem.status}
        onAssign={(inspector: string) =>
          handlePrepareMakeReady({ team: inspector })
        }
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.scheduleMakeReady) {
    return (
      <ScheduleInspectionModalContent
        readonly={readonly}
        category={actionItem.category}
        scheduleDate={convertPseudoDateToRealDate(
          (actionItemPayload.payload as ScheduleMakeReadyActionPayload)
            .scheduledDate
        )}
        status={actionItem.status}
        onSkip={handleSkip}
        onSchedule={(scheduledDate: Date) =>
          handlePrepareMakeReady({ scheduledDate })
        }
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.generateMakeReadyPlan) {
    return (
      <PlanInspectionItemsModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        data={
          (actionItemPayload.payload as ReportMakeReadyPlanActionPayload).data
        }
        status={actionItem.status}
        onApprove={() => handlePrepareMakeReady({})}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.sendMakeReadyRequest) {
    return (
      <SendInspectionRequestModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        scheduledDate={
          convertPseudoDateToRealDate(
            (actionItemPayload.payload as SendMakeReadyRequestActionPayload)
              .scheduledDate
          )!
        }
        inspectorId={
          (actionItemPayload.payload as SendMakeReadyRequestActionPayload)
            .teamId
        }
        status={actionItem.status}
        onSubmit={handleApprove}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.checkInOnMakeReadyProgress) {
    return (
      <CheckInOnInspectionProgressModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        scheduledDate={
          convertPseudoDateToRealDate(
            (
              actionItemPayload.payload as CheckInOnMakeReadyProgressActionPayload
            ).scheduledDate
          )!
        }
        inspectorId={
          (actionItemPayload.payload as CheckInOnMakeReadyProgressActionPayload)
            .teamId
        }
        status={actionItem.status}
        onDone={handleApprove}
        onSkip={handleSkip}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.reviewMakeReadyReport && inspection) {
    return (
      <ReviewInspectionReportModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        conditionReports={inspection.conditionReport}
        inspectorId={inspection.inspectorId!}
        submittedDate={inspection.updateDate}
        status={actionItem.status}
        onSkip={handleSkip}
        onApprove={onOpenApproveModal}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  return null;
}
