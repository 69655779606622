import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';

import {
  Project,
  Building,
  Unit,
  StateCompletionStatus,
} from '@urbanmix-tech/shared-js';
import {
  SubStateType,
  InspectSubState,
  LeaseLifecycle,
  LeaseSubState,
  NoticeSubState,
  TurnoverSubState,
} from '@urbanmix-tech/shared-js';

const mockMeshIds = [
  'apt_8b',
  'apt_7b',
  'apt_6b',
  'apt_5b',
  'apt_4b',
  'apt_8a',
  'apt_7a',
  'apt_6a',
  'apt_5a',
  'apt_4a',
  'apt_10a',
  'apt_11a',
  'apt_9a',
  'apt_11b',
  'apt_10b',
  'apt_9b',
  'apt_4c',
  'apt_8c',
  'apt_7c',
  'apt_6c',
  'apt_5c',
  'apt_8d',
  'apt_7d',
  'apt_6d',
  'apt_5d',
  'apt_8e',
  'apt_7e',
  'apt_6e',
  'apt_5e',
  'apt_4e',
  'apt_8f',
  'apt_7f',
  'apt_6f',
  'apt_5f',
  'apt_4f',
  'apt_8g',
  'apt_7g',
  'apt_6g',
  'apt_5g',
  'apt_4g',
  'apt_11c',
  'apt_10c',
  'apt_9c',
  'apt_10d',
  'apt_9d',
  'apt_11_pha_1',
  'apt_11_pha_2',
];

function generateStateCompletionStatus(keys: string[]) {
  return Object.fromEntries(
    keys.map((key) => [
      key,
      {
        startedAt: null,
        updatedAt: null,
        completedAt: null,
      } as StateCompletionStatus,
    ])
  );
}

export function getFakeUnit(
  unitId: string,
  meshId: string | null,
  projectId: string,
  buildingId: number
): Unit {
  const states = Object.values(LeaseLifecycle);
  const subStates = {
    [LeaseLifecycle.NOTICE]: Object.values(NoticeSubState),
    [LeaseLifecycle.INSPECT]: Object.values(InspectSubState),
    [LeaseLifecycle.TURNOVER]: Object.values(TurnoverSubState),
    [LeaseLifecycle.LEASE]: Object.values(LeaseSubState),
    [LeaseLifecycle.DONE]: ['Occupied'],
  };

  const currentStateIndex = 0;
  const currentSubStateIndex = 0;

  return {
    id: unitId,
    projectId,
    buildingId,
    name: unitId,
    line: '',
    type: '',
    floor: 0,
    floorPlanPath: null,
    coverImagePath: null,
    modelPath: null,
    '3dScanPath': null,
    updatedAt: new Date(),
    currentState: states[currentStateIndex],
    currentSubState: subStates[states[currentStateIndex]][
      currentSubStateIndex
    ] as SubStateType,
    meshId,
    numBathrooms: 0,
    numBedrooms: 0,
    sqft: 0,
    affordable: false,
    marketRent: null,

    contractSignedDate: null,
    leaseStartDate: null,
    scheduledMoveInDate: null,
    actualMoveInDate: null,

    actualRent: null,
    residentDeposit: null,
    recurringCharges: null,

    leaseEndDate: null,
    scheduledMoveOutDate: null,
    actualMoveOutDate: null,

    renewalNotices: [],
    renewalAccepted: null,

    turnoverGCId: 'turnoverGCId',
    turnoverSignOffDate: null,
    scheduledTurnoverStartDate: null,
    actualTurnoverStartDate: null,
    scheduledTurnoverCompletionDate: null,
    actualTurnoverCompletionDate: null,
    turnoverCost: null,
    turnoverPaymentDate: null,

    leasePublishDate: null,
    leaseOfficeId: 'leaseOfficeId',
    shows: [],

    lastInspectionId: 0,

    files: [],

    allStates: {
      [LeaseLifecycle.NOTICE]: generateStateCompletionStatus(
        Object.values(NoticeSubState)
      ) as Record<NoticeSubState, StateCompletionStatus>,
      [LeaseLifecycle.INSPECT]: generateStateCompletionStatus(
        Object.values(InspectSubState)
      ) as Record<InspectSubState, StateCompletionStatus>,
      [LeaseLifecycle.TURNOVER]: generateStateCompletionStatus(
        Object.values(TurnoverSubState)
      ) as Record<TurnoverSubState, StateCompletionStatus>,
      [LeaseLifecycle.LEASE]: generateStateCompletionStatus(
        Object.values(LeaseSubState)
      ) as Record<LeaseSubState, StateCompletionStatus>,
      [LeaseLifecycle.DONE]: {
        Occupied: {
          startedAt: null,
          updatedAt: null,
          completedAt: null,
        },
      },
    },
  };
}

function generateFakeUnits(projectId: string, buildingId: number): Unit[] {
  const FakeData: Unit[] = [];

  for (let i = 0; i < mockMeshIds.length; i += 1) {
    FakeData.push(
      getFakeUnit(`unitId_${i}`, mockMeshIds[i], projectId, buildingId)
    );
  }

  return FakeData;
}

function getFakeBuilding(projectId: string): Building {
  // const modelPath =
  //   'https://firebasestorage.googleapis.com/v0/b/urbanmix-dev.appspot.com/o/projects%2F5bf16a9b-c56c-4f1b-84e2-c62ea03dcb85%2F1%2Fmodel%2F2023-12-30-19-59-42363607_green_house.glb?alt=media&token=a53ee26f-0d33-405e-b493-40c86fd4d183';
  const modelPath =
    'https://firebasestorage.googleapis.com/v0/b/urbanmix-bruno-app.appspot.com/o/projects%2F004bb518-66fc-4a38-a102-6e82ff4e6a1b%2F1%2Fmodel%2F2023-12-27-09-47-78390930_green_house%20(1).glb?alt=media&token=20b30d34-72b4-4aba-891c-fffecbbdde56';

  generateFakeUnits(projectId, 1);

  return {
    id: 1,
    name: faker.commerce.productName(),
    type: 'Residential',
    yearBuilt: faker.date.anytime({ refDate: 'Date' }).getFullYear(),
    coverImagePath: null,
    modelPath,
    '3dData': {
      cameraConf: {
        target: [0, 0, 0],
        distance: 0,
        polarAngle: 0,
        azimuthAngle: 0,
      },
    },
    unitSummary: {
      unitCnt: {
        [LeaseLifecycle.NOTICE]: {},
        [LeaseLifecycle.INSPECT]: {},
        [LeaseLifecycle.TURNOVER]: {},
        [LeaseLifecycle.LEASE]: {},
        [LeaseLifecycle.DONE]: {},
      },
      affordableRent: {
        sumOfAffordableRent: 0,
        sumOfSQFT: 0,
        units: 0,
      },
      marketRent: {
        sumOfMarketRent: 0,
        sumOfSQFT: 0,
        units: 0,
      },
      summariesByType: {},
    },
    floors: 10,
  };
}

function getFakeProject(): Project {
  const projectId = uuidv4();
  return {
    id: projectId,
    name: faker.commerce.productName(),
    clientName: 'Client Name',
    address: faker.location.streetAddress(),
    coverImagePath: null,
    buildings: [getFakeBuilding(projectId)],
  };
}

export function generateFakeProjects(count: number): Project[] {
  const FakeData: Project[] = [];

  for (let i = 0; i < count; i += 1) {
    FakeData.push(getFakeProject());
  }

  return FakeData;
}

export const fakeProjects = generateFakeProjects(10);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJSONUnit(data: any): Unit {
  return {
    ...data,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    updatedAt: new Date(data.updatedAt as string),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    contractSignedDate: new Date(data.contractSignedDate as string),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    leaseStartDate: new Date(data.leaseStartDate as string),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    actualMoveInDate: new Date(data.actualMoveInDate as string),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    leaseEndDate: new Date(data.leaseEndDate as string),
  } as Unit;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJSONBuilding(data: any): Building {
  return {
    ...data,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    units: data.units.map((unit: any) => parseJSONUnit(unit)),
  } as Building;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJSONProject(data: any): Project {
  return {
    ...data,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    buildings: data.buildings.map((building: any) =>
      parseJSONBuilding(building)
    ),
  } as Project;
}
