import { ActionItem, UserData } from '@urbanmix-tech/shared-js';

import { useEffect, useState } from 'react';
// import { getUserDataById } from '@urbanmix-tech/shared-js/data';
import { GeneralModalContent } from './GeneralModalContent/GeneralModalContent';
import { ActionItemModal } from './ActionItemModal/ActionItemModal';

export type UnitGeneralModalContentProps = {
  actionItem: ActionItem;
  onChangeModalOpen: (val: boolean) => void;
  onSkipAction: () => void;
  onApproveAction: (payload?: unknown) => Promise<void>;
};

export function UnitGeneralModalContent({
  actionItem,
  onChangeModalOpen,
  onSkipAction,
  onApproveAction,
}: UnitGeneralModalContentProps) {
  const [owner, setOwner] = useState<UserData | undefined>(undefined);
  const [supervisor, setSupervisor] = useState<UserData | undefined>(undefined);
  // const [followers, setFollowers] = useState<UserData[] | undefined>(undefined);

  const handleSkip = () => {
    onSkipAction();
    onChangeModalOpen(false);
  };

  const handleApprove = () => {
    onApproveAction();
    onChangeModalOpen(false);
  };

  if (actionItem.category !== 'general') {
    return null;
  }

  return (
    <ActionItemModal
      actionItem={actionItem}
      onChangeModalOpen={onChangeModalOpen}
    />
  );
}
