import { FC } from 'react';

const AnimationLogo: FC = () => {
  return (
    <div className="h-[80px] w-[80px] [perspective:1000px]">
      <div className="animation-rotate-logo relative h-full w-full">
        <div className="absolute inset-0">
          <img
            src="/icon-loader.svg"
            alt="Loaging"
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default AnimationLogo;
