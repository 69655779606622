import {
  useCallback,
  useState,
  useMemo,
  useRef,
  SyntheticEvent,
  useEffect,
  MouseEvent,
  Suspense,
} from 'react';
import {
  Stack,
  Typography,
  Tab,
  Tabs,
  Menu,
  PopoverPosition,
  Button,
  Modal,
  Paper,
  Chip,
} from '@mui/material';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls, Sphere } from '@react-three/drei';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { parse } from 'csv-parse/sync';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from 'dayjs';

import { convertPseudoDateToRealDate, isFile } from '@/helpers/common';

import { getFakeUnit } from '@/shared/data/firebase/fakeProjectData';
import { reportAppError } from '@/shared/monitoring';
import {
  Building,
  Unit,
  LeaseLifecycle,
  SubStateType,
} from '@urbanmix-tech/shared-js';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { useLoading, useUIContext } from '@/shared/context/UIContextProvider';

import { FileUploadButton } from '@/components/shared/FileUploads/FileUploadButton';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ErrorBoundary } from '@sentry/react';
import { D3Model } from '../D3Model/D3Model';
import ModelParameters from './ModelParamers';
import { IdAutocomplete } from './IdAutocomplete';
import { UnitTable } from './UnitTable';
import { Loader } from '../Loader/Loader';
import { CsvHeaders, unitKeys } from './CsvHeaders';

import { materials } from '../D3Model/materials';

function useCustomLoader(url?: string | null): GLTF | null {
  const [object, setObject] = useState<GLTF | null>(null);

  useEffect(() => {
    if (url) {
      const loader = new GLTFLoader();

      loader.load(url, (gltf) => {
        setObject(gltf);
      });
    }
  }, [url]);

  return object;
}

export type Parameters = {
  file: string | File;
  target: [number, number, number];
  distance: number;
  polarAngle: number;
  azimuthAngle: number;
  units: Unit[];
};

type BuildingModelEditProps = {
  projectId: string;
  building: Building;
  units: Unit[];
  onChangeParameters(parameters: Parameters): void;
};

export default function BuildingModelEdit({
  projectId,
  building,
  units,
  onChangeParameters,
}: BuildingModelEditProps) {
  const { showAlert } = useAlert();
  const { createLoading } = useLoading();
  const { openConfirm } = useUIContext();

  const object = useCustomLoader(building.modelPath);

  const [tabValue, setTabValue] = useState<'camera' | 'unit' | 'bulk'>(
    'camera'
  );
  const [initialized, setInitialized] = useState<boolean>(false);
  const [hoveredMeshId, setHoveredMeshId] = useState<string | null>(null);
  const [menuParameters, setMenuParameters] = useState<{
    position: PopoverPosition;
    meshId: string;
  } | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [headersMap, setHeadersMap] = useState<Record<string, string>>(
    Object.fromEntries(unitKeys.map((v) => [v, v]))
  );
  const [isValidCSVHeader, setIsValidCSVHeader] = useState(false);
  const [isNewUnitMixUploaded, setIsNewUnitMixUploaded] = useState(false);

  const [parameters, setParameters] = useState<
    Omit<Parameters, 'file'> & { file: string | File | null }
  >({
    file: building.modelPath || null,
    distance: building['3dData'].cameraConf.distance,
    polarAngle: building['3dData'].cameraConf.polarAngle,
    azimuthAngle: building['3dData'].cameraConf.azimuthAngle || 0,
    target: building['3dData'].cameraConf.target,
    units,
  });

  const orbitRef = useRef<OrbitControlsImpl>(null);
  const meshIdsRef = useRef<string[]>([]);
  const meshIdsMapRef = useRef<Record<string, string | null | undefined>>({});
  const unitIdsMapRef = useRef<Record<string, string | null | undefined>>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const modelUrl = useMemo(() => {
    if (!parameters.file) {
      return null;
    }

    if (isFile(parameters.file)) {
      return URL.createObjectURL(parameters.file);
    }

    return parameters.file;
  }, [parameters.file]);

  useEffect(() => {
    const tempMeshIdsMap: Record<string, string | null> = {};
    const tempUnitIdsMap: Record<string, string | null> = {};

    units.forEach((unit) => {
      tempUnitIdsMap[unit.id] = unit.meshId;

      if (unit.meshId) {
        tempMeshIdsMap[unit.meshId] = unit.id;
      }
    });

    meshIdsMapRef.current = tempMeshIdsMap;
    unitIdsMapRef.current = tempUnitIdsMap;
  }, [building, units]);

  const alignModel = useCallback(
    (cameraConfig: {
      target: [number, number, number];
      distance: number;
      polarAngle: number;
      azimuthAngle: number;
    }) => {
      if (orbitRef.current) {
        const cameraPosition = orbitRef.current.object.position;

        const target = {
          x: cameraConfig.target[0],
          y: cameraConfig.target[1],
          z: cameraConfig.target[2],
        };

        const deltaX = cameraPosition.x - target.x;
        const deltaY = cameraPosition.y - target.y;
        const deltaZ = cameraPosition.z - target.z;

        const d = cameraConfig.distance;
        const distance = Math.sqrt(
          deltaX * deltaX + deltaY * deltaY + deltaZ * deltaZ
        );

        const newPosition = {
          x: target.x + (d / distance) * deltaX,
          y: target.y + (d / distance) * deltaY,
          z: target.z + (d / distance) * deltaZ,
        };

        orbitRef.current.object.position.set(
          newPosition.x,
          newPosition.y,
          newPosition.z
        );
        orbitRef.current.setPolarAngle(cameraConfig.polarAngle);
        orbitRef.current.setAzimuthalAngle(cameraConfig.azimuthAngle || 0);

        setTimeout(() => {
          setInitialized(true);
        }, 2000);
      } else {
        setTimeout(() => alignModel(cameraConfig), 0);
      }
    },
    []
  );

  useEffect(() => {
    if (!building.modelPath) {
      showAlert('Not exists building model', 'error');
    }
  }, [building.modelPath, showAlert]);

  useEffect(() => {
    if (object) {
      meshIdsRef.current = [];
      object.scene.traverse((o) => meshIdsRef.current.push(o.name));
    }
  }, [object]);

  useEffect(() => {
    alignModel(building['3dData'].cameraConf);
  }, [alignModel, building, initialized]);

  const handleChangeTarget = useCallback(
    (target: [number, number, number]) => {
      if (initialized) {
        setParameters((_prev) => ({
          ..._prev,
          target,
        }));
      }
    },
    [initialized]
  );

  const handleChangeFile = useCallback(
    (file: File) =>
      setParameters((_prev) => ({
        ..._prev,
        file,
      })),
    []
  );

  const handleChangeOrbit = useCallback(
    (controls: OrbitControlsImpl) => {
      if (!initialized) {
        return;
      }

      if (tabValue !== 'camera') {
        return;
      }

      const polarAngle = controls.getPolarAngle();
      const azimuthAngle = controls.getAzimuthalAngle();
      const tempTarget = controls.target;
      const distance = controls.getDistance();

      setParameters((prev) => ({
        ...prev,
        distance,
        polarAngle,
        azimuthAngle,
        target: [tempTarget.x, tempTarget.y, tempTarget.z],
      }));
    },
    [initialized, tabValue]
  );

  const handleBulkUnitInserting = useCallback(
    (rawRecords: string[][]) => {
      const newUnits: Unit[] = [];

      if (rawRecords.length > 0) {
        const header = rawRecords[0];
        const propertyMap: Record<number, string> = {};

        header.forEach((item, index) => {
          Object.entries(headersMap).forEach(([key, value]) => {
            if (item.trim() === value.trim() && value.trim() !== '') {
              propertyMap[index] = key;
            }
          });
        });

        const bulkData = rawRecords.slice(1).map((item) => {
          const data: Record<string, string> = {};

          item.forEach((cell, index) => {
            if (cell.trim() !== '') {
              data[propertyMap[index]] = cell.trim();
            }
          });

          return data;
        });

        bulkData.forEach((data) => {
          const exist = parameters.units.find((unit) => unit.id === data.id);

          const unit = exist || getFakeUnit(data.id, null, projectId, 1);

          const newUnit: Unit = {
            ...unit,
            projectId: data.projectId ? data.projectId : unit.projectId,
            buildingId: data.buildingId ? +data.buildingId : unit.buildingId,
            name: data.name ? data.name : unit.name,
            line: data.line ? data.line : unit.line,
            type: data.type ? data.type : unit.type,
            floor: data.floor ? +data.floor : unit.floor,
            floorPlanPath: data.floorPlanPath
              ? data.floorPlanPath
              : unit.floorPlanPath,
            coverImagePath: data.coverImagePath
              ? data.coverImagePath
              : unit.coverImagePath,
            modelPath: data.modelPath ? data.modelPath : unit.modelPath,
            '3dScanPath': data['3dScanPath']
              ? data['3dScanPath']
              : unit['3dScanPath'],
            currentState: data.currentState
              ? (data.currentState
                  .replace(' ', '')
                  .split(',') as LeaseLifecycle[])
              : unit.currentState,
            currentSubState: data.currentSubState
              ? (data.currentSubState as SubStateType)
              : unit.currentSubState,
            meshId: data.meshId ? data.meshId : unit.meshId,
            numBedrooms: data.numBedrooms
              ? +data.numBedrooms
              : unit.numBedrooms,
            numBathrooms: data.numBathrooms
              ? +data.numBathrooms
              : unit.numBathrooms,
            sqft: data.sqft ? +data.sqft : unit.sqft,
            affordable: data.affordable
              ? data.affordable !== 'Market'
              : unit.affordable,
            marketRent: data.marketRent
              ? +data.marketRent.replace(/[, $]/g, '')
              : unit.marketRent,

            contractSignedDate: data.contractSignedDate
              ? new Date(data.contractSignedDate)
              : unit.contractSignedDate,
            leaseStartDate: data.leaseStartDate
              ? new Date(data.leaseStartDate)
              : unit.leaseStartDate,
            scheduledMoveInDate: data.scheduledMoveInDate
              ? new Date(data.scheduledMoveInDate)
              : unit.scheduledMoveInDate,
            actualMoveInDate: data.actualMoveInDate
              ? new Date(data.actualMoveInDate)
              : unit.actualMoveInDate,

            actualRent: data.actualRent
              ? +data.actualRent.replace(/[, $]/g, '')
              : unit.actualRent,
            residentDeposit: data.residentDeposit
              ? +data.residentDeposit.replace(/[, $]/g, '')
              : unit.residentDeposit,
            recurringCharges: data.recurringCharges
              ? +data.recurringCharges
              : unit.recurringCharges,

            leaseEndDate: data.leaseEndDate
              ? new Date(data.leaseEndDate)
              : unit.leaseEndDate,
            scheduledMoveOutDate: data.scheduledMoveOutDate
              ? new Date(data.scheduledMoveOutDate)
              : unit.scheduledMoveOutDate,
            actualMoveOutDate: data.actualMoveOutDate
              ? new Date(data.actualMoveOutDate)
              : unit.actualMoveOutDate,

            turnoverGCId: data.turnoverGCId
              ? data.turnoverGCId
              : unit.turnoverGCId,
            turnoverSignOffDate: data.turnoverSignOffDate
              ? new Date(data.turnoverSignOffDate)
              : unit.turnoverSignOffDate,
            scheduledTurnoverStartDate: data.scheduledTurnoverStartDate
              ? new Date(data.scheduledTurnoverStartDate)
              : unit.scheduledTurnoverStartDate,
            actualTurnoverStartDate: data.actualTurnoverStartDate
              ? new Date(data.actualTurnoverStartDate)
              : unit.actualTurnoverStartDate,
            scheduledTurnoverCompletionDate:
              data.scheduledTurnoverCompletionDate
                ? new Date(data.scheduledTurnoverCompletionDate)
                : unit.scheduledTurnoverCompletionDate,
            actualTurnoverCompletionDate: data.actualTurnoverCompletionDate
              ? new Date(data.actualTurnoverCompletionDate)
              : unit.actualTurnoverCompletionDate,
            turnoverCost: data.turnoverCost
              ? +data.turnoverCost.replace(/[, $]/g, '')
              : unit.turnoverCost,
            turnoverPaymentDate: data.turnoverPaymentDate
              ? new Date(data.turnoverPaymentDate)
              : unit.turnoverPaymentDate,

            leasePublishDate: data.leasePublishDate
              ? new Date(data.leasePublishDate)
              : unit.leasePublishDate,
            leaseOfficeId: data.leaseOfficeId
              ? data.leaseOfficeId
              : unit.leaseOfficeId,
          };

          if (newUnit.meshId) {
            unitIdsMapRef.current[newUnit.id] = newUnit.meshId;
          }

          newUnits.push(newUnit);
        });
      }

      setParameters((prev) => ({
        ...prev,
        units: [
          ...newUnits,
          ...prev.units.filter(
            (unit) => !newUnits.find((item) => item.id === unit.id)
          ),
        ],
      }));

      setIsNewUnitMixUploaded(true);
    },
    [headersMap, parameters.units, projectId]
  );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleBulkInsertFromText = () => {
    const csvText = textareaRef.current?.value || '';

    try {
      const rawRecords = parse(csvText) as string[][];

      handleBulkUnitInserting(rawRecords);

      handleCloseModal();
    } catch (err) {
      reportAppError(err as Error);
      showAlert('Failed at parsing csv file', 'error');
    }
  };

  const handleSelectCsvFile = useCallback(
    (file: File) => {
      const reader = new FileReader();

      reader.readAsText(file);

      const loader = createLoading();

      loader.startLoading({
        title: 'Reading CSV File...',
      });

      reader.onload = () => {
        loader.stopLoading();

        try {
          const rawRecords = parse(reader.result as string) as string[][];

          handleBulkUnitInserting(rawRecords);
        } catch (err) {
          reportAppError(err as Error);
          showAlert('Failed at parsing csv file', 'error');
        }
      };

      reader.onerror = () => {
        if (reader.error) {
          reportAppError(reader.error);
        }
      };
    },
    [createLoading, handleBulkUnitInserting, showAlert]
  );

  const handleSave = () => {
    if (!parameters.file) {
      showAlert('Idealy save with model next time', 'error');
    }

    onChangeParameters(parameters as Parameters);

    setIsNewUnitMixUploaded(false);
    setIsValidCSVHeader(false);
  };

  const handleInit = () => {
    setParameters({
      file: building.modelPath || null,
      distance: building['3dData'].cameraConf.distance,
      polarAngle: building['3dData'].cameraConf.polarAngle,
      azimuthAngle: building['3dData'].cameraConf.azimuthAngle,
      target: building['3dData'].cameraConf.target,
      units,
    });
    setIsNewUnitMixUploaded(false);
    setIsValidCSVHeader(false);
  };

  const handleInitUnit = () => {
    setParameters((prev) => ({
      ...prev,
      units,
    }));
    setIsNewUnitMixUploaded(false);
  };

  const handleChangeTab = useCallback(
    (_event: SyntheticEvent, newValue: 'camera' | 'unit') => {
      setTabValue(newValue);
      setInitialized(false);
      setIsValidCSVHeader(false);

      setTimeout(() => {
        alignModel({
          target: parameters.target,
          distance: parameters.distance,
          polarAngle: parameters.polarAngle,
          azimuthAngle: parameters.azimuthAngle,
        });
      }, 500);
    },
    [
      alignModel,
      parameters.azimuthAngle,
      parameters.distance,
      parameters.polarAngle,
      parameters.target,
    ]
  );

  const handleContextMenu =
    (meshId: string | null) => (event: MouseEvent<HTMLDivElement>) => {
      if (!meshId) {
        return;
      }

      setMenuParameters({
        position: {
          top: event.clientY,
          left: event.clientX,
        },
        meshId,
      });
    };

  const handleCloseMenu = () => {
    setMenuParameters(null);
  };

  const handleChangeUnitId = (
    unitId: string,
    newUnitId: string,
    failCb?: () => void
  ) => {
    const exists = parameters.units.find((unit) => unit.id === newUnitId);

    if (exists) {
      showAlert('There is same unit Id!', 'error');

      failCb?.();
      return;
    }

    const meshId = unitIdsMapRef.current[unitId];

    unitIdsMapRef.current[newUnitId] = meshId;

    if (meshId) {
      meshIdsMapRef.current[meshId] = newUnitId;
    }

    delete unitIdsMapRef.current[unitId];

    const updatedUnits = parameters.units.map((unit) => {
      if (unit.id === unitId) {
        return {
          ...unit,
          id: newUnitId,
        };
      }

      return unit;
    });

    setParameters({
      ...parameters,
      units: updatedUnits,
    });
  };

  const handleChangeUnitName = (unitId: string, newName: string | null) => {
    const updatedUnits = parameters.units.map((unit) => {
      if (unit.id === unitId) {
        return {
          ...unit,
          name: newName,
        };
      }

      return unit;
    });

    setParameters({
      ...parameters,
      units: updatedUnits,
    });
  };

  const handleChangeMeshId = (unitId: string, changedMeshId: string | null) => {
    setHoveredMeshId(changedMeshId);

    const associatedUnitId = changedMeshId
      ? meshIdsMapRef.current[changedMeshId]
      : null;
    const associatedMeshId = associatedUnitId
      ? unitIdsMapRef.current[associatedUnitId]
      : null;

    const changeUnit = () => {
      unitIdsMapRef.current[unitId] = changedMeshId;

      if (associatedUnitId) {
        unitIdsMapRef.current[associatedUnitId] = null;
      }

      if (changedMeshId) {
        meshIdsMapRef.current[changedMeshId] = unitId;
      }

      if (associatedMeshId) {
        meshIdsMapRef.current[associatedMeshId] = null;
      }

      const updatedUnits = parameters.units.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            meshId: changedMeshId,
          };
        }

        if (associatedUnitId && associatedUnitId !== unitId) {
          return {
            ...unit,
            meshId: null,
          };
        }

        return unit;
      });

      setParameters({
        ...parameters,
        units: updatedUnits,
      });
    };

    if (associatedUnitId && associatedUnitId !== unitId) {
      const handleAction = (value: boolean) => {
        if (value === true) {
          changeUnit();
        }
      };

      openConfirm({
        title: 'Change',
        description: `${changedMeshId} used in Unit(${associatedUnitId}), Are you sure to change?`,
        onAction: handleAction,
      });
    }
  };

  const handleDownloadUnitMix = () => {
    const rows: string[][] = [unitKeys];

    parameters.units.forEach((unit) => {
      const newRow: string[] = [];
      const dateKeys = [
        'contractSignedDate',
        'leaseStartDate',
        'scheduledMoveInDate',
        'actualMoveInDate',
        'leaseEndDate',
        'scheduledMoveOutDate',
        'actualMoveOutDate',
        'turnoverSignOffDate',
        'scheduledTurnoverStartDate',
        'actualTurnoverStartDate',
        'scheduledTurnoverCompletionDate',
        'actualTurnoverCompletionDate',
        'turnoverPaymentDate',
        'leasePublishDate',
      ];

      unitKeys.forEach((key) => {
        if (
          unit[key as keyof typeof unit] !== undefined &&
          unit[key as keyof typeof unit] !== null
        ) {
          if (key === 'affordable') {
            newRow.push(unit.affordable ? 'Rent Stabilized' : 'Market');
          } else if (key === 'currentState') {
            if (Array.isArray(unit.currentState)) {
              newRow.push(`"${unit.currentState.join(',')}"`);
            } else {
              newRow.push(unit.currentState);
            }
          } else if (dateKeys.includes(key)) {
            newRow.push(
              dayjs(
                convertPseudoDateToRealDate(
                  unit[key as keyof typeof unit] as Date
                )
              ).format('MM/DD/YYYY')
            );
          } else {
            newRow.push(`${unit[key as keyof typeof unit] as string | number}`);
          }
        } else {
          newRow.push('');
        }
      });

      rows.push(newRow);
    });

    const csvContent = rows.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'table_data.csv');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleAssignUnitId = (unitId: string | null) => {
    if (!menuParameters) {
      return;
    }

    const associatedUnitId = meshIdsMapRef.current[menuParameters.meshId];
    const associatedMeshId = unitId ? unitIdsMapRef.current[unitId] : null;

    const assignUnitId = () => {
      if (unitId) {
        unitIdsMapRef.current[unitId] = menuParameters.meshId;
      }

      if (associatedUnitId) {
        unitIdsMapRef.current[associatedUnitId] = null;
      }

      if (associatedMeshId) {
        meshIdsMapRef.current[associatedMeshId] = null;
      }

      meshIdsMapRef.current[menuParameters.meshId] = unitId;

      const updatedUnits = parameters.units.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            meshId: menuParameters.meshId,
          };
        }

        if (associatedUnitId && unit.id === associatedUnitId) {
          return {
            ...unit,
            meshId: null,
          };
        }

        return unit;
      });

      setParameters({
        ...parameters,
        units: updatedUnits,
      });

      handleCloseMenu();
    };

    if (associatedMeshId && associatedMeshId !== menuParameters.meshId) {
      const handleAction = (value: boolean) => {
        if (value === true) {
          assignUnitId();
        }
      };

      openConfirm({
        title: 'Assign',
        description: `${unitId} associated with Mesh(${associatedMeshId}), Are you sure to change?`,
        onAction: handleAction,
      });
    }
  };

  const handleDelete = (unitId: string) => {
    setParameters((prev) => ({
      ...prev,
      units: prev.units.filter((unit) => unit.id !== unitId),
    }));
  };

  const handleAddMore = () => {
    const newUnit = getFakeUnit('new_unit_id', null, projectId, 1);

    unitIdsMapRef.current[newUnit.id] = newUnit.meshId;

    setParameters((prev) => ({
      ...prev,
      units: [newUnit, ...prev.units.filter((unit) => unit.id !== newUnit.id)],
    }));
  };

  const handleClickFromText = () => {
    setOpenModal(true);
  };

  const handleChangeCeil = (
    unitId: string,
    column: 'unitId' | 'unitName' | 'meshId',
    newValue: string | null,
    failCb?: () => void
  ) => {
    switch (column) {
      case 'unitId': {
        if (!newValue) {
          failCb?.();
          return;
        }

        handleChangeUnitId(unitId, newValue, failCb);
        break;
      }

      case 'unitName': {
        handleChangeUnitName(unitId, newValue);
        break;
      }

      case 'meshId': {
        handleChangeMeshId(unitId, newValue);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      alignItems="flex-start"
      className="h-[600px] w-full px-[100px]"
      gap="30px"
    >
      <Stack gap="20px" className="rounded-2xl bg-white/5 p-24">
        <Stack
          className="min-w-[600px] border-b border-[#333]"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab
              label="Camera Setting"
              value="camera"
              className="text-stone-200"
              disabled={!initialized && tabValue === 'camera'}
            />
            <Tab
              label="Unit Names"
              value="unit"
              className="text-stone-200"
              disabled={!initialized && tabValue === 'camera'}
            />
            <Tab
              label="Bulk Inserting"
              value="bulk"
              className="text-stone-200"
              disabled={!initialized && tabValue === 'camera'}
            />
          </Tabs>

          <Button
            variant="contained"
            endIcon={<FileDownloadIcon />}
            onClick={handleDownloadUnitMix}
          >
            UnitMix to CSV
          </Button>
        </Stack>

        {tabValue === 'camera' ? (
          <ModelParameters
            file={parameters.file}
            target={parameters.target}
            distance={parameters.distance}
            polarAngle={parameters.polarAngle}
            azimuthAngle={parameters.azimuthAngle}
            onChangeTarget={handleChangeTarget}
            onChangeFile={handleChangeFile}
          />
        ) : null}
        {tabValue === 'unit' ? (
          <Stack gap="24px">
            <UnitTable
              units={parameters.units}
              meshIds={meshIdsRef.current}
              onChangeCeil={handleChangeCeil}
              onDelete={handleDelete}
              onAddMore={handleAddMore}
            />
          </Stack>
        ) : null}
        {tabValue === 'bulk' ? (
          <Stack gap="24px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="caption" className="text-white">
                Bulk inserting of units
              </Typography>
              {isNewUnitMixUploaded && (
                <Chip
                  label="New UnitMix is Uploaded"
                  onDelete={handleInitUnit}
                  className="!bg-rose-400/30 text-stone-200"
                />
              )}
            </Stack>

            <Stack direction="row" gap="24px" alignItems="center">
              <FileUploadButton
                accept=".csv"
                label="From File"
                onSelect={handleSelectCsvFile}
                disabled={!isValidCSVHeader || isNewUnitMixUploaded}
              />
              <Button
                variant="contained"
                onClick={handleClickFromText}
                color="primary"
                disabled={!isValidCSVHeader || isNewUnitMixUploaded}
                fullWidth
              >
                From Text
              </Button>
            </Stack>

            <CsvHeaders
              headersMap={headersMap}
              onChange={setHeadersMap}
              validHeader={isValidCSVHeader}
              onChangeValidHeader={setIsValidCSVHeader}
            />
          </Stack>
        ) : null}

        <ConfirmButtons
          confirm={{
            label: 'Save',
            onClick: handleSave,
          }}
          cancel={{
            label: 'Cancel',
            onClick: handleInit,
          }}
        />
      </Stack>

      <ErrorBoundary
        fallback={
          <Typography variant="h1" className="text-red-500">
            Error loading model
          </Typography>
        }
      >
        {modelUrl ? (
          <Suspense fallback={<Loader title="Rendering 3D..." center />}>
            {tabValue === 'camera' ? (
              <Canvas
                style={{ visibility: initialized ? 'visible' : 'hidden' }}
              >
                <D3Model url={modelUrl} mode="standard" />
                <Sphere
                  args={[1, 16, 16, 0, Math.PI * 2, 0, Math.PI * 2]}
                  position={parameters.target}
                  material={materials.redColor}
                />

                <OrbitControls
                  target={parameters.target}
                  ref={orbitRef}
                  onChange={(e) => {
                    if (e) {
                      handleChangeOrbit(
                        e.target as unknown as OrbitControlsImpl
                      );
                    }
                  }}
                />
                <Environment preset="sunset" />
              </Canvas>
            ) : (
              <Canvas key={2} onClick={handleContextMenu(hoveredMeshId)}>
                <D3Model
                  url={modelUrl}
                  hoveredMeshId={hoveredMeshId}
                  setHoveredMeshId={setHoveredMeshId}
                  mode="standard"
                />
                <Sphere
                  args={[1, 16, 16, 0, Math.PI * 2, 0, Math.PI * 2]}
                  position={parameters.target}
                  material={materials.redColor}
                />

                <OrbitControls
                  target={parameters.target}
                  minDistance={parameters.distance}
                  maxDistance={parameters.distance}
                  enablePan={false}
                  minPolarAngle={parameters.polarAngle}
                  maxPolarAngle={parameters.polarAngle}
                />
                <Environment preset="sunset" />
              </Canvas>
            )}
          </Suspense>
        ) : (
          <Typography variant="h1" className="text-red-500">
            There is no Model. Please upload a new model
          </Typography>
        )}
      </ErrorBoundary>

      <Menu
        open={Boolean(menuParameters)}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={menuParameters?.position}
        slotProps={{
          paper: {
            className: 'bg-black',
          },
        }}
      >
        <IdAutocomplete
          id={
            menuParameters
              ? meshIdsMapRef.current[menuParameters.meshId] || null
              : null
          }
          ids={Object.keys(unitIdsMapRef.current)}
          onSave={handleAssignUnitId}
          label="Unit Id"
        />
      </Menu>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Paper className="fixed left-1/2 top-1/2 w-[400px] -translate-x-1/2 -translate-y-1/2 bg-gray-950 p-[16px]">
          <textarea
            ref={textareaRef}
            className="w-full bg-transparent text-white/80"
            rows={5}
            placeholder="Paste csv..."
          />

          <Button
            variant="contained"
            onClick={handleBulkInsertFromText}
            color="secondary"
            className=" bg-[rgba(245,40,169,0.60)]"
            fullWidth
          >
            Save
          </Button>
        </Paper>
      </Modal>
    </Stack>
  );
}
