'use client';

import { useRef, useState, DragEventHandler, ChangeEventHandler } from 'react';
import { Stack, Typography } from '@mui/material';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

import { useAlert } from '@/contexts/Alert/AlertContext';

export type FileUploadProps = {
  onChangeFile(file: File): void;
};

export function FileUpload({ onChangeFile }: FileUploadProps) {
  const { showAlert } = useAlert();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : null;

    if (file) {
      setSelectedFile(file);
      onChangeFile(file);
    } else {
      showAlert('Please select a valid file.', 'info');
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    if (file) {
      setSelectedFile(file);
      onChangeFile(file);
    } else {
      showAlert('Please drop a valid file.', 'info');
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div
      className="cursor-pointer rounded-md border border-dashed border-gray-400 px-6 py-11"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        accept="*"
        className="hidden"
        onChange={handleFileChange}
      />

      <Stack
        className="cursor-pointer"
        gap="16px"
        alignItems="center"
        onClick={handleClick}
      >
        <BackupOutlinedIcon width={40} height={40} className="text-gray-400" />

        {selectedFile ? (
          <Typography variant="caption" className="text-gray-400">
            {selectedFile.name}
          </Typography>
        ) : (
          <>
            <Typography variant="caption2" className="text-gray-400">
              Select Files to Upload
            </Typography>
            <Typography variant="caption2" className="text-gray-400">
              or Drag and drop, Copy and Paste Files
            </Typography>
          </>
        )}
      </Stack>
    </div>
  );
}
