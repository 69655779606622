import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Card } from '@mui/material';

import { ActionItem } from '@urbanmix-tech/shared-js';

import {
  ITableCell,
  ITableRow,
  VirtualizedTable,
} from '@/components/shared/Table';
import { convertPseudoDateToRealDate } from '@/helpers/common';
import { useProjects } from '@/shared/hooks/useProjects';
import { ProgressBar } from './ProgressBar';
import { tabList } from './TodoTabs';

type TodoTableProps = {
  actionItems: ActionItem[];
  tabKey: string;
  onSelectUnit(unitId: string | null): void;
  style?: React.CSSProperties;
};

export function TodoTable({
  actionItems,
  onSelectUnit,
  tabKey,
  style,
}: TodoTableProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(250);
  const projects = useProjects();

  useEffect(() => {
    if (ref.current) {
      const elem = ref.current;

      new ResizeObserver(() => {
        setHeight(elem.clientHeight);
      }).observe(elem);
    }
  }, []);

  const handleClickRow = useCallback(() => {
    // nop
  }, []);

  const columns: ITableCell[] = useMemo(
    () => [
      {
        label: 'Process',
      },
      {
        label: 'Action',
        sortable: true,
      },
      {
        label: 'Due Date',
        sortable: true,
      },
      {
        label: 'Project',
        sortable: true,
      },
      {
        label: 'Unit',
        sortable: true,
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const allRows = actionItems.map((item) => {
      const date = item.dueDate
        ? convertPseudoDateToRealDate(item.dueDate)
        : null;
      const projectName =
        projects.find((x) => x.id === item.projectId)?.name || '';
      const data = {
        Process: {
          label: item.category,
          children: (
            <div style={{ marginRight: '8px' }}>
              <ProgressBar
                category={item.category}
                value={Math.random() * 100}
              />
            </div>
          ),
          width: 60,
        },
        Action: {
          label: item.title,
          value: item.title,
          width: 250,
        },
        'Due Date': {
          label: date ? dayjs(date).format('MM.DD.YYYY') : '',
          value: date?.getTime(),
          width: 120,
        },
        Project: {
          label: projectName,
          value: projectName,
          width: 200,
        },
        Unit: {
          label: item.unitId,
          value: item.unitId,
          width: 50,
        },
      } as Record<string, ITableCell>;

      return {
        id: item.id,
        data,
      } as ITableRow;
    });

    const filteredItems = allRows.reduce<Record<string, ITableRow[]>>(
      (acc, item) => {
        const tabId = item.data.Process.label;
        acc[tabId] = [...(acc[tabId] ?? []), item];
        return acc;
      },
      {}
    );
    filteredItems[tabList[0].tabId] = allRows;
    return filteredItems;
  }, [actionItems, projects]);

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const handleHoverRow = useCallback((rowId: string | null) => {
    setHoveredRowId(rowId);
  }, []);

  useEffect(() => {
    const row = rows[tabKey]?.find((x) => x.id === hoveredRowId);
    const unitId = row?.data.Unit?.label ?? null;
    onSelectUnit(unitId);
  }, [hoveredRowId, onSelectUnit, rows, tabKey]);

  return (
    <Card
      variant="elevation"
      elevation={0}
      className="mt-8 h-full min-w-[318px] bg-white bg-opacity-5 p-16"
      style={{ width: '714px', ...style }}
      ref={ref}
    >
      <VirtualizedTable
        rows={rows[tabKey] ?? []}
        columns={columns}
        height={`${height - 32}px`}
        px={8}
        onClickRow={handleClickRow}
        hoveredRowId={hoveredRowId || undefined}
        onChangeHover={handleHoverRow}
      />
    </Card>
  );
}
