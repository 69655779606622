import { useRef, ChangeEventHandler } from 'react';
import { Button, FormControl, FormHelperText } from '@mui/material';

import { Picture } from '@/components/shared/Icons/Picture';

export type FileUploadButtonProps = {
  accept: string;
  onSelect: (file: File) => void;
  label?: string;
  helperText?: string;
  disabled?: boolean;
};

export function FileUploadButton({
  accept,
  label,
  onSelect,
  helperText,
  disabled,
}: FileUploadButtonProps) {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    fileInput?.current?.click();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      onSelect(e.target.files[0]);
      if (fileInput?.current?.value) {
        fileInput.current.value = '';
      }
    }
  };

  return (
    <FormControl className="flex w-full flex-col gap-2">
      <input
        ref={fileInput}
        hidden
        type="file"
        accept={accept}
        onChange={handleChange}
        disabled={disabled}
      />
      <Button
        variant={label ? 'outlined' : 'contained'}
        onClick={handleClick}
        color="primary"
        className="rounded px-5 py-3 text-base font-semibold leading-5 tracking-[.4px] text-white"
        disabled={disabled}
      >
        <Picture />
        <span className="max-w-[200px] overflow-clip truncate whitespace-nowrap ">
          {label}
        </span>
      </Button>
      {helperText ? (
        <FormHelperText className="text-[10px] text-red-600">
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
