import { useEffect, useState, useContext, useCallback, ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Stack, ButtonBase, Button } from '@mui/material';

import { AppContextProvider } from '@/shared/context/AppContextProvider';
import { useLoading, useUIContext } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { useAuth } from '@/shared/context/AuthProvider';

import {
  ActionItem,
  Inspection,
  Project,
  Unit,
  AssignInspectorPayload,
  ScheduleInspectionActionPayload,
  ReportInspectionPlanActionPayload,
  AssignTeamPayload,
  ScheduleMakeReadyActionPayload,
  ReportMakeReadyPlanActionPayload,
  ActionItemCategory,
  InspectionStatus,
  LeaseLifecycle,
  createUnitAction,
} from '@urbanmix-tech/shared-js';
import {
  getProjectById,
  getUnitById,
  getActionsByUnitId,
  saveActionItem,
  saveActionItemPayload,
  getInspectionsByUnitId,
  saveInspection,
  findDraftInspections,
  createNewInspection,
  findActionItemsByCategoryAndStatus,
  updateUnit,
} from '@urbanmix-tech/shared-js/data';

import { actionTitles } from '@/shared/constants';
import { getConditionReportItemTemplate } from '@/helpers/actions';
import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { TabListItem, Tabs } from '@/components/shared/Tabs/Tabs';

import { UnitD3Model } from '@/components/UnitD3Model/UnitD3Model';
// import { UnitHighlight } from '@/components/Highlights/UnitHighlight';

import { Breadcrumbs } from '@/components/shared/Breadcrumbs/Breadcrumbs';
import { UnitModal } from '@/components/ActionModals/units/UnitModal';

import { TodoTabContent } from '@/components/UnitTabs/Todo/TodoTabContent';
import { ReportsTabContent } from '@/components/UnitTabs/ReportsTabContent';

import { FilesTabContent } from '@/components/UnitTabs/FilesTabContent';
import { FloorPlanModal } from '@/components/UnitTabs/FloorPlanModal';
import { UnitMixEditModal } from '@/components/UnitTabs/UnitMixEditModal';

import { convertPseudoDateToRealDate } from '@/helpers/common';
import {
  FIRST_ACTION_TITLE_OF_CATEGORY,
  LAST_ACTION_TITLE_OF_CATEGORY,
  statusByCategory,
} from '@/components/Highlights/getLatestActionStats';
import { UnitDetails } from '@/components/Highlights/UnitDetails';
import { twMerge } from 'tailwind-merge';

function removeStatus(
  category: ActionItemCategory,
  currentState?: LeaseLifecycle[] | LeaseLifecycle
): LeaseLifecycle[] {
  let newCurrentStatus: LeaseLifecycle[] = [];

  if (Array.isArray(currentState)) {
    newCurrentStatus = [
      ...currentState.filter(
        (item) =>
          item !== statusByCategory[category as keyof typeof statusByCategory]
      ),
    ];
  } else {
    newCurrentStatus =
      currentState &&
      currentState !==
        statusByCategory[category as keyof typeof statusByCategory]
        ? [currentState]
        : [];
  }

  // remove any occupied status when start a new status
  newCurrentStatus = newCurrentStatus.filter(
    (item) => item !== LeaseLifecycle.DONE
  );

  return newCurrentStatus;
}

const tabList: TabListItem[] = [
  {
    tabId: 'todos',
    label: 'Unit Tasks',
    iconName: 'actions',
  },
  {
    tabId: 'reports',
    label: 'Reports',
    iconName: 'file',
  },
  {
    tabId: 'files',
    label: 'Files',
    iconName: 'file',
  },
];

export default function UnitPage() {
  const { projectId, buildingId, unitId, defaultTab } = useParams<{
    projectId: string;
    buildingId: string;
    unitId: string;
    defaultTab?: string;
  }>();

  const { setLastSelectedUnitPage } = useContext(AppContextProvider);
  const { selectedOption, userData, isEditMode } = useAuth();

  const { showAlert } = useAlert();
  const { createLoading } = useLoading();
  const { openConfirm } = useUIContext();

  const [project, setProject] = useState<Project | null>(null);
  const [unit, setUnit] = useState<Unit | null>(null);
  const [actions, setActions] = useState<ActionItem[]>([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);

  const [tabKey, setTabKey] = useState<string>(tabList[0].tabId);
  const [selectedActionId, setSelectedActionId] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUnitMixModalOpen, setIsUnitMixModalOpen] = useState(false);
  const [isFloorPlanModalOpen, setIsFloorPlanModalOpen] = useState(false);

  const handleUpdateUnit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (updateData: Record<string, any>) => {
      if (!projectId || !unitId) {
        return;
      }

      await updateUnit(projectId, unitId, updateData);

      setUnit((old) => {
        if (!old) {
          return null;
        }

        const newUnit: Record<string, unknown> = { ...old };
        Object.keys(updateData).forEach((key) => {
          newUnit[key] = updateData[key];
        });

        return newUnit as Unit;
      });
    },
    [projectId, unitId]
  );

  const handleSaveAction = useCallback(
    async (actionItem: ActionItem, payload?: unknown) => {
      if (!unit) {
        return;
      }

      await saveActionItem(actionItem);

      if (payload) {
        await saveActionItemPayload({
          id: actionItem.id,
          payload,
        });
      }

      setActions((prev) => {
        const exist = prev.find((item) => item.id === actionItem.id);

        if (exist) {
          return [
            ...prev.map((item) => {
              if (item.id === actionItem.id) {
                return actionItem;
              }

              return item;
            }),
          ];
        }

        return [actionItem, ...prev];
      });

      if (
        ([
          FIRST_ACTION_TITLE_OF_CATEGORY[actionItem.category],
          LAST_ACTION_TITLE_OF_CATEGORY[actionItem.category],
        ].includes(actionItem.title) &&
          actionItem.status === 'done') ||
        actionItem.status === 'ignored'
      ) {
        const currentStatus: LeaseLifecycle[] = removeStatus(
          actionItem.category,
          unit.currentState
        );

        if (
          actionItem.title ===
          FIRST_ACTION_TITLE_OF_CATEGORY[actionItem.category]
        ) {
          currentStatus.push(
            statusByCategory[
              actionItem.category as keyof typeof statusByCategory
            ]
          );
        }

        handleUpdateUnit({
          currentState: currentStatus,
        });
      }
    },
    [handleUpdateUnit, unit]
  );

  const handleSkipActions = useCallback(
    async (category: ActionItemCategory) => {
      if (!projectId || !buildingId || !unitId || !unit) {
        return;
      }

      const activeActions = await findActionItemsByCategoryAndStatus(
        projectId,
        +buildingId,
        unitId,
        category,
        ['active', 'future']
      );

      const promises = activeActions.map((data) =>
        handleSaveAction({
          ...data,
          status: 'ignored',
          updateDate: new Date(),
        })
      );

      await Promise.all(promises);
    },
    [buildingId, handleSaveAction, projectId, unit, unitId]
  );

  const handleSaveInspection = useCallback(
    async (inspection: Inspection): Promise<string | null> => {
      if (!projectId || !unitId) {
        return null;
      }
      let newId: string | null = null;

      if (inspection.id === '') {
        newId = await createNewInspection(projectId, unitId, inspection);
        if (!newId) {
          showAlert(
            'There is something wrong, please check your connectivity!',
            'error'
          );
          return null;
        }

        inspection.id = newId;
      } else {
        await saveInspection(projectId, unitId, inspection);
        newId = inspection.id;
      }

      setInspections((prev) => {
        const exist = prev.find((item) => item.id === inspection.id);

        if (exist) {
          return [
            ...prev.map((item) => {
              if (item.id === inspection.id) {
                return inspection;
              }
              return item;
            }),
          ];
        }
        return [inspection, ...prev];
      });

      return newId;
    },
    [projectId, showAlert, unitId]
  );

  const handleInspectionRequiredEvent = useCallback(
    async (isReinspection: boolean) => {
      if (!unit || !projectId || !buildingId || !unitId) {
        return;
      }

      try {
        const inspectionId = await handleSaveInspection({
          id: '',
          unitId,
          buildingId: +buildingId,
          scheduledDate: null,
          actualDate: null,
          inspectorId: null,
          inspectorName: null,
          createDate: new Date(),
          updateDate: new Date(),
          status: InspectionStatus.Draft,
          conditionReport: getConditionReportItemTemplate(
            unit.numBedrooms,
            unit.numBathrooms
          ),
          notes: '',
        });

        if (inspectionId !== null) {
          const createUnitActionByTitle = (title: string) => {
            return createUnitAction({
              projectId,
              buildingId: +buildingId,
              unitId,
              title,
              description: '',
              category: 'inspect',
              action_type: {
                inputType: 'text',
                reminder: false,
                type: 'approve',
              },
            });
          };

          const assignInspectorAction = createUnitActionByTitle(
            actionTitles.assignInspector
          );
          const scheduleInspectionAction = createUnitActionByTitle(
            actionTitles.scheduleInspection
          );
          const planInspectionAction = createUnitActionByTitle(
            actionTitles.planInspectionItems
          );

          const actionIds = [
            assignInspectorAction.id,
            scheduleInspectionAction.id,
            planInspectionAction.id,
          ];

          const assignInspectorPayload: AssignInspectorPayload = {
            relatedActionIds: actionIds,
            inspectionId,
            isReinspection,
          };

          const scheduleActionPayload: ScheduleInspectionActionPayload = {
            relatedActionIds: actionIds,
            inspectionId,
            isReinspection,
          };

          const planInspectionPayload: ReportInspectionPlanActionPayload = {
            relatedActionIds: actionIds,
            isReinspection,
            inspectionId,
            data: getConditionReportItemTemplate(
              unit.numBedrooms,
              unit.numBathrooms
            ),
          };

          if (Array.isArray(unit.currentState)) {
            handleUpdateUnit({
              currentState: [
                ...unit.currentState.filter(
                  (item) => item !== LeaseLifecycle.INSPECT
                ),
                LeaseLifecycle.INSPECT,
              ],
            });
          } else {
            handleUpdateUnit({
              currentState:
                unit.currentState &&
                unit.currentState !== LeaseLifecycle.INSPECT
                  ? [unit.currentState, LeaseLifecycle.INSPECT]
                  : [LeaseLifecycle.INSPECT],
            });
          }

          (
            [
              [assignInspectorAction, assignInspectorPayload],
              [scheduleInspectionAction, scheduleActionPayload],
              [planInspectionAction, planInspectionPayload],
            ] as [ActionItem, unknown][]
          ).forEach((data) => handleSaveAction(data[0], data[1]));
        } else {
          showAlert(
            'There is something wrong, please check your connectivity!',
            'error'
          );
        }
      } catch (error) {
        console.error('Error handling inspection event:', error);
        showAlert(
          'An error occurred while handling the inspection event',
          'error'
        );
      }
    },
    [
      buildingId,
      handleSaveAction,
      handleSaveInspection,
      handleUpdateUnit,
      projectId,
      showAlert,
      unit,
      unitId,
    ]
  );

  const handleMakeReadyRequiredEvent = useCallback(async () => {
    if (!projectId || !buildingId || !unitId || !unit) {
      return;
    }

    const activeActions = await findActionItemsByCategoryAndStatus(
      projectId,
      +buildingId,
      unitId,
      'makeReady',
      ['active', 'future']
    );

    if (activeActions.length > 0) {
      const handleAction = async (value: boolean) => {
        if (value) {
          const promises = activeActions.map((data) =>
            handleSaveAction({
              ...data,
              status: 'ignored',
              updateDate: new Date(),
            })
          );

          await Promise.all(promises);

          handleMakeReadyRequiredEvent();
        }
      };

      openConfirm({
        title: 'Confirm',
        description:
          'Make Ready actions are already started. Are you sure you want to override the previous actions and begin a new one?',
        onAction: handleAction,
      });

      return;
    }

    const createUnitActionByTitle = (title: string) => {
      return createUnitAction({
        projectId,
        buildingId: +buildingId,
        unitId,
        title,
        description: '',
        category: 'makeReady',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      });
    };

    const assignTeamAction = createUnitActionByTitle(actionTitles.assignTeam);
    const scheduleMakeReadyAction = createUnitActionByTitle(
      actionTitles.scheduleMakeReady
    );
    const generateMakeReadyPlanAction = createUnitActionByTitle(
      actionTitles.generateMakeReadyPlan
    );

    const actionIds = [
      assignTeamAction.id,
      scheduleMakeReadyAction.id,
      generateMakeReadyPlanAction.id,
    ];

    const assignTeamPayload: AssignTeamPayload = {
      relatedActionIds: actionIds,
      teamId: null,
    };

    const scheduleMakeReadyActionPayload: ScheduleMakeReadyActionPayload = {
      relatedActionIds: actionIds,
      scheduledDate: null,
    };

    const generateMakeReadyPlanPayload: ReportMakeReadyPlanActionPayload = {
      relatedActionIds: actionIds,
      data: getConditionReportItemTemplate(unit.numBedrooms, unit.numBathrooms),
    };

    await handleSaveAction({
      ...createUnitAction({
        projectId,
        buildingId: +buildingId,
        unitId,
        title: actionTitles.startMakeReady,
        description: '',
        category: 'makeReady',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      }),
      status: 'done',
    });

    (
      [
        [assignTeamAction, assignTeamPayload],
        [scheduleMakeReadyAction, scheduleMakeReadyActionPayload],
        [generateMakeReadyPlanAction, generateMakeReadyPlanPayload],
      ] as [ActionItem, unknown][]
    ).forEach((data) => handleSaveAction(data[0], data[1]));
  }, [buildingId, handleSaveAction, openConfirm, projectId, unit, unitId]);

  const handleStartLeaseRequired = useCallback(async () => {
    if (!projectId || !buildingId || !unitId || !unit) {
      return;
    }

    const activeActions = await findActionItemsByCategoryAndStatus(
      projectId,
      +buildingId,
      unitId,
      'lease',
      ['active', 'future']
    );

    if (activeActions.length > 0) {
      const handleAction = async (value: boolean) => {
        if (value === true) {
          const promises = activeActions.map((data) =>
            handleSaveAction({
              ...data,
              status: 'ignored',
              updateDate: new Date(),
            })
          );

          await Promise.all(promises);

          handleStartLeaseRequired();
        }
      };

      openConfirm({
        title: 'Confirm',
        description:
          'Lease actions are already started. Are you sure you want to override the previous actions and begin a new one?',
        onAction: handleAction,
      });

      return;
    }

    await handleSaveAction({
      ...createUnitAction({
        projectId,
        buildingId: +buildingId,
        unitId,
        title: actionTitles.startLease,
        description: '',
        category: 'lease',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      }),
      status: 'done',
    });

    handleSaveAction(
      createUnitAction({
        projectId,
        buildingId: +buildingId,
        unitId,
        title: actionTitles.generateMarketingPackage,
        description: '',
        category: 'lease',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      })
    );
  }, [buildingId, handleSaveAction, openConfirm, projectId, unit, unitId]);

  useEffect(() => {
    if (projectId && buildingId && unitId) {
      setLastSelectedUnitPage({
        projectId,
        unitId,
      });

      const unitLoader = createLoading();

      unitLoader.startLoading({ title: 'Loading Unit...' });

      getUnitById(projectId, unitId).then((value) => {
        unitLoader.stopLoading();

        if (value) {
          setUnit(value);
        } else {
          showAlert('Unit not exists!', 'error');
        }
      });

      const projectLoader = createLoading();

      projectLoader.startLoading({ title: 'Loading Project...' });

      getProjectById(projectId).then((_project) => {
        projectLoader.stopLoading();
        setProject(_project);
      });

      const actionLoader = createLoading();

      actionLoader.startLoading({ title: 'Loading actions...' });

      getActionsByUnitId(projectId, +buildingId, unitId).then((value) => {
        actionLoader.stopLoading();
        setActions(value);
      });

      const inspectionLoader = createLoading();

      inspectionLoader.startLoading({ title: 'Loading inspections...' });

      getInspectionsByUnitId(projectId, unitId).then((value) => {
        inspectionLoader.stopLoading();
        setInspections(value);
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [
    projectId,
    buildingId,
    showAlert,
    unitId,
    createLoading,
    setLastSelectedUnitPage,
  ]);

  const handleClickActionItem = (actionId: string) => {
    setSelectedActionId(actionId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedActionId(null);
  };

  const handleChangeModal = (open: boolean) => {
    if (open === false) {
      setSelectedActionId(null);
    }
    setIsModalOpen(open);
  };

  const handleOpenFloorPlanModal = () => {
    setIsFloorPlanModalOpen(true);
  };

  const handleCloseFloorPlanModal = () => {
    setIsFloorPlanModalOpen(false);
  };

  const handleOpenUnitMixEditModal = () => {
    setIsUnitMixModalOpen(true);
  };

  const handleCloseUnitMixEditModal = () => {
    setIsUnitMixModalOpen(false);
  };

  const handleClickNewNotice = useCallback(async () => {
    if (!projectId || !buildingId || !unitId || !unit) {
      return;
    }

    const activeActions = await findActionItemsByCategoryAndStatus(
      projectId,
      +buildingId,
      unitId,
      'notice',
      ['active', 'future']
    );

    if (activeActions.length > 0) {
      const handleAction = async (value: boolean) => {
        if (value === true) {
          const promises = activeActions.map((data) =>
            handleSaveAction({
              ...data,
              status: 'ignored',
              updateDate: new Date(),
            })
          );

          await Promise.all(promises);

          handleClickNewNotice();
        }
      };

      openConfirm({
        title: 'Confirm',
        description:
          'Notice actions are already started. Are you sure you want to override the previous actions and begin a new one?',
        onAction: handleAction,
      });

      return;
    }

    handleSaveAction(
      createUnitAction({
        projectId,
        buildingId: +buildingId,
        unitId,
        title: actionTitles.sendRenewalNotice,
        description: '',
        category: 'notice',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      })
    );

    const currentStatus: LeaseLifecycle[] = removeStatus(
      'notice',
      unit.currentState
    );

    currentStatus.push(
      statusByCategory['notice' as keyof typeof statusByCategory]
    );

    handleUpdateUnit({
      currentState: currentStatus,
    });
  }, [
    buildingId,
    handleSaveAction,
    handleUpdateUnit,
    openConfirm,
    projectId,
    unit,
    unitId,
  ]);

  const handleClickNewInspection = useCallback(
    async (isNew?: boolean) => {
      if (!projectId || !buildingId || !unitId) {
        return;
      }

      const draftInspections = await findDraftInspections(projectId, unitId);

      if (draftInspections.length > 0) {
        showAlert('Draft inspection already exists!', 'info');
        return;
      }

      const activeActions = await findActionItemsByCategoryAndStatus(
        projectId,
        +buildingId,
        unitId,
        'inspect',
        ['active', 'future']
      );

      if (activeActions.length > 0) {
        const handleAction = async (value: boolean) => {
          if (value === true) {
            const promises = activeActions.map((data) =>
              handleSaveAction({
                ...data,
                status: 'ignored',
                updateDate: new Date(),
              })
            );

            await Promise.all(promises);

            handleClickNewInspection(isNew);
          }
        };

        openConfirm({
          title: 'Confirm',
          description:
            'Notice actions are already started. Are you sure you want to override the previous actions and begin a new one?',
          onAction: handleAction,
        });

        return;
      }

      if (isNew) {
        // create a consider new inspect with done status
        await handleSaveAction({
          ...createUnitAction({
            projectId,
            buildingId: +buildingId,
            unitId,
            title: actionTitles.considerNewInspeciton,
            description: '',
            category: 'inspect',
            action_type: {
              inputType: 'text',
              reminder: false,
              type: 'approve',
            },
          }),
          status: 'done',
        });
      }

      handleInspectionRequiredEvent(false);
    },
    [
      buildingId,
      handleInspectionRequiredEvent,
      handleSaveAction,
      openConfirm,
      projectId,
      showAlert,
      unitId,
    ]
  );

  const handleReInspectionAction = () => {
    handleInspectionRequiredEvent(true);
  };

  const handleMarkAsOccupied = useCallback(
    async (payload?: { scheduledDate: Date; rent: number }) => {
      if (!unit || !projectId || !unitId || !buildingId) {
        return;
      }

      const ignorePromises = actions
        .filter(
          (action) => action.status === 'active' || action.status === 'future'
        )
        .map((action) =>
          handleSaveAction({
            ...action,
            status: 'ignored',
            updateDate: new Date(),
          })
        );

      const draftInspections = await findDraftInspections(projectId, unitId);

      const draftPromises = draftInspections.map((draftInspection) =>
        handleSaveInspection({
          ...draftInspection,
          status: InspectionStatus.Denied,
          updateDate: new Date(),
        })
      );

      await Promise.all([...ignorePromises, draftPromises]);

      const unitUpdate: Record<string, unknown> = {};

      if (payload) {
        unitUpdate.actualMoveInDate = payload.scheduledDate;
        unitUpdate.actualRent = payload.rent;
      }

      unitUpdate.currentState = [LeaseLifecycle.DONE];

      handleUpdateUnit(unitUpdate);

      handleSaveAction({
        ...createUnitAction({
          projectId,
          buildingId: +buildingId,
          unitId,
          title: actionTitles.occupied,
          description: '',
          category: 'general',
          action_type: {
            inputType: 'text',
            reminder: false,
            type: 'approve',
          },
        }),
        status: 'done',
      });
    },
    [
      actions,
      buildingId,
      handleSaveAction,
      handleSaveInspection,
      handleUpdateUnit,
      projectId,
      unit,
      unitId,
    ]
  );

  const handleScheduleMoveOut = useCallback(
    async (scheduledMoveOutDate: Date) => {
      const unitUpdate: Record<string, unknown> = {};

      unitUpdate.scheduledMoveOutDate = scheduledMoveOutDate;

      await handleUpdateUnit(unitUpdate);
    },
    [handleUpdateUnit]
  );

  const handleExtendLease = useCallback(
    async (leaseEndDate: Date, rent: number) => {
      const unitUpdate: Record<string, unknown> = {};

      unitUpdate.leaseEndDate = leaseEndDate;
      unitUpdate.actualRent = rent;

      await handleUpdateUnit(unitUpdate);
    },
    [handleUpdateUnit]
  );

  const paths = [
    {
      label:
        selectedOption.value !== 'all' ? selectedOption.label : 'Portfolio',
      href: '/portfolio',
    },
    {
      label: project?.name || 'Building',
      href: `/projects/${project?.id}/${project?.buildings[0].id}`,
    },
    {
      label: unit?.name || unitId || 'Unit',
      href: window.location.href,
    },
  ];

  const tabContent: Record<string, ReactNode> = {
    todos: (
      <TodoTabContent
        projectId={projectId}
        buildingId={buildingId}
        unitId={unitId}
        actions={actions}
        onClickActionItem={handleClickActionItem}
        onSaveAction={handleSaveAction}
      />
    ),
    reports:
      unit && projectId && unitId ? (
        <ReportsTabContent
          unitName={unit.name || unit.id}
          inspections={inspections}
          onSaveAction={handleSaveAction}
          onSaveInspection={handleSaveInspection}
          onReinspectAction={handleReInspectionAction}
          reviewInspectionActions={actions.filter(
            (action) =>
              action.title === actionTitles.reviewInspectionReport &&
              action.status === 'active'
          )}
          onNewMakeReadyAction={handleMakeReadyRequiredEvent}
          onNewLeaseAction={handleStartLeaseRequired}
        />
      ) : null,
    files: <FilesTabContent unit={unit} onUpdateUnit={handleUpdateUnit} />,
  };

  const leaseEndDate = convertPseudoDateToRealDate(unit?.leaseEndDate);

  useEffect(() => {
    if (defaultTab) {
      console.log('setting tab', defaultTab);
      setTabKey(defaultTab);
    }
  }, [defaultTab]);

  return (
    <Stack gap="16px" className="w-full min-w-[470px] p-24">
      <Stack gap="12px" className="w-full">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs paths={paths} />
          {userData?.isDev && isEditMode && (
            <Button variant="outlined" onClick={handleOpenUnitMixEditModal}>
              Edit UnitMix
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Tabs items={tabList} tabKey={tabKey} setTabKey={setTabKey} />

        <Stack direction="row" alignItems="center" gap="10px">
          {unit && unit['3dScanPath'] && (
            <Link target="_blank" to={unit['3dScanPath']}>
              <ButtonBase className="leading-14 h-[30px] w-[107px] items-center justify-center gap-9 rounded-[4px] border !border-solid border-zinc-400 bg-neutral-700 text-xs font-medium tracking-tight text-stone-200/80">
                <AppIcon name="the3dapp" className="-mt-1" />
                3D scan
              </ButtonBase>
            </Link>
          )}
          {unit && unit.floorPlanPath && (
            <ButtonBase
              onClick={handleOpenFloorPlanModal}
              className="leading-14 h-[30px] w-[107px] items-center justify-center gap-9 rounded-[4px] border !border-solid border-zinc-400 bg-neutral-700 text-xs font-medium tracking-tight text-stone-200/80"
            >
              <AppIcon name="floorplan" />
              Floor Plan
            </ButtonBase>
          )}
        </Stack>
      </Stack>

      <Stack direction="row" gap="36px">
        <Stack className="max-h-[calc(100vh-317px)]">
          {tabContent[tabKey]}
        </Stack>

        <Stack
          direction="row"
          gap="24px"
          className="relative h-[calc(100vh-357px)] flex-1 overflow-hidden rounded-[10px] border border-neutral-400"
        >
          {unit && project?.buildings[0].modelPath ? (
            <div className="w-full">
              <Stack
                gap="16px"
                className={twMerge(
                  'absolute right-0 rounded-[10px] border border-stone-800 bg-zinc-800 px-20 py-14'
                )}
                alignItems="center"
                justifyContent="center"
              >
                <UnitDetails unit={unit} />
              </Stack>
              <UnitD3Model
                buildingModelPath={project?.buildings[0].modelPath}
                unit={unit}
              />
            </div>
          ) : null}
        </Stack>
      </Stack>

      {unit && project && selectedActionId ? (
        <UnitModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          projectAddress={project.address}
          projectName={project.name}
          buildingName={project.buildings[0].name}
          unitName={unit.name || unit.id}
          leaseEndDate={leaseEndDate}
          actualRent={unit.actualRent || 0}
          unitLeaseEndDate={unit.leaseEndDate}
          actionId={selectedActionId}
          onChangeModalOpen={handleChangeModal}
          onSaveAction={handleSaveAction}
          onSkipActions={handleSkipActions}
          onSaveInspection={handleSaveInspection}
          onReinspectionAction={handleReInspectionAction}
          onScheduleMoveOut={handleScheduleMoveOut}
          onExtendLease={handleExtendLease}
          onNewInspectionAction={handleClickNewInspection}
          onNewMakeReadyAction={handleMakeReadyRequiredEvent}
          onNewLeaseAction={handleStartLeaseRequired}
          onSetNewTenant={handleMarkAsOccupied}
        />
      ) : null}

      {unit && unit.floorPlanPath && (
        <FloorPlanModal
          floorPlanPath={unit.floorPlanPath}
          isOpen={isFloorPlanModalOpen}
          onClose={handleCloseFloorPlanModal}
        />
      )}

      {unit && (
        <UnitMixEditModal
          isModalOpen={isUnitMixModalOpen}
          onClose={handleCloseUnitMixEditModal}
          unit={unit}
        />
      )}
    </Stack>
  );
}
