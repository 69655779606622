import { MouseEvent, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  Button,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import { Unit } from '@urbanmix-tech/shared-js';
import { Input } from './Input';
import { IdAutocomplete } from './IdAutocomplete';

export type UnitTableProps = {
  units: Unit[];
  meshIds: string[];
  onChangeCeil(
    unitId: string,
    ceil: 'unitId' | 'unitName' | 'meshId',
    newUnitId: string | null,
    failCb?: () => void
  ): void;
  onDelete(unitId: string): void;
  onAddMore(): void;
};

export function UnitTable({
  units,
  meshIds,
  onChangeCeil,
  onDelete,
  onAddMore,
}: UnitTableProps) {
  const [popoverParameters, setPopoverParameters] = useState<{
    anchorEl: HTMLElement;
    unit: Unit;
  } | null>(null);

  const handleClickMesh = (unit: Unit) => (event: MouseEvent<HTMLElement>) => {
    setPopoverParameters({
      anchorEl: event.currentTarget,
      unit,
    });
  };

  const handleClose = () => {
    setPopoverParameters(null);
  };

  const handleDelete = (unitId: string) => () => {
    onDelete(unitId);
  };

  const handleChangeCeil =
    (unitId: string, ceil: 'unitId' | 'unitName' | 'meshId') =>
    (newValue: string | null, failCb: () => void) => {
      onChangeCeil(unitId, ceil, newValue, failCb);
    };

  const handleChangeMeshId = (meshId: string) => {
    if (popoverParameters) {
      onChangeCeil(popoverParameters.unit.id, 'meshId', meshId);
    }
  };

  const open = Boolean(popoverParameters);

  return (
    <TableContainer
      component="div"
      sx={{ maxHeight: 300, background: 'transparent' }}
    >
      <Table stickyHeader sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>Unit ID</TableCell>
            <TableCell>Unit Name</TableCell>
            <TableCell>Mesh ID</TableCell>
            <TableCell className="w-[160px]">
              <Button
                variant="contained"
                onClick={onAddMore}
                color="primary"
                fullWidth
              >
                +Add
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit) => (
            <TableRow
              key={unit.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Input
                  value={unit.id}
                  onChange={handleChangeCeil(unit.id, 'unitId')}
                />
              </TableCell>
              <TableCell>
                <Input
                  value={unit.name || ''}
                  onChange={handleChangeCeil(unit.id, 'unitName')}
                />
              </TableCell>
              <TableCell onClick={handleClickMesh(unit)}>
                {unit.meshId || ''}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={handleDelete(unit.id)}
                  startIcon={<Delete />}
                  fullWidth
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Popover
        open={open}
        anchorEl={popoverParameters?.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            className: 'p-[10px] bg-black',
          },
        }}
      >
        {popoverParameters && (
          <IdAutocomplete
            id={popoverParameters.unit.meshId}
            ids={meshIds}
            onSave={handleChangeMeshId}
            label="Mesh Id"
          />
        )}
      </Popover>
    </TableContainer>
  );
}
