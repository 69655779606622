import { LinearProgress } from '@mui/material';

import { ActionItemCategory } from '@urbanmix-tech/shared-js';
import {
  getActionBgColorByCategory,
  getActionColorByCategory,
} from '@/helpers/common';

export type ProgressBarProps = {
  category: ActionItemCategory;
  value?: number;
};

export function ProgressBar({ category, value }: ProgressBarProps) {
  const porgressColor = getActionColorByCategory(category);
  const bgColor = getActionBgColorByCategory(category);
  return (
    <div className="m-auto w-full p-2">
      <LinearProgress
        sx={{
          backgroundColor: bgColor,
          '& .MuiLinearProgress-bar': {
            backgroundColor: porgressColor,
          },
        }}
        variant="determinate"
        value={value}
      />
    </div>
  );
}
