import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Breadcrumbs } from '@/components/shared/Breadcrumbs/Breadcrumbs';
import { ActionItem, Project, Unit, AnalyzingFilter } from '@urbanmix-tech/shared-js';
import { useLoading } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';
import {
  getActionItemsByBuildingId,
  getProjectById,
  getUnitsByProjectId,
} from '@urbanmix-tech/shared-js/data';
import BuildingModel from '@/components/BuildingModel/BuildingModel';

import TodoTabs, { tabList } from '@/components/TodoTabs/TodoTabs';
import { TodoTable } from '@/components/TodoTabs/TodoTable';
import NavigateSelect from '@/components/shared/Selects/NavigateSelect';

export default function TodoBuildingPage() {
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();
  const [project, setProject] = useState<Project | null>(null);
  const [units, setUnits] = useState<Unit[]>([]);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [tabKey, setTabKey] = useState<string>(tabList[0].tabId);
  const [hoveredUnitId, setHoveredUnitId] = useState<string | null>(null);

  const { showAlert } = useAlert();
  const { createLoading } = useLoading();

  useEffect(() => {
    if (projectId) {
      const loader = createLoading();

      loader.startLoading({ title: 'Loading Project from db...' });

      getProjectById(projectId).then((value) => {
        if (value && value.buildings.length > 0) {
          setProject(value);
        } else {
          showAlert('Building not exists!', 'error');
        }

        loader.stopLoading();
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [projectId, buildingId, showAlert, createLoading]);

  useEffect(() => {
    if (projectId && buildingId) {
      const unitLoader = createLoading();

      unitLoader.startLoading({ title: 'Loading Units from db...' });

      getUnitsByProjectId(projectId).then((_units) => {
        setUnits(_units);
        unitLoader.stopLoading();
      });

      const actionItemsLoader = createLoading();

      actionItemsLoader.startLoading({ title: 'Loading Todo...' });

      getActionItemsByBuildingId(projectId, +buildingId).then((value) => {
        setActionItems(value);
        actionItemsLoader.stopLoading();
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [projectId, buildingId, showAlert, createLoading]);

  const handleHoverUnit = useCallback((unitId: string | null) => {
    setHoveredUnitId(unitId);
  }, []);

  const handleSelectUnit = useCallback(() => {
    // nop
  }, []);

  const unitsDropDown: Record<string, string> = useMemo(() => {
    const routeingMap: Record<string, string> = {};
    units.forEach((unit) => {
      routeingMap[`${projectId}/${buildingId}/units/${unit.id}`] = unit.id;
    });
    return routeingMap;
  }, [projectId, buildingId, units]);

  if (!project) {
    return null;
  }

  const breadcrumbsPath = [
    {
      label: 'Actions Breakdown',
      href: '/todo',
    },
    {
      label: 'Portfolio',
      href: '/todo',
    },
    {
      label: project?.name || 'Building',
      href: `/todo/${project?.id}/${project?.buildings[0].id}`,
    },
  ];

  return (
    <Stack gap="16px" className="h-full w-full min-w-[470px] p-24">
      <Stack gap="24px" direction="row" className="p-24 pb-0">
        <Breadcrumbs paths={breadcrumbsPath} />
        <NavigateSelect
          pathRoot="todo"
          items={unitsDropDown}
          placeHolder="Select Unit"
        />
      </Stack>

      <TodoTabs
        setTabKey={setTabKey}
        tabKey={tabKey}
        actionItems={actionItems}
      />
      <Stack gap="24px" direction="row" className="flex-1">
        <TodoTable
          actionItems={actionItems}
          tabKey={tabKey}
          onSelectUnit={handleHoverUnit}
        />
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: `calc(100% - 714px)`,
          }}
        >
          {project.buildings.length > 0 ? (
            <BuildingModel
              building={project.buildings[0]}
              units={units}
              expandedStatus={[]}
              subStatus={null}
              analyzingFilter={AnalyzingFilter.None}
              selectedUnitIds={hoveredUnitId ? [hoveredUnitId] : []}
              onSelectUnit={handleSelectUnit}
              onHoverUnit={handleHoverUnit}
            />
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
