import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';

import { ActionItemStatus, ActionItemCategory } from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type CheckInOnInspectionProgressModalContentProps = {
  readonly?: boolean;
  unitName: string;
  category: ActionItemCategory;
  isReinspection?: boolean;
  inspectorId: string;
  scheduledDate: Date;
  status: ActionItemStatus;
  onDone: () => void;
  onSkip: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function CheckInOnInspectionProgressModalContent({
  readonly,
  unitName,
  category,
  isReinspection,
  inspectorId,
  scheduledDate,
  status,
  onDone,
  onSkip,
  onChangeModalOpen,
}: CheckInOnInspectionProgressModalContentProps) {
  const headerTitle = isReinspection
    ? `${category === 'inspect' ? 'Reinspection' : 'Make Ready'} Status`
    : `${category === 'inspect' ? 'Inspection' : 'Make Ready'} Status`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Done',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onDone,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onSkip,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <div
        className={twMerge(
          'mt-[-8px] flex h-[22px] w-[74px] items-center justify-center rounded bg-stone-200 bg-opacity-20',
          'text-xs font-semibold leading-[14px] tracking-tight text-stone-200'
        )}
      >
        OVERDUE
      </div>
      <Stack direction="column" gap="24px" className="mt-[-8px]">
        <Typography
          variant="caption"
          className="w-[265px] !font-thin text-stone-200 text-opacity-80"
        >
          Inspection for Unit {unitName} is <b>overdue</b>. Reach out to the
          assigned inspector for more details.
        </Typography>

        <Stack direction="column" gap="16px">
          <Stack direction="row">
            <Stack direction="column" className="w-2/3">
              <Typography variant="captionMedium" className="text-stone-200">
                Date
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {dayjs(scheduledDate).format('dddd')}
                <br />
                {dayjs(scheduledDate).format('ddd DD, YYYY')}
              </Typography>
            </Stack>

            <Stack direction="column" className="w-1/3">
              <Typography variant="captionMedium" className="text-stone-200">
                Time
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {dayjs(scheduledDate).format('h:mm A')}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row">
            <Stack direction="column">
              <Typography variant="captionMedium" className="text-stone-200">
                Assigned Inspector
              </Typography>
              <Typography
                variant="caption"
                className="text-stone-200 text-opacity-80"
              >
                {inspectorId}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalContent>
  );
}
