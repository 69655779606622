import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Sidebar, Menu } from 'react-pro-sidebar';
import { Stack } from '@mui/material';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

import { useProjects } from '@/shared/hooks/useProjects';

import { menuItemStyles } from './theme';
import { MenuItem, IMenuItem } from './MenuItem';

export function MainAppSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId, buildingId } = useParams<{
    projectId?: string;
    buildingId?: string;
  }>();

  const [collapsed, setCollapsed] = useState(false);

  const [activePath, setActivePath] = useState<string[]>([]);
  const projects = useProjects();

  useEffect(() => {
    const pathName = location.pathname;

    if (pathName === '/portfolio') {
      setActivePath(['portfolio']);
    }
  }, [location]);

  useEffect(() => {
    if (projectId && buildingId) {
      setActivePath(['portfolio', projectId]);
    }
  }, [projectId, buildingId]);

  const menuItems: IMenuItem[] = useMemo(() => {
    return [
      {
        id: 'portfolio',
        label: 'Portfolio',
        icon: <AppIcon name="portfolio" fontSize={16} />,
        rootStyles: {
          borderBottom: '1px solid rgba(255,255,255,0.12)',
          paddingBottom: '12px',
          paddingTop: '12px',
          // maxHeight: '700px',
          overflowY: 'auto',
        },
        subMenu: [
          ...projects.map((project) => ({
            id: project.id,
            label: project.name,
            onClick: () =>
              navigate(`/projects/${project.id}/${project.buildings[0].id}/`),
          })),
        ],
        onClick: () => navigate('/portfolio'),
      },
      // {
      //   id: 'todo',
      //   label: 'To-Do',
      //   icon: <AppIcon name="bookMark" fontSize={16} />,
      //   onClick: () => navigate('/todo'),
      // },
    ];
  }, [navigate, projects]);

  return (
    <Sidebar
      collapsed={collapsed}
      rootStyles={{
        height: '100%',
        border: 'none',
      }}
      backgroundColor="rgba(255, 255, 255, 0.03)"
      collapsedWidth="64px"
      width="218px"
    >
      <Stack className="h-full">
        <div className="h-[calc(100%-64px)] overflow-auto">
          <Menu
            className="text-sm"
            menuItemStyles={menuItemStyles}
            renderExpandIcon={() => null}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                item={item}
                activePath={activePath}
                onChangeActivePath={setActivePath}
              />
            ))}
          </Menu>
        </div>

        <div className="flex w-full items-center justify-end px-16 py-24">
          <ArrowIcon
            className={`cursor-pointer ${collapsed && 'rotate-180'}`}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </Stack>
    </Sidebar>
  );
}
