import { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import {
  getInspectionById,
  getActionItem,
  getActionItemPayload,
} from '@urbanmix-tech/shared-js/data';
import {
  ActionItem,
  ActionItemPayload,
  Inspection,
  InspectionStatus,
  ReviewInspectionActionPayload,
  ActionItemCategory,
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';

import { useModal } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';

import { UnitNoticeModalContent } from './UnitNoticeModalContent';
import { UnitInspectionModalContent } from './UnitInspectionModalContent';
import { UnitMakeReadyModalContent } from './UnitMakeReadyModalContent';
import { InspectionApproveModalContent } from './InspectionApproveModalContent/InspectionApproveModalContent';
import { ConsiderInspectorModalContent } from './ConsiderInspectorModalContent/ConsiderInspectorModalContent';
import { UnitLeaseModalContent } from './UnitLeaseModalContent';
import { UnitGeneralModalContent } from './UnitGeneralModalContent';
import { SuccessModalContent } from '../SuccessModalContent';
import { GeneralModalContent } from './GeneralModalContent/GeneralModalContent';
import { ActionItemModal } from './ActionItemModal/ActionItemModal';

export type UnitModalContentProps = {
  readonly?: boolean;
  projectName: string;
  projectAddress: string;
  buildingName: string;
  unitName?: string;
  leaseEndDate?: Date | null;
  actualRent: number;
  unitLeaseEndDate: Date | null;
  actionId: string;
  onChangeModalOpen: (val: boolean) => void;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
  onSkipActions: (category: ActionItemCategory) => void;
  onSaveInspection: (inspection: Inspection) => Promise<string | null>;
  onReinspectionAction(): void;
  onScheduleMoveOut(scheduledMoveOutDate: Date): Promise<void>;
  onExtendLease(leaseEndDate: Date, rent: number): Promise<void>;
  onNewInspectionAction(): void;
  onNewMakeReadyAction(): void;
  onNewLeaseAction(): void;
  onSetNewTenant: (payload: { scheduledDate: Date; rent: number }) => void;
};

function UnitModalContent({
  readonly,
  projectName,
  projectAddress,
  buildingName,
  unitName,
  leaseEndDate,
  actualRent,
  unitLeaseEndDate,
  actionId,
  onSaveAction,
  onSkipActions,
  onSaveInspection,
  onReinspectionAction,
  onNewInspectionAction,
  onChangeModalOpen,
  onScheduleMoveOut,
  onExtendLease,
  onNewMakeReadyAction,
  onNewLeaseAction,
  onSetNewTenant,
}: UnitModalContentProps) {
  const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);

  const [actionItem, setActionItem] = useState<ActionItem | null>(null);
  const [actionItemPayload, setActionItemPayload] =
    useState<ActionItemPayload | null>(null);

  const { createModal } = useModal();
  const { showAlert } = useAlert();

  useEffect(() => {
    getActionItem(actionId).then((value) => {
      if (value) {
        setActionItem(value);
      }
    });

    getActionItemPayload(actionId).then((value) => {
      if (value) {
        setActionItemPayload(value);
      }
    });
  }, [actionId]);

  const handleApprove = async (payload?: unknown) => {
    if (!actionItem) {
      return;
    }

    await onSaveAction(
      {
        ...actionItem,
        updateDate: new Date(),
        status: 'done',
      },
      payload
    );

    const { openModal, closeModal } = createModal();

    openModal(
      <SuccessModalContent actionItem={actionItem} onClose={closeModal} />
    );
  };

  const handleInspectionApprove = async () => {
    if (actionItem) {
      await handleApprove();

      const inspection = await getInspectionById(
        actionItem.projectId,
        actionItem.unitId,
        (actionItemPayload!.payload as ReviewInspectionActionPayload)
          .inspectionId
      );

      if (!inspection) {
        return;
      }

      onSaveInspection({
        ...inspection,
        status: InspectionStatus.Approved,
        updateDate: new Date(),
      });
      onChangeModalOpen(false);
      setApproveModalOpen(false);
    }
  };

  const handleNewInspectionEvent = () => {
    onNewInspectionAction();
    onChangeModalOpen(false);
  };

  const handleSkipAction = () => {
    if (actionItem) {
      onSaveAction({
        ...actionItem,
        updateDate: new Date(),
        status: 'ignored',
      });
    }
  };

  if (actionItem && actionItem.title === actionTitles.considerNewInspeciton) {
    return (
      <ConsiderInspectorModalContent
        unitName={unitName || actionItem.unitId}
        action={actionItem}
        onApproveAction={handleNewInspectionEvent}
        onSkipAction={handleSkipAction}
        onChangeModalOpen={onChangeModalOpen}
        readonly={readonly}
      />
    );
  }

  if (!actionItem) {
    return null;
  }

  if (actionItem.category === 'notice' && !leaseEndDate) {
    onChangeModalOpen(false);
    showAlert(
      'This unit has no lease end date set. A lease end date is required to initiate the notice phase.',
      'error'
    );
    return null;
  }

  if (approveModalOpen) {
    return (
      <InspectionApproveModalContent
        unitName={unitName || actionItem.unitId}
        action={actionItem}
        onStartMakeReady={async () => {
          await handleInspectionApprove();
          onNewMakeReadyAction();
        }}
        onStartLeaseAction={async () => {
          await handleInspectionApprove();
          onNewLeaseAction();
        }}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  // check if any of values of the actionTitles map is equal to actionItem.title
  if (Object.values(actionTitles).includes(actionItem.title)) {
    return (
      <>
        {actionItem.category === 'notice' && leaseEndDate && (
          <UnitNoticeModalContent
            readonly={readonly}
            projectName={projectName}
            projectAddress={projectAddress}
            buildingName={buildingName}
            unitName={unitName || actionItem.unitId}
            leaseEndDate={leaseEndDate}
            actualRent={actualRent}
            actionItem={actionItem}
            onChangeModalOpen={onChangeModalOpen}
            onApproveAction={handleApprove}
            onSaveAction={onSaveAction}
            onScheduleMoveOutDate={onScheduleMoveOut}
            onExtendLease={onExtendLease}
          />
        )}
        {actionItemPayload && actionItem.category === 'inspect' && (
          <UnitInspectionModalContent
            projectName={projectName}
            projectAddress={projectAddress}
            unitName={unitName || actionItem.unitId}
            unitLeaseEndDate={unitLeaseEndDate}
            actionItem={actionItem}
            actionItemPayload={actionItemPayload}
            onChangeModalOpen={onChangeModalOpen}
            onReinspectAction={onReinspectionAction}
            onSaveAction={onSaveAction}
            onSaveInspection={onSaveInspection}
            onApproveAction={handleApprove}
            onSkipActions={onSkipActions}
            onOpenApproveModal={() => setApproveModalOpen(true)}
          />
        )}
        {actionItemPayload && (
          <UnitMakeReadyModalContent
            unitName={unitName || actionItem.unitId}
            actionItem={actionItem}
            actionItemPayload={actionItemPayload}
            onChangeModalOpen={onChangeModalOpen}
            onApproveAction={handleApprove}
            onSaveAction={onSaveAction}
            onSkipActions={onSkipActions}
            onOpenApproveModal={() => setApproveModalOpen(true)}
          />
        )}
        <UnitLeaseModalContent
          actionItem={actionItem}
          onChangeModalOpen={onChangeModalOpen}
          onApproveAction={handleApprove}
          onSaveAction={onSaveAction}
          onSkipActions={onSkipActions}
          onSetNewTenant={onSetNewTenant}
        />
        <UnitGeneralModalContent
          actionItem={actionItem}
          onChangeModalOpen={onChangeModalOpen}
          onApproveAction={handleApprove}
          onSkipAction={handleSkipAction}
        />
      </>
    );
  }

  return (
    <ActionItemModal
      actionItem={actionItem}
      onChangeModalOpen={onChangeModalOpen}
    />
  );
}

export function UnitModal({
  isOpen,
  onClose,
  onChangeModalOpen,
  readonly,
  projectName,
  projectAddress,
  buildingName,
  unitName,
  leaseEndDate,
  actualRent,
  unitLeaseEndDate,
  actionId,
  onSaveAction,
  onSkipActions,
  onSaveInspection,
  onScheduleMoveOut,
  onExtendLease,
  onReinspectionAction,
  onNewInspectionAction,
  onNewMakeReadyAction,
  onNewLeaseAction,
  onSetNewTenant,
}: UnitModalContentProps & {
  isOpen: boolean;
  onClose(): void;
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        component="div"
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
      >
        <UnitModalContent
          readonly={readonly}
          projectName={projectName}
          projectAddress={projectAddress}
          buildingName={buildingName}
          unitName={unitName}
          leaseEndDate={leaseEndDate}
          actualRent={actualRent}
          unitLeaseEndDate={unitLeaseEndDate}
          actionId={actionId}
          onChangeModalOpen={onChangeModalOpen}
          onSaveAction={onSaveAction}
          onSkipActions={onSkipActions}
          onSaveInspection={onSaveInspection}
          onScheduleMoveOut={onScheduleMoveOut}
          onExtendLease={onExtendLease}
          onReinspectionAction={onReinspectionAction}
          onNewInspectionAction={onNewInspectionAction}
          onNewMakeReadyAction={onNewMakeReadyAction}
          onNewLeaseAction={onNewLeaseAction}
          onSetNewTenant={onSetNewTenant}
        />
      </Box>
    </Modal>
  );
}
