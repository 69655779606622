import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import setupLogRocketReact from 'logrocket-react';
import mixpanel, { Dict } from 'mixpanel-browser';

export const reportAppError = (
  error: Error,
  message: string | undefined = undefined
) => {
  Sentry.captureException(error, { extra: { message } });
  if (message) {
    console.error(message, error);
  } else {
    console.error(error);
  }
};

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function reportEvent(event: string, properties: Dict | undefined) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  mixpanel.track(event, properties);
}

export const initReporting = () => {
  if (import.meta.env.VITE_ENV === 'production') {
    LogRocket.init('urbanmix/urbanmix');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setupLogRocketReact(LogRocket);
    Sentry.init({
      dsn: 'https://62fbca2c932f2ba0d602d4da0fb033a7@o4506352483434496.ingest.sentry.io/4506488075059200',
      integrations: [new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 1,
      replaysOnErrorSampleRate: 1.0,
      enabled: import.meta.env.VITE_ENV === 'production',
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  }
  mixpanel.init('458672b797c0b254332bdf232e5a4373', {
    track_pageview: true,
    persistence: 'localStorage',
  });
};
