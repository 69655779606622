import { useState } from 'react';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  IconButton,
  TextField,
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  ...props
}: Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    label?: string;
  },
  'renderInput'
>) {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const adornment = open ? (
    <ExpandLessIcon className="text-primary" />
  ) : (
    <ExpandMoreIcon className="text-[#3E3B4F] hover:text-[#9099BF]" />
  );

  return (
    <MuiAutocomplete
      {...props}
      open={open}
      blurOnSelect="touch"
      onOpen={handleOpen}
      onClose={handleClose}
      slotProps={{
        paper: {
          variant: 'outlined',
          sx: {
            borderRadius: '0 0 4px 4px',
            background: '#22212C',
            border: '1px solid #CD3185',
            padding: '10px 0',
            fontSize: '14px',
            '& .MuiAutocomplete-listbox': {
              padding: 0,
              '& li': {
                padding: '7px 8px',

                '&:hover': {
                  background: '#9099BF',
                },
              },
            },
          },
        },
      }}
      clearIcon={<CloseIcon className="text-[#3E3B4F] hover:text-[#CD3185]" />}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton onClick={handleToggle}>{adornment}</IconButton>
            ),
            notched: false,
          }}
          fullWidth
          sx={{
            display: 'flex',
            width: '100%',
            padding: 0,
            '& .MuiOutlinedInput-root': {
              height: '30px',
              padding: 0,
              paddingRight: '0 !important',
              borderRadius: open ? '4px 4px 0 0' : '4px',
              background: '#22212C',
              outline: '1px solid #E6E3E3CC',
              borderBottom: open ? 'none' : '1px solid #9099BF',
              borderColor: open ? '#CD3185' : '#9099BF',
              input: {
                background: 'transparent',
                color: 'white',
                fontSize: '14px',
                flex: 1,
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '0 14px !important',
            },
            '& .MuiButtonBase-root': {
              padding: '0 8px !important',
            },
          }}
        />
      )}
    />
  );
}
