import { Stack } from '@mui/material';
import { twMerge } from 'tailwind-merge';

export type BadgeTabProps = {
  label: string;
  status: number;
};

export function BadgeTab({ label, status }: BadgeTabProps) {
  return (
    <Stack direction="row" alignItems="flex-end" gap="8px">
      {label}
      <div
        className={twMerge(
          'h-16 rounded-sm bg-stone-200/50 px-4 py-1',
          'text-center text-[10px] font-semibold leading-[14px] tracking-tight text-white'
        )}
      >
        {status}
      </div>
    </Stack>
  );
}
