import { Stack, Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import dayjs from 'dayjs';

import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type SendRenewalNoticeModalContentProps = {
  readonly?: boolean;
  projectName: string;
  projectAddress: string;
  buildingName: string;
  unitName: string;
  leaseEndDate: Date;
  category: ActionItemCategory;
  status: ActionItemStatus;
  onApprove: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function SendRenewalNoticeModalContent({
  readonly,
  projectName,
  projectAddress,
  buildingName,
  unitName,
  leaseEndDate,
  category,
  status,
  onApprove,
  onChangeModalOpen,
}: SendRenewalNoticeModalContentProps) {
  const leaseEndDateString = dayjs(leaseEndDate).format('MMM DD, YYYY');
  const leaseEndDateAndTimeStr = dayjs(leaseEndDate).format(
    'MMM DD, YYYY h:mm A'
  );

  const handleCopy = () => {
    const contents = [
      `Subject: Upcoming Lease End Date Notification, Unit ${unitName}, ${buildingName}, ${projectName}`,
      'Dear (First name of tenant)',
      `As you are aware, your lease is due to expire on ${leaseEndDateString}. As such, your lease renewal offer for (move-in-date) to (move-out date) is at a gross monthly rate of $(rent) per month. Should you wish to re-sign, please let us know your decision, or if you do not wish to renew the lease, please provide written notice.`,
      'Thanks and hope to hear from you soon.',
    ];
    navigator.clipboard.writeText(contents.join('\n\n'));
  };

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={actionTitles.sendRenewalNotice}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Done',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onApprove,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: () => {},
            hidden: true,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
          justifyContent="flex-end"
          gap="24px"
        />
      }
    >
      <Stack gap="16px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          {`The lease for unit ${unitName} at ${projectAddress}/${unitName} is nearing its end date on ${leaseEndDateString}. To ensure a smooth transition and avoid any potential vacancies, it is crucial that we address this matter promptly.`}
        </Typography>

        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Please send a lease renewal notice to the current tenants as soon as
          possible. In the notice, kindly fill in the following:
        </Typography>

        <ol className="ml-36 list-decimal">
          <li className="list-item">
            <Typography
              variant="caption"
              className="!font-thin text-stone-200 text-opacity-80"
            >
              Lease Renewal Terms: Detail any changes to the lease terms,
              including rent adjustments, if applicable.
            </Typography>
          </li>
          <li className="list-item">
            <Typography
              variant="caption"
              className="!font-thin text-stone-200 text-opacity-80"
            >
              Response Deadline: Provide a reasonable deadline for the tenants
              to respond with their decision to renew or vacate.
            </Typography>
          </li>
          <li className="list-item">
            <Typography
              variant="caption"
              className="!font-thin text-stone-200 text-opacity-80"
            >
              Contact Information: Ensure tenants know whom to contact for any
              questions or to discuss the renewal terms.
            </Typography>
          </li>
        </ol>

        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          <b>Notice Location</b>:{' '}
          {projectName === buildingName
            ? projectName
            : `${projectName} - ${buildingName}`}{' '}
          <br />
          <b>Unit</b>: {unitName} <br />
          <b>Address</b>: {projectAddress} <br />
          <b>Date and Time of Lease End</b>: {leaseEndDateAndTimeStr} <br />
        </Typography>

        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          <b>
            Please use the following Email template (replace parameters with the
            needed values):
          </b>
        </Typography>

        <Stack gap="16px" className="relative rounded-md bg-white/10 p-16">
          <IconButton onClick={handleCopy} className="absolute right-8 top-8">
            <ContentCopyIcon className="text-white" />
          </IconButton>
          <Typography
            variant="caption"
            className="!font-thin text-stone-200 text-opacity-80"
          >
            Subject:{' '}
            <b>
              {`Upcoming Lease End Date Notification, Unit ${unitName}, ${buildingName}, ${projectName}`}
            </b>
          </Typography>
          <Typography
            variant="caption"
            className="!font-thin text-stone-200 text-opacity-80"
          >
            Dear (First name of tenant)
          </Typography>

          <Typography
            variant="caption"
            className="!font-thin text-stone-200 text-opacity-80"
          >
            {`As you are aware, your lease is due to expire on ${leaseEndDateString}. As
            such, your lease renewal offer for (move-in-date) to (move-out date)
            is at a gross monthly rate of $(rent) per month. Should you wish to
            re-sign, please let us know your decision, or if you do not wish to
            renew the lease, please provide written notice.`}
          </Typography>
          <Typography
            variant="caption"
            className="!font-thin text-stone-200 text-opacity-80"
          >
            Thanks and hope to hear from you soon.
          </Typography>
        </Stack>
      </Stack>
    </ModalContent>
  );
}
