import { useEffect, useMemo, useState, SyntheticEvent } from 'react';

import { Modal, Stack, Typography, IconButton, Tabs, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  getAllTeams,
  getAllUsers,
  saveTeam,
  UserData,
} from '@urbanmix-tech/shared-js/data';
import { Project, Team, TeamUser } from '@urbanmix-tech/shared-js';

import { UserRole } from '@/types/enums';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { useLoading } from '@/shared/context/UIContextProvider';
import { useAuth } from '@/shared/context/AuthProvider';
import { useProjects } from '@/shared/hooks/useProjects';

import { TeamSelector } from './TeamSelector';
import { NewTeamForm } from './NewTeamForm';
import { NewUserForm } from './NewUserForm';
import { TeamTable } from './TeamTable';
import { NewProjectForm } from './NewProjectForm';

const tabs = ['users', 'projects'];

export type TeamModalProps = {
  open: boolean;
  onClose(): void;
};

export function TeamsModal({ open, onClose }: TeamModalProps) {
  const { showAlert } = useAlert();
  const { createLoading } = useLoading();
  const { userData } = useAuth();

  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const projects = useProjects(true);

  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);

  const [mode, setMode] = useState<'view' | 'add-new-team'>('view');
  const [currentTab, setCurrentTab] = useState<'users' | 'projects'>('users');

  const handleChangeTab = (
    _event: SyntheticEvent,
    newValue: 'users' | 'projects'
  ) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const loader = createLoading();
    loader.startLoading({ title: 'Loading Users...' });

    getAllUsers().then((value) => {
      loader.stopLoading();

      if (value === null) {
        showAlert('Failed at loading Users', 'error');
        return;
      }

      setUsers(value);
    });
  }, [createLoading, showAlert]);

  useEffect(() => {
    const loader = createLoading();
    loader.startLoading({ title: 'Loading Teams...' });

    getAllTeams().then((value) => {
      loader.stopLoading();

      if (value === null) {
        showAlert('Failed at loading Teams', 'error');
        return;
      }

      setTeams(value);
    });
  }, [createLoading, showAlert]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    let tempTeams: Team[];

    if (UserRole[userData.role as keyof typeof UserRole] === UserRole.ADMIN) {
      tempTeams = teams;
    } else {
      tempTeams = teams.filter((team) => {
        const exists = team.users.find((item) => item.email === userData.email);

        return exists;
      });
    }

    setFilteredTeams(tempTeams);

    if (tempTeams.length > 0) {
      if (selectedTeam) {
        const exists = tempTeams.find(
          (item) => item.name === selectedTeam.name
        );

        if (exists) {
          return;
        }
      }

      setSelectedTeam(tempTeams[0]);
    }
  }, [selectedTeam, teams, userData]);

  const handleChangeTeams = async (newTeam: Team) => {
    const prevState: Team[] = teams;

    setTeams((prev) => {
      const exists = prev.find((item) => item.name === newTeam.name);

      if (exists) {
        return [
          ...prev.map((item) => (item.name === newTeam.name ? newTeam : item)),
        ];
      }

      return [...prev, newTeam];
    });

    const loader = createLoading();

    loader.startLoading({ title: 'Saving Team...' });

    const savedTeamId = await saveTeam(newTeam);

    loader.stopLoading();

    if (!savedTeamId) {
      setTeams(prevState);
      showAlert('Error at Updating Team', 'error');
    }

    setMode('view');
    setSelectedTeam(newTeam);
  };

  const handleRemoveUser = (email: string) => {
    if (selectedTeam) {
      handleChangeTeams({
        ...selectedTeam,
        users: selectedTeam.users.filter((user) => user.email !== email),
      });
    }
  };

  const handleRemoveProject = (projectId: string) => {
    if (selectedTeam) {
      handleChangeTeams({
        ...selectedTeam,
        projectIds: selectedTeam.projectIds.filter((id) => id !== projectId),
      });
    }
  };

  const handleAddUser = (teamUser: TeamUser) => {
    if (selectedTeam) {
      handleChangeTeams({
        ...selectedTeam,
        users: [...selectedTeam.users, teamUser],
      });
    }
  };

  const handleAddProject = (projectId: string) => {
    if (selectedTeam) {
      handleChangeTeams({
        ...selectedTeam,
        projectIds: [...selectedTeam.projectIds, projectId],
      });
    }
  };

  const teamNamesMap = useMemo(() => {
    const tempTeamNamesMap: Record<string, boolean> = {};
    teams.forEach((item: Team) => {
      tempTeamNamesMap[item.name] = true;
    });

    return tempTeamNamesMap;
  }, [teams]);

  const handleChangeTeam = (teamName: string) => {
    const exists = teams.find((item) => item.name === teamName);

    if (exists) {
      setSelectedTeam(exists);
    } else {
      setSelectedTeam(null);
    }
  };

  const handleClickAddNewTeam = () => {
    setMode('add-new-team');
  };

  const handleCancel = () => {
    setMode('view');
  };

  const projectMap = useMemo(() => {
    const tempMap = new Map<string, Project>();

    projects.forEach((project) => tempMap.set(project.id, project));

    return tempMap;
  }, [projects]);

  const usersPanelCom = mode === 'view' && selectedTeam && (
    <Stack gap="24px" alignItems="center" className="flex-1">
      {selectedTeam.users && (
        <TeamTable
          columns={['email', 'name', 'role']}
          rows={selectedTeam.users.map((user) => ({
            id: user.email,
            email: user.email,
            name: user.name,
            role: user.role,
          }))}
          onRemove={handleRemoveUser}
          showDeleteButton={
            !!(
              userData &&
              UserRole[userData.role as keyof typeof UserRole] ===
                UserRole.ADMIN
            )
          }
        />
      )}

      {userData &&
        UserRole[userData.role as keyof typeof UserRole] === UserRole.ADMIN && (
          <NewUserForm
            users={users}
            teamUsers={selectedTeam.users}
            onAdd={handleAddUser}
          />
        )}
    </Stack>
  );

  const projectsPanelCom = mode === 'view' && selectedTeam && (
    <Stack gap="24px" alignItems="center" className="flex-1">
      {selectedTeam.projectIds && (
        <TeamTable
          columns={['name', 'id']}
          rows={selectedTeam.projectIds.map((projectId) => ({
            id: projectId,
            name: projectMap.get(projectId)?.name || '',
          }))}
          onRemove={handleRemoveProject}
          showDeleteButton={
            !!(
              userData &&
              UserRole[userData.role as keyof typeof UserRole] ===
                UserRole.ADMIN
            )
          }
        />
      )}

      {userData &&
        UserRole[userData.role as keyof typeof UserRole] === UserRole.ADMIN && (
          <NewProjectForm
            projects={projects}
            teamProjectIds={selectedTeam.projectIds}
            onAdd={handleAddProject}
          />
        )}
    </Stack>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        className="absolute left-1/2 top-1/2 m-[3px] max-h-[60%] w-[80%] min-w-[350px] max-w-[700px] -translate-x-1/2 -translate-y-1/2 !rounded-2xl border-[5px] border-[#38384A] bg-black p-[5px]"
        gap="5px"
      >
        <Stack
          className=" rounded-2xl p-[12px]"
          sx={{ backgroundImage: `linear-gradient(#38384A 0%, black 90%)` }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap="24px">
              <Typography variant="h4" className="uppercase" color="text.white">
                Team -
              </Typography>
              {filteredTeams.length === 1 ? (
                <Typography
                  variant="h4"
                  className="uppercase"
                  color="text.white"
                >
                  {filteredTeams[0].name}
                </Typography>
              ) : (
                selectedTeam && (
                  <TeamSelector
                    teams={filteredTeams.map((item) => item.name)}
                    value={selectedTeam.name}
                    onChange={handleChangeTeam}
                  />
                )
              )}
            </Stack>

            {userData &&
              UserRole[userData.role as keyof typeof UserRole] ===
                UserRole.ADMIN && (
                <IconButton onClick={handleClickAddNewTeam}>
                  <AddIcon className="text-white" />
                </IconButton>
              )}
          </Stack>

          {mode === 'view' ? (
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              className="border-b border-primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab} label={tab} value={tab} className="text-white" />
              ))}
            </Tabs>
          ) : null}

          {mode === 'add-new-team' && (
            <NewTeamForm
              teamNames={teamNamesMap}
              onAdd={handleChangeTeams}
              onCancel={handleCancel}
            />
          )}
        </Stack>

        <Stack gap="24px" className="overflow-y-scroll px-12 pb-16">
          {currentTab === 'users' ? usersPanelCom : projectsPanelCom}
        </Stack>
      </Stack>
    </Modal>
  );
}
