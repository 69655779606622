import { ReactNode, useState } from 'react';

import { Divider, Link, Stack, Typography } from '@mui/material';

import { ActionItem, ActionItemCategory } from '@urbanmix-tech/shared-js';
import { getActionColorByCategory } from '@/helpers/common';
import { useUIContext } from '@/shared/context/UIContextProvider';

import AppIcon from '@/components/shared/AppIcon/AppIcon';

import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ErrorBoundary } from '@sentry/react';
import { deleteActionItem, saveActionItem } from '@urbanmix-tech/shared-js/data';

export type ModalContentProps = {
  actionCategory: ActionItemCategory;
  closeComponent?: ReactNode;
  headerTitle: ReactNode;
  description?: ReactNode;
  owner?: string;
  supervisor?: string;
  followers?: string[];
  moreInfo?: string;
  actionComponent: ReactNode;
  background?: string;
  width?: string;
  height?: string;
  padding?: string;
  onChangeModalOpen: (val: boolean) => void;
  children?: ReactNode;
  form_scheme?: JsonSchema;
  form_content?: any;
  form_ui_scheme?: UISchemaElement;
  on_content_change?: (data: any) => void;
  actionItem?: ActionItem;
  readonly?: boolean;
};

export function ModalContent({
  actionCategory,
  closeComponent,
  headerTitle,
  description,
  owner,
  supervisor,
  followers,
  moreInfo,
  actionComponent,
  background,
  width,
  height,
  padding,
  onChangeModalOpen,
  children,
  form_scheme,
  form_content,
  form_ui_scheme,
  on_content_change,
  actionItem,
  readonly = false,
}: ModalContentProps) {
  const ui_schema = form_ui_scheme;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { openConfirm } = useUIContext();

  const confirmDelete = () => {
    if (!actionItem?.id) {
      return;
    }

    deleteActionItem(actionItem?.id);
    // close the modal
    onChangeModalOpen(false);
  };

  const confirmMakeActive = () => {
    if (!actionItem?.id) {
      return;
    }

    saveActionItem({
      ...actionItem,
      status: 'active',
      completedOn: undefined,
    });

    handleClose();
    onChangeModalOpen(false);
  };

  const handleMakeActive = () => {
    openConfirm({
      title: 'Are you sure you want to make this task active?',
      description:
        'This will not reverse any actions or processes that have been created or triggers in response to the task completion.',
      onAction: (value: boolean) => {
        if (value) {
          confirmMakeActive();
        }
      },
    });
  };

  const handleDelete = () => {
    // ask the user to confirm the deletion
    // then delete the action item
    openConfirm({
      title: 'Are you sure you want to delete this task?',
      description:
        'Deleting this task does not reverse or undo any actions or processes.',
      onAction: (value: boolean) => {
        if (value) {
          confirmDelete();
        }
      },
    });
  };

  const ITEM_HEIGHT = 48;

  const menu = (
    <div className="flex">
      <IconButton
        className="p-0"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        <MenuItem
          key="delete"
          selected={false}
          onClick={handleDelete}
          disabled={readonly}
        >
          <Typography
            variant="caption"
            className="uppercase text-stone-200 text-opacity-100"
          >
            Delete
          </Typography>
        </MenuItem>
        <MenuItem
          key="uncomplete"
          selected={false}
          onClick={handleMakeActive}
          disabled={actionItem?.status !== 'done' || readonly}
        >
          <Typography
            variant="caption"
            className="uppercase text-stone-200 text-opacity-100"
          >
            Make Active
          </Typography>
        </MenuItem>
        <Divider orientation="horizontal" className="bg-zinc-600" />
        <div>
          <Stack direction="row" gap="4px" alignItems="center">
            <Typography
              variant="caption2"
              className="uppercase text-stone-200 text-opacity-80"
            >
              owner: {owner || '-'}
              <br />
              supervisor: {supervisor || '-'}
              <br />
              followers: {followers ? followers?.join(', ') : '-'}
              <br />
            </Typography>
            {moreInfo && (
              <>
                <Divider orientation="horizontal" className="bg-zinc-600" />
                <Typography
                  variant="caption2"
                  className="uppercase text-stone-200 text-opacity-80"
                >
                  More info:
                  {moreInfo}
                </Typography>
              </>
            )}
          </Stack>
        </div>
      </Menu>
    </div>
  );

  return (
    <Stack
      gap="24px"
      className="relative max-h-[90vh] max-w-[90vw] rounded-[10px] border-[1px] border-blue-400 border-opacity-[0.55]"
      sx={{
        background: background || '#0A0E15',
        width,
        height,
        padding: padding || '32px',
        borderColor: getActionColorByCategory(actionCategory),
      }}
    >
      {closeComponent || (
        <Stack className="absolute left-16 top-24 flex w-[calc(100%-32px)] flex-row">
          <ErrorBoundary>{menu}</ErrorBoundary>
          <Typography
            variant="h4"
            className="mb-auto mt-auto flex-1 text-center !text-white"
          >
            {headerTitle}
          </Typography>
          <AppIcon
            name="close"
            className="vertical-align-middle mb-auto mt-auto cursor-pointer text-stone-300"
            onClick={() => onChangeModalOpen(false)}
          />
        </Stack>
      )}

      <div className="max-h-[70vh] overflow-y-auto pt-32">
        {/* <Divider orientation="horizontal" className="bg-zinc-600" /> */}
        <Stack direction="column" gap="8px" className="min-w-[600px] pt-16">
          {description && description}
        </Stack>
        {children}
      </div>
      <Stack className="flex flex-col">
        {(form_content && JSON.stringify(form_content) != '{}') ||
        (form_scheme && JSON.stringify(form_scheme) != '{}') ? (
          <>
            <Divider orientation="horizontal" className="bg-zinc-600" />
            <div className="json-forms block flex-1 p-30">
              <JsonForms
                readonly={readonly}
                data={form_content}
                schema={form_scheme}
                uischema={ui_schema}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ errors, data }) => {
                  if (on_content_change) on_content_change({ errors, data });
                }}
              />
            </div>
          </>
        ) : null}
        <Stack direction="row" gap="24px">
          <div className="flex-1">{actionComponent}</div>

          <Link
            href={`/projects/${actionItem?.projectId}/${actionItem?.buildingId}/units/${actionItem?.unitId}`}
          >
            <Typography
              variant="caption2"
              className="mb-auto mt-auto flex-1 text-center "
            >
              go to unit
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}
