import { SvgIcon, type SvgIconProps } from '@mui/material';

export function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.33333 11.8605L4 8.52718L7.33333 5.19385"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9998 11.8605L8.6665 8.52718L11.9998 5.19385"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
