import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AlertProvider } from '@/contexts/Alert/AlertContext';

import router from '@/router';

import muiTheme from '@/mui/mui-reset';
import { initialize } from '@urbanmix-tech/shared-js/data';

import {
  AppContext,
  AppContextProvider,
} from '@/shared/context/AppContextProvider';
import { initReporting } from '@/shared/monitoring';
import { AuthProvider } from '@/shared/context/AuthProvider';
import { UIContextProvider } from '@/shared/context/UIContextProvider';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import { getApps } from 'firebase/app';
import { BuildingModelProvider } from './contexts/Building/BuildingContext';

const {
  VITE_FIREBASE_APIKEY,
  VITE_FIREBASE_AUTHDOMAIN,
  VITE_FIREBASE_PROJECTID,
  VITE_FIREBASE_STORAGEBUCKET,
  VITE_FIREBASE_MESSAGINGSENDERID,
  VITE_FIREBASE_APPID,
  VITE_FIREBASE_MEASUREMENTID,
  VITE_USE_FUNCTIONS_EMULATOR,
} = import.meta.env;

initReporting();

// TODO decide on enviroment and corrosponding firebase config
initialize({
  apiKey: VITE_FIREBASE_APIKEY,
  authDomain: VITE_FIREBASE_AUTHDOMAIN,
  projectId: VITE_FIREBASE_PROJECTID,
  storageBucket: VITE_FIREBASE_STORAGEBUCKET,
  messagingSenderId: VITE_FIREBASE_MESSAGINGSENDERID,
  appId: VITE_FIREBASE_APPID,
  measurementId: VITE_FIREBASE_MEASUREMENTID,
});

// TODO FIX THIS so that you can toggle between emulators and production
if (VITE_USE_FUNCTIONS_EMULATOR === 'true') {
  const app = getApps()[0];
  console.log("frontend using functions emulators 2",VITE_USE_FUNCTIONS_EMULATOR);
  // get functions from app
  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
} 

const App = () => {
  const { value } = AppContext();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <AlertProvider>
          <AuthProvider>
            <AppContextProvider.Provider value={value}>
              <UIContextProvider>
                <BuildingModelProvider>
                  <CssBaseline />
                  <RouterProvider router={router} />
                </BuildingModelProvider>
              </UIContextProvider>
            </AppContextProvider.Provider>
          </AuthProvider>
        </AlertProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
