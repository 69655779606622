import { useState } from 'react';
import { Stack, Select, MenuItem, Typography, Modal, Box } from '@mui/material';
import dayjs from 'dayjs';

import { useAlert } from '@/contexts/Alert/AlertContext';
import { useLoading } from '@/shared/context/UIContextProvider';
import { Unit } from '@urbanmix-tech/shared-js';
import { updateUnit } from '@urbanmix-tech/shared-js/data';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';
import { Input } from '../shared/Inputs/Input';

const numberKeys = {
  floor: 'Floor*',
  numBedrooms: 'Bedrooms*',
  numBathrooms: 'Bathrooms*',
  sqft: 'SQFT*',
  marketRent: 'Market Rent*',
  actualRent: 'Actual Rent*',
  residentDeposit: 'Resident Deposit*',
  recurringCharges: 'Recurring Charges*',
  turnoverCost: 'Trunover Cost*',
};
const strKeys = {
  name: 'Unit Name*',
  line: 'Unit Line*',
  type: 'Unit Type*',
  floorPlanPath: 'Floor Plan Path*',
  coverImagePath: 'Cover Image Path*',
  modelPath: 'Model Path*',
  '3dScanPath': '3D Scan Path*',
  meshId: 'Mesh Id*',
  turnoverGCId: 'Turnover GC ID*',
  leaseOfficeId: 'Lease Office Id*',
};
const dateKeys = {
  contractSignedDate: 'Contract Signed Date*',
  leaseStartDate: 'Lease Start Date*',
  scheduledMoveInDate: 'Scheduled Move In Date*',
  actualMoveInDate: 'Actual Move In Date*',
  leaseEndDate: 'Lease End Date*',
  scheduledMoveOutDate: 'Scheduled Move Out Date*',
  actualMoveOutDate: 'Actual Move Out Date*',
  turnoverSignOffDate: 'Turnover Sign Off Date*',
  scheduledTurnoverStartDate: 'Scheduled Turnover Start Date*',
  actualTurnoverStartDate: 'Actual Turnover Start Date*',
  scheduledTurnoverCompletionDate: 'Scheduled Turnover Completion Date*',
  actualTurnoverCompletionDate: 'Actual Turnover Completion Date*',
  turnoverPaymentDate: 'Turnover Paytment Date*',
  leasePublishDate: 'Lease Publish Date*',
};
// const specialKeys = {
//   currentState: 'Current State*',
//   affordable: 'Affordable',
// };
const keys = [
  'name',
  'line',
  'type',
  'floor',
  'floorPlanPath',
  'coverImagePath',
  'modelPath',
  '3dScanPath',
  'currentState',
  'meshId',
  'numBedrooms',
  'numBathrooms',
  'sqft',
  'affordable',
  'marketRent',
  'contractSignedDate',
  'leaseStartDate',
  'scheduledMoveInDate',
  'actualMoveInDate',
  'actualRent',
  'residentDeposit',
  'recurringCharges',
  'leaseEndDate',
  'scheduledMoveOutDate',
  'actualMoveOutDate',
  'turnoverGCId',
  'turnoverSignOffDate',
  'scheduledTurnoverStartDate',
  'actualTurnoverStartDate',
  'scheduledTurnoverCompletionDate',
  'actualTurnoverCompletionDate',
  'turnoverCost',
  'turnoverPaymentDate',
  'leasePublishDate',
  'leaseOfficeId',
];

export type UnitMixEditModalProps = {
  isModalOpen: boolean;
  unit: Unit;
  onClose(): void;
};

export function UnitMixEditModal({
  isModalOpen,
  unit,
  onClose,
}: UnitMixEditModalProps) {
  const { showAlert } = useAlert();
  const { createLoading } = useLoading();

  const [editedUnit, setEditedUnit] = useState<Unit>(unit);

  const handleEdit = async () => {
    const record: Record<string, unknown> = editedUnit;

    delete record.lastInspectionId;

    const loader = createLoading();
    loader.startLoading({ title: 'Updating Unit Mix...' });

    try {
      await updateUnit(unit.projectId, unit.id, record);
    } catch (err) {
      console.log(err);
      showAlert('Failed at Updating Unit Mix', 'error');
    }

    loader.stopLoading();

    onClose();
    window.location.reload();
  };

  const handleChangeUnit = (key: string, value: unknown) => {
    if (dateKeys[key as keyof typeof dateKeys]) {
      setEditedUnit((prev) => ({
        ...prev,
        [key]: new Date(value as string),
      }));
    } else {
      setEditedUnit((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const coms = keys.map((key) => {
    if (numberKeys[key as keyof typeof numberKeys]) {
      return (
        <Stack key={key} gap="4px">
          <Typography variant="caption2" className="uppercase text-stone-200">
            {numberKeys[key as keyof typeof numberKeys]}
          </Typography>
          <Input
            onChange={(e) => handleChangeUnit(key, e.currentTarget.value)}
            value={editedUnit[key as keyof typeof editedUnit] || 0}
            type="number"
            fullWidth
            placeholder={numberKeys[key as keyof typeof numberKeys]}
            className="border border-primary text-stone-200"
            required
          />
        </Stack>
      );
    }

    if (strKeys[key as keyof typeof strKeys]) {
      return (
        <Stack key={key} gap="4px">
          <Typography variant="caption2" className="uppercase text-stone-200">
            {strKeys[key as keyof typeof strKeys]}
          </Typography>
          <Input
            onChange={(e) => handleChangeUnit(key, e.currentTarget.value)}
            value={editedUnit[key as keyof typeof editedUnit] || ''}
            type="text"
            fullWidth
            placeholder={strKeys[key as keyof typeof strKeys]}
            className="border border-primary text-stone-200"
            required
          />
        </Stack>
      );
    }

    if (dateKeys[key as keyof typeof dateKeys]) {
      const date = editedUnit[key as keyof typeof editedUnit]
        ? convertPseudoDateToRealDate(
            editedUnit[key as keyof typeof editedUnit] as Date
          )
        : '';

      return (
        <Stack key={key} gap="4px">
          <Typography variant="caption2" className="uppercase text-stone-200">
            {dateKeys[key as keyof typeof dateKeys]}
          </Typography>
          <Input
            onChange={(e) => handleChangeUnit(key, e.currentTarget.value)}
            value={date ? dayjs(date).format('YYYY-MM-DD') : ''}
            type="date"
            fullWidth
            placeholder={dateKeys[key as keyof typeof dateKeys]}
            className="border border-primary text-stone-200"
            required
          />
        </Stack>
      );
    }

    if (key === 'currentState') {
      return (
        <Stack key={key} gap="4px">
          <Typography variant="caption2" className="uppercase text-stone-200">
            Current State
          </Typography>
          <Select
            variant="outlined"
            className="bg-[rgba(255,255,255,0.2)] text-white"
            size="small"
            value={editedUnit.currentState || LeaseLifecycle.NOTICE}
            onChange={(e) => handleChangeUnit(key, e.target.value)}
            MenuProps={{
              slotProps: {
                paper: {
                  className: 'bg-black',
                },
              },
            }}
          >
            {Object.keys(LeaseLifecycle).map((item) => (
              <MenuItem value={item} key={item} className="text-white">
                {item}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      );
    }

    if (key === 'affordable') {
      return (
        <Stack key={key} gap="4px">
          <Typography variant="caption2" className="uppercase text-stone-200">
            Affordable
          </Typography>
          <Select
            variant="outlined"
            className="bg-[rgba(255,255,255,0.2)] text-white"
            size="small"
            value={editedUnit.affordable ? 'Rent Stabilized' : 'Market'}
            onChange={(e) => handleChangeUnit(key, e.target.value)}
            MenuProps={{
              slotProps: {
                paper: {
                  className: 'bg-black',
                },
              },
            }}
          >
            {['Market', 'Rent Stabilized'].map((item) => (
              <MenuItem value={item} key={item} className="text-white">
                {item}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      );
    }

    return null;
  });

  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <Box
        component="div"
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
      >
        <Stack
          gap="24px"
          className="relative w-[500px] rounded-[10px] bg-neutral-950 p-24"
        >
          <Typography variant="h4" className="!text-white">
            Edit Unit Mix
          </Typography>

          <Stack gap="24px" className="h-[70vh] overflow-auto">
            {coms}
          </Stack>

          <ConfirmButtons
            confirm={{
              label: 'Edit',
              onClick: handleEdit,
            }}
            cancel={{
              label: 'Cancel',
              onClick: onClose,
            }}
          />
        </Stack>
      </Box>
    </Modal>
  );
}
