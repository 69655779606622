import * as THREE from 'three';

const anaylzeHighColor = new THREE.MeshStandardMaterial({
  color: '#D9D9D9',
  metalness: 0, // Set metalness to 0 for a non-metallic look
  roughness: 0.8, // Set roughness to a high value for a matte appearance
});
const anaylzeMediumColor = new THREE.MeshStandardMaterial({
  color: '#8D8D8D',
  metalness: 0, // Set metalness to 0 for a non-metallic look
  roughness: 0.8, // Set roughness to a high value for a matte appearance
});
const anaylzeLowColor = new THREE.MeshStandardMaterial({
  color: '#4F4F4F',
  metalness: 0, // Set metalness to 0 for a non-metallic look
  roughness: 0.8, // Set roughness to a high value for a matte appearance
});

const regular = new THREE.MeshStandardMaterial({
  color: '#ffffff',
  metalness: 0, // Set metalness to 0 for a non-metallic look
  roughness: 0.8, // Set roughness to a high value for a matte appearance
});

const inspect = new THREE.MeshStandardMaterial({
  color: '#FF7395',
  metalness: 0,
  roughness: 0.8,
});

const notice = new THREE.MeshStandardMaterial({
  color: '#B67BFF',
  metalness: 0,
  roughness: 0.8,
});

const construct = new THREE.MeshStandardMaterial({
  color: '#FFA760',
  metalness: 0,
  roughness: 0.8,
});

const lease = new THREE.MeshStandardMaterial({
  color: '#8FD3CD',
  metalness: 0,
  roughness: 0.8,
});

const hoveredColor = new THREE.MeshStandardMaterial({
  color: '#95f5f5',
  metalness: 0,
  roughness: 0.8,
});

const selectionColor = new THREE.MeshStandardMaterial({
  color: '#A7C7E7',
  metalness: 0,
  roughness: 0.8,
});

const selectedRegular = new THREE.MeshStandardMaterial({
  color: '#e3e3e3',
  metalness: 0,
  roughness: 0.8,
});

const selectedInspect = new THREE.MeshStandardMaterial({
  color: '#f8819e',
  metalness: 0,
  roughness: 0.8,
});

const selectedConstruct = new THREE.MeshStandardMaterial({
  color: '#eaae7b',
  metalness: 0,
  roughness: 0.8,
});

const selectedLease = new THREE.MeshStandardMaterial({
  color: '#9bd3ce',
  metalness: 0,
  roughness: 0.8,
});

const regularTrans = new THREE.MeshStandardMaterial({
  color: 'lightgray',
  transparent: true,
  opacity: 0.8,
  metalness: 0,
  roughness: 0.8,
});

const windowTrans = new THREE.MeshStandardMaterial({
  color: 'lightgray',
  transparent: true,
  opacity: 0.9,
  metalness: 0,
  roughness: 0.8,  
});

const hardTrans = new THREE.MeshStandardMaterial({
  color: 'lightgray',
  transparent: true,
  opacity: 0,
  metalness: 0,
  roughness: 0.8,
});

const hoveredColorTrans = new THREE.MeshStandardMaterial({
  color: '#6bd1d1',
  transparent: true,
  opacity: 0.5,
  metalness: 0,
  roughness: 0.8,
});

const redColor = new THREE.MeshStandardMaterial({
  color: '#ff7272',
  metalness: 0,
  roughness: 0.8,
});

const greenColor = new THREE.MeshStandardMaterial({
  color: '#00ffc4',
  metalness: 0,
  roughness: 0.8,
});

const plaster = new THREE.MeshPhysicalMaterial({
  color: '#ffffff',
  emissive: '#0000000',
  roughness: 1.0,
  metalness: 0,
  ior: 1.5,
  reflectivity: 0.5,
  iridescence: 0.5,
  iridescenceIOR: 1.3,
  sheenRoughness: 1,
  sheen: 1,

});

const sensorBase = new THREE.MeshPhysicalMaterial({
  color: '#ffffff',
  emissive: '#000000',
  transparent: true,
  opacity: 0.8,
  roughness: 0,
  metalness: 0,
  ior: 0,
  reflectivity: 0,
  iridescence: 0,
  iridescenceIOR: 0,
  sheenRoughness: 0,
  sheen: 0,

});

const sensorToon = new THREE.MeshPhongMaterial({
  color: '#ffffff',
  emissive: '#ffffff',
  emissiveIntensity: 0.3,
  flatShading: true,
  fog: true,
});

function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function getMaterialBetweenDarkToWhite(
  rate: number
): THREE.MeshStandardMaterial {
  const hue = 0;
  const sat = 0;
  const lightness = 100 * rate;

  return new THREE.MeshStandardMaterial({
    color: hslToHex(hue, sat, lightness),
    metalness: 0,
    roughness: 0.8,
  });
}

export const materials = {
  regular,
  notice,
  inspect,
  construct,
  lease,
  hoveredColor,
  regularTrans,
  windowTrans,
  hoveredColorTrans,
  selectionColor,
  selectedInspect,
  selectedConstruct,
  selectedLease,
  selectedRegular,
  redColor,
  greenColor,
  hardTrans,
  anaylzeHighColor,
  anaylzeMediumColor,
  anaylzeLowColor,
  plaster,
  sensorBase,
  sensorToon,
};
