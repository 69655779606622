import { ActionItem, createUnitAction } from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';

import { SendRenewalNoticeModalContent } from './SendRenewalNoticeModalContent/SendRenewalNoticeModalContent';
import { ExtendLeaseOrScheduleModalContent } from './ExtendLeaseOrScheduleModalContent/ExtendLeaseOrScheduleModalContent';
import { CheckRenewalStatusModalContent } from './CheckRenewalStatusModalContent/CheckRenewalStatusModalContent';

export type UnitNoticeModalContentProps = {
  readonly?: boolean;
  projectName: string;
  projectAddress: string;
  buildingName: string;
  unitName: string;
  leaseEndDate: Date;
  actualRent: number;
  actionItem: ActionItem;
  onChangeModalOpen: (val: boolean) => void;
  onApproveAction: (payload?: unknown) => Promise<void>;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
  onScheduleMoveOutDate: (scheduledDate: Date) => Promise<void>;
  onExtendLease: (leaseEndDate: Date, rent: number) => Promise<void>;
};

export function UnitNoticeModalContent({
  readonly,
  projectName,
  projectAddress,
  buildingName,
  unitName,
  leaseEndDate,
  actualRent,
  actionItem,
  onChangeModalOpen,
  onApproveAction,
  onSaveAction,
  onScheduleMoveOutDate,
  onExtendLease,
}: UnitNoticeModalContentProps) {
  const handleSnooze = () => {
    onSaveAction({
      ...actionItem,
      status: 'future',
    });
    onChangeModalOpen(false);
  };

  const handleSendRenewalNotice = () => {
    onApproveAction();

    onSaveAction(
      createUnitAction({
        projectId: actionItem.projectId,
        buildingId: actionItem.buildingId,
        unitId: actionItem.unitId,
        title: actionTitles.checkRenewalStatus,
        description: '',
        category: 'notice',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      })
    );

    onChangeModalOpen(false);
  };

  const handleTenantResponded = () => {
    onApproveAction();

    onSaveAction(
      createUnitAction({
        projectId: actionItem.projectId,
        buildingId: actionItem.buildingId,
        unitId: actionItem.unitId,
        title: actionTitles.extendLeaseOrScheduleMoveOutDate,
        description: '',
        category: 'notice',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      })
    );

    onChangeModalOpen(false);
  };

  const handleApprove = () => {
    onApproveAction();
    onChangeModalOpen(false);
  };

  if (actionItem.title === actionTitles.sendRenewalNotice) {
    return (
      <SendRenewalNoticeModalContent
        readonly={readonly}
        projectName={projectName}
        projectAddress={projectAddress}
        buildingName={buildingName}
        unitName={unitName}
        leaseEndDate={leaseEndDate}
        category={actionItem.category}
        status={actionItem.status}
        onApprove={handleSendRenewalNotice}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.checkRenewalStatus) {
    return (
      <CheckRenewalStatusModalContent
        readonly={readonly}
        category={actionItem.category}
        status={actionItem.status}
        onDone={handleTenantResponded}
        onSnooze={handleSnooze}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.extendLeaseOrScheduleMoveOutDate) {
    return (
      <ExtendLeaseOrScheduleModalContent
        readonly={readonly}
        category={actionItem.category}
        status={actionItem.status}
        leaseEndDate={leaseEndDate}
        actualRent={actualRent}
        onScheduleMoveOutDate={onScheduleMoveOutDate}
        onExtendLease={onExtendLease}
        onApprove={handleApprove}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  return null;
}
