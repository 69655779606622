import { useRef, useEffect, useState, useId, useMemo } from 'react';
import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableCellProps as MuiTableCellProps,
  TableContainer,
  Typography,
} from '@mui/material';
import { twMerge } from 'tailwind-merge';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

export type ITableCell = MuiTableCellProps & {
  label: string;
  value?: number | string | Date | null;
  sortable?: boolean;
};

export type ITableRow = {
  id: string;
  hovered?: boolean;
  data: Record<string, ITableCell>;
};

export type TableProps = MuiTableProps & {
  rows: ITableRow[];
  columns: ITableCell[];
  onClickRow?: (rowId: string) => void;
  onMouseLeaveRow?: (rowId: string) => void;
  onMouseEnterRow?: (rowId: string) => void;
  height?: string;
  headerHeight?: string;
  rowHeight?: string;
  px?: number;
};

export function Table({
  rows,
  columns,
  height,
  stickyHeader,
  headerHeight,
  rowHeight,
  px = 32,
  onClickRow,
  onMouseLeaveRow,
  onMouseEnterRow,
  ...rest
}: TableProps) {
  const id = useId();

  const ref = useRef<HTMLTableElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement | null>(null);

  const [hasScroll, setHasScroll] = useState(false);
  const [sortKey, setSortKey] = useState<string>('');
  const [ascOrder, setAscOrder] = useState<boolean | null>(null);

  const tblRows = useMemo(() => {
    if (!sortKey) return rows;

    return rows.sort((a, b) => {
      const first = a.data[sortKey].value;
      const second = b.data[sortKey].value;
      const direction = ascOrder || ascOrder === null ? 1 : -1;

      if (first === undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === undefined && second !== undefined) {
        return -1 * direction;
      }

      if (first !== undefined && second === undefined) {
        return 1 * direction;
      }

      if (first === second) {
        return 0;
      }

      if (first! > second!) {
        return 1 * direction;
      }

      if (first! < second!) {
        return -1 * direction;
      }

      return 0;
    });
  }, [rows, sortKey, ascOrder]);

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const table = ref.current;

    if (columns.length < 2 || rows.length === 0) {
      return () => {
        const spacingElements = table.getElementsByClassName(
          `${id}-spacing-element`
        );

        for (let i = 0; i < spacingElements.length; i += 1) {
          spacingElements[i].remove();
        }
      };
    }

    for (let i = 0; i < columns.length; i += 1) {
      const elements = table.getElementsByClassName(`${id}-${i}`);

      let maxWidth = 0;

      for (let j = 0; j < elements.length; j += 1) {
        maxWidth = Math.max(maxWidth, elements[j].clientWidth);
      }

      for (let j = 0; j < elements.length; j += 1) {
        const space = maxWidth - elements[j].clientWidth;
        const newDiv = document.createElement('div');
        newDiv.setAttribute('class', `${id}-spacing-element`);
        newDiv.style.width = `${space}px`;
        newDiv.style.height = `1px`;
        newDiv.style.display = 'inline-block';
        elements[j].appendChild(newDiv);
      }
    }

    return () => {
      const spacingElements = table.getElementsByClassName(
        `${id}-spacing-element`
      );

      for (let i = 0; i < spacingElements.length; i += 1) {
        spacingElements[i].remove();
      }
    };
  }, [columns, rows, id, px]);

  useEffect(() => {
    const tblBody = bodyRef.current;

    if (tblBody) {
      const hasVerticalScrollbar = tblBody.scrollHeight > tblBody.clientHeight;
      const hasHorizontalScrollbar = tblBody.scrollWidth > tblBody.clientWidth;

      if (hasVerticalScrollbar || hasHorizontalScrollbar) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }
  }, [rows]);

  const handleDir = (label: string) => {
    if (ascOrder === null) {
      setAscOrder(true);
      return;
    }

    if (sortKey === label) {
      setAscOrder((prevOrder) => !prevOrder);
    } else {
      setSortKey(label);
    }
  };

  return (
    <TableContainer
      className="rounded-xl border border-white/20"
      sx={{ maxHeight: height, overflow: 'hidden' }}
    >
      <MuiTable
        ref={ref}
        className={twMerge(stickyHeader && 'relative flex w-full flex-col')}
        {...rest}
      >
        <TableHead
          className={twMerge(stickyHeader && 'sticky top-0 z-0 flex w-full')}
        >
          <TableRow
            className="flex w-full justify-between"
            sx={{
              gap: `8px`,
              '& > .MuiTableCell-head': {
                paddingTop: headerHeight ? '7px!important' : 'auto',
              },
              paddingRight: hasScroll ? '8px' : 'none',
            }}
          >
            {columns.map(
              (
                { sortable, label, children, value, className, ...rest1 },
                index
              ) => (
                <TableCell
                  key={label}
                  {...rest1}
                  className={twMerge(
                    'group relative flex cursor-pointer justify-start',
                    `${id}-${index}`,
                    className,
                    headerHeight ? '!text-[12px]' : ''
                  )}
                  sx={{
                    ':first-of-type': {
                      paddingLeft: `${px}px` || '32px',
                    },
                    ':last-of-type': {
                      paddingRight: `${px}px` || '32px',
                    },
                    height: headerHeight,
                    fontSize: headerHeight ? '12px' : '16px',
                  }}
                  onClick={() => handleDir(label)}
                >
                  {children || label}
                  {sortable ? (
                    <AppIcon
                      name={
                        label === sortKey && ascOrder !== null
                          ? ascOrder
                            ? 'sortAsc'
                            : 'sortDesc'
                          : 'sort'
                      }
                      className="opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 "
                    />
                  ) : null}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody
          className="!overflow-y-auto"
          ref={bodyRef}
          style={{ maxHeight: height }}
        >
          {tblRows.map((row) => (
            <TableRow
              key={row.id}
              className={twMerge(
                'flex w-full justify-between',
                row.hovered && 'MuiTableRow-hover'
              )}
              onClick={() => onClickRow && onClickRow(row.id)}
              onMouseEnter={() => onMouseEnterRow && onMouseEnterRow(row.id)}
              onMouseLeave={() => onMouseLeaveRow && onMouseLeaveRow(row.id)}
              sx={{
                gap: `8px`,
                cursor: onClickRow ? 'pointer' : '',
                '&:hover': {
                  background: onClickRow ? 'rgba(255,255,255,0.12)' : '',
                },
                '&.MuiTableRow-hover': {
                  background: onClickRow ? 'rgba(255,255,255,0.12)' : '',
                },
                '& .MuiTableCell-body': {
                  paddingTop: rowHeight ? '5px' : 'auto',
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell
                  key={column.label}
                  {...row.data[column.label]}
                  className={`flex ${id}-${index} ${column.className || ''}`}
                  sx={{
                    ':first-of-type': {
                      paddingLeft: `${px}px` || '32px',
                    },
                    ':last-of-type': {
                      paddingRight: `${px}px` || '32px',
                    },
                    height: headerHeight,
                  }}
                >
                  {row.data[column.label] && row.data[column.label].children
                    ? row.data[column.label].children
                    : row.data[column.label].label}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {tblRows.length === 0 ? (
            <TableRow>
              <TableCell>
                <Typography variant="caption" className="pl-10 text-stone-200">
                  No data to display
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
