import { Stack, TextField, Typography } from '@mui/material';
import { FileUploadButton } from '@/components/shared/FileUploads/FileUploadButton';
import { isString, isFile } from '@/helpers/common';

type ModelParamersProps = {
  file: string | File | null;
  target: [number, number, number];
  distance: number;
  polarAngle: number;
  azimuthAngle: number;
  onChangeTarget(target: [number, number, number]): void;
  onChangeFile(file: File): void;
};

export default function ModelParamers({
  file,
  target,
  distance,
  polarAngle,
  azimuthAngle,
  onChangeFile,
  onChangeTarget,
}: ModelParamersProps) {
  const handleChangeTarget = (value: number, attr: 'x' | 'y' | 'z') => {
    if (target) {
      switch (attr) {
        case 'x': {
          onChangeTarget([value, target[1], target[2]]);
          return;
        }
        case 'y': {
          onChangeTarget([target[0], value, target[2]]);
          return;
        }
        case 'z': {
          onChangeTarget([target[0], target[1], value]);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      switch (attr) {
        case 'x': {
          onChangeTarget([value, 0, 0]);
          return;
        }
        case 'y': {
          onChangeTarget([0, value, 0]);
          return;
        }
        case 'z': {
          onChangeTarget([0, 0, value]);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  let filename: string = '';

  if (isString(file)) {
    filename = file;
  } else if (isFile(file)) {
    filename = file.name;
  }

  return (
    <Stack gap="10px">
      <Stack>
        <Typography variant="caption" className="text-white">
          Please upload a model
        </Typography>
        <FileUploadButton
          accept=".glb"
          label={filename}
          onSelect={onChangeFile}
        />
      </Stack>
      <Stack>
        <Typography variant="caption" className="text-white">
          Please change a center point
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
        >
          <TextField
            type="number"
            variant="standard"
            label="X"
            value={target ? target[0] : ''}
            onChange={(e) => handleChangeTarget(+e.target.value, 'x')}
          />
          <TextField
            type="number"
            variant="standard"
            label="Y"
            value={target ? target[1] : ''}
            onChange={(e) => handleChangeTarget(+e.target.value, 'y')}
          />
          <TextField
            type="number"
            variant="standard"
            label="Z"
            value={target ? target[2] : ''}
            onChange={(e) => handleChangeTarget(+e.target.value, 'z')}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Stack sx={{ flex: 1 }}>
          <TextField
            type="number"
            variant="standard"
            label="Distance"
            value={distance}
            sx={{
              flex: 1,
              '& .Mui-disabled': {
                WebkitTextFillColor: 'white',
              },
            }}
            className="!text-white"
            disabled
          />
        </Stack>

        <Stack sx={{ flex: 1 }}>
          <TextField
            type="number"
            variant="standard"
            label="Polar Angle"
            value={polarAngle}
            sx={{
              flex: 1,
              '& .Mui-disabled': {
                WebkitTextFillColor: 'white',
              },
            }}
            disabled
          />
        </Stack>

        <Stack sx={{ flex: 1 }}>
          <TextField
            type="number"
            variant="standard"
            label="Azimuth Angle"
            value={azimuthAngle}
            sx={{
              flex: 1,
              '& .Mui-disabled': {
                WebkitTextFillColor: 'white',
              },
            }}
            disabled
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
