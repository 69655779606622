import { Divider, Stack, Typography } from '@mui/material';
import { Unit } from '@urbanmix-tech/shared-js';

import { convertPseudoDateToRealDate } from '@/helpers/common';
import dayjs from 'dayjs';
import { useAuth } from '@/shared/context/AuthProvider';

export type UnitDetailsProps = {
  unit: Unit;
};

export function UnitDetails({ unit }: UnitDetailsProps) {
  const date = unit.leaseEndDate || unit.actualMoveOutDate;

  console.log('unit', unit);

  return (
    <Stack
      className="w-full"
      direction="row"
      gap="22px"
      alignItems="flex-start"
    >
      <Stack direction="row" gap="16px">
        <Stack gap="1px">
          <Typography variant="body2" className="text-stone-200/80">
            Lease ends
          </Typography>

          <Typography variant="caption" className="!text-sm text-stone-200/80">
            {date
              ? dayjs(convertPseudoDateToRealDate(date)).format('MMM DD, YYYY')
              : '-'}
          </Typography>
        </Stack>
        <Divider orientation="vertical" className="h-48 border-zinc-700" />
        <Stack gap="1px">
          <Typography variant="body2" className="text-stone-200/80">
            {unit.numBedrooms === 0
              ? `Studio | ${unit.numBathrooms} Bath`
              : `${unit.numBedrooms} BD | ${unit.numBathrooms} Bath`}
          </Typography>
          <Typography variant="caption" className="!text-sm text-stone-200/80">
            {`$${unit.affordable ? unit.actualRent : unit.marketRent} | ${
              unit.affordable ? 'Affordable' : 'Market'
            } Rate`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
