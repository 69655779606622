import { Typography } from '@mui/material';

import { ActionItem } from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type ConsiderInspectorModalContentProps = {
  unitName: string;
  readonly?: boolean;
  action: ActionItem;
  onSkipAction: () => void;
  onApproveAction: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function ConsiderInspectorModalContent({
  unitName,
  readonly,
  action,
  onSkipAction,
  onApproveAction,
  onChangeModalOpen,
}: ConsiderInspectorModalContentProps) {
  const handleSkip = () => {
    onSkipAction();
    onChangeModalOpen(false);
  };

  return (
    <ModalContent
      actionCategory={action.category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle="Consider a new inspection"
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Start Inspection',
            disabled: !['active', 'future'].includes(action.status) || readonly,
            onClick: onApproveAction,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(action.status) || readonly,
            onClick: handleSkip,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    >
      <Typography
        variant="caption"
        className="!font-thin text-stone-200 text-opacity-80"
      >
        Consider a new inspection for Unit {unitName}
      </Typography>
    </ModalContent>
  );
}
