import { useState, useCallback, SyntheticEvent } from 'react';
import {
  Avatar,
  Button,
  Typography,
  Stack,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';

import { TeamsModal } from '@/components/TeamsModal/TeamsModal';
import { Autocomplete } from '@/components/shared/Autocomplete/Autocomplete';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLoading } from '@/shared/context/UIContextProvider';

import {
  useAuth,
  ImpersonatedTeamOptionType,
  defaultImpersonatedTeamOption,
} from '@/shared/context/AuthProvider';

const MainAppUserInfo = () => {
  const {
    userData,
    signOut,
    setSelectedOption,
    selectedOption,
    teamOptions,
    isEditMode,
    setIsEditMode,
  } = useAuth();

  interface GetGmailAutherizationUrlResultType {
    data: {
      authorizationUrl: string;
    };
  }

  const { createLoading } = useLoading();  

  const connectGmail = async () => {
    const functions = getFunctions();
    // call the onCall function autherizationUrl
    const getGmailAutherizationUrl = httpsCallable(
      functions,
      'getGmailAuthorizationUrl'
    );
    const result =
      (await getGmailAutherizationUrl()) as GetGmailAutherizationUrlResultType;

    console.log('done with google');
    if (result.data && result.data.authorizationUrl) {
      window.location.href = result.data.authorizationUrl;
    }
  };

  const handleGmailConnect = () => {
    const loader = createLoading();

    loader.startLoading({
      title: 'Contacting Google...',
    });
    connectGmail().then(() => {
      // loader.stopLoading();
    });
  };

  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [impersonatedTeamInputFilter, setImpersonatedTeamInputFilter] =
    useState('');

  const handleChangeSelectedOption = (
    _event: SyntheticEvent<Element, Event>,
    value: ImpersonatedTeamOptionType | null
  ) => {
    if (value) {
      setSelectedOption(value);
    } else {
      setSelectedOption(defaultImpersonatedTeamOption);
    }
  };

  const handleChangeFilter = (
    _event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setImpersonatedTeamInputFilter(newInputValue);
  };

  const handleOpenTeamModal = useCallback(() => {
    setOpenTeamModal(true);
  }, []);

  const handleCloseTeamModal = useCallback(() => {
    setOpenTeamModal(false);
  }, []);

  return (
    <>
      <Stack className="p-8 pb-12 pr-16">
        <Stack direction="row" alignItems="flex-start" gap="12px">
          <Avatar variant="square" className="rounded-md !bg-[#B67BFF]" />

          <Stack gap="8px">
            <Stack>
              <Typography variant="caption" className="text-stone-200">
                {userData?.fullName}
              </Typography>
              <Typography variant="caption2" className="text-stone-200/80">
                {userData?.email}
              </Typography>
            </Stack>
            {userData?.isDev ? (
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={isEditMode}
                    onChange={(e) => setIsEditMode(e.target.checked)}
                  />
                }
                slotProps={{
                  typography: {
                    className: 'text-stone-200 text-sm',
                  },
                }}
                label="Edit Mode"
                labelPlacement="end"
              />
            ) : null}
            {userData?.isDev ? (
              <Button
                variant="outlined"
                onClick={handleOpenTeamModal}
                className="w-fit"
                color="primary"
                sx={{
                  color: '#E6E3E3CC !important',
                  borderColor: '#E6E3E3CC !important',
                  lineHeight: '14px',
                  '&:hover, &:focus': {
                    color: '#CD3185 !important',
                    borderColor: '#CD3185 !important',
                    outline: 'none',
                  },
                }}
                startIcon={<Diversity1OutlinedIcon className="text-lg" />}
              >
                Manage Teams
              </Button>
            ) : null}
            {userData?.isDev ? (
              <Autocomplete
                value={selectedOption}
                onChange={handleChangeSelectedOption}
                inputValue={impersonatedTeamInputFilter}
                onInputChange={handleChangeFilter}
                options={teamOptions}
                getOptionLabel={(option) => option.label}
                fullWidth
                className="w-[263px]"
              />
            ) : null}
            <div className="my-8 border-t border-stone-200/20" />
            <Button
              variant="outlined"
              onClick={handleGmailConnect}
              className="w-fit"
              color="primary"
              sx={{
                color: '#E6E3E3CC !important',
                borderColor: '#E6E3E3CC !important',
                lineHeight: '14px',
                '&:hover, &:focus': {
                  color: '#CD3185 !important',
                  borderColor: '#CD3185 !important',
                  outline: 'none',
                },
              }}
            >
              Connect Gmail
            </Button>
            <div className="my-8 border-t border-stone-200/20" />
            <Button
              variant="outlined"
              onClick={signOut}
              className="w-fit"
              color="primary"
              sx={{
                color: '#E6E3E3CC !important',
                borderColor: '#E6E3E3CC !important',
                lineHeight: '14px',
                '&:hover, &:focus': {
                  color: '#CD3185 !important',
                  borderColor: '#CD3185 !important',
                  outline: 'none',
                },
              }}
            >
              Log Out
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TeamsModal open={openTeamModal} onClose={handleCloseTeamModal} />
    </>
  );
};

export default MainAppUserInfo;
