import { useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  Stack,
  Typography,
  Button,
  Modal,
  Box,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Unit } from '@urbanmix-tech/shared-js';

import { Table, ITableCell, ITableRow } from '@/components/shared/Table';
import { FileUpload } from '@/components/shared/FileUploads/FileUpload';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';
import { Input } from '@/components/shared/Inputs/Input';

import { useUIContext, useLoading } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';

import { uploadUnitAttachment } from '@urbanmix-tech/shared-js/data';

export type FilesTabContentProps = {
  unit: Unit | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateUnit(updateData: Record<string, any>): void;
};

export function FilesTabContent({ unit, onUpdateUnit }: FilesTabContentProps) {
  const { openConfirm } = useUIContext();
  const { showAlert } = useAlert();
  const { createLoading } = useLoading();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string>('');

  const files = useMemo(() => unit?.files || [], [unit?.files]);

  const handleOpenUploadModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveFile = async () => {
    if (!file || !unit) {
      showAlert('You are not ready to upload a file', 'error');
      return;
    }

    const loader = createLoading();
    loader.startLoading({ title: 'Uploading...' });

    const url = await uploadUnitAttachment(
      file,
      filename.trim(),
      unit.projectId,
      unit.id
    );

    loader.stopLoading();

    if (!url) {
      showAlert('Failed at uploading a file', 'error');
      return;
    }

    onUpdateUnit({
      files: [...files, { name: filename.trim(), url }],
    });

    handleCloseModal();
  };

  const handleDeleteFile = useCallback(
    (name: string) => {
      openConfirm({
        title: 'Remove file',
        description: 'Are you sure?',
        onAction: (value: boolean) => {
          if (value) {
            const newFiles = files.filter((item) => item.name !== name);

            onUpdateUnit({ files: newFiles });
          }
        },
      });
    },
    [files, onUpdateUnit, openConfirm]
  );

  const handleDownloadFile = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const handleChangeFile = (changedFile: File) => {
    setFile(changedFile);
    setFilename(changedFile.name);
  };

  const columns: ITableCell[] = [
    {
      label: 'Name',
      sortable: true,
    },    
    {
      label: '',
      sortable: false,
    },
  ];

  const rows: ITableRow[] = useMemo(() => {
    if (!files) {
      return [];
    }

    return files.map((item) => {
      const data: Record<string, ITableCell> = {
        Name: {
          label: item.name,
          value: item.name,
          children: (
            <div className="w-[200px] overflow-x-auto">{item.name}</div>
          ),
        },
        URL: {
          label: item.url,
          children: <div className="w-[400px] overflow-x-auto">{item.url}</div>,
        },
        '': {
          label: '',
          children: (
            <>
              <IconButton
                onClick={() => handleDownloadFile(item.url)}
                className="p-0"
              >
                <FileDownloadIcon className="font-[18px] text-stone-200" />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteFile(item.name)}
                className="p-0"
              >
                <DeleteIcon className="font-[18px] text-stone-200" />
              </IconButton>
            </>
          ),
        },
      };

      return {
        id: item.name,
        data,
      } as ITableRow;
    });
  }, [files, handleDeleteFile, handleDownloadFile]);

  const helperTexts: string[] = [];

  if (file) {
    if (filename.trim() === '') {
      helperTexts.push('Please input filename');
    }

    if (files.find((item) => item.name === filename.trim())) {
      helperTexts.push('Duplicate filename. Please choose another');
    }
  }

  return (
    <Stack gap="16px" className="h-full w-[640px]">
      <Button
        variant="contained"
        className="w-fit px-16 py-8"
        onClick={handleOpenUploadModal}
      >
        <FileUploadIcon />
        Upload file
      </Button>

      <Stack
        className="h-[calc(100%-36px)] rounded-[10px] bg-white/5 p-16"
        gap="16px"
      >
        <Typography variant="caption" className="tracking-tight text-stone-200">
          {files?.length || 0} files
        </Typography>

        <Table
          rows={rows}
          columns={columns}
          stickyHeader
          height="calc(100% - 32px)"
          px={8}
        />
      </Stack>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          component="div"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
        >
          <Stack gap="16px" className="rounded-[10px] bg-gray-950 p-16">
            <FileUpload onChangeFile={handleChangeFile} />
            <Stack>
              <Input
                onChange={(e) => setFilename(e.target.value)}
                value={filename}
                type="text"
                fullWidth
                placeholder="Change file name*"
                className="border border-primary text-stone-200"
                required
              />
              {helperTexts.map((helper) => (
                <Typography
                  key={helper}
                  variant="caption3"
                  className="text-red-400"
                >
                  {helper}
                </Typography>
              ))}
            </Stack>

            <ConfirmButtons
              confirm={{
                label: 'Save',
                disabled: Boolean(file === null || helperTexts.length > 0),
                onClick: handleSaveFile,
              }}
              cancel={{
                label: 'Cancel',
                onClick: handleCloseModal,
              }}
            />
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
}
