import { menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import colors from '@/mui/colors';

export const menuItemStyles: MenuItemStyles = {
  root: {
    fontSize: '14px',
    fontWeight: 400,
    color: colors.text,
  },

  icon: {
    color: 'inherit',
    [`&.${menuClasses.open}`]: {
      fontWeight: 500,
    },
    [`&.${menuClasses.active}`]: {
      fontWeight: 500,
    },
  },

  button: {
    paddingLeft: '14px',
    [`&.${menuClasses.open}`]: {
      color: colors.selected,
    },
    [`&.${menuClasses.active}`]: {
      color: colors.selected,
    },
    '&:hover': {
      color: colors.hovered,
      background: 'transparent',
    },
  },

  label: {
    color: 'inherit',
    [`&.${menuClasses.open}`]: {
      fontWeight: 500,
    },
    [`&.${menuClasses.active}`]: {
      fontWeight: 500,
    },
  },

  subMenuContent: {
    paddingLeft: '56px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%), #0A0E15',
  },

  SubMenuExpandIcon: {
    color: 'inherit',
    [`&.${menuClasses.open}`]: {
      fontWeight: 500,
    },
    [`&.${menuClasses.active}`]: {
      fontWeight: 500,
    },
  },
};
