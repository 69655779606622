import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useAlert } from '@/contexts/Alert/AlertContext';
import { useLoading } from '@/shared/context/UIContextProvider';
import { getProjectById, saveProject } from '@urbanmix-tech/shared-js/data';
import { Project } from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';
import { Input } from '../shared/Inputs/Input';

export type NewProjectModalContentProps = {
  projectId?: string;
  onClose(): void;
};

export function NewProjectModalContent({
  projectId,
  onClose,
}: NewProjectModalContentProps) {
  const { showAlert } = useAlert();
  const { createLoading } = useLoading();

  const [oldProject, setOldProject] = useState<Project | null>(null);

  const [projectName, setProjectName] = useState('');
  const [clientName, setClientName] = useState('');
  const [address, setAddress] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [yearBuilt, setYearBuilt] = useState(new Date().getFullYear());

  useEffect(() => {
    if (projectId) {
      getProjectById(projectId).then((value) => {
        if (value && value.buildings.length > 0) {
          setOldProject(value);

          setProjectName(value.name);
          setClientName(value.clientName);
          setAddress(value.address);
          setBuildingName(value.buildings[0].name);
          setBuildingType(value.buildings[0].type);
          setYearBuilt(value.buildings[0].yearBuilt);
        } else {
          showAlert('Building not exists!', 'error');
        }
      });
    }
  }, [projectId, showAlert]);

  const createNewProject = async () => {
    const newProjectId = uuidv4();

    const loader = createLoading();

    loader.startLoading({
      title: 'Saving new building...',
    });

    const newProject: Project = {
      id: newProjectId,
      clientName,
      buildings: [
        {
          id: 1,
          floors: 0,
          yearBuilt,
          type: buildingType as 'Residential' | 'Commercial' | 'Industrial',
          '3dData': {
            cameraConf: {
              polarAngle: 1,
              azimuthAngle: 1,
              distance: 49,
              target: [-0.8, 15.5, 2.7],
            },
          },
          coverImagePath: null,
          unitSummary: {
            affordableRent: {
              sumOfAffordableRent: 0,
              units: 0,
              sumOfSQFT: 0,
            },
            summariesByType: {},
            marketRent: {
              sumOfSQFT: 0,
              sumOfMarketRent: 0,
              units: 0,
            },
            unitCnt: {
              TURNOVER: {},
              INSPECT: {},
              LEASE: {},
              DONE: {},
              NOTICE: {},
            },
          },
          modelPath: null,
          name: buildingName,
        },
      ],
      name: projectName,
      address,
      coverImagePath: null,
    };

    await saveProject(newProject);

    loader.stopLoading();
  };

  const handleSaveProject = async () => {
    if (projectId && oldProject) {
      const newProject: Project = {
        ...oldProject,
        clientName,
        name: projectName,
        address,
        buildings: [
          {
            ...oldProject.buildings[0],
            type: buildingType as 'Residential' | 'Commercial' | 'Industrial',
            yearBuilt,
            name: buildingName,
          },
        ],
      };

      await saveProject(newProject);
    } else {
      await createNewProject();
    }

    onClose();
    window.location.reload();
  };

  const anyEmpty =
    [projectName, clientName, address, buildingName, buildingType].findIndex(
      (item) => item.trim() === ''
    ) !== -1;

  return (
    <Stack
      gap="24px"
      className="relative w-[500px] rounded-[10px] bg-neutral-950 p-24"
    >
      <Typography variant="h4" className="!text-white">
        {projectId ? 'Edit' : 'Add New'} Building
      </Typography>

      <Input
        onChange={(e) => setProjectName(e.currentTarget.value)}
        value={projectName}
        type="text"
        fullWidth
        placeholder="Project Name*"
        className="border border-primary text-stone-200"
        required
      />

      <Input
        onChange={(e) => setClientName(e.currentTarget.value)}
        value={clientName}
        type="text"
        fullWidth
        placeholder="Project Client Name*"
        className="border border-primary text-stone-200"
        required
      />

      <Input
        onChange={(e) => setAddress(e.currentTarget.value)}
        value={address}
        type="text"
        fullWidth
        placeholder="Project Address*"
        className="border border-primary text-stone-200"
        required
      />

      <Input
        onChange={(e) => setBuildingName(e.currentTarget.value)}
        value={buildingName}
        type="text"
        fullWidth
        placeholder="Building Name*"
        className="border border-primary text-stone-200"
        required
      />

      <Input
        onChange={(e) => setBuildingType(e.currentTarget.value)}
        value={buildingType}
        type="text"
        fullWidth
        placeholder="Building Type*"
        className="border border-primary text-stone-200"
        required
      />

      <Input
        onChange={(e) => setYearBuilt(+e.currentTarget.value)}
        value={yearBuilt}
        type="number"
        inputProps={{
          min: '1500',
          max: `${new Date().getFullYear()}`,
        }}
        fullWidth
        placeholder="Year Built*"
        className="border border-primary text-stone-200"
        required
      />

      <ConfirmButtons
        confirm={{
          label: projectId ? 'Edit' : 'Create',
          disabled: anyEmpty,
          onClick: handleSaveProject,
        }}
        cancel={{
          label: 'Cancel',
          onClick: onClose,
        }}
      />
    </Stack>
  );
}
