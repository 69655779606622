const colors = {
  white: '#FFF',

  text: '#e5e5e5',
  hovered: '#fafaf9',
  selected: '#fff',

  primary: '#CD3185',
  'primary-900': '#F13CA6',
  'primary-800': '#E752A2',
  'primary-700': '#DD68A0',
  'primary-600': '#D2839E',
  'primary-400': '#C79E9C',
  'primary-300': '#D1757E',
  'primary-200': '#99CAFF',
  'primary-100': '#CCE4FF',
  'primary-50': '#E6F2FF',
  'primary-25': '#F2F8FF',
  secondary: '#3A3A3C',
  'info-800': '#28282A',
  'info-700': '#28282A',
  'info-600': '#28282A',
  'info-400': '#5C5B66',
  'info-300': '#757577',
  'info-200': '#939395',
  'info-100': '#B0B0B2',
  'info-50': '#CCCCCD',
  'info-25': '#EBEBEC',

  info: '#5889FF',
  'secondary-900': '#122759',
  'secondary-800': '#233F82',
  'secondary-700': '#3558AC',
  'secondary-600': '#4670D5',
  'secondary-400': '#79A1FF',
  'secondary-300': '#9BB8FF',
  'secondary-200': '#BCD0FF',
  'secondary-100': '#DEE7FF',
  'secondary-50': '#EEF3FF',
  'secondary-25': '#F6F8FF',

  warning: '#E9730A',
  'warning-900': '#2F1702',
  'warning-800': '#5D2E04',
  'warning-700': '#8C4506',
  'warning-600': '#BA5C08',
  'warning-400': '#ED8F3B',
  'warning-300': '#F2AB6C',
  'warning-200': '#F6C79D',
  'warning-100': '#FBE3CE',
  'warning-50': '#FDF1E7',
  'warning-25': '#FEF9F5',

  'info-light': '#F9FBFD',
  'info-light-900': '#34679B',
  'info-light-800': '#5B85AF',
  'info-light-700': '#AAC0D6',
  'info-light-600': '#D2DDE9',
  'info-light-50': '#FAFCFD',

  stroke: '#54EAFF',
  stroke2: '#DDE5EC',
  stroke3: '#53565B',
};

export default colors;
