import { Stack, Typography } from '@mui/material';
import { twMerge } from 'tailwind-merge';

export type SelectStatusProps = {
  label: string;
  status: string;
};

export function SelectStatus({ label, status }: SelectStatusProps) {
  return (
    <Stack direction="row" gap="4px">
      <Typography
        variant="caption"
        className={twMerge('text-inherit opacity-50')}
      >
        {label}
      </Typography>
      <Typography variant="caption" className={twMerge('text-inherit')}>
        {status}
      </Typography>
    </Stack>
  );
}
