import { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs } from '@/components/shared/Breadcrumbs/Breadcrumbs';
import { Card, Stack } from '@mui/material';
import NavigateSelect from '@/components/shared/Selects/NavigateSelect';
import { useProjects } from '@/shared/hooks/useProjects';
import { useLoading } from '@/shared/context/UIContextProvider';
import { getActionItems } from '@urbanmix-tech/shared-js/data';
import { ActionItem } from '@urbanmix-tech/shared-js';
import { useAuth } from '@/shared/context/AuthProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { TodoTable } from '@/components/TodoTabs/TodoTable';
import TodoTabs, { tabList } from '@/components/TodoTabs/TodoTabs';

export default function TodoPortfolio() {
  const projects = useProjects();
  const { createLoading } = useLoading();
  const { allowedProjectIds, userData } = useAuth();
  const { showAlert } = useAlert();
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [tabKey, setTabKey] = useState<string>(tabList[0].tabId);

  const breadcrumbsPath = [
    {
      label: 'Actions Breakdown',
      href: '#',
    },
    {
      label: 'Portfolio',
      href: '/todo',
    },
  ];

  const buildings: Record<string, string> = useMemo(() => {
    const buildingMap: Record<string, string> = {};
    projects.forEach((project) => {
      buildingMap[`${project.id}/${project.buildings[0].id}`] = project.name;
    });
    return buildingMap;
  }, [projects]);

  const handleHoverUnit = useCallback(() => {
    // nop
  }, []);

  useEffect(() => {
    const loader = createLoading();
    if (!userData) {
      return;
    }

    loader.startLoading({ title: 'Loading Todos from db...' });
    getActionItems().then((i) => {
      if (i && i.length > 0) {
        if (userData.isDev) {
          setActionItems(i);
        } else {
          setActionItems(
            i.filter((actionItem) =>
              allowedProjectIds.includes(actionItem.projectId)
            )
          );
        }
      } else {
        showAlert('Todos not exists!', 'error');
      }

      loader.stopLoading();
    });

    loader.stopLoading();
  }, [allowedProjectIds, userData, createLoading, showAlert]);

  return (
    <Stack gap="24px" className="h-full w-full min-w-[470px] p-24">
      <Stack gap="24px" direction="row" className="p-24 pb-0">
        <Breadcrumbs paths={breadcrumbsPath} />
        <NavigateSelect
          pathRoot="todo"
          items={buildings}
          placeHolder="Select Building"
        />
      </Stack>
      <Card
        variant="elevation"
        elevation={0}
        className="h-full min-w-[318px] bg-white bg-opacity-5 p-16"
      >
        <TodoTabs
          setTabKey={setTabKey}
          tabKey={tabKey}
          actionItems={actionItems}
        />
        <TodoTable
          actionItems={actionItems}
          tabKey={tabKey}
          onSelectUnit={handleHoverUnit}
          style={{ width: '100%' }}
        />
      </Card>
    </Stack>
  );
}
