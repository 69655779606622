import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import { useUIContext } from '@/shared/context/UIContextProvider';

export interface BasicRow {
  id: string;
}

export type TeamTableProps<T extends BasicRow> = {
  columns: (keyof T)[];
  rows: T[];
  onRemove(id: string): void;
  showDeleteButton?: boolean;
};

export function TeamTable<T extends BasicRow>({
  columns,
  rows,
  showDeleteButton,
  onRemove,
}: TeamTableProps<T>) {
  const { openConfirm } = useUIContext();

  const handleRemove = (id: string) => {
    const handleAction = (value: boolean) => {
      if (value === true) {
        onRemove(id);
      }
    };

    openConfirm({
      title: 'Delete',
      description: 'Are you sure to remove this?',
      onAction: handleAction,
    });
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column as string} className="uppercase">
              {column as string}
            </TableCell>
          ))}

          {showDeleteButton && <TableCell />}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow className="text-[13px]">
          {rows.length === 0 && (
            <Typography className="mb-8">No data to display</Typography>
          )}
        </TableRow>

        {rows.map((row) => (
          <TableRow key={row.id} className="text-[13px]">
            {columns.map((column) => (
              <TableCell key={column as string}>
                {row[column] as string}
              </TableCell>
            ))}

            {showDeleteButton && (
              <TableCell align="right">
                <IconButton onClick={() => handleRemove(row.id)}>
                  <DeleteOutline sx={{ color: '#9099BF', fontSize: '18px' }} />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
