import { useMemo } from "react";
import { Stack } from "@mui/material";

import { ActionItem, ActionItemCategory } from "@urbanmix-tech/shared-js";

import { ActionGroup } from "./TrackerActionGroup";
import { act } from "@react-three/fiber";

export type ActionGroupListProps = {
  actionItems: ActionItem[];
  onClickActionItem(id: string): void;
  onBuildingDataChange?: (data: Record<string, any>) => void;
};

export function ActionGroupList({
  actionItems,
  onClickActionItem,
  onBuildingDataChange,
}: ActionGroupListProps) {
  const groups = useMemo(() => {
    const unitMap: Record<string, ActionItem[]> = {};

    console.log("action items",actionItems)
    actionItems.forEach((actionItem) => {
      if (unitMap[actionItem.unitId] === undefined) {
        unitMap[actionItem.unitId] = [];
      }
      unitMap[actionItem.unitId].push(actionItem);
    });

    return Object.keys(unitMap)
      .sort()
      .map((unitId) => ({
        id: unitId,
        unitId,
        actionItems: unitMap[unitId],
      }))
      .filter((item) => item.actionItems.length > 0);
  }, [actionItems]);

  return (
    <Stack gap="22px" className="overflow-auto">
      {groups.map((group) => (
        <ActionGroup
          key={group.id}
          actionItems={group.actionItems}
          category="Recent"
          unitId={group.unitId}
          onClickActionItem={onClickActionItem}
        />
      ))}
    </Stack>
  );
}
