import { ChangeEvent } from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const unitKeys = [
  'id',
  'projectId',
  'buildingId',
  'name',
  'line',
  'type',
  'floor',
  'floorPlanPath',
  'coverImagePath',
  'modelPath',
  '3dScanPath',
  'currentState',
  'currentSubState',
  'meshId',
  'numBedrooms',
  'numBathrooms',
  'sqft',
  'affordable',
  'marketRent',

  'contractSignedDate',
  'leaseStartDate',
  'scheduledMoveInDate',
  'actualMoveInDate',

  'actualRent',
  'residentDeposit',
  'recurringCharges',

  'leaseEndDate',
  'scheduledMoveOutDate',
  'actualMoveOutDate',

  'turnoverGCId',
  'turnoverSignOffDate',
  'scheduledTurnoverStartDate',
  'actualTurnoverStartDate',
  'scheduledTurnoverCompletionDate',
  'actualTurnoverCompletionDate',
  'turnoverCost',
  'turnoverPaymentDate',

  'leasePublishDate',
  'leaseOfficeId',
];

export type CsvHeadersProps = {
  validHeader: boolean;
  onChangeValidHeader(value: boolean): void;
  headersMap: Record<string, string>;
  onChange: (headersMap: Record<string, string>) => void;
};

export function CsvHeaders({
  validHeader,
  onChangeValidHeader,
  headersMap,
  onChange,
}: CsvHeadersProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeValidHeader(event.target.checked);
  };

  return (
    <Accordion className="!m-0 bg-transparent">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-stone-200" />}
        className="!m-0 !min-h-0 rounded-md border-b border-neutral-800 bg-white/10 !px-24 !py-8 text-base text-stone-200"
        sx={{ '& .MuiAccordionSummary-content': { margin: '0 !important' } }}
      >
        <Stack direction="row" alignItems="center" gap="30px">
          Check CSV Headers and DB properties
          <Checkbox
            checked={validHeader}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails className="px-0">
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>DB Properties</TableCell>
              <TableCell>Csv Headers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitKeys.map((unitKey) => (
              <TableRow key={unitKey}>
                <TableCell align="right" className="pr-24">
                  {unitKey}
                </TableCell>
                <TableCell>
                  <TextField
                    key={unitKey}
                    value={headersMap[unitKey] || ''}
                    onChange={(e) =>
                      onChange({ ...headersMap, [unitKey]: e.target.value })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}
