import { RouteObject, Navigate } from 'react-router-dom';

import AuthLayout from '@/layouts/AuthLayout';

import PortfolioPage, { PortfolioModals } from '@/pages/Portfolio';
import WelcomePage from '@/pages/Welcome';
import BuildingPage from '@/pages/Building';
import BuildingModelPage from '@/pages/BuildingModel';
import UnitPage from '@/pages/Unit';

import BlockMobileUsagePage from '@/pages/BlockMobileUsage';
import AccessDeniedPage from '@/pages/AccessDeniedPage';
import NotFoundPage from '@/pages/NotFound';

import Login from '@/containers/Auth/Login/Login';
import Register from '@/containers/Auth/Register/Register';

import PublicRoutes from '@/containers/PublicRoutes';
import PrivateRoutes from '@/containers/PrivateRoutes';
import TodoPortfolio from '@/pages/TodoPortfolio';
import TodoBuildingPage from '@/pages/TodoBuildingPage';
import TodoUnitPage from '@/pages/TodoUnitPage';

const privateRoutes: RouteObject[] = [
  {
    path: '/',
    element: <PrivateRoutes />,
    errorElement: <NotFoundPage />,

    children: [
      {
        index: true,
        element: <Navigate to="/portfolio" replace />,
      },
      {
        path: '/google_connect_success',
        element: <PortfolioPage modal="google_connect" />,
      },
      {
        path: 'portfolio',
        element: <PortfolioPage />,
      },
      {
        path: 'todo',
        element: <TodoPortfolio />,
      },
      {
        path: 'todo/:projectId/:buildingId',
        children: [
          {
            index: true,
            element: <TodoBuildingPage />,
          },
          {
            path: 'units/:unitId',
            element: <TodoUnitPage />,
          },
        ],
      },
      {
        path: 'projects/:projectId/:buildingId',
        children: [
          {
            index: true,
            element: <BuildingPage />,
          },
          {
            path: 'model',
            element: <BuildingModelPage />,
          },
          {
            path: 'units/:unitId',
            element: <UnitPage />,
            children: [
              {
                index: true,
                element: <UnitPage />,
              },
              {
                path: 'group/:category',
                element: <UnitPage />,
              },
              {
                path: 'tab/:defaultTab',
                element: <UnitPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'access-denied',
    element: <AccessDeniedPage />,
  },
];

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <PublicRoutes />,
    errorElement: <NotFoundPage />,

    children: [
      {
        path: 'welcome',
        element: <WelcomePage />,
      },
      {
        path: 'block-mobile-usage',
        element: <BlockMobileUsagePage />,
      },
      {
        path: 'login',
        element: (
          <AuthLayout>
            <Login />
          </AuthLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <AuthLayout>
            <Register />
          </AuthLayout>
        ),
      },
    ],
  },
];

const routes: RouteObject[] = [...privateRoutes, ...publicRoutes];

export default routes;
