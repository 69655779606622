import { useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { ActionItem, ActionItemCategory } from '@urbanmix-tech/shared-js';

import { useAuth } from '@/shared/context/AuthProvider';
import { convertPseudoDateToRealDate } from '@/helpers/common';
import { ActionGroup } from './DashboardActionGroup';

const actionCategoryList: ActionItemCategory[] = [
  'notice',
  'inspect',
  'makeReady',
  'lease',
  'general',
];

export type ActionGroupListProps = {
  actionItems: ActionItem[];
  onClickActionItem(id: string): void;
  onBuildingDataChange?: (data: Record<string, any>) => void;
};

export function ActionGroupList({
  actionItems,
  // onClickGroup,
  onClickActionItem,
  onBuildingDataChange,
}: ActionGroupListProps) {
  const { userData } = useAuth();
  const { groups, ownerTasks } = useMemo(() => {
    const unitMap: Record<
      string,
      Record<ActionItemCategory, ActionItem[]>
    > = {};

    const filteredActionItems = actionItems.filter((item: ActionItem) => {
      if (item.status !== 'done') {
        return true;
      }
      if (!item.completedOn) {
        return true;
      }

      // const today = new Date();
      // const completedAt: Date = (item.completedOn as any).toDate();
      // // console.log('completed at',completedAt)

      // console.log('today',today, completedAt,completedAt);

      // console.log('diff',today.getTime() - completedAt.getTime());

      // // if the task was completed more than 1 minute ago return false
      // if (today.getTime() - completedAt.getTime() > 60000) {
      //   return false;
      // }
    });

    const ownTasks = filteredActionItems.filter(
      (item) =>
        (item.owner &&
        item.owner.userId === userData?.id &&
        item.status !== 'done')
    );

    actionItems.forEach((actionItem) => {
      if (unitMap[actionItem.unitId] === undefined) {
        unitMap[actionItem.unitId] = {
          notice: [],
          inspect: [],
          makeReady: [],
          lease: [],
          general: [],
        };
      }

      if (actionCategoryList.includes(actionItem.category) === false) {
        actionCategoryList.push(actionItem.category);
      }

      if (unitMap[actionItem.unitId][actionItem.category] === undefined) {
        unitMap[actionItem.unitId][actionItem.category] = [];
      }

      unitMap[actionItem.unitId][actionItem.category].push(actionItem);
    });

    const newGroups = Object.keys(unitMap)
      .sort()
      .map((key) =>
        actionCategoryList.map((category) => ({
          id: `${key}-${category}`,
          unitId: key,
          category,
          actionItems: unitMap[key][category] ?? [],
          expanded: filteredActionItems.length > 0,
        }))
      )
      .reduce((acc, cur) => [...acc, ...cur], [])
      .filter((item) => item.actionItems.length > 0);

    // if (onBuildingDataChange) {
    //   const data: Record<string, any> = {};
    //   newGroups.forEach((group) => {
    //     data[group.unitId] = {
    //       unit: group.unitId,
    //       unitMeshId: '',
    //       // value: group.expanded ? 1 : 100,
    //       color: materials.notice,
    //     };
    //   });
    //   onBuildingDataChange(data);
    // }

    return { groups: newGroups, ownerTasks: ownTasks };
  }, [actionItems, userData?.id]);

  function getEarliestDueDate(actionItems: ActionItem[]): Date | null {
    if (actionItems.length === 0) return null;
    // Convert dueDates to Date objects and find the earliest one
    return new Date(
      Math.min(
        ...actionItems.map(
          (item) =>
            convertPseudoDateToRealDate(item.dueDate)?.getTime() ??
            new Date().getTime()
        )
      )
    );
  }

  groups.sort((groupA, groupB) => {
    const earliestA = getEarliestDueDate(groupA.actionItems);
    const earliestB = getEarliestDueDate(groupB.actionItems);

    // Handle cases where there may be no action items in a group (optional, if applicable)
    if (earliestA === null) return 1;
    if (earliestB === null) return -1;

    // Compare the two earliest dates to determine sort order
    return earliestA.getTime() - earliestB.getTime();
  });

  return (
    <Stack gap="22px" className="overflow-auto">
      {ownerTasks && ownerTasks.length == 0 ? (
        <Typography variant="h6" className="text-center py-32">
          No more tasks for today
        </Typography>
      ) : null}
      {groups.map((group) => (
        <ActionGroup
          key={group.id}
          actionItems={group.actionItems}
          category={group.category}
          onClickActionItem={onClickActionItem}
          unitId={group.unitId}
        />
      ))}
    </Stack>
  );
}
