import { useMemo, useState } from 'react';
import { Stack, Typography, Button, Modal, Box } from '@mui/material';
import dayjs from 'dayjs';

import {
  Inspection,
  ConditionReportItem,
  ActionItem,
} from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';
import { InspectionAccordion } from '@/components/ActionModals/units/InspectionAccordion';
import { ModalContent } from '@/components/ActionModals/containers/ModalContent';
import { InspectionStatus } from '@urbanmix-tech/shared-js';

import { InspectionApproveModalContent } from '@/components/ActionModals/units/InspectionApproveModalContent/InspectionApproveModalContent';

export type ReviewInspectionReportProps = {
  unitName: string;
  inspection: Inspection & {
    action?: ActionItem;
  };
  onClose(): void;
  onSaveInspection(inspection: Inspection): void;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
  onReinspectAction(): void;
  onNewMakeReadyAction: () => void;
  onNewLeaseAction: () => void;
};

export function ReviewInspectionReport({
  unitName,
  inspection,
  onClose,
  onSaveAction,
  onSaveInspection,
  onReinspectAction,
  onNewMakeReadyAction,
  onNewLeaseAction,
}: ReviewInspectionReportProps) {
  const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleApprove = async () => {
    if (!inspection.action) {
      return;
    }

    await onSaveAction({
      ...inspection.action,
      updateDate: new Date(),
      status: 'done',
    });
  };

  const handleDeny = () => {
    const tempInspection = { ...inspection };
    delete tempInspection.action;

    onSaveInspection({
      ...tempInspection,
      status: InspectionStatus.Denied,
      updateDate: new Date(),
    });

    if (inspection.action) {
      onSaveAction(
        {
          ...inspection.action,
          status: 'done',
        },
        undefined
      );

      onReinspectAction();

      onClose();
    }
  };

  const handleOpenApproveModal = () => {
    setApproveModalOpen(true);
  };

  const handleCloseApproveModal = () => {
    setApproveModalOpen(false);
  };

  const conditionReportsByRoomType = useMemo(() => {
    const conditionMap: Record<string, ConditionReportItem[]> = {};

    if (!inspection) {
      return [];
    }

    inspection.conditionReport.forEach((report) => {
      const arr = conditionMap[report.roomType];

      if (arr) {
        arr.push(report);
      } else {
        conditionMap[report.roomType] = [report];
      }
    });

    return Object.keys(conditionMap)
      .sort()
      .map((key) => ({
        roomType: key,
        conditionReport: conditionMap[key],
      }));
  }, [inspection]);

  const summittedDate = convertPseudoDateToRealDate(
    inspection.updateDate || new Date()
  );
  const inspectorId = inspection?.inspectorId;

  const reportContent = (
    <ModalContent
      actionCategory="inspect"
      onChangeModalOpen={onClose}
      headerTitle="Inspection Review"
      background="#ffffff0d"
      width="100%"
      height="100%"
      padding="16px"
      actionComponent={
        inspection.action && (
          <ConfirmButtons
            confirm={{
              label: 'Approve',
              onClick: handleOpenApproveModal,
            }}
            cancel={{
              label: 'Deny',
              onClick: handleDeny,
            }}
            justifyContent="flex-end"
            gap="24px"
          />
        )
      }
      closeComponent={
        <Stack direction="row" className="absolute right-16 top-16">
          {!modalOpen && (
            <Button
              variant="text"
              className="text-stone-200"
              onClick={handleOpenModal}
            >
              <OpenInNewIcon />
            </Button>
          )}
          {!modalOpen && (
            <Button
              className="text-stone-200"
              variant="text"
              startIcon={
                <AppIcon
                  name="navArrowLeft"
                  className="h-24 w-24 text-stone-200"
                />
              }
              onClick={onClose}
            >
              Back
            </Button>
          )}

          {modalOpen && (
            <AppIcon
              name="close"
              className="h-24 w-24 cursor-pointer text-stone-200"
              onClick={handleCloseModal}
            />
          )}
        </Stack>
      }
    >
      <Typography
        variant="caption"
        className="!font-thin text-stone-200 text-opacity-80"
      >
        Review and approve the inspection list for Unit {unitName} below:
      </Typography>
      <Stack direction="row" justifyContent="space-between" className="px-4">
        <Stack direction="column" className="w-1/2">
          <Typography variant="captionMedium" className="text-stone-200">
            Submitted
          </Typography>
          <Typography
            variant="caption"
            className="font-thin text-stone-200 text-opacity-80"
          >
            {summittedDate
              ? dayjs(summittedDate).format('dddd, MMM DD, YYYY at h:mm A')
              : 'No schedule'}
          </Typography>
        </Stack>

        <Stack direction="column" className="w-1/2">
          <Typography variant="captionMedium" className="text-stone-200">
            Assigned Inspector
          </Typography>
          <Typography
            variant="caption"
            className="font-thin text-stone-200 text-opacity-80"
          >
            {inspectorId || 'No inspector'}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        gap="4px"
        className="h-[calc(100%-200px)] w-full overflow-y-auto"
      >
        {conditionReportsByRoomType.map(({ roomType, conditionReport }) => (
          <InspectionAccordion
            key={roomType}
            roomType={roomType}
            conditionReports={conditionReport}
          />
        ))}
      </Stack>
    </ModalContent>
  );

  return (
    <>
      {reportContent}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          component="div"
          className="absolute left-1/2 top-1/2 max-h-[90vh] max-w-[850px] -translate-x-1/2 -translate-y-1/2 overflow-x-auto overflow-y-scroll rounded-[10px] border border-stone-200 border-opacity-[0.15] bg-black shadow outline-none"
        >
          {reportContent}
        </Box>
      </Modal>

      <Modal open={approveModalOpen} onClose={handleCloseApproveModal}>
        <Box
          component="div"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
        >
          {inspection.action ? (
            <InspectionApproveModalContent
              unitName={unitName}
              action={inspection.action}
              onStartMakeReady={async () => {
                await handleApprove();
                onNewMakeReadyAction();
                onClose();
                handleCloseApproveModal();
              }}
              onStartLeaseAction={async () => {
                await handleApprove();
                onNewLeaseAction();
                onClose();
                handleCloseApproveModal();
              }}
              onChangeModalOpen={handleCloseApproveModal}
            />
          ) : null}
        </Box>
      </Modal>
    </>
  );
}
