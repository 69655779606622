import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type LeaseGeneralModalContentProps = {
  category: ActionItemCategory;
  title: string;
  status: ActionItemStatus;
  onApprove: () => void;
  onSkip: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function LeaseGeneralModalContent({
  category,
  title,
  status,
  onApprove,
  onSkip,
  onChangeModalOpen,
}: LeaseGeneralModalContentProps) {
  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={title}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Approve',
            onClick: onApprove,
            disabled: !['active', 'future'].includes(status),
          }}
          cancel={{
            label: 'Skip Task',
            onClick: onSkip,
            disabled: !['active', 'future'].includes(status),
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    />
  );
}
