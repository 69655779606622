/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  useRef,
  useState,
  useEffect,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
import * as THREE from 'three';

import { useLoader, Canvas } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Environment, OrbitControls } from '@react-three/drei';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';

import { useAlert } from '@/contexts/Alert/AlertContext';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { colorsByLeaseLifecycle } from '@/helpers/common';

import { Unit } from '@urbanmix-tech/shared-js';
import { Loader } from '@/components/Loader/Loader';

import { D3Model, D3ModelHandle } from '../D3Model/D3Model';
import { materials } from '../D3Model/materials';

const regex = /^[a-zA-Z]+_(\d+)/;
function getFloorFromMeshId(value: string) {
  const meshId = value.trim();
  let floor = parseInt(regex.exec(meshId)?.[1] || '', 10);
  if (floor > 99) {
    floor = Math.floor(floor / 100);
  }

  if (Number.isNaN(floor)) {
    if (meshId === 'bottom_mesh') return 0;
    return Number.MAX_VALUE;
  }

  return floor;
}

type UnitD3ModelProps = {
  unit: Unit;
  buildingModelPath: string;
};

export function UnitD3Model({ unit, buildingModelPath }: UnitD3ModelProps) {
  const { showAlert } = useAlert();

  const object = useLoader(GLTFLoader, buildingModelPath || '');

  const ref = useRef<D3ModelHandle>(null);
  const orbitRef = useRef<OrbitControlsImpl>(null);

  const [target, setTarget] = useState<[number, number, number]>([0, 0, 0]);

  const moveCameraToTarget = useCallback((meshId: string) => {
    if (ref.current && orbitRef.current) {
      const mesh = ref.current.getMeshById(meshId);

      if (!mesh) {
        return;
      }

      const box = new THREE.Box3();
      box.setFromObject(mesh);

      const center = box.getCenter(new THREE.Vector3());

      setTarget([center.x, center.y, center.z]);

      orbitRef.current.object.position.set(
        center.x + 5,
        center.y + 5,
        center.z + 5
      );
    } else {
      setTimeout(() => {
        moveCameraToTarget(meshId);
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (!unit.meshId) {
      showAlert('Not exists building model', 'error');
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).getModel = () => {
      return orbitRef.current;
    };

    setTimeout(() => {
      moveCameraToTarget(unit.meshId!);
    }, 0);
  }, [moveCameraToTarget, showAlert, unit.meshId]);

  const meshMaterialMap = useMemo(() => {
    if (!unit.meshId) {
      return {};
    }

    const sameFloorMeshIdsMap: Record<string, THREE.MeshStandardMaterial> = {};

    const currentFloor = getFloorFromMeshId(unit.meshId);

    object.scene.traverse((o) => {
      const meshId = o.name;
      const currentMesh = getFloorFromMeshId(meshId);

      if (currentMesh <= currentFloor) {
        if (!meshId.includes(unit?.meshId?.toString() || '')) {
          sameFloorMeshIdsMap[meshId] = materials.regularTrans;
        } else {
          sameFloorMeshIdsMap[meshId] = materials.windowTrans;
        }
      } else {
        sameFloorMeshIdsMap[meshId] = materials.hardTrans;
      }
    });

    return {
      ...sameFloorMeshIdsMap,
      [unit.meshId]: materials.regular,
    };
  }, [object.scene, unit.meshId]);

  const outlineColor = useMemo(() => {
    if (unit) {
      const currentStatus = Array.isArray(unit.currentState)
        ? unit.currentState.length > 0
          ? unit.currentState[unit.currentState.length - 1]
          : LeaseLifecycle.NOTICE
        : (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE;

      return {
        visible: parseInt(colorsByLeaseLifecycle[currentStatus].slice(1), 16),
        hidden: parseInt(colorsByLeaseLifecycle[currentStatus].slice(1), 16),
        strength: 4,
      };
    }
    return undefined;
  }, [unit]);

  return (
    <Suspense fallback={<Loader title="Rendering 3D..." />}>
      <Canvas>
        <D3Model
          ref={ref}
          url={buildingModelPath}
          meshMaterialMap={meshMaterialMap}
          mode="hardTransparent"
          selectedMeshIds={unit.meshId ? [unit.meshId] : undefined}
          outlineColor={outlineColor}
        />

        <OrbitControls ref={orbitRef} target={target} />

        <Environment preset="forest" />
      </Canvas>
    </Suspense>
  );
}
