import { Stack } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { ActionItemCategory } from '@urbanmix-tech/shared-js';

import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import { ErrorBoundary } from '@sentry/react';
import {
  getBgColorClassByCategory,
  getIconNameByCategory,
} from './TodoAccordion';

export type ActionCategoryCircleProps = {
  category: ActionItemCategory;
  icon?: string;
  grayed?: boolean;
  colorClass?: string;
  color?: string;
};

export function ActionCategoryCircle({
  category,
  icon,
  grayed,
  colorClass,
  color,
}: ActionCategoryCircleProps) {
  const borderColorClass = `border-[${color}]`;

  let iconComponent = null;
  switch (icon) {
    case 'owner':
      iconComponent = <AccountCircleOutlinedIcon className="text-zinc-900" />;
      break;
    case 'late':
      iconComponent = <AssignmentLateOutlinedIcon className="text-zinc-900" />;
      break;
    case 'waiting':
      iconComponent = <HourglassEmptyOutlinedIcon className="text-zinc-900" />;
      break;
    case 'supervisor':
      iconComponent = <VisibilityOutlinedIcon className="text-zinc-900" />;
      break;
    case 'done':
      iconComponent = <TaskAltOutlinedIcon className="text-zinc-900" />;
      break;
    default:
      iconComponent = <AppIcon name={getIconNameByCategory(category)} width={34} height={34} className="text-zinc-900" />;

  }

  return (
    <Stack
      gap="16px"
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={twMerge(
        'h-[40px] w-[40px] rounded-full',
        !grayed
          ? colorClass || getBgColorClassByCategory(category)
          : 'bg-gray-700',
        grayed ? 'border border-dotted' : '',
        grayed ? borderColorClass : null
      )}
    >
      <ErrorBoundary>
        {iconComponent}
        {/* <AssignmentLateOutlinedIcon className="text-zinc-900" /> */}
        {/* <AppIcon
          name={getIconNameByCategory(icon ?? category)}
          width={34}
          height={34}
          className="text-zinc-900"
        /> */}
      </ErrorBoundary>
    </Stack>
  );
}
