import { Stack } from '@mui/material';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

import {
  colorsByLeaseLifecycle,
  iconNamesByLeaseLifecycle,
} from './LeaseStatusBar';

export type LeaseStatusCircleProps = {
  status: LeaseLifecycle;
  size?: number;
};

export function LeaseStatusCircle({ status, size }: LeaseStatusCircleProps) {
  return (
    <Stack
      gap="16px"
      direction="row"
      className="rounded-full"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: colorsByLeaseLifecycle[status],
        width: size ? `${size}px` : '30px',
        height: size ? `${size}px` : '30px',
      }}
    >
      <AppIcon
        name={iconNamesByLeaseLifecycle[status]}
        width={size ? size - 6 : 24}
        height={size ? size - 6 : 24}
        className="text-zinc-900"
      />
    </Stack>
  );
}
