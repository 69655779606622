import { useEffect, useMemo, useState } from 'react';
import { IconButton, Stack, Typography, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import {
  ActionItem,
  ActionItemPayload,
  Inspection,
  ReviewInspectionActionPayload,
} from '@urbanmix-tech/shared-js';
import {
  convertPseudoDateToRealDate,
  getActionColorByCategory,
} from '@/helpers/common';
import { getActionItemPayload } from '@urbanmix-tech/shared-js/data';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { Table, ITableCell, ITableRow } from '@/components/shared/Table';

import { InspectionStatusColumn } from './InspectionStatusColumn';
import { ReviewInspectionReport } from './ReviewInspectionReport';

export type InspectionTabContentProps = {
  unitName: string;
  inspections: Inspection[];
  reviewInspectionActions: ActionItem[];
  onSaveInspection(inspection: Inspection): void;
  onSaveAction: (actionItem: ActionItem, payload: unknown) => Promise<void>;
  onReinspectAction: () => void;
  onNewMakeReadyAction: () => void;
  onNewLeaseAction: () => void;
};

export function InspectionTabContent({
  unitName,
  inspections,
  reviewInspectionActions,
  onSaveInspection,
  onSaveAction,
  onReinspectAction,
  onNewMakeReadyAction,
  onNewLeaseAction,
}: InspectionTabContentProps) {
  const [selectedInspection, setSelectedInspection] = useState<
    (Inspection & { action?: ActionItem }) | null
  >(null);
  const [actionPayloads, setActionPayloads] = useState<ActionItemPayload[]>([]);

  useEffect(() => {
    const promises = reviewInspectionActions.map((action) =>
      getActionItemPayload(action.id)
    );

    Promise.all(promises).then((values) => {
      setActionPayloads(values.filter((value) => value) as ActionItemPayload[]);
    });
  }, [reviewInspectionActions]);

  const inspectionsWithActionId = useMemo(() => {
    const payloadMap = new Map<string, string>();

    actionPayloads.forEach((actionPayload) => {
      const payload = actionPayload.payload as ReviewInspectionActionPayload;

      payloadMap.set(payload.inspectionId, actionPayload.id);
    });

    return inspections.map((inspection) => {
      const actionId = payloadMap.get(inspection.id);

      if (!actionId) {
        return {
          ...inspection,
          action: undefined,
        };
      }

      const action = reviewInspectionActions.find(
        (item) => item.id === actionId
      );

      return {
        ...inspection,
        action,
      };
    });
  }, [actionPayloads, inspections, reviewInspectionActions]);

  const handleClickRow = (id: string) => {
    const exist = inspectionsWithActionId.find((item) => item.id === id);

    if (exist) {
      setSelectedInspection(exist);
    }
  };

  const handleClose = () => {
    setSelectedInspection(null);
  };

  const columns: ITableCell[] = [
    {
      label: 'ID',
      sortable: true,
    },
    {
      label: 'Progress',
      sortable: true,
    },
    {
      label: 'Scheduled for',
      sortable: true,
    },
    {
      label: 'Inspector',
      sortable: true,
    },
  ];

  const rows: ITableRow[] = useMemo(() => {
    return inspectionsWithActionId.map((inspection) => {
      const scheduledDate = convertPseudoDateToRealDate(
        inspection.scheduledDate
      );
      const updateDate = convertPseudoDateToRealDate(inspection.updateDate);

      const data: Record<string, ITableCell> = {
        ID: {
          label: inspection.id.startsWith('INS_')
            ? inspection.id.slice(4)
            : inspection.id,
          value: inspection.id,
          children: (
            <div>
              <Tooltip
                title={inspection.id}
                placement="top"
                className="z-[2000]"
              >
                <Stack direction="row" alignItems="center" gap="4px">
                  <Typography variant="caption" className="text-stone-200/80">
                    {inspection.id.startsWith('INS_')
                      ? `#${inspection.id.slice(4)}`
                      : `${inspection.id.slice(0, 4)}...`}
                  </Typography>
                  {inspection.action && (
                    <AppIcon
                      name="info"
                      style={{ color: getActionColorByCategory('inspect') }}
                    />
                  )}
                </Stack>
              </Tooltip>
              <div />
            </div>
          ),
        },
        Progress: {
          label: inspection.status,
          value: inspection.status,
          children: (
            <InspectionStatusColumn
              status={inspection.status}
              updateDate={updateDate!}
            />
          ),
        },
        'Scheduled for': {
          label: scheduledDate ? scheduledDate.toDateString() : '',
          value: scheduledDate,
          children: (
            <Stack gap="4px">
              <Typography variant="caption" className="text-stone-200/80">
                {scheduledDate
                  ? dayjs(scheduledDate).format('DD MMM YYYY')
                  : ''}
              </Typography>
              <Typography variant="caption" className="text-stone-200/80">
                {scheduledDate ? dayjs(scheduledDate).format('hh:mm A') : ''}
              </Typography>
            </Stack>
          ),
        },
        Inspector: {
          label: inspection.inspectorId || '',
          value: inspection.inspectorId || '',
          children: (
            <Stack gap="4px">
              <Stack
                direction="row"
                justifyContent="space-between"
                className="min-w-[150px]"
              >
                <Typography
                  variant="caption"
                  className="text-start text-stone-200/80"
                >
                  {inspection.inspectorId}
                </Typography>
                <IconButton className="h-24 w-24 p-0">
                  <AppIcon
                    name="moreVertical"
                    className="text-stone-200/80"
                    width={16}
                    height={16}
                  />
                </IconButton>
              </Stack>

              <Typography
                variant="caption"
                className="text-start text-stone-200/80"
              >
                Jane Doe
              </Typography>
            </Stack>
          ),
        },
      };

      return {
        id: inspection.id,
        data,
      } as ITableRow;
    });
  }, [inspectionsWithActionId]);

  return (
    <Stack gap="16px" className="h-full w-[640px]">
      {selectedInspection ? (
        <ReviewInspectionReport
          unitName={unitName}
          inspection={selectedInspection}
          onClose={handleClose}
          onReinspectAction={onReinspectAction}
          onSaveAction={onSaveAction}
          onSaveInspection={onSaveInspection}
          onNewMakeReadyAction={onNewMakeReadyAction}
          onNewLeaseAction={onNewLeaseAction}
        />
      ) : (
        <Stack className="h-full rounded-[10px] bg-white/5 p-16" gap="16px">
          <Typography
            variant="caption"
            className="tracking-tight text-stone-200"
          >
            {inspections.length} reports
          </Typography>

          <Table
            rows={rows}
            columns={columns}
            stickyHeader
            height="calc(100% - 32px)"
            px={8}
            onClickRow={handleClickRow}
          />
        </Stack>
      )}
    </Stack>
  );
}
