import { UserData } from '@urbanmix-tech/shared-js';
import { getAllUsers } from '@urbanmix-tech/shared-js/data';
import { useState, useEffect } from 'react';

const useUsersData = (): Record<string, UserData> => {
  const [users, setUsers] = useState<Record<string, UserData>>({});

  // useEffect(() => {
  //   const getUsers = async () => {
  //     const allUsers = await getAllUsers();

  //     if (allUsers && allUsers.length > 0) {
  //       // turn all users array to a hashmap
  //       const usersData = allUsers.reduce(
  //         (acc: Record<string, UserData>, user) => {
  //           acc[user.id] = user;
  //           return acc;
  //         },
  //         {} as Record<string, UserData>
  //       );
  //       setUsers(usersData);
  //     }
  //   };
  //   getUsers();
  // }, []);

  return users;
};

export default useUsersData;
