import { Stack, Tooltip } from '@mui/material';

import { useAuth } from '@/shared/context/AuthProvider';
import { UserRef } from '@urbanmix-tech/shared-js';

export type UsersAvatarsProps = {
  users: UserRef[];
  hightlightCurrentUser?: boolean;
};

export function UsersAvatars({ users, hightlightCurrentUser = true }: UsersAvatarsProps) {
  const authContext = useAuth();
  const { usersData, usersColors } = authContext;
  const currentUserId = authContext.auth.currentUser?.uid;  

  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      sx={{ marginLeft: 'auto', flex: 'grow' }}
      className="px-20"
    >
      {users.map((user) => (
        <Tooltip title={usersData[user.userId]?.fullName}>
          <span
            key={user.userId}
            className="text-sm tracking-tight"
            style={{
              width: '32px', // Set desired width (circle size)
              height: '32px', // Set desired height (circle size)
              borderRadius: '50%', // Make it a circle
              backgroundColor: '#222222', // Background color (replace as needed)
              border:
                user.userId === currentUserId
                  ? `2px solid ${usersColors[user.email]}`
                  : `1px solid ${usersColors[user.email]}`,
              color: user.userId === currentUserId && hightlightCurrentUser ? 'white' : 'text-zinc-400', // Text color
              display: 'flex', // Flexbox to center content
              alignItems: 'center', // Vertically center the text
              justifyContent: 'center', // Horizontally center the text
              fontWeight: user.userId === currentUserId ? 'normal' : 'normal', // Make text bold
              textTransform: 'uppercase', // Ensure initial is uppercase
            }}
          >
            {usersData[user.userId]?.fullName?.charAt(0)}
          </span>
        </Tooltip>
      ))}
    </Stack>
  );
}
