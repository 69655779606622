import { ReactNode } from 'react';
import { Stack, Typography, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AnimationLogo from '@/components/AnimationLogo/AnimationLogo';

export type LoaderProps = {
  title?: string;
  description?: string;
  status?: string | number;
  onCancel?: () => void;
  loader?: ReactNode;
  center?: boolean;
};

export function Loader({
  title,
  description,
  status,
  loader,
  onCancel,
  center,
}: LoaderProps) {
  const isNumber = typeof status === 'number';

  const loadingComp = (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap="8px"
      className="w-[300px]"
    >
      <div className="relative h-[150px] w-[150px]">
        {status ? (
          <>
            <CircularProgress
              variant={isNumber ? 'determinate' : 'indeterminate'}
              value={status as number}
              className="absolute left-0 top-0 text-white"
              size={150}
            />
            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded font-bold">
              {`${status}${isNumber ? '%' : ''}`}
            </span>
          </>
        ) : (
          <>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {loader || <AnimationLogo />}
            </div>
            <CircularProgress
              className="absolute left-0 top-0 text-white"
              size={150}
            />
          </>
        )}
        {onCancel && (
          <IconButton
            onClick={onCancel}
            className="absolute right-0 rounded-full border-[1px] border-red-500 text-[#ef4444]"
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {title && (
        <Typography variant="body1" color="text.white">
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body2" color="text.white">
          {description}
        </Typography>
      )}
    </Stack>
  );

  if (!center) {
    return loadingComp;
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      {loadingComp}
    </div>
  );
}
