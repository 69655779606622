import { Tooltip } from '@mui/material';

import AppIcon from '@/components/shared/AppIcon/AppIcon';

const icons = {
  bad: {
    icon: <AppIcon name="bad" />,
    label: 'bad',
    tooltip: 'Element needs to be fixed or replaced',
  },
  good: {
    label: 'good',
    icon: <AppIcon name="good" />,
    tooltip: 'Element is in good state',
  },
  noCondition: {
    label: 'noCondition',
    icon: <AppIcon name="noCond" />,
    tooltip: 'Need to check the state of an element',
  },
};

export type ConditionIconProps = {
  variant: 'bad' | 'good' | 'noCondition';
};

export function ConditionIcon({ variant }: ConditionIconProps) {
  return (
    <Tooltip title={icons[variant].tooltip} placement="top" className="z-20">
      <span className="flex items-center">{icons[variant].icon}</span>
    </Tooltip>
  );
}
