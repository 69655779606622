import { useState } from 'react';
import { Stack, Typography } from '@mui/material';

import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';

import { Calendar } from '@/components/shared/Calendar/Calendar';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

export type ScheduleInspectionModalContentProps = {
  readonly?: boolean;
  category: ActionItemCategory;
  isReinspection?: boolean;
  scheduleDate: Date | null;
  status: ActionItemStatus;
  onSchedule: (date: Date) => void;
  onSkip: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function ScheduleInspectionModalContent({
  readonly,
  category,
  isReinspection,
  scheduleDate,
  status,
  onSchedule,
  onSkip,
  onChangeModalOpen,
}: ScheduleInspectionModalContentProps) {
  const [date, setDate] = useState<Date>(scheduleDate || new Date());

  const handleChangeDate = (value: Date | null) => {
    const today = new Date();

    if (
      !value ||
      new Date(value.toDateString()) <= new Date(today.toDateString())
    ) {
      setDate(new Date());
    } else {
      setDate(value);
    }
  };

  const handleSchedule = () => {
    onSchedule(date);
  };

  const headerTitle = isReinspection
    ? `Schedule ${category === 'inspect' ? 'Reinspection' : 'Make Ready'}`
    : `Schedule ${category === 'inspect' ? 'Inspection' : 'Make Ready'}`;

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={headerTitle}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Schedule',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: handleSchedule,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: !['active', 'future'].includes(status) || readonly,
            onClick: onSkip,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    >
      <Stack direction="column" gap="12px">
        <Typography
          variant="caption"
          className="!font-thin text-stone-200 text-opacity-80"
        >
          Choose a date:
        </Typography>

        <Calendar value={date} onChange={handleChangeDate} />
      </Stack>
    </ModalContent>
  );
}
