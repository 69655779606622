/* eslint-disable react/no-unknown-property */
import { useEffect, useMemo, Suspense, memo } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls } from '@react-three/drei';
import { Stack } from '@mui/material';

import { useAlert } from '@/contexts/Alert/AlertContext';

import { Building, Unit } from '@urbanmix-tech/shared-js';
import { Loader } from '@/components/Loader/Loader';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';

import { ErrorBoundary } from '@sentry/react';
import { D3Model } from '../D3Model/D3Model';
import { leaseLifecycleStateToColor } from '../BuildingModel/BuildingModel';

type BuildingDisplayModelProps = {
  building: Building;
  units: Unit[];
  statusFilter: LeaseLifecycle[];
};

export default memo(function BuildingDisplayModel({
  building,
  units,
  statusFilter,
}: BuildingDisplayModelProps) {
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!building.modelPath) {
      showAlert('Not exists building model', 'error');
    }
  }, [building.modelPath, showAlert]);

  const { meshMaterialMap, d3ModelMode } = useMemo(() => {
    const tempMap = new Map<string, Unit>();
    const tempMeshMaterialMap: Record<string, THREE.Material> = {};
    const isChecklistEmpty = !statusFilter || statusFilter.length === 0;
    let tempD3ModelMode: 'standard' | 'transparent' = 'standard';

    tempD3ModelMode = isChecklistEmpty ? 'standard' : 'transparent';

    units.forEach((unit) => {
      if (!unit.meshId) {
        return;
      }

      const currentStatus = Array.isArray(unit.currentState)
        ? unit.currentState.length > 0
          ? unit.currentState[unit.currentState.length - 1]
          : LeaseLifecycle.NOTICE
        : (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE;

      tempMap.set(unit.meshId, unit);

      if (
        tempD3ModelMode !== 'standard' &&
        !statusFilter.includes(currentStatus)
      ) {
        return;
      }
      tempMeshMaterialMap[unit.meshId] =
        leaseLifecycleStateToColor[currentStatus];
    });

    return {
      meshMaterialMap: tempMeshMaterialMap,
      d3ModelMode: tempD3ModelMode,
    };
  }, [statusFilter, units]);

  const { target, distance, polarAngle, azimuthAngle } = useMemo(() => {
    return {
      target: building['3dData'].cameraConf.target,
      distance: building['3dData'].cameraConf.distance,
      polarAngle: building['3dData'].cameraConf.polarAngle,
      azimuthAngle: building['3dData'].cameraConf.azimuthAngle,
    };
  }, [building]);

  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      className="h-full w-full"
    >
      {building.modelPath !== null && (
        <Suspense fallback={<Loader title="Rendering 3D..." center />}>
          <ErrorBoundary fallback={<p>Error loading model</p>}>
            <Canvas key={building.id}>
              <D3Model
                url={building.modelPath}
                meshMaterialMap={meshMaterialMap}
                mode={d3ModelMode}
                hideOutline
              />
              <OrbitControls
                target={target}
                minDistance={distance || 0}
                maxDistance={distance || 0}
                enablePan={false}
                enableRotate={false}
                minPolarAngle={polarAngle || 0}
                maxPolarAngle={(polarAngle || 0) * 1.2}
                minAzimuthAngle={azimuthAngle || 0}
                maxAzimuthAngle={azimuthAngle || 0}
              />
              <Environment preset="sunset" />
              <mesh rotation={[-Math.PI / 2, 0, 0]}>
                <planeGeometry args={[100, 100]} />
                <meshStandardMaterial color={0x8c8c8c} />
              </mesh>
            </Canvas>
          </ErrorBoundary>
        </Suspense>
      )}
    </Stack>
  );
});
