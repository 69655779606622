import { InspectionStatus } from '@urbanmix-tech/shared-js';
import { Stack, Typography } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';

export type InspectionStatusColumnProps = {
  status: InspectionStatus;
  updateDate: Date;
};

export function InspectionStatusColumn({
  status,
  updateDate,
}: InspectionStatusColumnProps) {
  const classNames: Record<InspectionStatus, string> = {
    [InspectionStatus.Draft]: 'text-stone-200 bg-stone-200/20',
    [InspectionStatus.Scheduled]: 'text-emerald-400 bg-emerald-400/20',
    [InspectionStatus.InProgress]: 'text-sky-400 bg-sky-400/20',
    [InspectionStatus.PendingReview]: 'text-primary-400 bg-primary-400/20',
    [InspectionStatus.RequireReinspection]: 'text-orange-400 bg-orange-400/20',
    [InspectionStatus.Denied]: 'text-rose-400 bg-rose-400/20',
    [InspectionStatus.Approved]: 'text-lime-300 bg-lime-300/20',
  };

  return (
    <Stack gap="4px">
      <div
        className={twMerge(
          'w-fit rounded-[4px] px-8 py-4 font-semibold uppercase tracking-tight',
          classNames[status]
        )}
      >
        {status}
      </div>
      <Typography variant="caption" className="text-stone-200/80">
        Updated on {dayjs(updateDate).format('DD MMM YYYY')}
      </Typography>
    </Stack>
  );
}
