import {
  ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';
import {
  Auth,
  UserCredential,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  onAuthStateChanged,
  signOut as signOutFirebase,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
// import LogRocket from 'logrocket';
import LogRocket from 'logrocket';
import {
  getAllTeams,
  getAllUsers,
  getUserDataById,
  saveUser,
  UserData,
} from '@urbanmix-tech/shared-js/data';

const colorByTeam = (team: string): string => {
  switch (team) {
    case 'notice':
      return '#A855F7';
    case 'inspect':
      return '#F43F5E';
    case 'Make Ready':
      return '#FB923C';
    case 'Lease':
      return '#10B981';
    case 'general':
      return '#9CA3AF';
    default:
      return '#A855F7';
  }
};

const colorClassByTeam = (team: string): string => {
  switch (team) {
    case 'notice':
      return 'bg-purple-400';
    case 'inspect':
      return 'bg-rose-400';
    case 'Make Ready':
      return 'bg-orange-300';
    case 'Lease':
      return 'bg-emerald-200';
    case 'general':
      return 'bg-gray-400';
    default:
      return 'bg-purple-400';
  }
};

export type ImpersonatedTeamOptionType = {
  label: string;
  value: 'all' | string[];
};
export const defaultImpersonatedTeamOption: ImpersonatedTeamOptionType = {
  label: 'All projects',
  value: 'all',
};

export interface AuthProviderProps {
  children: ReactNode | null;
}

export interface AuthContextModel {
  isLoading: boolean;
  isEditMode: boolean;
  auth: Auth;
  user: User | null | undefined;
  userData: UserData | null;
  usersData: Record<string, UserData>;
  usersColorClasses: Record<string, string>;
  usersColors: Record<string, string>;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (
    email: string,
    password: string,
    data: Partial<UserData>
  ) => Promise<UserCredential>;
  resetPassword: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<UserCredential>;
  allowedProjectIds: string[] | 'all';
  teamOptions: ImpersonatedTeamOptionType[];
  selectedOption: ImpersonatedTeamOptionType;
  setSelectedOption: (value: ImpersonatedTeamOptionType) => void;
  setIsEditMode: (mode: boolean) => void;
  permissionsLoaded: boolean;
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [usersData, setUsersData] = useState<Record<string, UserData>>({});
  const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const auth = getAuth();
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [allowedProjectIds, setAllowedProjectIds] = useState<string[] | 'all'>(
    []
  );
  const [teamOptions, setTeamOptions] = useState<ImpersonatedTeamOptionType[]>([
    defaultImpersonatedTeamOption,
  ]);
  const [selectedOption, setSelectedOption] =
    useState<ImpersonatedTeamOptionType>(defaultImpersonatedTeamOption);

  const [usersColors, setUsersColors] = useState<Record<string, string>>({});
  const [usersColorClasses, setUsersColorClasses] = useState<
    Record<string, string>
  >({});

  const signIn = useCallback(
    async (email: string, password: string): Promise<UserCredential> => {
      setIsLoading(true);
      setUser(undefined);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const fetchedUserData = await getUserDataById(userCredential.user.uid);
      setUserData(fetchedUserData);
      LogRocket.identify(userCredential.user.uid, {
        name: fetchedUserData?.fullName || '',
        email: fetchedUserData?.email || '',
        // isAdmin: `${userData?.isAdmin}`,
        isDev: `${fetchedUserData?.isDev}`,
      });
      setIsLoading(false);
      return userCredential;
    },
    [auth]
  );

  const signUp = useCallback(
    async (
      email: string,
      password: string,
      signUpUserData: Partial<UserData>
    ): Promise<UserCredential> => {
      setIsLoading(true);
      setUser(undefined);

      let userCredential: UserCredential;

      if (signUpUserData.authProvider === 'google') {
        const authProvider = new GoogleAuthProvider();
        userCredential = await signInWithPopup(auth, authProvider);
        signUpUserData.email = userCredential.user.email || undefined;
        signUpUserData.fullName = userCredential.user.displayName || undefined;
      } else {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
      }

      await saveUser({
        ...signUpUserData,
        id: userCredential.user.uid,
      } as UserData);
      setUserData({
        ...signUpUserData,
        id: userCredential.user.uid,
      } as UserData);
      setIsLoading(false);
      return userCredential;
    },
    [auth]
  );

  const resetPassword = useCallback(
    (email: string): Promise<void> => {
      return sendPasswordResetEmail(auth, email);
    },
    [auth]
  );

  const signOut = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    await signOutFirebase(auth);
    setIsLoading(false);
  }, [auth]);

  const signInWithGoogle = useCallback(async (): Promise<UserCredential> => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      let currentUser = await getUserDataById(userCredential.user.uid);
      if (!currentUser) {
        currentUser = {
          id: userCredential.user.uid,
          authProvider: 'google',
          fullName: userCredential.user.displayName || '',
          email: userCredential.user.email || '',
          profileImageUrl: '',
          role: '',
          isDev: false,
        };
        await saveUser(currentUser);
      }
      setUserData(currentUser);
      LogRocket.identify(currentUser.id, {
        name: currentUser?.fullName || '',
        email: currentUser?.email || '',
        isDev: `${currentUser?.isDev}`,
      });
      return userCredential;
    } finally {
      setIsLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    return onAuthStateChanged(auth, async (loggedUser: User | null) => {
      setUser(loggedUser);

      if (loggedUser) {
        if (!userData) {
          setIsLoading(true);
          const userLoadedData = await getUserDataById(loggedUser.uid);
          setUserData(userLoadedData);
          // LogRocket.identify(loggedUser.uid, {
          //   name: `${userData?.fullName}`,
          //   email: `${userData?.email}`,
          //
          //   isAdmin: `${userData?.isAdmin}`,
          //   isDev: `${userData?.isDev}`,
          // });
          setIsLoading(false);
        }
      } else {
        setUserData(null);
      }
    });
  }, [auth, userData]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    getAllTeams().then((value) => {
      if (value === null) {
        return;
      }

      const tempTeamOptions = userData.isDev
        ? [
            defaultImpersonatedTeamOption,
            ...value.map((item) => ({
              label: item.name,
              value: item.projectIds,
            })),
          ]
        : [
            ...value
              .filter((item) =>
                item.users.find((i) => i.email === userData.email)
              )
              .map((item) => ({
                label: item.name,
                value: item.projectIds,
              })),
          ];

      setTeamOptions(tempTeamOptions);

      if (tempTeamOptions.length === 0) {
        setSelectedOption(defaultImpersonatedTeamOption);
      } else {
        setSelectedOption(tempTeamOptions[0]);
      }
    });
  }, [userData]);

  useEffect(() => {
    if (!userData || !userData.isDev) {
      return;
    }
    setAllowedProjectIds(selectedOption.value);
    setPermissionsLoaded(true);
  }, [selectedOption.value, setAllowedProjectIds, userData]);

  useEffect(() => {
    (async () => {
      if (!userData || !userData.email) {
        return;
      }

      if (userData && userData.isDev) {
        return;
      }

      const teams = await getAllTeams();

      if (!teams) {
        return;
      }

      const tempProjectIds: Record<string, boolean> = {};

      const involvedTeams = teams.filter((team) =>
        team.users.map((item) => item.email).includes(userData.email)
      );

      involvedTeams.forEach((team) => {
        const projectIds = [...team.projectIds];

        projectIds.forEach((id) => {
          tempProjectIds[id] = true;
        });
      });

      console.log(
        'setting allowedProjectIds to (second option)',
        Object.keys(tempProjectIds)
      );
      setAllowedProjectIds(Object.keys(tempProjectIds));
      setPermissionsLoaded(true);
    })();
  }, [user, userData]);

  useEffect(() => {
    const getUsers = async () => {
      const allUsers = await getAllUsers();

      if (allUsers && allUsers.length > 0) {
        // turn all users array to a hashmap
        const newUsersData = allUsers.reduce(
          (acc: Record<string, UserData>, curr: UserData) => {
            if (curr) {
              acc[curr.id] = curr;
            }
            return acc;
          },
          {} as Record<string, UserData>
        );

        const teams = await getAllTeams();

        // go through each user and each team and assign a color to each user
        const newColors: Record<string, string> = {};
        const newColorClasses: Record<string, string> = {};

        // go through each user in each team
        // assign a color to each user
        if (teams) {
          teams.forEach((team) => {
            team.users.forEach((teamUser) => {
              if (teamUser.email) {
                newColors[teamUser.email] = colorByTeam(team.name);
                newColorClasses[teamUser.email] = colorClassByTeam(team.name);
              }
            });
          });
        }

        setUsersData(newUsersData);
        setUsersColors(newColors);
        setUsersColorClasses(newColorClasses);
      }
    };
    getUsers();
  }, []);

  const values = useMemo(
    () => ({
      isLoading,
      isEditMode,
      auth,
      user,
      userData,
      usersData,
      signIn,
      signUp,
      resetPassword,
      signOut,
      signInWithGoogle,
      allowedProjectIds,
      teamOptions,
      selectedOption,
      setSelectedOption,
      setIsEditMode,
      permissionsLoaded,
      usersColors,
      usersColorClasses,
    }),
    [
      isLoading,
      isEditMode,
      auth,
      user,
      userData,
      usersData,
      signIn,
      signUp,
      resetPassword,
      signOut,
      signInWithGoogle,
      allowedProjectIds,
      teamOptions,
      selectedOption,
      setIsEditMode,
      permissionsLoaded,
      usersColors,
      usersColorClasses,
    ]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
