import { useEffect, useState } from 'react';

import {
  getActionItem,
  getInspectionById,
  sendMessage,
} from '@urbanmix-tech/shared-js/data';
import {
  Inspection,
  ActionItem,
  ActionItemPayload,
  InspectionActionPayload,
  AssignInspectorPayload,
  ScheduleInspectionActionPayload,
  ReportInspectionPlanActionPayload,
  SendInspectionRequestActionPayload,
  CheckInOnInspectionProgressActionPayload,
  ReviewInspectionActionPayload,
  ActionItemCategory,
  InspectionStatus,
  MessageTemplateType,
  createUnitAction
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';

import { convertPseudoDateToRealDate } from '@/helpers/common';

import { useAlert } from '@/contexts/Alert/AlertContext';
import { useAuth } from '@/shared/context/AuthProvider';

import { AssignInspectorModalContent } from './AssignInspectorModalContent/AssignInspectorModalContent';
import { ScheduleInspectionModalContent } from './ScheduleInspectionModalContent/ScheduleInspectionModalContent';
import { PlanInspectionItemsModalContent } from './PlanInspectionItemsModalContent/PlanInspectionItemsModalContent';
import { SendInspectionRequestModalContent } from './SendInspectionRequestModalContent/SendInspectionRequestModalContent';
import { CheckInOnInspectionProgressModalContent } from './CheckInOnInspectionProgressModalContent/CheckInOnInspectionProgressModalContent';
import { ReviewInspectionReportModalContent } from './ReviewInspectionReportModalContent/ReviewInspectionReportModalContent';

export type UnitInspectionModalContentProps = {
  readonly?: boolean;
  projectName: string;
  projectAddress: string;
  unitName: string;
  unitLeaseEndDate: Date | null;
  actionItem: ActionItem;
  actionItemPayload: ActionItemPayload;
  onChangeModalOpen: (val: boolean) => void;
  onSkipActions: (category: ActionItemCategory) => void;
  onApproveAction: (payload?: unknown) => Promise<void>;
  onSaveAction: (actionItem: ActionItem, payload?: unknown) => Promise<void>;
  onReinspectAction(): void;
  onSaveInspection(inspection: Inspection): Promise<string | null>;
  onOpenApproveModal(): void;
};

export function UnitInspectionModalContent({
  readonly,
  projectName,
  projectAddress,
  unitName,
  unitLeaseEndDate,
  actionItem,
  actionItemPayload,
  onChangeModalOpen,
  onSkipActions,
  onApproveAction,
  onSaveAction,
  onReinspectAction,
  onSaveInspection,
  onOpenApproveModal,
}: UnitInspectionModalContentProps) {
  const { showAlert } = useAlert();
  const { userData } = useAuth();

  const [inspection, setInspection] = useState<Inspection | null>(null);

  useEffect(() => {
    if ((actionItemPayload.payload as InspectionActionPayload).inspectionId) {
      getInspectionById(
        actionItem.projectId,
        actionItem.unitId,
        (actionItemPayload.payload as InspectionActionPayload).inspectionId
      ).then((value) => setInspection(value));
    }
  }, [actionItem, actionItemPayload]);

  const handleInspectionReadyToSend = async (
    ids: string[],
    isReinspection: boolean
  ) => {
    if (!inspection) {
      return;
    }

    const actionPromises = ids.map((id: string) => {
      return getActionItem(id);
    });

    const actions = await Promise.all(actionPromises);

    if (actions.find((action) => action === null || action.status !== 'done')) {
      return;
    }

    const assignInspectionAction = actions.find(
      (action) => action?.title === actionTitles.assignInspector
    );
    const planInspectionAction = actions.find(
      (action) => action?.title === actionTitles.planInspectionItems
    );
    const scheduleInspectionAction = actions.find(
      (action) => action?.title === actionTitles.scheduleInspection
    );

    if (
      !assignInspectionAction ||
      !scheduleInspectionAction ||
      !planInspectionAction
    ) {
      return;
    }

    const sendInspectionAction = createUnitAction({
      projectId: actionItem.projectId,
      buildingId: actionItem.buildingId,
      unitId: actionItem.unitId,
      title: actionTitles.sendInspectionRequest,
      description: '',
      category: 'inspect',
      action_type: {
        inputType: 'text',
        reminder: false,
        type: 'approve',
      },
    });

    const checkPayload: SendInspectionRequestActionPayload = {
      inspectionId: inspection.id,
      isReinspection,
    };

    onSaveAction(sendInspectionAction, checkPayload);
  };
 
  const handleReinspectionEvent = async () => {
    if (!inspection) {
      return;
    }

    await onSaveInspection({
      ...inspection,
      status: InspectionStatus.Denied,
      updateDate: new Date(),
    });
    await onSaveAction({
      ...actionItem,
      status: 'ignored',
      updateDate: new Date(),
    });
    onReinspectAction();
    onChangeModalOpen(false);
  };

  const handleSkip = () => {
    if (!inspection) {
      return;
    }

    onSkipActions('inspect');

    onSaveInspection({
      ...inspection,
      status: InspectionStatus.Denied,
      updateDate: new Date(),
    });

    onChangeModalOpen(false);
  };

  const handlePrepareInspection = async ({
    inspector,
    inspectorName,
    scheduleDate,
  }: {
    inspector?: string;
    inspectorName?: string | null;
    scheduleDate?: Date;
  }) => {
    if (!inspection) {
      return;
    }

    onSaveInspection({
      ...inspection,
      inspectorId: inspector || inspection.inspectorId,
      inspectorName: inspectorName || inspection.inspectorName,
      scheduledDate: scheduleDate || inspection.scheduledDate,
      updateDate: new Date(),
    });

    await onApproveAction();

    const { relatedActionIds, isReinspection } = actionItemPayload.payload as {
      relatedActionIds: string[];
      isReinspection: boolean;
    };

    handleInspectionReadyToSend(relatedActionIds, isReinspection);

    onChangeModalOpen(false);
  };

  const handleSendInspectionRequest = async () => {
    if (!inspection || !inspection.inspectorId) {
      return;
    }

    onSaveInspection({
      ...inspection,
      status: InspectionStatus.Scheduled,
      updateDate: new Date(),
    });

    await onApproveAction();

    const realDate = convertPseudoDateToRealDate(inspection.scheduledDate);
    const leaseEndRealDate = convertPseudoDateToRealDate(unitLeaseEndDate);

    if (realDate && userData) {
      sendMessage({
        to: inspection.inspectorId,
        projectName,
        unitName,
        inspectorName: inspection.inspectorName || inspection.inspectorId,
        assignerName: userData.fullName,
        scheduledDate: realDate,
        address: projectAddress,
        leaseEndDate: leaseEndRealDate,
        templateType: MessageTemplateType.INSPECTION_REQUESTED,
      }).then((data) => {
        if (data.success !== true) {
          showAlert('Failed to email inspector!', 'error');
        }
      });
    }

    onChangeModalOpen(false);
  };

  const handleApprove = () => {
    onApproveAction();
    onChangeModalOpen(false);
  };

  if (!inspection) {
    return <div>No data exists</div>;
  }

  if (actionItem.title === actionTitles.assignInspector) {
    return (
      <AssignInspectorModalContent
        readonly={readonly}
        category={actionItem.category}
        isReinspection={
          (actionItemPayload.payload as AssignInspectorPayload).isReinspection
        }
        inspectorId={inspection.inspectorId}
        projectId={actionItem.projectId}
        status={actionItem.status}
        onAssign={(inspector, inspectorName) =>
          handlePrepareInspection({ inspector, inspectorName })
        }
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.scheduleInspection) {
    return (
      <ScheduleInspectionModalContent
        readonly={readonly}
        category={actionItem.category}
        isReinspection={
          (actionItemPayload.payload as ScheduleInspectionActionPayload)
            .isReinspection
        }
        scheduleDate={convertPseudoDateToRealDate(inspection.scheduledDate)}
        status={actionItem.status}
        onSkip={handleSkip}
        onSchedule={(scheduleDate: Date) =>
          handlePrepareInspection({ scheduleDate })
        }
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.planInspectionItems) {
    return (
      <PlanInspectionItemsModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        isReinspection={
          (actionItemPayload.payload as ReportInspectionPlanActionPayload)
            .isReinspection
        }
        data={
          (actionItemPayload.payload as ReportInspectionPlanActionPayload).data
        }
        status={actionItem.status}
        onApprove={() => handlePrepareInspection({})}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.sendInspectionRequest) {
    return (
      <SendInspectionRequestModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        isReinspection={
          (actionItemPayload.payload as SendInspectionRequestActionPayload)
            .isReinspection
        }
        scheduledDate={convertPseudoDateToRealDate(inspection.scheduledDate)!}
        inspectorId={inspection.inspectorId!}
        status={actionItem.status}
        onSubmit={handleSendInspectionRequest}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.checkInOnInspectionProgress) {
    return (
      <CheckInOnInspectionProgressModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        isReinspection={
          (
            actionItemPayload.payload as CheckInOnInspectionProgressActionPayload
          ).isReinspection
        }
        scheduledDate={convertPseudoDateToRealDate(inspection.scheduledDate)!}
        inspectorId={inspection.inspectorId!}
        status={actionItem.status}
        onDone={handleApprove}
        onSkip={handleSkip}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  if (actionItem.title === actionTitles.reviewInspectionReport) {
    return (
      <ReviewInspectionReportModalContent
        readonly={readonly}
        unitName={unitName}
        category={actionItem.category}
        isReinspection={
          (actionItemPayload.payload as ReviewInspectionActionPayload)
            .isReinspection
        }
        conditionReports={inspection.conditionReport}
        inspectorId={inspection.inspectorId!}
        submittedDate={inspection.updateDate}
        status={actionItem.status}
        onSkip={handleSkip}
        onApprove={onOpenApproveModal}
        onAskToReinspect={handleReinspectionEvent}
        onChangeModalOpen={onChangeModalOpen}
      />
    );
  }

  return <div>Unknown inspect action item</div>;
}
