import { ReactNode, useState } from 'react';
import { CSSObject } from '@emotion/styled';
import { SubMenu, MenuItem as UIMenuItem } from 'react-pro-sidebar';
import { Stack, IconButton } from '@mui/material';

import AppIcon from '@/components/shared/AppIcon/AppIcon';

export type IMenuItem = {
  id: string;
  label: string;
  icon?: ReactNode;
  rootStyles?: CSSObject;
  subMenu?: IMenuItem[];
  onClick?: () => void;
};

export type MenuItemProps = {
  item: IMenuItem;
  activePath: string[];
  onChangeActivePath: (key: string[]) => void;
};

export function MenuItem({
  item,
  activePath,
  onChangeActivePath,
}: MenuItemProps) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClickSubMenu = () => {
    onChangeActivePath([item.id]);

    if (item.onClick) {
      item.onClick();
    }
  };

  const handleClickMenuItem = () => {
    onChangeActivePath([item.id]);

    if (item.onClick) {
      item.onClick();
    }
  };

  const handleChangeActivePath = (path: string[]) => {
    if (path.length === 0) {
      onChangeActivePath([]);
    } else {
      onChangeActivePath([item.id, ...path]);
    }
  };

  const active = activePath.length > 0 ? activePath[0] === item.id : false;
  const newActivePath = activePath.length > 1 ? activePath.slice(1) : [];

  if (item.subMenu) {
    return (
      <Stack direction="row" alignItems="flex-start">
        <SubMenu
          label={
            <Stack direction="row" alignItems="center" gap="8px">
              <div onClick={handleClickSubMenu}>{item.label}</div>
              <IconButton className="text-inherit" onClick={toggleOpen}>
                <AppIcon name={open ? 'arrowUp' : 'arrowDown'} fontSize={16} />
              </IconButton>
            </Stack>
          }
          icon={item.icon}
          rootStyles={item.rootStyles}
          active={active}
          open={open}
        >
          {item.subMenu.map((sub) => (
            <MenuItem
              key={sub.id}
              item={sub}
              activePath={newActivePath}
              onChangeActivePath={handleChangeActivePath}
            />
          ))}
        </SubMenu>
      </Stack>
    );
  }

  return (
    <UIMenuItem
      onClick={handleClickMenuItem}
      key={item.id}
      icon={item.icon}
      rootStyles={item.rootStyles}
      active={active}
    >
      {item.label}
    </UIMenuItem>
  );
}
