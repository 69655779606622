import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import AppIcon from '@/components/shared/AppIcon/AppIcon';

import { MainAppUserInfoDropdown } from '@/components/MainAppUserInfo/MainAppUserInfoDropdown';
import { MainAppLogo } from '@/components/MainAppLogo/MainAppLogo';
import GlobalSearchBar from '../GlobalSearchBar/GlobalSearchBar';

export function MainAppHeader() {
  const today = dayjs().locale('en').format('DD MMM YYYY');

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="h-[62px] w-full flex-shrink-0 border-b border-white border-opacity-10 bg-white bg-opacity-5 px-24"
    >
      <div className="flex-shrink-0">
        <MainAppLogo />
      </div>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="24px"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          className="hidden lg:flex"
        >
          <AppIcon
            name="calendar"
            width={16}
            className="text-white text-opacity-80"
          />
          <Typography variant="caption" className="text-white text-opacity-60">
            {today}
          </Typography>
        </Stack>

        <GlobalSearchBar />

        <MainAppUserInfoDropdown />
      </Stack>
    </Stack>
  );
}
