import { SvgIcon, type SvgIconProps } from '@mui/material';

export function Vacancy(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.8539L17.2615 17.8539L8.63076 8.99998L17.2615 0.146016L-7.74038e-07 0.146017L8.63076 8.99998L0 17.8539ZM8.63076 6.44179L13.0272 1.93169L4.23435 1.93169L8.63076 6.44179ZM8.63076 11.5582L4.23435 16.0683L13.0272 16.0683L8.63076 11.5582Z"
        fill="currentColor"
        stroke="none"
      />
    </SvgIcon>
  );
}
