import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Breadcrumbs } from '@/components/shared/Breadcrumbs/Breadcrumbs';
import { ActionItem, Project, Unit } from '@urbanmix-tech/shared-js';
import { useLoading } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';
import {
  getActionItemsByUnitId,
  getProjectById,
  getUnitById,
} from '@urbanmix-tech/shared-js/data';
import TodoTabs, { tabList } from '@/components/TodoTabs/TodoTabs';
import { TodoTable } from '@/components/TodoTabs/TodoTable';
import { UnitD3Model } from '@/components/UnitD3Model/UnitD3Model';

export default function TodoUnitPage() {
  const { projectId, buildingId, unitId } = useParams<{
    projectId: string;
    buildingId: string;
    unitId: string;
  }>();
  const [project, setProject] = useState<Project | null>(null);
  const [unit, setUnit] = useState<Unit | null>(null);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [tabKey, setTabKey] = useState<string>(tabList[0].tabId);

  const { showAlert } = useAlert();
  const { createLoading } = useLoading();

  useEffect(() => {
    if (projectId) {
      const loader = createLoading();

      loader.startLoading({ title: 'Loading Project from db...' });

      getProjectById(projectId).then((value) => {
        if (value && value.buildings.length > 0) {
          setProject(value);
        } else {
          showAlert('Building not exists!', 'error');
        }

        loader.stopLoading();
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [projectId, buildingId, showAlert, createLoading]);

  useEffect(() => {
    if (projectId && buildingId && unitId) {
      const unitLoader = createLoading();

      unitLoader.startLoading({ title: 'Loading Units from db...' });

      getUnitById(projectId, unitId).then((_unit) => {
        setUnit(_unit);
        unitLoader.stopLoading();
      });

      const actionItemsLoader = createLoading();

      actionItemsLoader.startLoading({ title: 'Loading Todo...' });

      getActionItemsByUnitId(projectId, +buildingId, unitId).then((value) => {
        setActionItems(value);
        actionItemsLoader.stopLoading();
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [projectId, buildingId, unitId, showAlert, createLoading]);

  const handleHoverUnit = useCallback(() => {
    // nop
  }, []);

  if (!project) {
    return null;
  }

  const breadcrumbsPath = [
    {
      label: 'Actions Breakdown',
      href: '/todo',
    },
    {
      label: 'Portfolio',
      href: '/todo',
    },
    {
      label: project?.name || 'Building',
      href: `/todo/${project?.id}/${project?.buildings[0].id}`,
    },
    {
      label: unit?.name || unitId || 'Unit',
      href: window.location.href,
    },
  ];

  return (
    <Stack gap="16px" className="h-full w-full min-w-[470px] p-24">
      <Stack gap="24px" className="p-24 pb-0">
        <Breadcrumbs paths={breadcrumbsPath} />
      </Stack>

      <TodoTabs
        setTabKey={setTabKey}
        tabKey={tabKey}
        actionItems={actionItems}
      />
      <Stack gap="24px" direction="row" className="flex-1">
        <TodoTable
          actionItems={actionItems}
          tabKey={tabKey}
          onSelectUnit={handleHoverUnit}
        />
        <Stack
          direction="row"
          gap="24px"
          className="h-[calc(100vh-336px)] flex-1 overflow-hidden rounded-[10px]"
        >
          {unit && project?.buildings[0].modelPath ? (
            <UnitD3Model
              buildingModelPath={project?.buildings[0].modelPath}
              unit={unit}
            />
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
