import { Stack, Button, Tooltip } from '@mui/material';

import { useUIContext } from '@/shared/context/UIContextProvider';

export type ButtonType = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
  confirmDialog?: {
    title: string;
    description: string;
  };
  tooltip?: string;
};

export type ConfirmButtonsProps = {
  confirm: ButtonType;
  cancel: ButtonType;
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
};

export function ConfirmButtons({
  confirm,
  cancel,
  gap,
  justifyContent,
  alignItems,
}: ConfirmButtonsProps) {
  const { onClick: clickConfirm } = confirm;
  const { onClick: clickCancel } = cancel;

  const { openConfirm } = useUIContext();

  const handleClickConfirm = () => {
    if (confirm.confirmDialog) {
      openConfirm({
        title: confirm.confirmDialog.title,
        description: confirm.confirmDialog.description,
        onAction: (value: boolean) => {
          if (value) {
            clickConfirm();
          }
        },
      });
    } else {
      clickConfirm();
    }
  };

  const handleClickCancel = () => {
    if (cancel.confirmDialog) {
      openConfirm({
        title: cancel.confirmDialog.title,
        description: cancel.confirmDialog.description,
        onAction: (value: boolean) => {
          if (value) {
            clickCancel();
          }
        },
      });
    } else {
      clickCancel();
    }
  };

  return (
    <Stack
      direction="row"
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'space-between'}
      gap={gap || '24px'}
      className="h-[28px]"
    >
      {!confirm.hidden ? (
        <Tooltip title={confirm.tooltip || ''}>
          <span>
            <Button
              variant="contained"
              onClick={handleClickConfirm}
              color="primary"
              disabled={confirm.disabled}
              className="h-full min-w-[92px] !bg-white px-16 !text-black"
            >
              {confirm.label}
            </Button>
          </span>
        </Tooltip>
      ) : null}
      {!cancel.hidden ? (
        <Tooltip title={cancel.tooltip || ''}>
          <Button
            variant="outlined"
            onClick={handleClickCancel}
            color="primary"
            disabled={cancel.disabled}
            className="h-full min-w-[92px] !border-white !text-white"
          >
            {cancel.label}
          </Button>
        </Tooltip>
      ) : null}
    </Stack>
  );
}
