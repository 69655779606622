import { useState } from 'react';
import { Select, MenuItem, InputBase } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

type TeamSelectorProps = {
  teams: string[];
  value: string;
  onChange(value: string): void;
};

export function TeamSelector({ teams, value, onChange }: TeamSelectorProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Select
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      onChange={handleChange}
      size="small"
      inputProps={{
        sx: {
          color: 'white',
        },
      }}
      sx={{
        '& .MuiSelect-icon': {
          color: 'white !important',
        },
        border: '1px solid white',
        padding: '2px 12px',
        borderRadius: '6px',
      }}
      input={<InputBase />}
    >
      {teams.map((team) => (
        <MenuItem value={team}>{team}</MenuItem>
      ))}
    </Select>
  );
}
