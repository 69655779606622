import { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useLoading } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';

import { isFile, isString } from '@/helpers/common';

import BuildingModelEdit from '@/components/BuildingModelEdit/BuildingModelEdit';
import type { Parameters } from '@/components/BuildingModelEdit/BuildingModelEdit';

import { Project, Unit } from '@urbanmix-tech/shared-js';
import {
  getProjectById,
  uploadGlbFile,
  saveProject,
  getUnitsByProjectId,
  saveUnit,
  deleteUnit,
} from '@urbanmix-tech/shared-js/data';

const BuildingModelUpload = () => {
  const navigator = useNavigate();
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();
  const { createLoading } = useLoading();
  const { showAlert } = useAlert();

  const [project, setProject] = useState<Project | null>(null);
  const [units, setUnits] = useState<Unit[]>([]);

  useEffect(() => {
    if (projectId) {
      const loader = createLoading();

      loader.startLoading({ title: 'Loading Project...' });
      getProjectById(projectId).then((value) => {
        getUnitsByProjectId(projectId).then((_units) => {
          setUnits(_units);
          if (value && value.buildings.length > 0) {
            setProject(value);
          } else {
            showAlert('Building not exists!', 'error');
          }
          loader.stopLoading();
        });
      });
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
    }
  }, [projectId, buildingId, showAlert, createLoading]);

  const handleChangeParameters = useCallback(
    async (parameters: Parameters) => {
      if (!project) {
        showAlert('There is no project', 'error');
        return;
      }

      let fileUrl: string = '';

      if (isString(parameters.file)) {
        fileUrl = parameters.file;
      }

      if (isFile(parameters.file)) {
        const loader = createLoading();

        loader.startLoading({
          title: 'Saving Building Model to the Storage...',
        });

        const result = await uploadGlbFile(
          parameters.file,
          project.id,
          `${project.buildings[0].id}`
        );

        loader.stopLoading();

        if (result === null) {
          showAlert('Model uploading is failed', 'error');
          return;
        }

        fileUrl = result;
      }

      const loader = createLoading();
      loader.startLoading({ title: 'Saving building parameters...' });

      const result = await saveProject({
        ...project,
        buildings: [
          {
            ...project.buildings[0],
            modelPath: fileUrl,
            '3dData': {
              cameraConf: {
                target: parameters.target,
                distance: parameters.distance,
                polarAngle: parameters.polarAngle,
                azimuthAngle: parameters.azimuthAngle,
              },
            },
          },
        ],
      });

      const promises = [
        ...parameters.units.map(async (unit) => {
          return saveUnit(project.id, unit);
        }),
        ...units
          .filter(
            (unit) => !parameters.units.find((item) => item.id === unit.id)
          )
          .map((unit) => deleteUnit(project.id, unit.id)),
      ];

      await Promise.allSettled(promises);

      loader.stopLoading();

      if (!result) {
        showAlert('Project updating is failed', 'error');
      } else {
        showAlert('Project is successfully updated', 'success');
        navigator(`/projects/${projectId}/${buildingId}`);
      }
    },
    [buildingId, createLoading, navigator, project, projectId, showAlert, units]
  );

  return (
    <div className="relative flex h-full w-full items-center justify-center border-red-500">
      {project && project.buildings.length > 0 ? (
        <BuildingModelEdit
          key={project.id}
          projectId={project.id}
          building={project.buildings[0]}
          units={units}
          onChangeParameters={handleChangeParameters}
        />
      ) : null}
    </div>
  );
};

export default BuildingModelUpload;
