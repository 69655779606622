import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

type IdAutocompleteProps = {
  id: string | null;
  label: string;
  ids: string[];
  onSave(id: string | null): void;
};

export function IdAutocomplete({
  id,
  ids,
  label,
  onSave,
}: IdAutocompleteProps) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      value={id}
      onChange={(_event, newValue: string | null) => {
        onSave(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={ids}
      sx={{ width: 300 }}
      slotProps={{
        paper: {
          className: 'bg-[black]',
        },
        clearIndicator: {
          className: 'text-white',
        },
        popupIndicator: {
          className: 'text-white',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{
            background: 'black',
            border: 'none',
            '& input': { background: 'black' },
          }}
        />
      )}
    />
  );
}
