import { FC } from 'react';
import { Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { Net } from '@/components/shared/Icons/Net';
import { Vacancy } from '@/components/shared/Icons/Vancy';
import animationData from '../assets/city.json';

const BlockMobileUsage: FC = () => {
  return (
    <div className="relative flex h-screen w-full items-center justify-center">
      <Lottie
        style={{
          height: '100vh',
          width: '100%',
          cursor: 'auto',
          position: 'absolute',
          left: '0',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        animationData={animationData}
        autoPlay
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        loop
      />
      <div className="relative flex flex-col items-center rounded-3xl bg-black bg-opacity-70 p-20">
        <Typography
          variant="h1"
          className="pb-20 align-middle text-white sm:text-[70px]"
          sx={{ textShadow: '2px 2px 10px rgba(255,255,255,0.5)' }}
        >
          URBAN
          <Net
            sx={{
              fontSize: '36px',
              marginLeft: '5px',
              marginRight: '-5px',
              paddingTop: '5px',
              filter: 'drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.5))',
            }}
          />
          I
          <Vacancy
            sx={{
              fontSize: '36px',
              marginLeft: '0px',
              marginRight: '-14px',
              paddingTop: '5px',
              filter: 'drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.5))',
            }}
          />
        </Typography>
        <Typography variant="h1" className="text-white">
          This website is optimized for desktop usage!
        </Typography>
      </div>
    </div>
  );
};

export default BlockMobileUsage;
