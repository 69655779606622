import { useState, SyntheticEvent, ChangeEventHandler } from 'react';

import {
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { TeamUser } from '@urbanmix-tech/shared-js';

import { Autocomplete } from '@/components/shared/Autocomplete/Autocomplete';
import { UserData } from '@urbanmix-tech/shared-js/data';
import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';

export type NewUserFormProps = {
  users: UserData[];
  teamUsers: TeamUser[];
  onAdd(teamUser: TeamUser): void;
};

export function NewUserForm({ users, teamUsers, onAdd }: NewUserFormProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<UserData | null>(null);
  const [role, setRole] = useState<'DM' | 'EX'>('DM');

  const [userFormData, setUserFormData] = useState<{
    email: string;
    fullName: string;
  }>({
    email: '',
    fullName: '',
  });

  const [mode, setMode] = useState<'registered' | 'invited'>('registered');

  const handleSetEditing = () => {
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setUser(null);
    setRole('DM');
    setUserFormData({ email: '', fullName: '' });
  };

  const handleChangeUser = (
    _event: SyntheticEvent<Element, Event>,
    value: UserData | null
  ) => {
    setUser(value);
  };

  const handleAddUser = () => {
    if (mode === 'registered') {
      if (!user) {
        return;
      }

      onAdd({
        name: user.fullName,
        email: user.email,
        role,
      });
    } else {
      if (
        userFormData.email.trim() === '' ||
        userFormData.fullName.trim() === ''
      ) {
        return;
      }

      onAdd({
        name: userFormData.fullName.trim(),
        email: userFormData.email.trim(),
        role,
      });
    }

    handleCancelEditing();
  };

  const handleChangeRole = (e: SelectChangeEvent) => {
    setRole(e.target.value as 'DM' | 'EX');
  };

  const handleChangeEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserFormData((prev) => ({
      ...prev,
      email: e.target.value,
    }));
  };

  const handleChangeFullName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserFormData((prev) => ({
      ...prev,
      fullName: e.target.value,
    }));
  };

  const handleChangeMode: ChangeEventHandler<HTMLInputElement> = (e) => {
    const checked = e?.target.checked;

    if (checked) {
      setMode('registered');
    } else {
      setMode('invited');
    }
  };

  const isReadyToSubmit = () => {
    if (mode === 'registered') {
      if (user === null) {
        return true;
      }

      const exists = teamUsers.find((item) => item.email === user.email);

      return Boolean(exists);
    }

    if (
      userFormData.email.trim() === '' ||
      userFormData.fullName.trim() === ''
    ) {
      return true;
    }

    return Boolean(
      teamUsers.find((item) => item.email === userFormData.email.trim())
    );
  };

  return !isEditing ? (
    <Button variant="contained" onClick={handleSetEditing}>
      Add New User
    </Button>
  ) : (
    <Stack gap="5px" className="w-full py-10">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption2" className="text-white">
          User
        </Typography>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={mode === 'registered'}
              sx={{
                '& .MuiSwitch-track': {
                  background: '#eee',
                },
              }}
              onChange={handleChangeMode}
            />
          }
          label="Add an existing user"
          labelPlacement="start"
          sx={{
            '& .MuiFormControlLabel-label': {
              color: 'white',
              fontSize: '12px',
            },
          }}
        />
      </Stack>
      {mode === 'registered' ? (
        <Autocomplete
          value={user}
          onChange={handleChangeUser}
          options={users}
          getOptionLabel={(item) => `${item.fullName} ( ${item.email} )`}
          fullWidth
        />
      ) : (
        <Stack direction="row" gap="24px" className="w-full">
          <InputBase
            value={userFormData.email}
            placeholder="Email"
            onChange={handleChangeEmail}
            required
            inputProps={{ type: 'email' }}
            className="rounded-md border border-white p-4 text-[13px] text-white"
            fullWidth
          />
          <InputBase
            value={userFormData.fullName}
            placeholder="Full Name"
            onChange={handleChangeFullName}
            required
            inputProps={{ type: 'text' }}
            className="rounded-md border border-white p-4 text-[13px] text-white"
            fullWidth
          />
        </Stack>
      )}

      <FormControl fullWidth className="mb-6">
        <Typography variant="caption2" className="text-white">
          Role
        </Typography>
        <Select
          labelId="userTeamRoleLabel"
          value={role}
          onChange={handleChangeRole}
          input={
            <InputBase className="rounded-md border border-white p-4 text-[13px] text-white" />
          }
        >
          <MenuItem value="DM">DM</MenuItem>
          <MenuItem value="EX">EX</MenuItem>
        </Select>
      </FormControl>

      <ConfirmButtons
        confirm={{
          label: 'Confirm',
          onClick: handleAddUser,
          disabled: isReadyToSubmit(),
        }}
        cancel={{
          label: 'Cancel',
          onClick: handleCancelEditing,
        }}
      />
    </Stack>
  );
}
