import { useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ConditionReportItem } from '@urbanmix-tech/shared-js';

import { InspectionAccordionItemAccordion } from './InspectionAccordionItemAccordion';
import { ConditionStatistics } from './ConditionStatistics';

export type InspectionAccordionProps = {
  roomType: string;
  disableConditionSatistic?: boolean;
  conditionReports: ConditionReportItem[];
};

export function InspectionAccordion({
  roomType,
  disableConditionSatistic,
  conditionReports,
}: InspectionAccordionProps) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion className="!m-0 bg-transparent text-white" expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-primary" />}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '6px',
          padding: '8.5px 12px',
          background: '#ffffff1a',
          minHeight: '37px !important',
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant="captionMedium" className="flex-1 text-primary">
          {roomType}
        </Typography>

        {!disableConditionSatistic && (
          <ConditionStatistics items={conditionReports || []} />
        )}
      </AccordionSummary>
      <AccordionDetails className="p-0 pt-[5px]">
        <Stack gap="5px">
          <Stack
            direction="row"
            alignItems="center"
            className="px-[14px] py-[4px]"
          >
            <Typography
              variant="caption2"
              className="w-[200px] text-[#E6E3E366]"
            >
              Elements
            </Typography>
            <Typography
              variant="caption2"
              className="w-[100px] text-[#E6E3E366]"
            >
              Condition
            </Typography>
            <Typography
              variant="caption2"
              className="w-[150px] text-[#E6E3E366]"
            >
              Group
            </Typography>
            <Typography
              variant="caption2"
              className="w-[200px] text-[#E6E3E366]"
            >
              Notes
            </Typography>
          </Stack>

          {conditionReports.map((conditionReport) => (
            <InspectionAccordionItemAccordion
              key={conditionReport.name}
              disableConditionSatistic={disableConditionSatistic}
              conditionReport={conditionReport || []}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
