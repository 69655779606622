import { useState } from 'react';

import { Stack, Button, Modal, Box } from '@mui/material';

import { ActionItem, createActionItem } from '@urbanmix-tech/shared-js';

import { ErrorBoundary } from '@sentry/react';
import { CustomActionGenerationModalContent } from '../CustomActionGenerationModalContent';
import { CompletedAccordion } from './CompletedAccordion';
import { ActionGroupList } from '../../BuildingTabs/DashboardActionGroupList';

const today = new Date();

export type TodoTabContentProps = {
  projectId: string | undefined;
  buildingId: string | undefined;
  unitId: string | undefined;
  actions: ActionItem[];
  onClickActionItem(id: string): void;
  onSaveAction(actionItem: ActionItem, payload?: unknown): void;
};

export function TodoTabContent({
  projectId,
  buildingId,
  unitId,
  actions,
  onClickActionItem,
  onSaveAction,
}: TodoTabContentProps) {
  const [openedModal, setOpenedModal] = useState(false);

  const handleCloseModal = () => {
    setOpenedModal(false);
  };

  const handleClickAddCustomAction = () => {
    setOpenedModal(true);
  };

  const handleGenerateNewActionItem = (
    title: string,
    description: string,
    dueDate: Date
  ) => {
    if (projectId && buildingId && unitId) {
      const actionItem = createActionItem({
        projectId,
        buildingId: +buildingId,
        unitId,
        title,
        description,
        severtity: 'regular',
        dueDate,
        startDate: today,
        reminderFrequency: 1,
        status: 'active',
        category: 'general',
        action_type: {
          inputType: 'text',
          reminder: false,
          type: 'approve',
        },
      });
      onSaveAction(actionItem, {});
    }
  };

  return (
    <Stack gap="16px" className="h-full w-[640px]">
      <Stack
        direction="row"
        alignItems="center"
        gap="16px"
        className="z-20 w-[900px]"
      >
        <Button
          variant="outlined"
          size="small"
          className="!border-white px-8 py-0 !text-white"
          onClick={handleClickAddCustomAction}
        >
          + Add Custom Task
        </Button>
      </Stack>

      <Stack className="h-full overflow-auto pr-[8px]" gap="16px">
        <ErrorBoundary fallback={<p>Error loading tasks</p>}>
          <ActionGroupList
            actionItems={actions.filter((action) => action.status === 'active')}
            onClickActionItem={onClickActionItem}
          />
        </ErrorBoundary>
        <CompletedAccordion
          actions={actions.filter((action) => action.status !== 'active')}
          onClickActionItem={onClickActionItem}
        />
      </Stack>

      <Modal open={!!openedModal} onClose={handleCloseModal}>
        <Box
          component="div"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
        >
          <CustomActionGenerationModalContent
            onGenerate={handleGenerateNewActionItem}
            onClose={handleCloseModal}
          />
        </Box>
      </Modal>
    </Stack>
  );
}
