import { ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { iconTypes } from '@/components/shared/AppIcon/AppIcon';
import { ActionItemCategory } from '@urbanmix-tech/shared-js';

export const borderColorClassByCategory: Record<ActionItemCategory, string> = {
  notice: 'border-purple-400',
  inspect: 'border-rose-400',
  makeReady: 'border-orange-300',
  lease: 'border-emerald-200',
  general: 'border-rose-400',
};

// TODO replac this to support custom categories
export const getBgColorClassByCategory = (
  category: ActionItemCategory
): string => {
  switch (category) {
    case 'notice':
      return 'bg-purple-400';
    case 'inspect':
      return 'bg-rose-400';
    case 'makeReady':
      return 'bg-orange-300';
    case 'lease':
      return 'bg-emerald-200';
    case 'general':
      return 'bg-gray-400';
    default:
      return 'bg-purple-400';
  }
};

// TODO replac this to support custom categories
export const getIconNameByCategory = (
  category: ActionItemCategory
): keyof typeof iconTypes => {
  switch (category) {
    case 'notice':
      return 'emailMarkAsUnread';
    case 'inspect':
      return 'eye';
    case 'makeReady':
      return 'tool';
    case 'lease':
      return 'key';
    case 'general':
      return 'forbbiden';
    default:
      return 'avatar';
  }
};

export const labelByCategory = (category: string): string => {
  const labels: Record<string, string> = {
    notice: 'Notice',
    inspect: 'Inspect',
    makeReady: 'Make Ready',
    lease: 'Lease',
    general: 'General',
  };

  // Return the label if it exists, otherwise return the category itself or a default value
  return labels[category] || category;
};

export type TodoAccordionProps = {
  defaultExpanded?: boolean;
  summary: ReactNode;
  details: ReactNode;
};

export function TodoAccordion({
  defaultExpanded,
  summary,
  details,
}: TodoAccordionProps) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className="!m-0 bg-transparent"
    >
      <AccordionSummary
        className="!m-0 !min-h-0 rounded-md border-b border-neutral-800 bg-white/10 !px-24 !py-8 text-white"
        expandIcon={<ExpandMoreIcon className="text-white" />}
        sx={{ '& .MuiAccordionSummary-content': { margin: '0 !important' } }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className="px-0">{details}</AccordionDetails>
    </Accordion>
  );
}
