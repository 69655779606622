import { useEffect, useCallback, useState, useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Button, Stack, Collapse, IconButton, Modal, Box, colors } from '@mui/material';

import { useAlert } from '@/contexts/Alert/AlertContext';

import BuildingModel from '@/components/BuildingModel/BuildingModel';
import { UnitMixTabContent } from '@/components/BuildingTabs/UnitMixTabContent';
import { Breadcrumbs } from '@/components/shared/Breadcrumbs/Breadcrumbs';
import { TabListItem, Tabs } from '@/components/shared/Tabs/Tabs';

import { useAuth } from '@/shared/context/AuthProvider';
import {
  Project,
  Unit,
  ActionItem,
  UserData,
  LeaseLifecycle,
  AnalyzingFilter,
} from '@urbanmix-tech/shared-js';
import { useLoading } from '@/shared/context/UIContextProvider';
import {
  getActionsByProjectId,
  getAllUsers,
  getProjectById,
  getUnitsByProjectId,
  saveActionItem,
  subscribeActionsByProjectId,
} from '@urbanmix-tech/shared-js/data';

import { AnalyzeFilter } from '@/components/BuildingTabs/AnalyzeFilter';
import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';
import AppIcon from '@/components/shared/AppIcon/AppIcon';

import { Highlight } from '@/components/Highlights/Highlight';

import { TodoTabContent } from '@/components/BuildingTabs/TodoTabContent';
import { ErrorBoundary } from '@sentry/react';
import { MyDashboardTabContent } from '@/components/BuildingTabs/MyDashboardTabContent';
import { GeneralModalContent } from '@/components/ActionModals/units/GeneralModalContent/GeneralModalContent';
import { ActionItemModal } from '@/components/ActionModals/units/ActionItemModal/ActionItemModal';
import GenericBuildingModel from '@/components/BuildingModel/BuildingModelGeneric';
import { useBuildingModel } from '../contexts/Building/BuildingContext';
import { TrackerTabContent } from '@/components/BuildingTabs/TrackerTabContent';
import { SensorsTabContent } from '@/components/BuildingTabs/SensorsTabContent';

export default function Building() {
  const navigate = useNavigate();
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();
  const { createLoading } = useLoading();
  const { showAlert } = useAlert();
  const { userData, selectedOption, isEditMode } = useAuth();

  const [tabKey, setTabKey] = useState<string>('mydashboard');
  const [project, setProject] = useState<Project | null>(null);
  const [units, setUnits] = useState<Unit[]>([]);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [usersByEmail, setUsersByEmail] = useState<Record<string, UserData>>(
    {}
  );

  const [collapsed, setCollapsed] = useState(false);
  const [hoveredUnitId, setHoveredUnitId] = useState<string | null>(null);

  const [selectedAction, setSelectedAction] = useState<ActionItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [buildingData,setBuildingData] = useState<Record<string, any>>({});

  const [filters, setFilters] = useState<{
    unitFilter: string[];
    statusFilter: LeaseLifecycle[];
    analyzeFilter: string[];
  }>({
    unitFilter: [],
    statusFilter: [],
    analyzeFilter: [AnalyzingFilter.None],
  });

  const { selectedUnitId, selectUnit } = useBuildingModel();

  useEffect(() => {
    if (projectId) {
      const loader = createLoading();

      loader.startLoading({ title: 'Loading Project from db...' });

      getProjectById(projectId).then((value) => {
        if (value && value.buildings.length > 0) {
          setProject(value);
        } else {
          showAlert('Building not exists!', 'error');
        }

        loader.stopLoading();
      });

      getUnitsByProjectId(projectId).then((_units) => {
        setUnits(_units);
      });

      getAllUsers().then((_users) => {
        setUsers(_users || []);
        const usersByEmailObj: Record<string, UserData> = {};
        _users?.forEach((user) => {
          usersByEmailObj[user.email] = user;
        });
        setUsersByEmail(usersByEmailObj);        
      });

      const subscribeToUpdates = async () => {
        const unsubscribe = await subscribeActionsByProjectId(projectId,
          (items : ActionItem[]) => setActionItems(items), // onUpdate callback
          (error) => setError(error) // onError callback
        );
  
        // Clean up the listener when the component is unmounted
        return () => unsubscribe();
      };

      const unsubscribePromise = subscribeToUpdates();

      getActionsByProjectId(projectId).then((value) => {
        setActionItems(value);
      });

      return () => {
        unsubscribePromise.then((unsubscribe) => unsubscribe && unsubscribe());
      };
    } else {
      showAlert(
        'There is something wrong, please check your connectivity!',
        'error'
      );
      return () => {};
    }
  }, [createLoading, projectId, showAlert]);

  const handleClickUnit = useCallback(
    (unitId: string) => {
      navigate(`units/${unitId}`);
    },
    [navigate]
  );

  const handleHoverUnit = useCallback((unitId: string | null) => {
    // console.log('handle hovered unit', unitId);
    // selectUnit(unitId);
    setHoveredUnitId(unitId);
    // console.log('hovered unit', selectedUnitId);
    setHoveredUnitId(unitId);
  }, []);

  const handleChangeStatusFilter = useCallback((selected: LeaseLifecycle[]) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      statusFilter: selected,
    }));
  }, []);

  const handleChangeUnitFilter = useCallback((selected: string[]) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      unitFilter: selected,
    }));
  }, []);

  const handleChangeAnalyzeFilter = useCallback((selected: string[]) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      analyzeFilter: selected,
    }));
  }, []);

  const handleChangeModal = (open: boolean) => {
    if (open === false) {
      setSelectedAction(null);
    }
    setIsModalOpen(open);
  };


  const filteredUnits = useMemo(
    () =>
      units
        .filter((unit) => {
          if (filters.unitFilter.length > 0) {
            return filters.unitFilter.includes(`${unit.numBedrooms}br`);
          }

          return true;
        })
        .filter((unit) => {
          if (filters.statusFilter.length > 0) {
            return Array.isArray(unit.currentState)
              ? unit.currentState.length > 0
                ? filters.statusFilter.some((item) =>
                    unit.currentState.includes(item)
                  )
                : filters.statusFilter.includes(LeaseLifecycle.NOTICE)
              : filters.statusFilter.includes(
                  (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE
                );
          }

          return true;
        }),
    [filters, units]
  );

  const actionsById = useMemo(() => {
    const result: Record<string, ActionItem> = {};

    actionItems.forEach((action) => {
      result[action.id] = action;
    });

    return result;
  }, [actionItems]);

  const handleClickActionItem = (actionId: string) => {
    setSelectedAction(actionsById[actionId]);
    setIsModalOpen(true);
  };

  const filteredActionItems = useMemo(() => {
    const unitIds = filteredUnits.map((unit) => unit.id);

    return actionItems.filter((action) => unitIds.includes(action.unitId));
  }, [actionItems, filteredUnits]);

  const userActionItems = useMemo(() => {
    return filteredActionItems.filter((action) => {
      if (action.owner?.userId === userData?.id) {
        return true;
      }
      if (action.supervisor?.userId === userData?.id) {
        return true;
      }
      if (
        action.followers?.some((follower) => follower.userId === userData?.id)
      ) {
        return true;
      }

      if (!action.owner) {
        return true
      }
      return false;
    });
  }, [filteredActionItems, userData]);

  if (!project) {
    return null;
  }

  const tabList: TabListItem[] = [
    {
      tabId: 'mydashboard',
      label: 'My Dashboard',
      iconName: 'eye',
      status: userActionItems.length,
    },
    {
      tabId: 'todos',
      label: 'All Tasks',
      iconName: 'actions',
      status: filteredActionItems.length,
    },
    {
      tabId: 'unitmix',
      label: 'UnitMix',
      iconName: 'file',
      status: filteredUnits.length,
    },
    {
      tabId: 'sensors',
      label: 'Sensors',
      iconName: 'file',
      status: filteredUnits.length,
    },
  ];

  const breadcrumbsPath = [
    {
      label:
        selectedOption.value !== 'all' ? selectedOption.label : 'Portfolio',
      href: '/portfolio',
    },
    {
      label: (
        <Stack direction="row" gap="4px" alignItems="center">
          {project?.name || ''}
          {project?.address && !project.address.includes(project?.name)
            ? `, ${project.address}`
            : ''}
          <AppIcon name="info" />
        </Stack>
      ),
    },
  ];

  return (
    <Stack gap="16px" className="h-full w-full flex min-w-[470px] p-24">
      <Breadcrumbs paths={breadcrumbsPath} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        {userData?.isDev && isEditMode && (
          <Link to={`/projects/${projectId}/${buildingId}/model`}>
            <Button variant="outlined">Edit Project</Button>
          </Link>
        )}
      </Stack>

      {/* <Highlight
        units={filteredUnits}
        statusFilter={filters.statusFilter}
        onChangeStatusFilter={handleChangeStatusFilter}
        unitFilter={filters.unitFilter}
        onChangeUnitFilter={handleChangeUnitFilter}
      /> */}

      <Tabs items={tabList} tabKey={tabKey} setTabKey={setTabKey} />

      <Stack
        direction="row"
        className="h-[calc(100vh-18rem)] pb-20 overflow-visible"
        gap="16px"
      >
        <Collapse in={!collapsed} orientation="horizontal" className={twMerge(collapsed ? 'w-12/12' : 'w-6/12','','overflow-auto')}>
          <Stack
            gap="18px"
            className="wrapperInner-w-full  relative  flex-1 overflow-auto"
          >
            {tabKey === 'sensors' ? <SensorsTabContent /> : null}
            {tabKey === 'mydashboard' ? (
              <MyDashboardTabContent
                actionItems={userActionItems}
                leaseStatusFilter={filters.statusFilter}
                onClickActionItem={handleClickActionItem}
                onBuildingDataChange={(data) => {
                  setBuildingData(data);
                }}
              />
            ) : null}
            {tabKey === 'todos' ? (
              <TrackerTabContent
                actionItems={filteredActionItems}
                leaseStatusFilter={filters.statusFilter} onClickActionItem={handleClickActionItem}
                onBuildingDataChange={(data) => {
                  setBuildingData(data);
                }}/>
            ) : null}
            {tabKey === 'unitmix' ? (
              <UnitMixTabContent
                units={filteredUnits}
                onSelectUnit={handleHoverUnit}
                hoveredUnitId={hoveredUnitId}
              />
            ) : null}
          </Stack>
        </Collapse>

        <Stack
          direction="row"
          alignItems="center"
          className={twMerge(
            'min-h-[700px] rounded-[11px] border border-neutral-400',            
            collapsed ? 'w-full' : 'w-6/12'
          )}
        >
          {/* <IconButton
            onClick={() => setCollapsed(!collapsed)}
            className="absolute left-0 top-0 z-20 rounded-md bg-white/10 p-4 hover:bg-white/20"
          >
            <ArrowIcon
              className={twMerge(
                'cursor-pointer text-stone-200',
                collapsed && 'rotate-180'
              )}
            />
          </IconButton> */}
          {tabKey === 'unitmix' ? (
            <AnalyzeFilter
              className="absolute right-24 top-24 z-10"
              analyzeFilter={filters.analyzeFilter}
              onChangeAnalyzeFilter={handleChangeAnalyzeFilter}
            />
          ) : null}
          <ErrorBoundary
            fallback={
              <div>
                <p>Error loading model</p>
              </div>
            }
          >
            {/* {project.buildings.length > 0 && tabKey !== 'unitmix' ? ( */}
              <GenericBuildingModel
                building={project.buildings[0]}
                units={filteredUnits}
                selectedUnitIds={hoveredUnitId ? [hoveredUnitId] : []}
                onSelectUnit={handleClickUnit}
                onHoverUnit={handleHoverUnit} valueRange={{
                  minValue: 0,
                  maxValue: 100,
                  minColor: colors.red[500],
                  maxColor: colors.green[500],
                }} 
                data={buildingData}
                />
            {/* ) : null} */}
            {/* {project.buildings.length > 0 && (tabKey !== 'mydashboard') ? (              
              <BuildingModel
                building={project.buildings[0]}
                units={filteredUnits}
                expandedStatus={filters.statusFilter}
                subStatus={null}
                analyzingFilter={filters.analyzeFilter[0] as AnalyzingFilter}
                selectedUnitIds={hoveredUnitId ? [hoveredUnitId] : []}
                onSelectUnit={handleClickUnit}
                onHoverUnit={handleHoverUnit}
              />              
            ) : null} */}
          </ErrorBoundary>
        </Stack>
      </Stack>
      {selectedAction ? (
        <Modal
          open={isModalOpen}
          onClose={() => {
            setSelectedAction(null);
            setIsModalOpen(false);
          }}
        >
          <Box
            component="div"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] shadow outline-none"
          >
            <ActionItemModal
              actionItem={selectedAction}
              onChangeModalOpen={handleChangeModal}
             />
          </Box>
        </Modal>
      ) : null}
    </Stack>
  );
}
