import { ReactNode } from 'react';

import { Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';

export type BreadcrumbsProps = {
  paths: {
    label: ReactNode;
    href?: string;
  }[];
};

export function Breadcrumbs({ paths }: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs className="flex items-center text-stone-200/50">
      {paths.map((path, index) => (
        <Link
          key={path.label as string}
          underline={path.href ? 'hover' : 'none'}
          fontSize={20}
          color={index === paths.length - 1 ? '#E6E3E3' : '#E6E3E380'}
          href={path.href}
        >
          {path.label}
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
}
