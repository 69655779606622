/* eslint-disable react/no-unknown-property */
import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  Suspense,
} from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import {
  CameraControls,
  Environment,
  GradientTexture,
  OrbitControls,
} from '@react-three/drei';
import { Stack, Popover, PopoverPosition } from '@mui/material';

import { useAlert } from '@/contexts/Alert/AlertContext';

import { Building, Unit } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle, AnalyzingFilter, SubStateType } from '@urbanmix-tech/shared-js';
import { Loader } from '@/components/Loader/Loader';

import { D3Model } from '../D3Model/D3Model';
import { materials, getMaterialBetweenDarkToWhite } from '../D3Model/materials';
import { BuildingUnitPopup } from './BuildingUnitPopup';

export const leaseLifecycleStateToColor = {
  [LeaseLifecycle.NOTICE]: materials.notice,
  [LeaseLifecycle.INSPECT]: materials.inspect,
  [LeaseLifecycle.TURNOVER]: materials.construct,
  [LeaseLifecycle.LEASE]: materials.lease,
  [LeaseLifecycle.DONE]: materials.regular,
};

type BuildingModelProps = {
  building: Building;
  units: Unit[];
  analyzingFilter: AnalyzingFilter;
  expandedStatus: LeaseLifecycle[];
  subStatus: SubStateType | null;
  selectedUnitIds: string[];
  onSelectUnit(unitId: string): void;
  onHoverUnit(unitId: string | null): void;
};

export default function BuildingModel({
  building,
  units,
  analyzingFilter,
  expandedStatus,
  subStatus,
  selectedUnitIds,
  onSelectUnit,
  onHoverUnit,
}: BuildingModelProps) {
  const { showAlert } = useAlert();

  const [hoveredMeshId, setHoveredMeshId] = useState<string | null>(null);

  const [disableUnitClick, setDisableUnitClick] = useState(false);
  const disableUnitClickTimeout = useRef<NodeJS.Timeout | null>(null);
  const lastAzimuthAngle = useRef(0);

  const [anchor, setAnchor] = useState<{
    unit: Unit;
    position: PopoverPosition;
  } | null>(null);

  const cursorPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!building.modelPath) {
      showAlert('Not exists building model', 'error');
    }
  }, [building.modelPath, showAlert]);

  useEffect(() => {
    const mousePositionTracker = (e: MouseEvent) => {
      cursorPosition.current = {
        x: e.pageX,
        y: e.pageY,
      };
    };

    document.addEventListener('mousemove', mousePositionTracker);

    return () => {
      document.removeEventListener('mousemove', mousePositionTracker);
    };
  }, []);

  const { meshIdsMap, meshMaterialMap, d3ModelMode } = useMemo(() => {
    const tempMap = new Map<string, Unit>();
    const tempMeshMaterialMap: Record<string, THREE.Material> = {};

    let tempD3ModelMode: 'standard' | 'transparent' = 'standard';

    switch (analyzingFilter) {
      case AnalyzingFilter.None: {
        const isChecklistEmpty = !expandedStatus || expandedStatus.length === 0;

        tempD3ModelMode = isChecklistEmpty ? 'standard' : 'transparent';

        units.forEach((unit) => {
          if (!unit.meshId) {
            return;
          }

          const currentStatus = Array.isArray(unit.currentState)
            ? unit.currentState.length > 0
              ? unit.currentState[unit.currentState.length - 1]
              : LeaseLifecycle.NOTICE
            : (unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE;

          tempMap.set(unit.meshId, unit);

          if (
            tempD3ModelMode !== 'standard' &&
            !expandedStatus.includes(currentStatus)
          ) {
            return;
          }

          if (subStatus && subStatus !== unit.currentSubState) {
            return;
          }

          tempMeshMaterialMap[unit.meshId] =
            leaseLifecycleStateToColor[currentStatus];
        });

        break;
      }
      case AnalyzingFilter.Rent: {
        let maxRent: number | undefined;
        let minRent: number | undefined;

        tempD3ModelMode = 'transparent';

        units.forEach((unit) => {
          if (unit.marketRent) {
            if (maxRent === undefined || unit.marketRent > maxRent) {
              maxRent = unit.marketRent;
            }

            if (minRent === undefined || unit.marketRent < minRent) {
              minRent = unit.marketRent;
            }

            return;
          }

          if (unit.actualRent) {
            if (maxRent === undefined || unit.actualRent > maxRent) {
              maxRent = unit.actualRent;
            }

            if (minRent === undefined || unit.actualRent < minRent) {
              minRent = unit.actualRent;
            }
          }
        });

        units.forEach((unit) => {
          if (!unit.meshId) {
            return;
          }

          tempMap.set(unit.meshId, unit);

          if (unit.marketRent === null && unit.actualRent === null) {
            tempMeshMaterialMap[unit.meshId] = materials.regular;
          }

          if (minRent === undefined || maxRent === undefined) {
            return;
          }

          if (maxRent === minRent) {
            tempMeshMaterialMap[unit.meshId] = materials.anaylzeHighColor;
          }

          if (unit.marketRent) {
            tempMeshMaterialMap[unit.meshId] = getMaterialBetweenDarkToWhite(
              (unit.marketRent - minRent) / (maxRent - minRent)
            );
          } else if (unit.actualRent) {
            tempMeshMaterialMap[unit.meshId] = getMaterialBetweenDarkToWhite(
              (unit.actualRent - minRent) / (maxRent - minRent)
            );
          }
        });

        break;
      }
      case AnalyzingFilter.RentBySQFT: {
        let maxRentBySQFT: number | undefined;
        let minRentBySQFT: number | undefined;

        tempD3ModelMode = 'transparent';

        units.forEach((unit) => {
          if (unit.marketRent && unit.sqft) {
            if (
              maxRentBySQFT === undefined ||
              unit.marketRent / unit.sqft > maxRentBySQFT
            ) {
              maxRentBySQFT = unit.marketRent / unit.sqft;
            }

            if (
              minRentBySQFT === undefined ||
              unit.marketRent / unit.sqft < minRentBySQFT
            ) {
              minRentBySQFT = unit.marketRent / unit.sqft;
            }

            return;
          }

          if (unit.actualRent && unit.sqft) {
            if (
              maxRentBySQFT === undefined ||
              unit.actualRent / unit.sqft > maxRentBySQFT
            ) {
              maxRentBySQFT = unit.actualRent / unit.sqft;
            }

            if (
              minRentBySQFT === undefined ||
              unit.actualRent / unit.sqft < minRentBySQFT
            ) {
              minRentBySQFT = unit.actualRent / unit.sqft;
            }
          }
        });

        units.forEach((unit) => {
          if (!unit.meshId) {
            return;
          }

          tempMap.set(unit.meshId, unit);

          if (unit.marketRent === null && unit.actualRent === null) {
            tempMeshMaterialMap[unit.meshId] = materials.regular;
          }

          if (minRentBySQFT === undefined || maxRentBySQFT === undefined) {
            return;
          }

          if (maxRentBySQFT === minRentBySQFT) {
            tempMeshMaterialMap[unit.meshId] = materials.greenColor;
          }

          if (unit.marketRent && unit.sqft) {
            tempMeshMaterialMap[unit.meshId] = getMaterialBetweenDarkToWhite(
              (unit.marketRent / unit.sqft - minRentBySQFT) /
                (maxRentBySQFT - minRentBySQFT)
            );
          } else if (unit.actualRent && unit.sqft) {
            tempMeshMaterialMap[unit.meshId] = getMaterialBetweenDarkToWhite(
              (unit.actualRent / unit.sqft - minRentBySQFT) /
                (maxRentBySQFT - minRentBySQFT)
            );
          }
        });

        break;
      }
      default: {
        break;
      }
    }

    return {
      meshIdsMap: tempMap,
      meshMaterialMap: tempMeshMaterialMap,
      d3ModelMode: tempD3ModelMode,
    };
  }, [analyzingFilter, units, expandedStatus, subStatus]);

  useEffect(() => {
    if (!hoveredMeshId) {
      return () => {};
    }

    const unit = meshIdsMap.get(hoveredMeshId);

    if (!unit) {
      return () => {};
    }

    const timer = setTimeout(() => {
      setAnchor({
        position: {
          top: cursorPosition.current.y,
          left: cursorPosition.current.x,
        },
        unit,
      });
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hoveredMeshId, meshIdsMap, onSelectUnit]);

  const selectedMeshIds = useMemo(() => {
    const tempMap = new Map<string, boolean>();

    selectedUnitIds.forEach((unitId) => {
      tempMap.set(unitId, true);
    });

    return units
      .filter((unit) => tempMap.get(unit.id))
      .map((unit) => unit.meshId)
      .filter((item) => item) as string[];
  }, [units, selectedUnitIds]);

  const handleUnitClick = (meshId: string | null) => () => {
    if (!meshId) {
      return;
    }

    if (disableUnitClick) {
      return;
    }

    const unit = meshIdsMap.get(meshId);

    if (!unit) {
      return;
    }

    onSelectUnit(unit.id);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleHoveredMeshId = useCallback(
    (meshId: string | null) => {
      if (meshId && meshId.includes('apt_')) {
        if (!meshId.includes('window')) {
          setHoveredMeshId(meshId);
        }

        const unit = meshIdsMap.get(meshId);

        if (unit) {
          onHoverUnit(unit.id);
        } else {
          onHoverUnit(null);
        }
      } else {
        setHoveredMeshId(null);
        onHoverUnit(null);
      }
    },
    [meshIdsMap, onHoverUnit]
  );

  const handleOnStart = (e?: {
    type: 'controlstart';
    target?: { azimuthAngle: number };
  }) => {
    if (!e?.target?.azimuthAngle) return;
    lastAzimuthAngle.current = e?.target?.azimuthAngle;
    setDisableUnitClick(true);
    if (disableUnitClickTimeout.current) {
      clearTimeout(disableUnitClickTimeout.current);
    }
  };

  const handleOnEnd = (e?: {
    type: 'controlend';
    target?: { azimuthAngle: number };
  }) => {
    if (!e?.target?.azimuthAngle) return;
    if (Math.abs(lastAzimuthAngle.current - e.target.azimuthAngle) < 0.0001) {
      setDisableUnitClick(false);
    } else
      disableUnitClickTimeout.current = setTimeout(() => {
        setDisableUnitClick(false);
      }, 20);
  };

  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      className="h-full w-full rounded-xl"
    >
      {building.modelPath !== null && (
        <Suspense fallback={<Loader title="Rendering 3D..." center />}>
          <Canvas
            onClick={handleUnitClick(hoveredMeshId)}
            className="rounded-xl"
          >
            <D3Model
              url={building.modelPath}
              meshMaterialMap={meshMaterialMap}
              setHoveredMeshId={handleHoveredMeshId}
              hoveredMeshId={hoveredMeshId}
              selectedMeshIds={selectedMeshIds}
              mode={d3ModelMode}
            />
            <CameraControls
              azimuthAngle={building['3dData'].cameraConf.azimuthAngle || 0}
              onStart={handleOnStart}
              onEnd={handleOnEnd}
            />
            <OrbitControls
              target={building['3dData'].cameraConf.target}
              minDistance={(building['3dData'].cameraConf.distance || 0) * 0.8}
              maxDistance={building['3dData'].cameraConf.distance || 0}
              enablePan={false}
              minPolarAngle={building['3dData'].cameraConf.polarAngle || 0}
              maxPolarAngle={building['3dData'].cameraConf.polarAngle || 0}
            />
            <Environment preset="forest" />
            {/* <ambientLight intensity={0.5} /> */}
            <directionalLight intensity={0.7} position={[-10, 10, 15]} />
            <directionalLight intensity={0.7} position={[10, -10, -15]} />
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
              <planeGeometry args={[100, 100]} />
              <meshStandardMaterial color={0x333333} />
            </mesh>
            <GradientTexture
              attach="background"
              stops={[0, 0.6, 1]}
              colors={['#090909', '#2e2e2e', '#dddddd']}
              size={500}
            />
          </Canvas>
        </Suspense>
      )}
      <Popover
        open={Boolean(anchor)}
        anchorReference="anchorPosition"
        onClose={handleClose}
        anchorPosition={anchor?.position}
        slotProps={{
          paper: {
            className: 'bg-black !border-none',
          },
        }}
      >
        {anchor && (
          <BuildingUnitPopup
            unitId={anchor.unit.id}
            numBedrooms={anchor.unit.numBedrooms || 0}
            numBathrooms={anchor.unit.numBathrooms || 0}
            rent={anchor.unit.actualRent || 0}
            statusList={
              Array.isArray(anchor.unit.currentState) &&
              anchor.unit.currentState.length > 0
                ? anchor.unit.currentState
                : [
                    (anchor.unit.currentState as LeaseLifecycle) ||
                      LeaseLifecycle.NOTICE,
                  ]
            }
          />
        )}
      </Popover>
    </Stack>
  );
}
