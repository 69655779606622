/* eslint-disable react/no-unknown-property */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  Suspense,
} from 'react';
import * as THREE from 'three';
import { Canvas, Vector3, useFrame } from '@react-three/fiber';
import {
  CameraControls,
  ContactShadows,
  Environment,
  GradientTexture,
  OrbitControls,
  Sphere,
  Plane,
  AccumulativeShadows,
  RandomizedLight,
} from '@react-three/drei';
import { Stack, Popover, PopoverPosition, Button } from '@mui/material';

import { useAlert } from '@/contexts/Alert/AlertContext';

import { Building, Unit, LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { Loader } from '@/components/Loader/Loader';

import { D3Model } from '../D3Model/D3Model';
import { materials, getMaterialBetweenDarkToWhite } from '../D3Model/materials';
import { BuildingUnitPopup } from './BuildingUnitPopup';
import { useBuildingModel } from '../../contexts/Building/BuildingContext';


export const leaseLifecycleStateToColor = {
  [LeaseLifecycle.NOTICE]: materials.notice,
  [LeaseLifecycle.INSPECT]: materials.inspect,
  [LeaseLifecycle.TURNOVER]: materials.construct,
  [LeaseLifecycle.LEASE]: materials.lease,
  [LeaseLifecycle.DONE]: materials.regular,
};

export type ValueRange = {
  minValue: number;
  maxValue: number;
  minColor: string;
  maxColor: string;
};

type BuildingModelProps = {
  building: Building;
  units: Unit[];
  selectedUnitIds: string[];
  valueRange: ValueRange;
  data: Record<string, any>; // unitid and measurement
  onSelectUnit(unitId: string): void;
  onHoverUnit(unitId: string | null): void;
};

export default function GenericBuildingModel({
  building,
  units,
  selectedUnitIds,
  valueRange,
  data,
  onSelectUnit,
  onHoverUnit,
}: BuildingModelProps) {
  const { showAlert } = useAlert();

  const [hoveredMeshId, setHoveredMeshId] = useState<string | null>(null);

  const [disableUnitClick, setDisableUnitClick] = useState(false);
  const disableUnitClickTimeout = useRef<NodeJS.Timeout | null>(null);
  const lastAzimuthAngle = useRef(0);

  const { selectedUnitId, hoveredUnitId, unitsOverlayData,displayMode } =
    useBuildingModel();

  const [anchor, setAnchor] = useState<{
    unit: Unit;
    position: PopoverPosition;
  } | null>(null);

  const cursorPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!building.modelPath) {
      showAlert('Not exists building model', 'error');
    }
  }, [building.modelPath, showAlert]);

  useEffect(() => {
    const mousePositionTracker = (e: MouseEvent) => {
      cursorPosition.current = {
        x: e.pageX,
        y: e.pageY,
      };
    };

    document.addEventListener('mousemove', mousePositionTracker);

    return () => {
      document.removeEventListener('mousemove', mousePositionTracker);
    };
  }, []);

  const { meshIdsMap, meshMaterialMap, d3ModelMode } = useMemo(() => {
    const tempMap = new Map<string, Unit>();
    const tempMeshMaterialMap: Record<string, THREE.Material> = {};

    const tempD3ModelMode: 'standard' | 'transparent' = 'standard';

    units.forEach((unit) => {
      // if unit.id starts with 0, truncate it and add apt_ to the start

      // if (unit.id.startsWith('0')) {
      //   unit.meshId = `unit${unit.id.slice(1)}`.toLowerCase();
      // } else {
      //   unit.meshId = `apt_${unit.id}`.toLowerCase();
      // }      
      
      // console.log('unit mesh id',unit.id, unit.meshId);
      if (unit.meshId) {
        // console.log('got mesh id for unit',unit.id,unit.meshId);
        tempMap.set(unit.meshId, unit);

        // get value from data
        const unitData = data[unit.id];
        // console.log('unit data',unitData);

        if (unitsOverlayData[unit.id]) {
          // console.log('setting unit overlay data',unitsOverlayData[unit.id]);
          // tempMeshMaterialMap[unit.meshId] = new THREE.MeshStandardMaterial({
          //   color: unitsOverlayData[unit.id].colorOverlay || 0x0000ff,
          //   transparent: true,
          //   opacity: 0.8,
          //   metalness: 0,
          //   roughness: 0.8,
          // });
          let mat: any | null = null;

          // if (displayMode == 0) {
          //   mat = materials.sensorBase.clone();
          //   mat.emissive = new THREE.Color(
          //     unitsOverlayData[unit.id].colorOverlay || 0x0000ff
          //   );
          //   mat.opacity = 0.8;
          //   mat.roughness = 1;
          // }
          // const mat = materials.sensorBase.clone();
          if (displayMode == 0) {
            mat = materials.sensorBase.clone();
            mat.emissive = new THREE.Color(
              unitsOverlayData[unit.id].colorOverlay || 0x0000ff
            );            
            mat.opacity = 0.5;
            mat.metalness = 1;
          } else if (displayMode == 1) {
            mat = materials.sensorBase.clone();
            mat.emissive = new THREE.Color(
              unitsOverlayData[unit.id].colorOverlay || 0x0000ff
            );
            mat.opacity = 0.8;
            mat.roughness = 1;
          } else if (displayMode == 2) {
            mat = materials.sensorBase.clone();
            mat.emissive = new THREE.Color(
              unitsOverlayData[unit.id].colorOverlay || 0x0000ff
            );
            mat.metalness = 1;
            mat.opacity = 1;
            mat.roughness = 1;
          }

          tempMeshMaterialMap[unit.meshId] = mat;
        } else if (unitData && unitData.color) {
          tempMeshMaterialMap[unit.meshId] = unitData.color;
        } else if (unitData && unitData.value) {
          console.log('setting value');
          tempMeshMaterialMap[unit.meshId] = getMaterialBetweenDarkToWhite(
            (unitData.value - valueRange.minValue) /
              (valueRange.maxValue - valueRange.minValue)
          );
          console.log('value set', tempMeshMaterialMap[unit.meshId]);
        }
      }
    });
    console.log('mesh matrial map from temp',tempMeshMaterialMap)
    return {
      meshIdsMap: tempMap,
      meshMaterialMap: tempMeshMaterialMap,
      d3ModelMode: tempD3ModelMode,
    };
  }, [units, data, unitsOverlayData, valueRange.minValue, valueRange.maxValue]);

  useEffect(() => {
    if (!hoveredMeshId) {
      return () => {};
    }

    const unit = meshIdsMap.get(hoveredMeshId);

    if (!unit) {
      return () => {};
    }

    const timer = setTimeout(() => {
      setAnchor({
        position: {
          top: cursorPosition.current.y,
          left: cursorPosition.current.x,
        },
        unit,
      });
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hoveredMeshId, meshIdsMap, onSelectUnit]);

  const selectedMeshIds = useMemo(() => {
    const tempMap = new Map<string, boolean>();

    if (selectedUnitId) {
      tempMap.set(selectedUnitId, true);
    }

    selectedUnitIds.forEach((unitId) => {
      tempMap.set(unitId, true);
    });

    return units
      .filter((unit) => tempMap.get(unit.id))
      .map((unit) => unit.meshId)
      .filter((item) => item) as string[];
  }, [units, selectedUnitIds, selectedUnitId]);

  const handleUnitClick = (meshId: string | null) => () => {
    if (!meshId) {
      return;
    }

    if (disableUnitClick) {
      return;
    }

    const unit = meshIdsMap.get(meshId);

    if (!unit) {
      return;
    }

    onSelectUnit(unit.id);
  };

  useEffect(() => {
    if (hoveredUnitId) {
      const meshId = units.find((unit) => unit.id === hoveredUnitId)?.meshId;
      if (meshId && (meshId.includes('apt_') || meshId.includes('unit_'))) {
        if (!meshId.includes('window')) {
          setHoveredMeshId(meshId);
        }
      }
    }
  }, [hoveredUnitId, units]);

  const handleClose = () => {
    setAnchor(null);
  };

  const handleHoveredMeshId = useCallback(
    (meshId: string | null) => {
      // console.log('hovered mesh id',meshId);
      if (meshId && (meshId.includes('apt_') || meshId.includes('unit_'))) {
        if (!meshId.includes('window')) {
          setHoveredMeshId(meshId);
        }

        const unit = meshIdsMap.get(meshId);

        if (unit) {
          onHoverUnit(unit.id);
        } else {
          onHoverUnit(null);
        }
      } else {
        setHoveredMeshId(null);
        onHoverUnit(null);
      }
    },
    [meshIdsMap, onHoverUnit]
  );

  const handleOnStart = (e?: {
    type: 'controlstart';
    target?: { azimuthAngle: number };
  }) => {
    if (!e?.target?.azimuthAngle) return;
    lastAzimuthAngle.current = e?.target?.azimuthAngle;
    setDisableUnitClick(true);
    if (disableUnitClickTimeout.current) {
      clearTimeout(disableUnitClickTimeout.current);
    }
  };

  const handleOnEnd = (e?: {
    type: 'controlend';
    target?: { azimuthAngle: number };
  }) => {
    if (!e?.target?.azimuthAngle) return;
    if (Math.abs(lastAzimuthAngle.current - e.target.azimuthAngle) < 0.0001) {
      setDisableUnitClick(false);
    } else
      disableUnitClickTimeout.current = setTimeout(() => {
        setDisableUnitClick(false);
      }, 20);
  };

  const lights = useMemo(() => {
    const positions: Vector3[] = [
      [0, 180, 100],
      [143, 180, -80.5],
      [0, 180, -160],
    ];
    return (
      <>
        {positions.map((pos) => (
          <>
            <pointLight intensity={30000} position={pos} castShadow />
            <Sphere
              args={[1, 16, 16, 0, Math.PI * 2, 0, Math.PI * 2]}
              position={pos}
              material={materials.redColor}
            />
          </>
        ))}
      </>
    );
  }, []);

  const [rotateAround, setRotateAround] = useState(false);

  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      className="h-full w-full rounded-xl"
    >
       <Button
      className="absolute right-0 top-0"
      onClick={() => setRotateAround(!rotateAround)}
    >
      Rotate
    </Button>
      
      {building.modelPath !== null && (
        <Suspense fallback={<Loader title="Rendering 3D..." center />}>
          <Canvas
            shadows
            // dpr={[1, 1.5]}
            // fog={{ color: '#a0a0a0', near: 50, far: 100 }} // This adds fo
            onClick={handleUnitClick(hoveredMeshId)}
            className="rounded-xl"
            // onCreated={({ scene }) => {
            //   // scene.background = new THREE.Color(0xffffff);  // Set background color
            // }}
          >
            <ambientLight intensity={1} />
            {lights}
            
            <D3Model
              url={building.modelPath}
              meshMaterialMap={meshMaterialMap}
              setHoveredMeshId={handleHoveredMeshId}
              hoveredMeshId={hoveredMeshId}
              selectedMeshIds={selectedMeshIds}
              mode={d3ModelMode}
              rotate={rotateAround}
            />
            <CameraControls
              azimuthAngle={building['3dData'].cameraConf.azimuthAngle || 0}
              onStart={handleOnStart}
              onEnd={handleOnEnd}
            />
            <OrbitControls
              target={building['3dData'].cameraConf.target}
              minDistance={(building['3dData'].cameraConf.distance || 0) * 0.8}
              maxDistance={building['3dData'].cameraConf.distance || 0}
              enablePan={false}
              minPolarAngle={building['3dData'].cameraConf.polarAngle || 0}
              maxPolarAngle={building['3dData'].cameraConf.polarAngle || 0}
            />

            {/* <fog attach="fog" args={["#f0f0f0", 0, 20]} /> */}
            {/* <ambientLight intensity={0.7} /> */}
            {/* <Environment preset="city"  /> */}
            {/* <ContactShadows
              resolution={512}
              position={[0, -0.8, 0]}
              opacity={1}
              scale={10}
              blur={2}
              far={0.8}
            /> */}

            {/* <Plane rotation={[-Math.PI / 2, 0, 0]} args={[100, 100]}>
              <meshPhongMaterial
                attach="material"
                color={0xcbcbcb}
                depthWrite={false}
              />
            </Plane> */}
            {/* <directionalLight intensity={0.7} position={[-10, 10, 15]} />
            <directionalLight intensity={0.7} position={[10, -10, -15]} /> */}
            <mesh rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
              <planeGeometry args={[400, 400]} />
              <meshStandardMaterial color={0xeeeeee} />
            </mesh>
            {/* <Color attach="background" args={['#f0f0f0']} /> */}
            <GradientTexture
              attach="background"
              stops={[0, 0]}
              colors={['#ffffff', '#2e2e2e', '#dddddd']}
              size={500}
            />
          </Canvas>
        </Suspense>
      )}
      <Popover
        open={Boolean(anchor)}
        anchorReference="anchorPosition"
        onClose={handleClose}
        anchorPosition={anchor?.position}
        slotProps={{
          paper: {
            className: 'bg-black !border-none',
          },
        }}
      >
        {anchor && (
          <BuildingUnitPopup
            unitId={anchor.unit.id}
            numBedrooms={anchor.unit.numBedrooms || 0}
            numBathrooms={anchor.unit.numBathrooms || 0}
            rent={anchor.unit.actualRent || 0}
            statusList={
              Array.isArray(anchor.unit.currentState) &&
              anchor.unit.currentState.length > 0
                ? anchor.unit.currentState
                : [
                    (anchor.unit.currentState as LeaseLifecycle) ||
                      LeaseLifecycle.NOTICE,
                  ]
            }
          />
        )}
      </Popover>
    </Stack>
  );
}

const BuildingRotation = ({ rotate }: { rotate: boolean }) => {
  useFrame((state) => {
    if (rotate) {
      const time = state.clock.getElapsedTime();
      state.camera.position.z = Math.sin(time / 2) * 10;
      state.camera.position.x = Math.cos(time / 2) * 10;
      // state.camera.lookAt(0, 0, 0);
      state.camera.updateProjectionMatrix();
    }
  });

  return (
    
   <></>
  );
}
