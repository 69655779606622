import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export type CalendarProps = {
  value: Date | null;
  onChange(value: Date | null): void;
  disabled?: boolean;
};

export function Calendar({ value, onChange, disabled }: CalendarProps) {
  const handleChange = (newValue: Dayjs | null) => {
    onChange(newValue ? newValue.toDate() : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        disabled={disabled}
        slotProps={{
          day: {
            className: 'text-white',
          },
          leftArrowIcon: {
            className: 'text-white',
          },
          rightArrowIcon: {
            className: 'text-white',
          },
          previousIconButton: {
            className: 'text-white',
          },
          switchViewIcon: {
            className: 'text-white',
          },
          switchViewButton: {
            className: 'text-white',
          },
        }}
        sx={{
          '& .MuiDayCalendar-weekDayLabel': {
            color: '#3E3B4F',
          },
          '& .MuiPickersCalendarHeader-label': {
            width: '100%',
            fontSize: '14px',
            fontWeight: '100 !important',
          },
          '& .MuiPickersCalendarHeader-labelContainer .MuiPickersFadeTransitionGroup-root':
            {
              width: '115px',
            },
          '& .MuiPickersCalendarHeader-root': {
            marginTop: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
          },
          '& .MuiPickersFadeTransitionGroup-root': {
            width: '270px',
          },
          '& .MuiDayCalendar-slideTransition': {
            minHeight: '230px',
          },
        }}
        className="h-fit w-[270px] bg-gray-950 text-white"
      />
    </LocalizationProvider>
  );
}
