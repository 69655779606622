import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { Vacancy } from '@/components/shared/Icons/Vancy';
import { Net } from '@/components/shared/Icons/Net';
import Lottie from 'lottie-react';
import animationData from '../assets/city.json';

const Welcome: FC = () => {
  return (
    <div className="relative h-screen w-full">
      <div
        style={{
          height: '70vh',
          width: '70%',
          cursor: 'auto',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Lottie
          className="absolute bottom-0 left-1/2 h-full w-full -translate-x-1/2 rounded-3xl opacity-80"
          style={{ filter: 'grayscale(60%)' }}
          animationData={animationData}
          autoPlay
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          loop
        />
        <Stack
          className="absolute bottom-0 left-1/2 -translate-x-1/2 rounded-3xl bg-black/70 p-20"
          gap="20px"
        >
          <Stack justifyContent="center" alignItems="center" gap="16px">
            <Typography
              variant="h1"
              className="align-middle text-white sm:text-[70px]"
              sx={{ textShadow: '2px 2px 10px rgba(255,255,255,0.5)' }}
            >
              URBAN
              <Net
                sx={{
                  fontSize: '70px',
                  marginLeft: '5px',
                  marginRight: '-14px',
                  paddingTop: '5px',
                  filter: 'drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.5))',
                }}
              />
              I
              <Vacancy
                sx={{
                  fontSize: '70px',
                  marginLeft: '5px',
                  marginRight: '-14px',
                  paddingTop: '5px',
                  filter: 'drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.5))',
                }}
              />
            </Typography>
            <Typography
              variant="h2"
              className="font-[30px] leading-[10px] text-white"
            >
              Digital Twin for Real Estate
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            className="min-w-[calc(100vw-20px)] rounded-full bg-primary p-3 sm:min-w-[600px] md:w-[70vw] md:max-w-[700px]"
          >
            <Link
              to="/login"
              className="grow border-0 border-r border-solid border-white pr-16 text-center text-white"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="grow border-0 border-l border-solid border-white pl-16 text-center text-white"
            >
              Sign Up
            </Link>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default Welcome;
