import { useState, ChangeEventHandler } from 'react';
import { Stack, Typography, InputBase } from '@mui/material';

import {
  ActionItemCategory,
  ActionItemStatus,
} from '@urbanmix-tech/shared-js';
import { actionTitles } from '@/shared/constants';

import { TabListItem, Tabs } from '@/components/shared/Tabs/Tabs';
import { Calendar } from '@/components/shared/Calendar/Calendar';
import { ConfirmButtons } from '@/components/shared/ConfirmButtons/ConfirmButtons';

import { ModalContent } from '../../containers/ModalContent';

const tabList: TabListItem[] = [
  {
    tabId: 'scheduleMoveOutDate',
    label: 'Schedule Move-Out',
  },
  {
    tabId: 'extendLease',
    label: 'Extend Lease',
  },
];

export type ExtendLeaseOrScheduleModalContentProps = {
  readonly?: boolean;
  category: ActionItemCategory;
  status: ActionItemStatus;
  leaseEndDate: Date;
  actualRent: number;
  onScheduleMoveOutDate: (scheduledDate: Date) => Promise<void>;
  onExtendLease: (leaseEndDate: Date, rent: number) => Promise<void>;
  onApprove: () => void;
  onChangeModalOpen: (val: boolean) => void;
};

export function ExtendLeaseOrScheduleModalContent({
  readonly,
  category,
  status,
  leaseEndDate,
  actualRent,
  onScheduleMoveOutDate,
  onExtendLease,
  onApprove,
  onChangeModalOpen,
}: ExtendLeaseOrScheduleModalContentProps) {
  const [tabKey, setTabKey] = useState<string>(tabList[0].tabId);

  const [date, setDate] = useState<Date>(leaseEndDate);
  const [rent, setRent] = useState<number>(actualRent);

  const handleChangeDate = (value: Date | null) => {
    const today = new Date();

    if (
      !value ||
      new Date(value.toDateString()) <= new Date(today.toDateString())
    ) {
      setDate(new Date());
    } else {
      setDate(value);
    }
  };

  const handleChangeRent: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRent(!Number.isNaN(e.currentTarget.value) ? +e.currentTarget.value : 0);
  };

  const handleSubmit = async () => {
    if (!date) {
      return;
    }

    if (tabKey === 'scheduleMoveOutDate') {
      await onScheduleMoveOutDate(date);
    }

    if (tabKey === 'extendLease' && rent) {
      await onExtendLease(date, rent);
    }

    onApprove();
  };

  return (
    <ModalContent
      actionCategory={category}
      onChangeModalOpen={onChangeModalOpen}
      headerTitle={actionTitles.extendLeaseOrScheduleMoveOutDate}
      actionComponent={
        <ConfirmButtons
          confirm={{
            label: 'Done',
            disabled:
              readonly ||
              Boolean(date === null || rent === null) ||
              !['active', 'future'].includes(status),
            onClick: handleSubmit,
          }}
          cancel={{
            label: 'Skip Task',
            disabled: readonly || !['active', 'future'].includes(status),
            onClick: () => {},
            hidden: true,
            confirmDialog: {
              title: 'Confirm',
              description: 'Are you sure?',
            },
          }}
        />
      }
    >
      <Tabs items={tabList} tabKey={tabKey} setTabKey={setTabKey} />
      <Stack gap="16px" className="w-[270px]">
        <Stack gap="8px" alignItems="flex-start">
          <Typography
            variant="caption"
            className="!font-thin text-stone-200 text-opacity-80"
          >
            Choose a date:
          </Typography>

          <Calendar value={date} onChange={handleChangeDate} />
        </Stack>

        {tabKey === 'extendLease' ? (
          <Stack gap="8px">
            <Typography
              variant="caption"
              className="!font-thin text-stone-200 text-opacity-80"
            >
              Rent:
            </Typography>

            <InputBase
              type="number"
              value={rent}
              onChange={handleChangeRent}
              className="rounded-md border border-white p-4 text-[13px] text-white"
              fullWidth
            />
          </Stack>
        ) : null}
      </Stack>
    </ModalContent>
  );
}
