import { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import { Stack, Pagination } from '@mui/material';

import { Project, ActionItem, Unit } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';

import { getActions } from '@urbanmix-tech/shared-js/data';

import { ProjectCard } from './ProjectCard';

export function getStats(units: Unit[]): Record<
  LeaseLifecycle,
  {
    units: number;
    percentage: number;
  }
> {
  const stats: Record<string, number> = {};

  Object.values(LeaseLifecycle).forEach((v) => {
    const cnt = units.filter((unit) =>
      Array.isArray(unit.currentState)
        ? unit.currentState.length > 0
          ? unit.currentState.includes(v)
          : LeaseLifecycle.NOTICE === v
        : ((unit.currentState as LeaseLifecycle) || LeaseLifecycle.NOTICE) === v
    ).length;

    stats[v] = cnt;
  });

  return Object.fromEntries(
    Object.entries(stats).map(([key, value]) => {
      return [
        key,
        {
          units: value,
          percentage: units.length > 0 ? value / units.length : 0,
        },
      ];
    })
  ) as Record<LeaseLifecycle, { units: number; percentage: number }>;
}

const CARD_MIN_WIDTH = 318;
const PADDING = 16;
const ITEMS_PER_PAGE = 9;

export type ProjectCardListProps = {
  projects: Project[];
  unitsByProjectId: Record<string, Unit[]>;
  statusFilter: string[];
};

export function ProjectCardList({
  projects,
  unitsByProjectId,
  statusFilter,
}: ProjectCardListProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(CARD_MIN_WIDTH);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getActions().then((value) => {
      setActionItems(value);
    });
  }, []);

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();

    const curWidth = rect.width;
    const cardNum = Math.floor(
      (curWidth + PADDING) / (CARD_MIN_WIDTH + PADDING)
    );

    setWidth((curWidth - (cardNum - 1) * PADDING) / cardNum);
  }, []);

  useEffect(() => {
    if (ref.current) {
      const elem = ref.current;

      new ResizeObserver(() => {
        handleResize();
      }).observe(elem);
    }
  }, [handleResize]);

  const actionsByProjectId = useMemo(() => {
    const obj: Record<string, ActionItem[]> = {};

    actionItems.forEach((actionItem) => {
      if (actionItem.status !== 'active') {
        return;
      }

      if (obj[actionItem.projectId] !== undefined) {
        const arr = obj[actionItem.projectId];
        arr.push(actionItem);
      } else {
        obj[actionItem.projectId] = [actionItem];
      }
    });

    return obj;
  }, [actionItems]);

  const pageCount = Math.ceil(projects.length / ITEMS_PER_PAGE);
  const paginatedProjects = projects.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <>
      <Stack
        direction="row"
        gap="16px"
        className="flex-wrap justify-start"
        ref={ref}
      >
        {paginatedProjects.map((project) => (
          <ProjectCard
            hasActiveAction={
              actionsByProjectId[project.id]
                ? actionsByProjectId[project.id].length > 0
                : false
            }
            key={project.id}
            projectId={project.id}
            buildingId={project.buildings[0].id}
            name={project.name}
            address={project.address}
            building={project.buildings[0]}
            units={unitsByProjectId[project.id]}
            stats={getStats(unitsByProjectId[project.id])}
            width={width}
            statusFilter={statusFilter}
          />
        ))}
      </Stack>
      <Stack direction="row" justifyContent="center" mt={4}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'gray',
            },
          }}
        />
      </Stack>
    </>
  );
}
