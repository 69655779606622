import { useState, MouseEvent } from 'react';
import { Avatar, ButtonBase, Popover } from '@mui/material';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuth } from '@/shared/context/AuthProvider';
import MainAppUserInfo from './MainAppUserInfo';

const { VITE_OAUTH_CLIENT_ID } = import.meta.env;

export function MainAppUserInfoDropdown() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { userData } = useAuth();

  return (
    <GoogleOAuthProvider clientId={VITE_OAUTH_CLIENT_ID}>
      <ButtonBase
        onClick={handleClick}
        className="h-36 gap-8 rounded-lg border border-white border-opacity-5 p-4"
        sx={{
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0A0E15 !important`,
        }}
        disableRipple
      >
        <AppIcon
          name="arrowDown"
          width={16}
          className="pl-2 text-stone-200 text-opacity-80"
        />

        <Avatar className="rounded bg-purple-400">
          {userData?.fullName?.charAt(0)}
        </Avatar>
      </ButtonBase>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            className: '!border-gray-950 mt-4 bg-[#161A21]',
            variant: 'outlined',
          },
        }}
      >
        <MainAppUserInfo />
      </Popover>
    </GoogleOAuthProvider>
  );
}
