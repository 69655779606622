import { ReactNode } from 'react';
import { Stack } from '@mui/material';

import { MainAppHeader } from '@/components/MainAppHeader/MainAppHeader';
import { MainAppSidebar } from '@/components/MainAppSidebar/MainAppSidebar';

export type PrivateLayoutProps = {
  children?: ReactNode;
};

export function PrivateLayout({ children }: PrivateLayoutProps) {
  return (
    <Stack className="h-screen w-screen overflow-hidden bg-gray-950">
      <MainAppHeader />

      <Stack direction="row" className="h-[calc(100%-62px)] w-full">
        <MainAppSidebar />

        <div className="relative h-full flex-1 overflow-auto">{children}</div>
      </Stack>
    </Stack>
  );
}

export default PrivateLayout;
