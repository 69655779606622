import { SvgIcon, type SvgIconProps } from '@mui/material';

export function DropArrowIcon({ height, width, ...rest }: SvgIconProps) {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-7 -8 24 24"
        fill="none"
        {...rest}
      >
        <path
          d="M0.955322 1.48633L4.96368 5.74862L8.97205 1.48633"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
