import { useState } from 'react';
import { Select, SelectChangeEvent, MenuItem } from '@mui/material/';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';

export type SimpleSelectProps<T> = {
  items: { label: string; value: T }[];
  selected?: T;
  onSelect?: (selected: T) => void;
};

export function SimpleSelect<T>({
  items,
  selected,
  onSelect,
}: SimpleSelectProps<T>) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: SelectChangeEvent<T>) => {
    const {
      target: { value },
    } = event;

    if (onSelect) {
      onSelect(value as T);
    }
  };

  return (
    <Select
      variant="standard"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={selected}
      onChange={handleChange}
      endAdornment={<UnfoldMoreOutlinedIcon />}
      className="w-fit text-white"
      inputProps={{ className: '!pr-4' }}
      MenuProps={{
        slotProps: {
          paper: {
            className:
              'bg-gray-950 my-4 !rounded !border-white/10 !translate-x-1/2',
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
    >
      {items.map((item) => (
        <MenuItem
          key={item.label}
          value={item.value as string}
          className="text-xl tracking-wide text-stone-200 hover:bg-white/20"
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
}
