import { Stack, Tab, Tabs } from '@mui/material';
import { ActionItem } from '@urbanmix-tech/shared-js';
import { BadgeTab } from './BadgeTab';
import { Agenda } from './Agenda';
import { TodoTabListItem } from './TodoTabListItem';

type TodoTabsProps = {
  tabKey: string;
  setTabKey: (newValue: string) => void;
  actionItems: ActionItem[];
};

export const tabList: TodoTabListItem[] = [
  {
    tabId: 'allActions',
    label: 'View All Actions',
    action: undefined,
  },
  {
    tabId: 'inspect',
    label: 'Inspection',
    action: 'inspect',
  },
  {
    tabId: 'makeReady',
    label: 'Make Ready',
    action: 'makeReady',
  },
  {
    tabId: 'lease',
    label: 'Lease',
    action: 'lease',
  },
];

export default function TodoTabs({
  tabKey,
  setTabKey,
  actionItems,
}: TodoTabsProps) {
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabKey(newValue);
  };

  const tabListWithRowCount: TodoTabListItem[] = tabList.map((tab) => ({
    ...tab,
    rowCount: tab.action
      ? actionItems.filter((item) => item.category === tab.action).length
      : actionItems.length,
  }));

  return (
    <Stack
      className="relative mt-[-7px] h-25 min-h-0 pb-10"
      direction="row"
      justifyContent="space-between"
    >
      <Tabs
        value={tabKey}
        onChange={handleChange}
        sx={{
          paddingX: '24px',
          '& .MuiTabs-scroller': {
            display: 'flex',
            height: '25px',
          },
          '& .MuiTabs-flexContainer': {
            gap: '32px !important',
          },
          '& .MuiButtonBase-root': {
            padding: '0 0 8px 0',
            minHeight: 32,
            minWidth: 0,
            width: 'fit-content',
          },
        }}
      >
        {tabListWithRowCount.map((list) => (
          <Tab
            sx={{
              '&[aria-selected="false"]': {
                color: '#E6E3E3CC',
                fontWeight: '400',
              },
              '& .MuiButtonBase-root': {
                fontSize: '16px',
                fontWeight: '400',
              },
              '&.Mui-selected .tab-label-badge': {
                backgroundColor: '#CD3185 !important',
              },
            }}
            label={<BadgeTab label={list.label} status={list.rowCount ?? 0} />}
            value={list.tabId}
            key={list.tabId}
          />
        ))}
      </Tabs>
      <Agenda processes={tabList.slice(1)} />
      <div className="absolute bottom-0 h-2 w-full bg-stone-200 bg-opacity-10" />
    </Stack>
  );
}
