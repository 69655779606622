import {
  Select,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { DropArrowIcon } from '../Icons/DropArrowIcon';

export type NavigateSelectProps = {
  placeHolder: string;
  pathRoot: string;
  items: Record<string, string>;
  selected?: number | undefined;
};

export default function NavigateSelect({
  placeHolder,
  pathRoot,
  items,
  selected,
}: NavigateSelectProps) {
  const allItems: Record<string, string> = { 0: placeHolder, ...items };
  const navigate = useNavigate();

  const handleRenderer = (option: number | '') => {
    if (option === undefined || option === 0) {
      return (
        <Typography variant="caption" className="text-gray-400">
          {placeHolder}
        </Typography>
      );
    }
    return (
      <Typography variant="caption" className="text-gray-400">
        {option}
      </Typography>
    );
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedId = event.target.value;
    if (selectedId === '0' || selectedId === undefined || selectedId === null) {
      return;
    }
    navigate(`/${pathRoot}/${selectedId}`);
  };

  return (
    <Select
      placeholder={placeHolder}
      onChange={handleChange}
      value={selected || 0}
      renderValue={handleRenderer}
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent={(props) => <DropArrowIcon {...props} />}
      className={twMerge(
        'h-[26px] w-fit border border-white/10 bg-white bg-opacity-10 p-0 text-sm text-stone-200/50'
      )}
      MenuProps={{
        PaperProps: {
          className:
            '!rounded-md max-w-[250px] !min-w-0 !w-fit border !border-white/40 mt-[8px] !p-16 flex flex-col gap-16',
          sx: {
            width: 'fit-content',
            minWidth: 'unset',
            '& .MuiList-root': {
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              padding: 0,
              width: 'fit-content',
              minWidth: 'unset',
            },
            background:
              'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #0A0E15;',
            opacity: 1,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      sx={{
        color: '#fff',
        cursor: 'pointer',
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
        '&.Mui-focused': {
          bgcolor: '#ffffff33 !important',
        },
        '& .MuiSelect-select': {
          paddingLeft: '10px',
        },
        '& .MuiSelect-icon': {
          right: '0px !important',
        },
        '& fieldset': {
          border: 'none',
        },
      }}
    >
      {Object.entries(allItems).map(([key, value]) => (
        <MenuItem
          key={`i-${key}`}
          value={key}
          className="bg-transparent text-stone-200 hover:bg-white/10"
          sx={{
            padding: 0,
            width: 'fit-content',
            minWidth: 0,
            '&.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent !important',
            },
          }}
        >
          <ListItemText
            primary={value}
            sx={{
              '& .MuiTypography-root': { fontSize: '12px' },
              '& .MuiTypography-body1': { color: '#E6E3E3' },
              '& .MuiListItemText-root': { minWidth: 0 },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
}
