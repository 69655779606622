import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';

export type UnitOverlayData = {
  unitId: string;
  colorOverlay?: string;
  material?: string;
  materialOverlay?: string;
};

interface BuildingModelContextProps {
  selectUnit(unitId: string | null): void;
  selectedUnitId: string | null;
  hoveredUnitId: string | null;
  setHoveredUnitId(unitId: string | null): void;
  unitsOverlayData: Record<string, UnitOverlayData>;
  setUnitOverlayData(data: UnitOverlayData): void;
  setAllUnitsOverlayData(data: Record<string, UnitOverlayData>): void;
  setDisplayMode: (mode: number) => void;
  displayMode: number;
}

const BuildingModelContext = createContext<
  BuildingModelContextProps | undefined
>(undefined);

export const useBuildingModel = () => {
  const context = useContext(BuildingModelContext);
  if (!context) {
    throw new Error(
      'useBuildingModel must be used within an BuildingModelProvider'
    );
  }
  return context;
};

interface BuildingModelProviderProps {
  children: ReactNode;
}

export const BuildingModelProvider: React.FC<BuildingModelProviderProps> = ({
  children,
}) => {
  const [selectedUnitId, setSelectedUnitId] = useState<string | null>(null);
  const [hoveredUnitId, setHoveredUnitId] = useState<string | null>(null);
  const [unitsOverlayData, setUnitsOverlayData] = useState<
    Record<string, UnitOverlayData>
  >({});

  const selectUnit = useCallback((unitId: string | null) => {
    setSelectedUnitId(unitId);
  }, []);

  const [displayMode, setTheDisplayMode] = useState<number>(0);

  const setDisplayMode = useCallback(
    (mode: number) => {
      if (displayMode !== mode) {
        setTheDisplayMode(mode);
      }
    },
    [displayMode, setTheDisplayMode]
  );

  const setAllUnitsOverlayData = useCallback(
    (data: Record<string, UnitOverlayData>) => {
      // check if data has changed
      // console.log("getting some new data",data)
      if (JSON.stringify(unitsOverlayData) === JSON.stringify(data)) {
        return;
      }
      // console.log("data changed",data)
      setUnitsOverlayData(data);
    },
    [setUnitsOverlayData, unitsOverlayData]
  );

  const setUnitOverlayData = useCallback((data: UnitOverlayData) => {
    console.log('getting some data', data);
    setUnitsOverlayData((prevData) => {
      const existingData = prevData[data.unitId];

      // Compare the existing data with the new data to avoid unnecessary updates
      if (
        existingData &&
        existingData.colorOverlay === data.colorOverlay &&
        existingData.material === data.material &&
        existingData.materialOverlay === data.materialOverlay
      ) {
        // If data hasn't changed, avoid setting state to prevent re-renders
        return prevData;
      }

      // Otherwise, update the state immutably
      return {
        ...prevData,
        [data.unitId]: {
          ...existingData, // Preserve previous data
          ...data, // Merge new data for the unitId
        },
      };
    });
  }, []);

  const value = useMemo(
    () => ({
      selectUnit,
      selectedUnitId,
      hoveredUnitId,
      setHoveredUnitId,
      unitsOverlayData,
      setUnitOverlayData,
      setAllUnitsOverlayData,
      displayMode,
      setDisplayMode,
    }),
    [
      selectUnit,
      selectedUnitId,
      hoveredUnitId,
      setHoveredUnitId,
      unitsOverlayData,
      setUnitOverlayData,
      setAllUnitsOverlayData,
      displayMode,
      setDisplayMode,
    ]
  );

  return (
    <BuildingModelContext.Provider value={value}>
      {children}
    </BuildingModelContext.Provider>
  );
};
