import { useState, useEffect } from 'react';

import { useLoading } from '@/shared/context/UIContextProvider';
import { useAlert } from '@/contexts/Alert/AlertContext';
import { useAuth } from '@/shared/context/AuthProvider';

import { Project } from '@urbanmix-tech/shared-js';
import { getProjects } from '@urbanmix-tech/shared-js/data';

export function useProjects(unlimited?: boolean) {
  const [projects, setProjects] = useState<Project[]>([]);
  const { allowedProjectIds, userData } = useAuth();

  const { createLoading } = useLoading();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!userData) {
      return;
    }

    const loader = createLoading();

    loader.startLoading({ title: 'Loading Projects...' });

    getProjects().then((value) => {
      if (value && value.length > 0) {
        if (unlimited || allowedProjectIds === 'all') {
          setProjects(value);
        } else {
          setProjects(
            value.filter((project) => allowedProjectIds.includes(project.id))
          );
        }
      } else {
        showAlert('Building not exists!', 'error');
      }

      loader.stopLoading();
    });
  }, [showAlert, createLoading, allowedProjectIds, unlimited, userData]);

  return projects;
}
