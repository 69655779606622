import { Modal, Box } from '@mui/material';

export type FloorPlanModalProps = {
  isOpen: boolean;
  onClose(): void;
  floorPlanPath: string;
};

export function FloorPlanModal({
  floorPlanPath,
  isOpen,
  onClose,
}: FloorPlanModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        component="div"
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[10px] border border-stone-200 border-opacity-[0.15] bg-[#0A0E15] p-20 shadow outline-none"
      >
        <div className="max-h-[85vh] max-w-[85vw] overflow-auto">
          <img src={floorPlanPath} className="min-w-[80vw]" loading="lazy" />
        </div>
      </Box>
    </Modal>
  );
}
