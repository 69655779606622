import { Tooltip } from '@mui/material';

import { LeaseLifecycle } from '@urbanmix-tech/shared-js';

import { colorsByLeaseLifecycle } from '@/helpers/common';

export type StatusBarProps = {
  units: number;
  percentage: number;
  status: LeaseLifecycle;
};

export function StatusBar({ units, percentage, status }: StatusBarProps) {
  return (
    <Tooltip
      title={
        <>
          <span
            className="block h-8 w-8 rounded-[1px]"
            style={{ background: colorsByLeaseLifecycle[status] }}
          />
          {units} units | {percentage.toFixed(2)}%
        </>
      }
      enterDelay={1000}
    >
      <div
        className="h-10 cursor-pointer rounded-sm"
        style={{
          background: colorsByLeaseLifecycle[status],
          width: `${percentage * 100}%`,
        }}
      />
    </Tooltip>
  );
}
