import { Stack, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { AnalyzingFilter } from '@urbanmix-tech/shared-js';

export type AnalyzeFilterProps = {
  analyzeFilter: string[];
  onChangeAnalyzeFilter(selected: string[]): void;
  className: string;
};

export function AnalyzeFilter({
  analyzeFilter,
  onChangeAnalyzeFilter,
  className,
}: AnalyzeFilterProps) {
  const items = [
    { label: 'High', color: '#D9D9D9' },
    {
      label: 'Medium',
      color: '#8D8D8D',
    },
    {
      label: 'Low',
      color: '#4F4F4F',
    },
  ];

  return (
    <Stack gap="12px" className={className}>
      <Stack direction="row" gap="8px">
        <FormControlLabel
          labelPlacement="start"
          className="m-0 flex min-w-[120px] items-center justify-center gap-4 rounded-md bg-[#1D2027] p-2"
          slotProps={{
            typography: {
              variant: 'caption2',
              className: 'text-gray-400',
            },
          }}
          control={
            <Checkbox
              checked={analyzeFilter.includes(AnalyzingFilter.Rent)}
              onChange={(_e, checked) => {
                if (checked) {
                  onChangeAnalyzeFilter([AnalyzingFilter.Rent as string]);
                } else {
                  onChangeAnalyzeFilter([AnalyzingFilter.None as string]);
                }
              }}
              className="p-0"
              sx={{ '& .MuiSvgIcon-root': { color: 'white' } }}
            />
          }
          label="Rent"
        />
        <FormControlLabel
          labelPlacement="start"
          className="m-0 flex min-w-[120px] items-center justify-center gap-4 rounded-md bg-[#1D2027] p-2"
          slotProps={{
            typography: {
              variant: 'caption2',
              className: 'text-gray-400',
            },
          }}
          control={
            <Checkbox
              checked={analyzeFilter.includes(AnalyzingFilter.RentBySQFT)}
              onChange={(_e, checked) => {
                if (checked) {
                  onChangeAnalyzeFilter([AnalyzingFilter.RentBySQFT as string]);
                } else {
                  onChangeAnalyzeFilter([AnalyzingFilter.None as string]);
                }
              }}
              className="p-0"
              sx={{ '& .MuiSvgIcon-root': { color: 'white' } }}
            />
          }
          label="Rent/SQFT"
        />
      </Stack>

      {analyzeFilter.includes(AnalyzingFilter.None) || (
        <Stack gap="8px">
          {items.map((item) => (
            <Stack
              key={item.label}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" className="text-stone-200">
                {item.label}
              </Typography>
              <div
                className="h-[16px] w-[180px] rounded"
                style={{ background: item.color }}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
