import { useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, ButtonGroup, Slider, Stack, Typography } from '@mui/material';

import { ActionItem, ActionItemCategory } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import { CompletedAccordion } from '../UnitTabs/Todo/CompletedAccordion';
import { Select } from '../shared/Selects/Select';
import { ActionGroupList } from './DashboardActionGroupList';
import { s } from 'node_modules/vite/dist/node/types.d-aGj9QkWt';
import {
  useBuildingModel,
  UnitOverlayData,
} from '@/contexts/Building/BuildingContext';
import { red } from '@mui/material/colors';

const excelColorGrade = [
  '#00FF00', // Green
  '#32CD32', // Lime Green
  '#7FFF00', // Chartreuse
  '#ADFF2F', // Green Yellow
  '#FFFF00', // Yellow
  '#FFD700', // Gold
  '#FFA500', // Orange
  '#FF8C00', // Dark Orange
  '#FF4500', // Orange Red
  '#FF0000', // Red
];

const runwiseColorGrade = ['#a58cff', '#fba400', '#2791f9'];


function interpolateColor(
  minValue: number,
  maxValue: number,
  minColor: string,
  maxColor: string,
  value: number
): string {
  // Clamp value between minValue and maxValue
  value = Math.max(minValue, Math.min(maxValue, value));

  // Parse hex colors and convert to RGB
  function hexToRgb(hex: string): { r: number; g: number; b: number } {
    let bigint = parseInt(hex.replace('#', ''), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  function rgbToHex(r: number, g: number, b: number): string {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  }

  const minRgb = hexToRgb(minColor);
  const maxRgb = hexToRgb(maxColor);

  // Calculate the ratio of the value between minValue and maxValue
  const ratio = (value - minValue) / (maxValue - minValue);

  // Interpolate between the min and max colors
  const r = Math.round(minRgb.r + ratio * (maxRgb.r - minRgb.r));
  const g = Math.round(minRgb.g + ratio * (maxRgb.g - minRgb.g));
  const b = Math.round(minRgb.b + ratio * (maxRgb.b - minRgb.b));

  // Convert back to hex color
  return rgbToHex(r, g, b);
}

type SensorsTabContentProps = {};

function getConditionalFormattingColor(
  colorFormat: number,
  minValue: number,
  maxValue: number,
  value: number
): string {
  // Clamp value between minValue and maxValue
  value = Math.max(minValue, Math.min(maxValue, value));

  // Predefined color stops between green (#00FF00) and red (#FF0000)
  const colors =
    colorFormat === 1
      ? runwiseColorGrade
      : colorFormat === 2
        ? excelColorGrade
        : runwiseColorGrade;

  if (colorFormat === 3) {
    return interpolateColor(minValue, maxValue, 'ffff85', '#FF0000', value);
  }
  // Calculate the ratio of the value between minValue and maxValue
  const ratio = (value - minValue) / (maxValue - minValue);

  // Find the index in the colors array
  const colorIndex = Math.floor(ratio * (colors.length - 1));

  // Return the color at the corresponding index
  return colors[colorIndex];
}


export function SensorsTabContent({}: SensorsTabContentProps) {
  const navigate = useNavigate();
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  const data = [
    {
      Time: '02/01/2024 12:00AM',
      Status: 'Vacuum Running',
      DHW: 133.21,
      'Return Line': 112.89,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 192.04,
      PSI: 3.96,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 74.02,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.4 },
        { unitId: '12B', value: 72.32 },
        { unitId: '12H', value: 75.92 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.58 },
        { unitId: '14H', value: 71.78 },
        { unitId: '15C', value: 72.32 },
        { unitId: '15H', value: 71.96 },
        { unitId: '16B', value: 74.84 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.48 },
        { unitId: '17H', value: 72.68 },
        { unitId: '18B', value: 74.84 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 73.4 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.3 },
        { unitId: '20F', value: 76.28 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.22 },
        { unitId: '23B', value: 77.54 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.56 },
        { unitId: '24A', value: 73.94 },
        { unitId: '24G', value: 74.84 },
        { unitId: '25A', value: 73.4 },
        { unitId: '25F', value: 74.48 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.3 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.58 },
        { unitId: '29F', value: 75.38 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 74.12 },
        { unitId: '32F', value: 74.48 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 74.84 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 74.84 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.58 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.04 },
        { unitId: '7F', value: 72.14 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 74.84 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.66 },
        { unitId: '9G', value: 72.86 },
      ],
    },
    {
      Time: '02/01/2024 01:00AM',
      Status: 'Vacuum Running',
      DHW: 133.15,
      'Return Line': 110.17,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 187.99,
      PSI: 3.76,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 73.9,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.22 },
        { unitId: '12B', value: 72.14 },
        { unitId: '12H', value: 75.38 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.58 },
        { unitId: '14H', value: 71.78 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 71.78 },
        { unitId: '16B', value: 74.84 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.3 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.66 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 73.22 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.12 },
        { unitId: '20F', value: 75.92 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.22 },
        { unitId: '23B', value: 77.36 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.66 },
        { unitId: '25A', value: 73.4 },
        { unitId: '25F', value: 74.48 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.58 },
        { unitId: '29F', value: 75.02 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 73.76 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 74.84 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 74.12 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.22 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 72.68 },
        { unitId: '7F', value: 71.78 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.02 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.3 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.48 },
        { unitId: '9G', value: 72.86 },
      ],
    },
    {
      Time: '02/01/2024 02:00AM',
      Status: 'Vacuum Running',
      DHW: 131.5,
      'Return Line': 111.43,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 142.23,
      PSI: 3.46,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 9,
      'Average APT': 73.7,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.94 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.04 },
        { unitId: '12B', value: 71.78 },
        { unitId: '12H', value: 74.84 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 71.6 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 71.42 },
        { unitId: '16B', value: 74.48 },
        { unitId: '16D', value: 73.58 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.48 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.48 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 73.22 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.12 },
        { unitId: '20F', value: 75.56 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.36 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 75.02 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.48 },
        { unitId: '25A', value: 73.22 },
        { unitId: '25F', value: null },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.4 },
        { unitId: '29F', value: 74.84 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 73.04 },
        { unitId: '32F', value: 73.04 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.02 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.76 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.22 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 72.32 },
        { unitId: '7F', value: 71.78 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.2 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: null },
        { unitId: '9G', value: 72.86 },
      ],
    },
    {
      Time: '02/01/2024 03:00AM',
      Status: 'Vacuum Running',
      DHW: 132.63,
      'Return Line': 111.57,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 144.12,
      PSI: 3.75,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 73.62,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.94 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.68 },
        { unitId: '12B', value: 71.42 },
        { unitId: '12H', value: 74.48 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 71.6 },
        { unitId: '15C', value: 71.96 },
        { unitId: '15H', value: 71.42 },
        { unitId: '16B', value: 74.48 },
        { unitId: '16D', value: 73.58 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.48 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.3 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: null },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 75.2 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.18 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 74.48 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.48 },
        { unitId: '25A', value: 73.22 },
        { unitId: '25F', value: null },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.76 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.4 },
        { unitId: '29F', value: 74.84 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.5 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 72.86 },
        { unitId: '32F', value: 72.86 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.02 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.4 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.04 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 72.14 },
        { unitId: '7F', value: 71.6 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.2 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.76 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.12 },
        { unitId: '9G', value: 73.04 },
      ],
    },
    {
      Time: '02/01/2024 04:00AM',
      Status: 'Vacuum Running',
      DHW: 133.07,
      'Return Line': 115.85,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 145.74,
      PSI: 2.75,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 73.51,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.94 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.5 },
        { unitId: '12B', value: 71.24 },
        { unitId: '12H', value: 74.3 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 71.42 },
        { unitId: '15C', value: 71.96 },
        { unitId: '15H', value: 71.24 },
        { unitId: '16B', value: 74.3 },
        { unitId: '16D', value: 73.4 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.3 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.3 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 72.68 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 74.84 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.18 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 74.3 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.12 },
        { unitId: '25A', value: 73.22 },
        { unitId: '25F', value: null },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.76 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.22 },
        { unitId: '29F', value: 74.48 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.5 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 72.68 },
        { unitId: '32F', value: 72.68 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.2 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.22 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.04 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 71.96 },
        { unitId: '7F', value: 71.6 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.38 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: null },
        { unitId: '9G', value: 73.22 },
      ],
    },
    {
      Time: '02/01/2024 05:00AM',
      Status: 'Vacuum Running',
      DHW: 132.49,
      'Return Line': 111.43,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 138.04,
      PSI: 3.12,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 73.56,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.94 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.32 },
        { unitId: '12B', value: null },
        { unitId: '12H', value: 74.12 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 72.86 },
        { unitId: '14H', value: 71.42 },
        { unitId: '15C', value: 71.78 },
        { unitId: '15H', value: 71.24 },
        { unitId: '16B', value: 74.12 },
        { unitId: '16D', value: 73.22 },
        { unitId: '16H', value: 71.24 },
        { unitId: '17B', value: 74.3 },
        { unitId: '17H', value: 72.5 },
        { unitId: '18B', value: 74.12 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: null },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.76 },
        { unitId: '20F', value: 74.84 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 76.82 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 74.12 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.12 },
        { unitId: '25A', value: 73.22 },
        { unitId: '25F', value: 73.58 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.76 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.22 },
        { unitId: '29F', value: 74.48 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 72.68 },
        { unitId: '32F', value: 72.68 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.38 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.04 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.22 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 71.78 },
        { unitId: '7F', value: null },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.38 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.58 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.12 },
        { unitId: '9G', value: 73.22 },
      ],
    },
    {
      Time: '02/01/2024 06:00AM',
      Status: 'Vacuum Running',
      DHW: 132.64,
      'Return Line': 110.19,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 141.82,
      PSI: 3.31,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 13,
      'Average APT': 73.35,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.58 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.32 },
        { unitId: '12B', value: 70.88 },
        { unitId: '12H', value: 73.76 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 72.68 },
        { unitId: '14H', value: 71.42 },
        { unitId: '15C', value: 71.78 },
        { unitId: '15H', value: 71.06 },
        { unitId: '16B', value: 73.94 },
        { unitId: '16D', value: 73.22 },
        { unitId: '16H', value: 71.24 },
        { unitId: '17B', value: 74.12 },
        { unitId: '17H', value: 72.32 },
        { unitId: '18B', value: 74.12 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: null },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.58 },
        { unitId: '20F', value: 74.48 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 72.86 },
        { unitId: '23B', value: 76.64 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 73.76 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 73.94 },
        { unitId: '25A', value: 73.22 },
        { unitId: '25F', value: null },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.58 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.22 },
        { unitId: '29F', value: 74.48 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 72.5 },
        { unitId: '32F', value: 72.68 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.38 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 72.68 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.22 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 71.78 },
        { unitId: '7F', value: 71.42 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.38 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: null },
        { unitId: '9G', value: 73.4 },
      ],
    },
    {
      Time: '02/01/2024 07:00AM',
      Status: 'Vacuum Running',
      DHW: 130.3,
      'Return Line': 162.31,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 189.34,
      PSI: 3.1,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 28,
      'Average APT': 73.55,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.58 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.32 },
        { unitId: '12B', value: null },
        { unitId: '12H', value: null },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 72.68 },
        { unitId: '14H', value: 71.42 },
        { unitId: '15C', value: 71.78 },
        { unitId: '15H', value: 71.42 },
        { unitId: '16B', value: 74.12 },
        { unitId: '16D', value: 73.22 },
        { unitId: '16H', value: 71.24 },
        { unitId: '17B', value: 74.12 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.3 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: null },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 74.48 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.04 },
        { unitId: '22G', value: 72.86 },
        { unitId: '23B', value: 76.82 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 73.94 },
        { unitId: '24A', value: 73.58 },
        { unitId: '24G', value: 74.84 },
        { unitId: '25A', value: 73.4 },
        { unitId: '25F', value: null },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.58 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.58 },
        { unitId: '29F', value: 75.02 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.3 },
        { unitId: '32A', value: 72.68 },
        { unitId: '32F', value: 72.86 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.38 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 72.86 },
        { unitId: '5G', value: 74.48 },
        { unitId: '6F', value: 73.22 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 72.14 },
        { unitId: '7F', value: 71.42 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.38 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.12 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 73.94 },
        { unitId: '9G', value: 73.22 },
      ],
    },
    {
      Time: '02/01/2024 08:00AM',
      Status: 'Vacuum Running',
      DHW: 131.83,
      'Return Line': 189.47,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 191.5,
      PSI: 3.45,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 34,
      'Average APT': 73.86,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 73.94 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.32 },
        { unitId: '12B', value: 71.78 },
        { unitId: '12H', value: 74.84 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 72.86 },
        { unitId: '14H', value: 71.42 },
        { unitId: '15C', value: 71.78 },
        { unitId: '15H', value: 71.96 },
        { unitId: '16B', value: 74.48 },
        { unitId: '16D', value: 73.4 },
        { unitId: '16H', value: 71.24 },
        { unitId: '17B', value: 74.48 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 74.84 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: null },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.84 },
        { unitId: '20F', value: 74.84 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.22 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.18 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 73.94 },
        { unitId: '24A', value: 74.12 },
        { unitId: '24G', value: 75.2 },
        { unitId: '25A', value: 73.58 },
        { unitId: '25F', value: 74.48 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.76 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.94 },
        { unitId: '29F', value: 75.2 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 73.22 },
        { unitId: '32F', value: 73.04 },
        { unitId: '32J', value: 73.58 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.56 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.58 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 73.58 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 72.68 },
        { unitId: '7F', value: 71.6 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 78.08 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: null },
        { unitId: '9G', value: 73.4 },
      ],
    },
    {
      Time: '02/01/2024 09:00AM',
      Status: 'Vacuum Running',
      DHW: 128.92,
      'Return Line': 193.68,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 197.24,
      PSI: 3.44,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 37,
      'Average APT': 74.02,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 72.68 },
        { unitId: '12B', value: 72.14 },
        { unitId: '12H', value: 75.2 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 71.78 },
        { unitId: '15C', value: 71.96 },
        { unitId: '15H', value: 72.32 },
        { unitId: '16B', value: 74.84 },
        { unitId: '16D', value: 73.58 },
        { unitId: '16H', value: 71.42 },
        { unitId: '17B', value: 74.84 },
        { unitId: '17H', value: 72.68 },
        { unitId: '18B', value: 74.84 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 75.02 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.66 },
        { unitId: '20F', value: 75.38 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.4 },
        { unitId: '22G', value: 73.22 },
        { unitId: '23B', value: 77.54 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 74.12 },
        { unitId: '24A', value: 74.48 },
        { unitId: '24G', value: 75.02 },
        { unitId: '25A', value: 73.76 },
        { unitId: '25F', value: 74.84 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.76 },
        { unitId: '29F', value: 75.38 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.48 },
        { unitId: '32A', value: 73.76 },
        { unitId: '32F', value: 73.58 },
        { unitId: '32J', value: 73.76 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 75.74 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.76 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 73.76 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.4 },
        { unitId: '7F', value: 71.96 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 76.64 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.12 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.3 },
        { unitId: '9G', value: 72.86 },
      ],
    },
    {
      Time: '02/01/2024 10:00AM',
      Status: 'Vacuum Running',
      DHW: 130.67,
      'Return Line': 189.63,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 163.83,
      PSI: 3.21,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 32,
      'Average APT': 74.23,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.22 },
        { unitId: '12B', value: 72.5 },
        { unitId: '12H', value: 76.28 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 71.96 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 72.86 },
        { unitId: '16B', value: 75.02 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 71.6 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 73.04 },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 76.28 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 75.56 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.58 },
        { unitId: '22G', value: 73.76 },
        { unitId: '23B', value: 77.54 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 74.48 },
        { unitId: '24A', value: 74.84 },
        { unitId: '24G', value: 75.38 },
        { unitId: '25A', value: 73.94 },
        { unitId: '25F', value: 75.38 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.12 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.58 },
        { unitId: '29F', value: 75.74 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.32 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.84 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.12 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 76.1 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 73.94 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 73.4 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 72.14 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 76.82 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.12 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.48 },
        { unitId: '9G', value: 72.14 },
      ],
    },
    {
      Time: '02/01/2024 11:00AM',
      Status: 'Vacuum Running',
      DHW: 131.2,
      'Return Line': 169.26,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 161.87,
      PSI: 3.38,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 25,
      'Average APT': 74.37,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.4 },
        { unitId: '12B', value: 72.5 },
        { unitId: '12H', value: 76.64 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 72.14 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 73.22 },
        { unitId: '16B', value: 75.02 },
        { unitId: '16D', value: 74.3 },
        { unitId: '16H', value: 71.78 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 73.58 },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 76.82 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 74.48 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.58 },
        { unitId: '22G', value: 73.76 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.02 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.2 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.38 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 73.76 },
        { unitId: '29F', value: 75.74 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.5 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.84 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.3 },
        { unitId: '32J', value: 74.3 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 76.28 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 76.28 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 73.58 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 72.32 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 76.28 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.94 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.02 },
        { unitId: '9G', value: 71.78 },
      ],
    },
    {
      Time: '02/01/2024 12:00PM',
      Status: 'Vacuum Running',
      DHW: 127.08,
      'Return Line': 126.34,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 157.21,
      PSI: 3.39,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.42,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.58 },
        { unitId: '12B', value: 72.32 },
        { unitId: '12H', value: 76.64 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 72.14 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 73.22 },
        { unitId: '16B', value: 75.02 },
        { unitId: '16D', value: 74.12 },
        { unitId: '16H', value: 71.78 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 73.58 },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.0 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.58 },
        { unitId: '20F', value: 74.84 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.58 },
        { unitId: '22G', value: 73.94 },
        { unitId: '23B', value: 77.54 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 75.74 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.38 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.76 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.12 },
        { unitId: '29F', value: 75.74 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 73.04 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.84 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.3 },
        { unitId: '32J', value: 74.48 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 76.28 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 78.08 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 73.76 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 72.68 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.92 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.58 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.84 },
        { unitId: '9G', value: 71.42 },
      ],
    },
    {
      Time: '02/01/2024 01:00PM',
      Status: 'Vacuum Running',
      DHW: 132.61,
      'Return Line': 118.24,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 173.55,
      PSI: 3.58,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.47,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.76 },
        { unitId: '12B', value: 72.32 },
        { unitId: '12H', value: 76.82 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 72.14 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 73.22 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 74.12 },
        { unitId: '16H', value: 71.78 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 73.94 },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.18 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.76 },
        { unitId: '20F', value: 74.12 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.76 },
        { unitId: '22G', value: 74.3 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.18 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.2 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.66 },
        { unitId: '29F', value: 75.74 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 73.04 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.84 },
        { unitId: '32A', value: 74.12 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.3 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 76.46 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 78.08 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 73.94 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 72.86 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.92 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.58 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.38 },
        { unitId: '9G', value: 71.06 },
      ],
    },
    {
      Time: '02/01/2024 02:00PM',
      Status: 'Vacuum Running',
      DHW: 131.99,
      'Return Line': 116.73,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 151.81,
      PSI: 3.52,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.45,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.76 },
        { unitId: '12B', value: 72.32 },
        { unitId: '12H', value: 76.46 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 72.32 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 72.86 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 71.78 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.18 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.58 },
        { unitId: '20F', value: 74.66 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.76 },
        { unitId: '22G', value: 73.94 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 74.84 },
        { unitId: '24G', value: 75.2 },
        { unitId: '25A', value: 74.3 },
        { unitId: '25F', value: 75.56 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.84 },
        { unitId: '29F', value: 75.56 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.86 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 74.12 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.3 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 76.64 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 76.64 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 73.94 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 73.04 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.92 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.3 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.2 },
        { unitId: '9G', value: 70.88 },
      ],
    },
    {
      Time: '02/01/2024 03:00PM',
      Status: 'Vacuum Running',
      DHW: 131.2,
      'Return Line': 117.18,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 151.68,
      PSI: 3.26,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.43,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.12 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.94 },
        { unitId: '12B', value: 72.14 },
        { unitId: '12H', value: 76.46 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 72.86 },
        { unitId: '14H', value: 72.68 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 72.32 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 71.78 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: null },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.54 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.22 },
        { unitId: '20F', value: 74.84 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.76 },
        { unitId: '22G', value: 73.22 },
        { unitId: '23B', value: 77.54 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.56 },
        { unitId: '25A', value: 74.3 },
        { unitId: '25F', value: 75.38 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 73.94 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.84 },
        { unitId: '29F', value: 75.56 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.86 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 74.84 },
        { unitId: '32A', value: 74.12 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.12 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.18 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 76.28 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 74.12 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.76 },
        { unitId: '7F', value: 73.22 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.74 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.76 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.48 },
        { unitId: '9G', value: 71.96 },
      ],
    },
    {
      Time: '02/01/2024 04:00PM',
      Status: 'Vacuum Running',
      DHW: 131.21,
      'Return Line': 117.76,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 196.23,
      PSI: 1.88,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.48,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.94 },
        { unitId: '12B', value: 72.14 },
        { unitId: '12H', value: 76.82 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 72.68 },
        { unitId: '15C', value: 72.14 },
        { unitId: '15H', value: 72.86 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 71.96 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 74.66 },
        { unitId: '18B', value: 75.02 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.72 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.58 },
        { unitId: '20F', value: 75.02 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.94 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.56 },
        { unitId: '25A', value: 74.48 },
        { unitId: '25F', value: 75.56 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.12 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.3 },
        { unitId: '29F', value: 75.38 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.48 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.0 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.92 },
        { unitId: '5G', value: 74.84 },
        { unitId: '6F', value: 74.12 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.76 },
        { unitId: '7F', value: 73.22 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.74 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.76 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 73.94 },
        { unitId: '9G', value: 72.68 },
      ],
    },
    {
      Time: '02/01/2024 05:00PM',
      Status: 'Vacuum Running',
      DHW: 132.15,
      'Return Line': 118.14,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 161.8,
      PSI: 3.96,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.47,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.94 },
        { unitId: '12B', value: 72.14 },
        { unitId: '12H', value: 77.0 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.04 },
        { unitId: '14H', value: 72.68 },
        { unitId: '15C', value: 72.32 },
        { unitId: '15H', value: 73.04 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 71.96 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 74.48 },
        { unitId: '18B', value: 75.38 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.72 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.58 },
        { unitId: '20F', value: 75.2 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.94 },
        { unitId: '22G', value: 72.86 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.36 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.38 },
        { unitId: '25A', value: 74.3 },
        { unitId: '25F', value: 75.56 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.12 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.48 },
        { unitId: '29F', value: 75.2 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.84 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.0 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.74 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 74.48 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.58 },
        { unitId: '7F', value: 72.86 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.74 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.76 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 73.58 },
        { unitId: '9G', value: 72.14 },
      ],
    },
    {
      Time: '02/01/2024 06:00PM',
      Status: 'Vacuum Running',
      DHW: 131.45,
      'Return Line': 119.45,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 154.18,
      PSI: 3.18,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.52,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.3 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 73.94 },
        { unitId: '12B', value: null },
        { unitId: '12H', value: 76.82 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 72.68 },
        { unitId: '15C', value: 72.32 },
        { unitId: '15H', value: 73.22 },
        { unitId: '16B', value: 75.2 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 72.14 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 74.3 },
        { unitId: '18B', value: 75.38 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 77.18 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 73.94 },
        { unitId: '20F', value: 75.38 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.94 },
        { unitId: '22G', value: 72.68 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.2 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.48 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.66 },
        { unitId: '29F', value: 75.02 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 73.94 },
        { unitId: '32J', value: 74.84 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.0 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.74 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 74.48 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.94 },
        { unitId: '7F', value: 72.68 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 75.74 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 73.76 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 73.4 },
        { unitId: '9G', value: 71.78 },
      ],
    },
    {
      Time: '02/01/2024 07:00PM',
      Status: 'Vacuum Running',
      DHW: 130.3,
      'Return Line': 120.58,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 162.21,
      PSI: 3.67,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 21,
      'Average APT': 74.61,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.48 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 74.12 },
        { unitId: '12B', value: 72.32 },
        { unitId: '12H', value: 79.7 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.22 },
        { unitId: '14H', value: 72.68 },
        { unitId: '15C', value: 72.5 },
        { unitId: '15H', value: 73.04 },
        { unitId: '16B', value: 75.38 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 72.14 },
        { unitId: '17B', value: 75.02 },
        { unitId: '17H', value: 74.12 },
        { unitId: '18B', value: 75.92 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 75.74 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.12 },
        { unitId: '20F', value: 75.38 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 73.94 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.38 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 75.38 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.66 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.66 },
        { unitId: '29F', value: 75.02 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 73.94 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.48 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.18 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.38 },
        { unitId: '5G', value: 75.02 },
        { unitId: '6F', value: 74.66 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.94 },
        { unitId: '7F', value: 73.04 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 76.82 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.12 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 74.48 },
        { unitId: '9G', value: 71.96 },
      ],
    },
    {
      Time: '02/01/2024 08:00PM',
      Status: 'Vacuum Running',
      DHW: 128.08,
      'Return Line': 120.61,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 182.26,
      PSI: 3.73,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.87,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.48 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 74.66 },
        { unitId: '12B', value: 73.04 },
        { unitId: '12H', value: 79.52 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.58 },
        { unitId: '14H', value: 72.86 },
        { unitId: '15C', value: 73.76 },
        { unitId: '15H', value: 72.86 },
        { unitId: '16B', value: 75.38 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 72.32 },
        { unitId: '17B', value: 75.2 },
        { unitId: '17H', value: 74.12 },
        { unitId: '18B', value: 75.74 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 75.2 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.48 },
        { unitId: '20F', value: 76.82 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 74.12 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.56 },
        { unitId: '24A', value: 75.2 },
        { unitId: '24G', value: 75.92 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.48 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.84 },
        { unitId: '29F', value: 75.2 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 74.12 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.12 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.18 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.74 },
        { unitId: '5G', value: 75.2 },
        { unitId: '6F', value: 74.84 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 73.94 },
        { unitId: '7F', value: 73.22 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 80.78 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.3 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.02 },
        { unitId: '9G', value: 72.68 },
      ],
    },
    {
      Time: '02/01/2024 09:00PM',
      Status: 'Vacuum Running',
      DHW: 129.49,
      'Return Line': 119.14,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 177.33,
      PSI: 3.53,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.91,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.48 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 74.48 },
        { unitId: '12B', value: 72.86 },
        { unitId: '12H', value: 79.52 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.58 },
        { unitId: '14H', value: 73.04 },
        { unitId: '15C', value: 73.22 },
        { unitId: '15H', value: 72.5 },
        { unitId: '16B', value: 75.38 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 72.32 },
        { unitId: '17B', value: 75.2 },
        { unitId: '17H', value: 74.3 },
        { unitId: '18B', value: 75.56 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 74.84 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.84 },
        { unitId: '20F', value: 76.28 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 74.12 },
        { unitId: '22G', value: 73.04 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.72 },
        { unitId: '23E', value: 75.56 },
        { unitId: '24A', value: 75.2 },
        { unitId: '24G', value: 76.28 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.92 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.48 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.84 },
        { unitId: '29F', value: 75.38 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.68 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 74.84 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.12 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.36 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.74 },
        { unitId: '5G', value: 75.38 },
        { unitId: '6F', value: 75.38 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 74.84 },
        { unitId: '7F', value: 73.4 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 79.16 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.3 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.74 },
        { unitId: '9G', value: 73.04 },
      ],
    },
    {
      Time: '02/01/2024 10:00PM',
      Status: 'Vacuum Running',
      DHW: 129.46,
      'Return Line': 119.17,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 153.16,
      PSI: 3.13,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 20,
      'Average APT': 74.93,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.48 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 74.84 },
        { unitId: '12B', value: 72.86 },
        { unitId: '12H', value: 78.62 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.58 },
        { unitId: '14H', value: 73.22 },
        { unitId: '15C', value: 73.04 },
        { unitId: '15H', value: 72.86 },
        { unitId: '16B', value: 75.38 },
        { unitId: '16D', value: 73.94 },
        { unitId: '16H', value: 72.14 },
        { unitId: '17B', value: 75.38 },
        { unitId: '17H', value: 74.3 },
        { unitId: '18B', value: 75.38 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 74.84 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.84 },
        { unitId: '20F', value: 76.28 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 74.12 },
        { unitId: '22G', value: 73.4 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.74 },
        { unitId: '24A', value: 75.38 },
        { unitId: '24G', value: 76.64 },
        { unitId: '25A', value: 74.3 },
        { unitId: '25F', value: 76.1 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.48 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 75.02 },
        { unitId: '29F', value: 75.56 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 72.86 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.02 },
        { unitId: '32A', value: 75.38 },
        { unitId: '32F', value: 74.12 },
        { unitId: '32J', value: 74.3 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.54 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.74 },
        { unitId: '5G', value: 75.38 },
        { unitId: '6F', value: 75.2 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 74.66 },
        { unitId: '7F', value: 73.58 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 77.72 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.3 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.74 },
        { unitId: '9G', value: 73.22 },
      ],
    },
    {
      Time: '02/01/2024 11:00PM',
      Status: 'Vacuum Running',
      DHW: 131.29,
      'Return Line': 112.57,
      Supply: 'ERR',
      Water: 0.0,
      Stack: 156.2,
      PSI: 3.39,
      'Boilers/Pumps': 'B1: On, R: On',
      'Valve Open Percent': 10,
      'Average APT': 74.8,
      apartments: [
        { unitId: '10A', value: null },
        { unitId: '10D', value: null },
        { unitId: '11B', value: 74.48 },
        { unitId: '11E', value: null },
        { unitId: '11G', value: 75.02 },
        { unitId: '12B', value: 72.68 },
        { unitId: '12H', value: 77.9 },
        { unitId: '14B', value: null },
        { unitId: '14D', value: 73.4 },
        { unitId: '14H', value: 73.4 },
        { unitId: '15C', value: 73.04 },
        { unitId: '15H', value: 72.32 },
        { unitId: '16B', value: 75.74 },
        { unitId: '16D', value: 73.76 },
        { unitId: '16H', value: 72.32 },
        { unitId: '17B', value: 75.38 },
        { unitId: '17H', value: 74.3 },
        { unitId: '18B', value: 75.38 },
        { unitId: '18E', value: null },
        { unitId: '18H', value: null },
        { unitId: '19A', value: 74.66 },
        { unitId: '19E', value: null },
        { unitId: '19H', value: null },
        { unitId: '20B', value: 74.84 },
        { unitId: '20F', value: 76.1 },
        { unitId: '21C', value: null },
        { unitId: '21J', value: null },
        { unitId: '22A', value: 74.3 },
        { unitId: '22G', value: 73.4 },
        { unitId: '23B', value: 77.72 },
        { unitId: '23C', value: 77.54 },
        { unitId: '23E', value: 75.74 },
        { unitId: '24A', value: 75.02 },
        { unitId: '24G', value: 76.1 },
        { unitId: '25A', value: 74.12 },
        { unitId: '25F', value: 75.74 },
        { unitId: '25J', value: null },
        { unitId: '26B', value: null },
        { unitId: '26C', value: null },
        { unitId: '27C', value: null },
        { unitId: '27F', value: 74.48 },
        { unitId: '28C', value: null },
        { unitId: '28J', value: null },
        { unitId: '29A', value: 74.84 },
        { unitId: '29F', value: 75.2 },
        { unitId: '30B', value: null },
        { unitId: '30J', value: null },
        { unitId: '31B', value: 73.04 },
        { unitId: '31E', value: null },
        { unitId: '31H', value: 75.2 },
        { unitId: '32A', value: 75.02 },
        { unitId: '32F', value: 73.94 },
        { unitId: '32J', value: 74.48 },
        { unitId: '3G', value: null },
        { unitId: '4D', value: 77.54 },
        { unitId: '4G', value: null },
        { unitId: '5A', value: 75.38 },
        { unitId: '5G', value: 75.38 },
        { unitId: '6F', value: 74.84 },
        { unitId: '6J', value: null },
        { unitId: '7B', value: 74.12 },
        { unitId: '7F', value: 73.4 },
        { unitId: '7J', value: null },
        { unitId: '8C', value: 77.18 },
        { unitId: '8D', value: null },
        { unitId: '8F', value: 74.12 },
        { unitId: '9A', value: null },
        { unitId: '9E', value: 75.56 },
        { unitId: '9G', value: 73.22 },
      ],
    },
  ];

  const [currentRow, setCurrentRow] = useState<number>(0);
  const [colorFormat,setColorFormat] = useState<number>(0);

  const handleClickNext = () => {
    console.log('click next', currentRow, data.length);
    if (currentRow < data.length - 1) {
      setCurrentRow(currentRow + 1);
    } else {
      setCurrentRow(0);
    }
  };

  const handleClickPrevious = () => {
    if (currentRow > 0) {
      setCurrentRow(currentRow - 1);
    } else {
      setCurrentRow(data.length - 1);
    }
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setCurrentRow(newValue);
    }
  };

  const {
    setUnitOverlayData,
    setAllUnitsOverlayData,
    unitsOverlayData,
    setDisplayMode,
    displayMode,
  } = useBuildingModel();

  const maxTemp = 77;
  const minTemp = 70;

  const maxColor = '#d50000';
  const minColor = '#ef5350';

  useEffect(() => {
    console.log('setting building overlay');
    if (data[currentRow]) {
      if (data[currentRow].apartments) {
        const unitOverlaysArray = data[currentRow].apartments.map(
          (apartment) => {
            // calculate the color according to the min and max range and min and max color
            const color = getConditionalFormattingColor(
              colorFormat,
              minTemp,
              maxTemp,
              apartment.value || 0
            );
            const unitId = apartment.unitId.toLowerCase();
            // console.log('Sensor unitId',unitId,color);
            return { unitId: unitId, colorOverlay: color };
          }
        );
        // make unitOverlayArray into a map where unitId is the key
        const unitOverlayData = unitOverlaysArray.reduce(
          (acc: Record<string, UnitOverlayData>, overlay) => {
            acc[overlay.unitId] = overlay;
            return acc;
          },
          {}
        );
        // console.log('setting units overlay data', unitOverlayData);
        setAllUnitsOverlayData(unitOverlayData);
        // console.log('unitsOrverlaydata set')
      }
    }
  }, [currentRow, data, setAllUnitsOverlayData, colorFormat]);

  

  return (
    // remove scroll bars
    
    <Stack
      className="flex-1 overflow-visible px-32 py-[120px] pr-32 pr-[8px] overflow-hidden"
      gap="16px"
      alignItems="center"
      justifyContent="center"
      
    >
      <Typography variant="h4">Sensor Data for</Typography>
      <Typography>{data[currentRow].Time}</Typography>
      <Slider
        className="w-[60] py-16"
        aria-label="Temperature"
        defaultValue={0}
        valueLabelDisplay="auto"
        // shiftStep={1}
        step={1}
        marks
        min={0}
        max={data.length - 1}
        onChange={handleChange}
      />
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button
          onClick={() => setColorFormat(1)}
          variant={colorFormat === 1 ? "contained" : "outlined"}
        >
          3 Colors
        </Button>
        <Button
          onClick={() => setColorFormat(2)}
          variant={colorFormat === 2 ? "contained" : "outlined"}
        >
          Color Range
        </Button>
        <Button
          onClick={() => setColorFormat(3)}
          variant={colorFormat === 3 ? "contained" : "outlined"}
        >
          Gradient
        </Button>
      </ButtonGroup>
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button
          onClick={() => setDisplayMode(1)}
          variant={displayMode === 1 ? "contained" : "outlined"}
        >
          Transparent
        </Button>
        <Button
          onClick={() => setDisplayMode(0)}
          variant={displayMode === 0 ? "contained" : "outlined"}
        >
          Solid
        </Button>
        <Button
          onClick={() => setDisplayMode(2)}
          variant={displayMode === 2 ? "contained" : "outlined"}
        >
          White
        </Button>
      </ButtonGroup>
      {/* <Button onClick={handleClickPrevious}>Previous</Button> */}
      {/* <Button onClick={handleClickNext}>Next</Button> */}
    </Stack>
  );
}
