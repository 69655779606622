import { ActionItemCategory, LeaseLifecycle } from '@urbanmix-tech/shared-js';

// Select/Deselect options in checkbox list. Returns a list of selected options
export const getSelectedOptions = (options: string[], id: string) => {
  const newOptions = [...options];
  const index = newOptions.indexOf(id);

  if (index !== -1) newOptions.splice(index, 1);
  else newOptions.splice(newOptions.length, index, id);
  return newOptions;
};

export const convertFirebaseTimestamp = (timestamp: {
  seconds: number;
  nanoseconds: number;
}): Date | null => {
  if (timestamp) {
    const seconds = timestamp.seconds || 0;
    const nanoseconds = timestamp.nanoseconds || 0;
    const milliseconds = seconds * 1000 + nanoseconds / 1e6;

    // Convert to JavaScript Date object
    return new Date(milliseconds);
  }

  return null;
};

export const convertPseudoDateToRealDate = (
  date?: Date | null
): Date | null => {
  if (!date) {
    return null;
  }

  const timestamp = date as unknown as {
    seconds: number;
    nanoseconds: number;
  };

  if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
    return convertFirebaseTimestamp(timestamp);
  }

  return date;
};

export function isString(file: string | File | null): file is string {
  return typeof file === 'string';
}

export function isFile(file: string | File | null): file is File {
  if (file) {
    return !isString(file);
  }

  return false;
}

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getLongNumberStr(num: number) {
  const numStr = num.toString().split('.');

  const styledNum = [];

  for (let i = 0; i < numStr[0].length; i += 1) {
    if (i && i % 3 === 0) {
      styledNum.unshift(',');
    }

    styledNum.unshift(numStr[0][numStr[0].length - i - 1]);
  }

  return numStr.length > 1
    ? `${styledNum.join('')}.${numStr[1]}`
    : styledNum.join('');
}

export const labelsByLeaseLifecycle: Record<LeaseLifecycle, string> = {
  NOTICE: 'NOTICE',
  INSPECT: 'INSPECT',
  TURNOVER: 'MAKE READY',
  LEASE: 'LEASE',
  DONE: 'OCCUPIED',
};

export const colorsByLeaseLifecycle: Record<LeaseLifecycle, string> = {
  NOTICE: '#C7A3F3',
  INSPECT: '#FF7395',
  TURNOVER: '#F4AB6F',
  LEASE: '#8FD3CD',
  DONE: '#FAFAFA',
};

export const bgColorsEndByLeaseLifeycycle: Record<LeaseLifecycle, string> = {
  NOTICE: 'rgba(255,255,255,0.2)',
  INSPECT: 'rgba(205, 49, 133, 0.2)',
  TURNOVER: 'rgba(248, 190, 28, 0.2)',
  LEASE: 'rgba(113, 220, 109, 0.2)',
  DONE: 'rgba(255,255,255,0.2)',
};

export const borderColorClassByLeaseLifecycle: Record<LeaseLifecycle, string> =
  {
    [LeaseLifecycle.NOTICE]: '!border-purple-400',
    [LeaseLifecycle.INSPECT]: '!border-rose-400',
    [LeaseLifecycle.TURNOVER]: '!border-orange-300',
    [LeaseLifecycle.LEASE]: '!border-emerald-200',
    [LeaseLifecycle.DONE]: '!border-white',
  };

export const bgColorsByLeaseLifecycle: Record<LeaseLifecycle, string> = {
  NOTICE: '#B67BFF',
  INSPECT: '#FF7395',
  TURNOVER: '#FFA760',
  LEASE: '#8FD3CD',
  DONE: '#FFFFFF',
};

export function getActionColorByCategory(category: ActionItemCategory) {
  switch (category) {
    case 'notice': {
      return colorsByLeaseLifecycle[LeaseLifecycle.NOTICE];
    }
    case 'inspect': {
      return colorsByLeaseLifecycle[LeaseLifecycle.INSPECT];
    }
    case 'makeReady': {
      return colorsByLeaseLifecycle[LeaseLifecycle.TURNOVER];
    }
    case 'lease': {
      return colorsByLeaseLifecycle[LeaseLifecycle.LEASE];
    }
    default: {
      return colorsByLeaseLifecycle[LeaseLifecycle.DONE];
    }
  }
}

export function getActionBgColorByCategory(category: ActionItemCategory) {
  switch (category) {
    case 'inspect': {
      return bgColorsEndByLeaseLifeycycle[LeaseLifecycle.INSPECT];
    }
    case 'makeReady': {
      return bgColorsEndByLeaseLifeycycle[LeaseLifecycle.TURNOVER];
    }
    case 'lease': {
      return bgColorsEndByLeaseLifeycycle[LeaseLifecycle.LEASE];
    }
    default: {
      return bgColorsEndByLeaseLifeycycle[LeaseLifecycle.DONE];
    }
  }
}
