import { Stack, Typography } from '@mui/material';

import { ConditionReportItem } from '@urbanmix-tech/shared-js';

import { ConditionIcon } from './ConditionIcon';

const variants = ['bad', 'good', 'noCondition'];

type ConditionStatisticsProps = {
  items: ConditionReportItem[];
};

export function ConditionStatistics({ items }: ConditionStatisticsProps) {
  const statistics = items.reduce(
    (acc, item) => {
      return {
        bad: acc.bad + (item.condition === false ? 1 : 0),
        good: acc.good + (item.condition === true ? 1 : 0),
        noCondition:
          acc.noCondition +
          (item.condition === null || item.condition === undefined ? 1 : 0),
      };
    },
    { bad: 0, good: 0, noCondition: 0 }
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <Stack gap="16px" direction="row" alignItems="center" className="mr-[24px]">
      {variants.map((variant) => (
        <Stack key={variant} gap="8px" direction="row" alignItems="center">
          <ConditionIcon variant={variant as 'bad' | 'good' | 'noCondition'} />
          <Typography variant="caption3" className="font-semibold text-white">
            {Math.floor(
              (statistics[variant as keyof typeof statistics] * 100) /
                items.length
            )}
            %
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
