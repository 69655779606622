import { useState, ChangeEventHandler } from 'react';

import { Stack, InputBase, Typography } from '@mui/material';

import { Team } from '@urbanmix-tech/shared-js';

import { ConfirmButtons } from '../shared/ConfirmButtons/ConfirmButtons';

export type NewTeamFormProps = {
  teamNames: Record<string, boolean>;
  onAdd(team: Team): void;
  onCancel(): void;
};

export function NewTeamForm({ teamNames, onAdd, onCancel }: NewTeamFormProps) {
  const [newTeamName, setNewTeamName] = useState('');

  const handleCancelEditing = () => {
    setNewTeamName('');
    onCancel();
  };

  const handleChangeTeamName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNewTeamName(e.target.value);
  };

  const handleAddUser = () => {
    if (newTeamName.trim() === '') {
      return;
    }

    onAdd({
      name: newTeamName,
      users: [],
      projectIds: [],
    });

    handleCancelEditing();
  };

  return (
    <Stack gap="24px">
      <Typography variant="caption2" className="text-white">
        Please enter team name
      </Typography>
      <InputBase
        value={newTeamName}
        onChange={handleChangeTeamName}
        className="rounded-md border border-white p-4 text-[13px] text-white"
        fullWidth
      />
      <ConfirmButtons
        confirm={{
          label: 'Confirm',
          onClick: handleAddUser,
          disabled:
            teamNames[newTeamName] === true || newTeamName.trim() === '',
        }}
        cancel={{
          label: 'Cancel',
          onClick: onCancel,
        }}
      />
    </Stack>
  );
}
