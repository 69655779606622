import React from 'react';
import { LinkProps, PaletteColor, createTheme } from '@mui/material';
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
} from 'react-router-dom';

import colors from './colors';

const fontFamily = "'Poppins', sans-serif";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink
      ref={ref}
      to={href}
      className={({ isActive, isPending }) =>
        isPending ? 'pending' : isActive ? 'active' : ''
      }
      end
      {...other}
    />
  );
});

const theme = createTheme({
  /* ---------- PALETTE ---------- */
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      900: colors['secondary-900'],
      800: colors['secondary-800'],
      700: colors['secondary-700'],
      600: colors['secondary-600'],
      400: colors['secondary-400'],
      300: colors['secondary-300'],
      200: colors['secondary-200'],
      100: colors['secondary-100'],
      50: colors['secondary-50'],
    },
    info: {
      main: colors.info,
      400: colors['info-400'],
      300: colors['info-300'],
      200: colors['info-200'],
      100: colors['info-100'],
      50: colors['info-50'],
    },
    warning: {
      main: colors.warning,
      900: colors['warning-900'],
      800: colors['warning-800'],
      700: colors['warning-700'],
      600: colors['warning-600'],
      400: colors['warning-400'],
      300: colors['warning-300'],
      200: colors['warning-200'],
      100: colors['warning-100'],
      50: colors['warning-50'],
    },
    background: {
      default: 'black',
      paper: 'black',
    },
    text: {
      primary: '#ffffff', // Default text color for most components
      secondary: '#b0bec5', // Secondary text color
    },
    action: {
      active: '#999999', // Color for active elements like icons
    },
  },

  /* ---------- TYPOGRAPHY ---------- */
  typography: {
    fontFamily,
    allVariants: {
      color: colors.white,
      fontFamily,
    },

    h1: {
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
    },
    h2: {
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '40px',
    },
    h3: {
      fontSize: '25px',
      fontWeight: '600',
      lineHeight: '32px',
    },
    h4: {
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '0.5px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
    },

    body1: {
      fontSize: '18px',
    },
    body2: {
      fontSize: '16px',
    },

    body2Medium: {
      fontSize: '16px',
      fontWeight: '500',
    },

    caption: {
      fontSize: '14px',
    },
    caption2: {
      fontSize: '12px',
    },
    caption3: {
      fontSize: '11px',
    },
    captionMedium: {
      fontSize: '14px',
      fontWeight: '500',
    },
    captionLight: {
      fontSize: '14px',
      fontWeight: '300',
    },
  },

  /* ---------- COMPONENTS ---------- */
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          outline: '1px solid',
          outlineColor: 'gray',
          boxShadow: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 10,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: 'white', // Sets the input text color to white for all TextField components
        },
        notchedOutline: {
          borderColor: 'gray', // Set the outline color to dark gray
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'black',
          color: 'white',
        },
      },
    },
    /* ---------- BUTTON ---------- */
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },

    MuiIconButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },

      styleOverrides: {
        root: (props) => ({
          boxShadow: 'none',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          borderRadius: '4px',
          padding: '5.5px 10px',
          fontSize: '14px',

          ':focus': {
            outline: '2px solid',
          },

          '&.Mui-disabled': {
            opacity: 0.5,
          },

          ...(props.ownerState.variant === 'contained' &&
            props.ownerState.color && {
              backgroundColor: `${
                (
                  props.theme.palette[
                    props.ownerState.color as keyof typeof props.theme.palette
                  ] as PaletteColor
                ).main
              }!important`,
              color: `${
                (
                  props.theme.palette[
                    props.ownerState.color as keyof typeof props.theme.palette
                  ] as PaletteColor
                ).contrastText
              }!important`,
            }),

          ...(props.ownerState.variant === 'outlined' &&
            props.ownerState.color && {
              borderColor: `${
                (
                  props.theme.palette[
                    props.ownerState.color as keyof typeof props.theme.palette
                  ] as PaletteColor
                ).main
              }!important`,
              backgroundColor: `transparent!important`,
              color: `${
                (
                  props.theme.palette[
                    props.ownerState.color as keyof typeof props.theme.palette
                  ] as PaletteColor
                ).main
              }!important`,
            }),
        }),
      },
    },

    /* ---------- TEXT FIELD ---------- */
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input, & input::placeholder': {
            fontSize: '16px',
            lineHeight: '16px',
            backgroundColor: '#0A0E15',
            borderRadius: '4px',
            color: 'white',
          },

          '.MuiSelect-select': {
            padding: '8px 14px',
            backgroundColor: '#0A0E15',
            borderRadius: '4px',
          },
          '.MuiInputBase-sizeSmall': {
            '& input, & input::placeholder': {
              fontSize: '14px',
              lineHeight: '14px',
            },
          },

          '.MuiInputBase-root:not(.MuiInputBase-sizeSmall) input': {
            padding: '9px 14px',
          },

          '& .MuiFormLabel-root': {
            color: colors.primary,
          },

          '& fieldset': {
            borderColor: colors.primary,
            transition: 'border .2s',
          },

          '.Mui-focused  fieldset': {
            color: `${colors.primary} !important`,
          },
          '&:hover .MuiInputBase-root:not(.Mui-focused) fieldset': {
            borderColor: `${colors.primary} !important`,
          },
        },
      },
    },

    /* ---------- TYPOGRAPHY COMPONENT ---------- */
    MuiTypography: {
      defaultProps: {
        fontFamily,
        color: colors.info,
      },
    },

    /* ---------- PAPER ---------- */
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 4px 18px rgba(221.05, 226.87, 232.69, 0.56)',
        },

        elevation2: {
          boxShadow: '0px 6px 24px rgba(221.05, 226.87, 232.69, 0.70)',
        },

        elevation3: {
          boxShadow: '0px 16px 40px rgba(181.22, 193.67, 206.13, 0.50)',
        },

        root: {
          borderRadius: '10px',

          '&.MuiPopover-paper': {
            borderRadius: '12px',
            border: `1px solid ${colors.stroke2}`,
          },
        },
      },
    },

    /* ---------- LIST ---------- */
    MuiList: {
      styleOverrides: {
        root: {
          padding: '12px 8px',
          minWidth: '250px',
        },
      },
    },

    /* ---------- MENU ITEM ---------- */
    MuiMenuItem: {
      defaultProps: {
        disableTouchRipple: true,
      },

      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          padding: '6px 12px',
          borderRadius: '8px',
          fontSize: '16px',
          color: colors['info-700'],

          '&:hover': {
            backgroundColor: colors.stroke,
          },

          '.MuiCheckbox-root, .MuiRadio-root': {
            padding: 0,
          },
        },
      },
    },

    /* ---------- TOOLTIP ---------- */
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 6px',
          borderRadius: '3px',
          backgroundColor:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #0A0E15;',
          color: '#E6E3E3',
          border: `1px solid rgba(255, 255, 255, 0.2)`,
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        },

        arrow: {
          '&:before': {
            borderRadius: '2px',
            backgroundColor: colors.info,
          },
        },
      },
    },

    /* ---------- DIALOG ---------- */
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          minWidth: '450px',
          minHeight: '200px',
        },

        root: {
          '.MuiBackdrop-root': {
            backgroundColor: '#58585A90',
          },
        },
      },
    },

    /* ---------- DIALOG TITLE ---------- */
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          fontWeight: '600',
        },
      },
    },

    /* ---------- LINK ---------- */
    MuiLink: {
      defaultProps: {
        color: colors.info,
        component: LinkBehavior,
      } as LinkProps,

      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          transition: 'all .1s',

          '&.active': {
            // fontWeight: '600',
          },

          '&.nav-link': {
            fontSize: '16px',

            '&.active, :hover': {
              // fontWeight: '600',
              color: colors.primary,

              '& svg, & path, & g': {
                stroke: colors.primary,
                opacity: 1,
              },
            },
          },
        },
      },
    },

    /* ---------- SKELETON ---------- */
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#F1F5F9',
        },
      },
    },

    /* ---------- CHIP ---------- */
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: '26px',
        },

        root: {
          '&.MuiChip-colorDefault': {
            borderColor: '#CCE4FF',
            backgroundColor: '#FFF',
          },
        },
      },
    },

    /* ---------- ACCORDION ---------- */
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '0',
          },

          '::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },

    /* ---------- BREADCRUMBS ---------- */
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .MuiBreadcrumbs-separator': {
            fontSize: '16px !important',
            margin: '0 8px',
            paddingTop: '4px',
          },
        },
      },
    },

    /* ---------- TABLE ---------- */
    MuiTable: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        head: {
          ':last-of-type': {
            borderBottom: `1px solid ${colors.stroke3}!important`,
          },
        },
        root: {
          borderBottom: `1px solid ${colors.stroke3}`,
          ':last-of-type': {
            borderBottom: 'none',
          },
          color: '#E6E3E3CC',
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #0A0E15;',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#E6E3E3',
          fontSize: '16px !important',
          lineHeight: '18px',
          fontWeight: 500,
          padding: '12px 0',
          letterSpacing: '0.4px',
          backgroundColor: 'transparent',
        },

        root: {
          padding: '10px 0',
          fontSize: '14px !important',
          border: 'none',
          color: 'inherit',
          backgroundColor: 'transparent',
          letterSpacing: '0.35px',
          minWidth: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
    },

    /* ---------- LINEAR PROGRESS ---------- */
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: '34px',
        },

        root: {
          height: '8px',
          borderRadius: '34px',
        },
      },
    },
  },
});

export default theme;
