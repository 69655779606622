import {
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  PointerEvent,
  useCallback,
} from 'react';
import * as THREE from 'three';

import { Intersection, ThreeEvent, useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';

import { materials } from './materials';
import { useBuildingModel } from '@/contexts/Building/BuildingContext';

export type D3ModelHandle = {
  getMeshById(meshId: string): THREE.Mesh | null;
};

interface D3ModelProps {
  url: string;
  meshMaterialMap?: Record<string, THREE.Material>;
  hoveredMeshId?: string | null;
  setHoveredMeshId?: (item: string | null) => void;
  selectedMeshIds?: string[];
  hideOutline?: boolean;
  outlineColor?: {
    visible: number;
    hidden: number;
    strength: number;
  };
  mode: 'standard' | 'transparent' | 'hardTransparent';
  rotate?: boolean;
}

export const D3Model = forwardRef<D3ModelHandle, D3ModelProps>(
  (
    {
      url,
      meshMaterialMap,
      hoveredMeshId,
      setHoveredMeshId,
      selectedMeshIds,
      hideOutline,
      outlineColor,
      mode,
      rotate = false,
    },
    ref
  ) => {
    const object = useLoader(GLTFLoader, url || '');

    const { displayMode } = useBuildingModel();

    useEffect(() => {
      console.log('adding edges', object);
      object.scene.traverse((o) => {
        const oMesh = o as THREE.Mesh;
        console.log('oMesh', oMesh);
        if (oMesh.name.includes('apt') || oMesh.name.includes('unit')) {
          const edges = new THREE.EdgesGeometry(oMesh.geometry);
          const line = new THREE.LineSegments(
            edges,
            new THREE.LineBasicMaterial({ color: 0x000000 })
          );
          oMesh.add(line);
        }
      });
  
    }, [object]);
    // after loading the model, we can access the scene object
    // lets add a edgeGeometry to every mesh that starts with apt
    


    

    useFrame(() => {
      if (rotate) {
        object.scene.rotation.y += 0.01;
      }
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          getMeshById(meshId: string): THREE.Mesh | null {
            let mesh: THREE.Mesh | null = null;

            object.scene.traverse((o) => {
              const oMesh = o as THREE.Mesh;
              if (o.name === meshId) {
                mesh = oMesh;
              }
            });

            return mesh;
          },
        };
      },
      [object.scene]
    );

    const selections = useMemo(() => {
      const selectedMeshIdsMap = new Map<string, boolean>();
      console.log('meshMaterialMap', meshMaterialMap);
      selectedMeshIds?.forEach((meshId) =>
        selectedMeshIdsMap.set(meshId, true)
      );

      const tempSelection: THREE.Mesh[] = [];

      object.scene.castShadow = true;
      object.scene.traverse((o) => {
        const oMesh = o as THREE.Mesh;
        const meshId = o.name;
        // console.log(`real meshId *${meshId}*`);

        if (meshId === hoveredMeshId) {
          oMesh.material =
            mode === 'standard'
              ? materials.hoveredColor
              : materials.hoveredColorTrans;
          return;
        }

        // // console.log('meshId', meshId);
        // if (meshId.includes('unit')) {
          
        //   tempSelection.push(oMesh);
        // }

        // console.log("meshId", meshId);
        if (meshMaterialMap && meshId in meshMaterialMap) {
          console.log("meshId in material map", meshId);
          oMesh.material = meshMaterialMap[meshId] || materials.plaster;
        } else if (meshId.includes('window')) {
          oMesh.material = materials.regularTrans;
        } else if (mode === 'transparent') {
          oMesh.material = materials.regularTrans;
        } else if (mode === 'hardTransparent') {
          oMesh.material = materials.hardTrans;
        } else {
          if (displayMode === 1) {
            oMesh.material = materials.sensorBase;
          } else if (displayMode === 0) {
            oMesh.material = materials.plaster;
          } else if (displayMode === 2) {
            oMesh.material = materials.sensorToon;
          } else {
            oMesh.material = materials.greenColor;
          }
        }
        oMesh.castShadow = true;
        // if (selectedMeshIdsMap.get(meshId)) {
        //   tempSelection.push(oMesh);
        // }
      });

      return tempSelection;
    }, [hoveredMeshId, meshMaterialMap, mode, object.scene, selectedMeshIds]);

    useEffect(() => {
      document.body.style.cursor = hoveredMeshId ? 'pointer' : 'auto';
    }, [hoveredMeshId]);

    const getSearchResult = useCallback(
      (objectItem: THREE.Object3D<THREE.Object3DEventMap> | undefined) => {
        if (objectItem) {
          setHoveredMeshId?.(objectItem.name);
        } else {
          setHoveredMeshId?.(null);
        }
      },
      [setHoveredMeshId]
    );
    return (
      <>
        <primitive
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            const layer = event.intersections;
            if (layer.length === 1) {
              getSearchResult(layer[0].object);
            } else if (layer.length > 1) {
              const foundedObject = layer.reduce<Intersection | undefined>(
                (prev, current) => {
                  if (prev) {
                    return prev.distance < current.distance ? prev : current;
                  }
                  return current;
                },
                undefined
              );
              getSearchResult(foundedObject?.object);
            }
          }}
          onPointerOut={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            setHoveredMeshId?.(null);
          }}
          onClick={(event: ThreeEvent<MouseEvent>) => {
            event.stopPropagation();
          }}
          // eslint-disable-next-line react/no-unknown-property
          object={object.scene}
        />
        {!hideOutline && (
          <EffectComposer autoClear={false}>
            <Outline
              selection={selections}
              blendFunction={BlendFunction.SCREEN}
              edgeStrength={outlineColor?.strength || 2}
              pulseSpeed={0.0}
              width={1000}
              visibleEdgeColor={outlineColor?.visible || 0xffffff}
              hiddenEdgeColor={outlineColor?.hidden || 0xffffff}
            />
          </EffectComposer>
        )}
      </>
    );
  }
);
