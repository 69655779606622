import { useState, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

import { ActionItem, Inspection } from '@urbanmix-tech/shared-js';
import { InspectionTabContent } from './InspectionTabContent';
import { SimpleSelect } from '../shared/Selects/SimpleSelect';

const selectItems = [
  { label: 'Inspection', value: 'inspection' },
  { label: 'Make Ready', value: 'makeReady' },
  { label: 'Lease', value: 'lease' },
];

export type ReportsTabContentProps = {
  unitName: string;
  inspections: Inspection[];
  reviewInspectionActions: ActionItem[];
  onSaveInspection(inspection: Inspection): void;
  onSaveAction: (actionItem: ActionItem, payload: unknown) => Promise<void>;
  onReinspectAction: () => void;
  onNewMakeReadyAction: () => void;
  onNewLeaseAction: () => void;
};

export function ReportsTabContent({
  unitName,
  inspections,
  reviewInspectionActions,
  onSaveInspection,
  onSaveAction,
  onReinspectAction,
  onNewMakeReadyAction,
  onNewLeaseAction,
}: ReportsTabContentProps) {
  const [subTab, setSubTab] = useState(selectItems[0].value);

  const contents: Record<string, ReactNode> = {
    inspection: (
      <InspectionTabContent
        unitName={unitName}
        inspections={inspections}
        onSaveAction={onSaveAction}
        onSaveInspection={onSaveInspection}
        onReinspectAction={onReinspectAction}
        reviewInspectionActions={reviewInspectionActions}
        onNewMakeReadyAction={onNewMakeReadyAction}
        onNewLeaseAction={onNewLeaseAction}
      />
    ),
    makeReady: (
      <Typography variant="caption2" className="text-gray-400">
        Coming soon...
      </Typography>
    ),
    lease: (
      <Typography variant="caption2" className="text-gray-400">
        Coming soon...
      </Typography>
    ),
  };

  return (
    <Stack gap="16px" className="h-full w-[640px]">
      <SimpleSelect
        items={selectItems}
        selected={subTab}
        onSelect={setSubTab}
      />
      {contents[subTab]}
    </Stack>
  );
}
