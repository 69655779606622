import { ConditionReportItem } from '@urbanmix-tech/shared-js';

const BedRoomItems = [
  'Floor',
  'Walls',
  'Ceiling',
  'Windows',
  'Doors',
  'Closets and storage',
  'Electrical',
  'Smoke Detectors',
];
const StudioRoomItems = [
  'Floor',
  'Walls',
  'Ceiling',
  'Windows',
  'Doors',
  'Closets and storage',
  'Electrical',
  'Smoke Detectors',
  'Cabinets and counter',
  'Hot water heater',
  'Appliances',
  'HVAC',
  'Washing Machine',
  'Dryer',
  'Intercom / Doorbell',
  'Balcony / Deck / Patio',
];
const BathRoomItems = [
  'Floor',
  'Walls',
  'Ceiling',
  'Windows',
  'Doors',
  'Electrical',
  'Smoke Detectors',
  'Cabinets and counter',
  'Bathtub',
  'Hot water heater',
];
const Kitchen = [
  'Floor',
  'Walls',
  'Ceiling',
  'Windows',
  'Doors',
  'Electrical',
  'Smoke Detectors',
  'Cabinets and counter',
  'Hot water heater',
  'Appliances',
];
const LivingRoom = [
  'Floor',
  'Walls',
  'Ceiling',
  'Windows',
  'Doors',
  'Electrical',
  'Smoke Detectors',
  'Closets and storage',
  'HVAC',
  'Washing Machine',
  'Dryer',
  'Intercom/Doorbell',
  'Balcony/Deck/Patio',
];

export function getConditionReportItemTemplate(
  bedrooms: number,
  bathrooms: number
): ConditionReportItem[] {
  if (bedrooms === 0) {
    return [
      ...StudioRoomItems.map((name) => ({
        name,
        roomType: 'Studio',
        condition: null,
        amount: 0,
        quality: 0,
        images: [],
        group: '',
      })),
      ...BathRoomItems.map((name) => {
        const arr: ConditionReportItem[] = [];

        for (let i = 0; i < bathrooms; i += 1) {
          arr.push({
            name,
            roomType: `Bathroom #${i + 1}`,
            condition: null,
            amount: 0,
            quality: 0,
            images: [],
            group: '',
          });
        }

        return arr;
      }).reduce((acc, arr) => [...acc, ...arr], []),
    ];
  }

  return [
    ...BedRoomItems.map((name) => {
      const arr: ConditionReportItem[] = [];

      for (let i = 0; i < bedrooms; i += 1) {
        arr.push({
          name,
          roomType: `Bedroom #${i + 1}`,
          condition: null,
          amount: 0,
          quality: 0,
          images: [],
          group: '',
        });
      }

      return arr;
    }).reduce((acc, arr) => [...acc, ...arr], []),
    ...BathRoomItems.map((name) => {
      const arr: ConditionReportItem[] = [];

      for (let i = 0; i < bathrooms; i += 1) {
        arr.push({
          name,
          roomType: `Bathroom #${i + 1}`,
          condition: null,
          amount: 0,
          quality: 0,
          images: [],
          group: '',
        });
      }

      return arr;
    }).reduce((acc, arr) => [...acc, ...arr], []),
    ...Kitchen.map((name) => ({
      name,
      roomType: 'Kitchen',
      condition: null,
      amount: 0,
      quality: 0,
      images: [],
      group: '',
    })),
    ...LivingRoom.map((name) => ({
      name,
      roomType: 'Living Room',
      condition: null,
      amount: 0,
      quality: 0,
      images: [],
      group: '',
    })),
  ];
}
