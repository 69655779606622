import { Button, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ActionItem } from '@urbanmix-tech/shared-js';

import AppIcon from '@/components/shared/AppIcon/AppIcon';
import { borderColorClassByCategory } from '@/components/UnitTabs/Todo/TodoAccordion';
import { twMerge } from 'tailwind-merge';

export type SuccessModalContentProps = {
  onClose(): void;
  actionItem: ActionItem;
};

export function SuccessModalContent({
  actionItem,
  onClose,
}: SuccessModalContentProps) {
  return (
    <Stack
      gap="70px"
      className={twMerge(
        'relative w-fit min-w-[337px] rounded-[10px] border-4 bg-gray-950 px-32 py-42 shadow',
        borderColorClassByCategory[actionItem.category]
      )}
      alignItems="center"
    >
      <IconButton
        className="absolute right-8 top-8 !bg-stone-200 p-4"
        onClick={onClose}
      >
        <CloseIcon className="text-[14px] text-black" />
      </IconButton>

      <Stack gap="20px" alignItems="center">
        <Typography variant="h4" className=" text-gray-400">
          Success!
        </Typography>

        <Typography variant="h3" className="text-stone-200">
          {actionItem.title}
        </Typography>
      </Stack>

      <div className="flex h-[117px] w-[117px] items-center justify-center">
        <AppIcon name="success" className="scale-[600%]" />
      </div>

      <Button
        variant="text"
        onClick={onClose}
        className="text-sm text-white !underline"
      >
        Close
      </Button>
    </Stack>
  );
}
