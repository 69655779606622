import { useCallback, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Stack } from '@mui/material';

import { ActionItem, ActionItemCategory } from '@urbanmix-tech/shared-js';
import { LeaseLifecycle } from '@urbanmix-tech/shared-js';
import { convertPseudoDateToRealDate } from '@/helpers/common';

import { CompletedAccordion } from '../UnitTabs/Todo/CompletedAccordion';
import { Select } from '../shared/Selects/Select';
import { ActionGroupList } from './TrackerActionGroupList';

const oneDayGap = 1 * 24 * 60 * 60 * 1000;

const priorityItems = [
  { label: 'Overdue', value: 'overdue' },
  { label: 'Due now', value: 'duenow' },
];

type TrackerTabContentProps = {
  actionItems: ActionItem[];
  leaseStatusFilter: LeaseLifecycle[];
  onClickActionItem(id: string): void;
  onBuildingDataChange?: (data: Record<string, any>) => void;
};

export function TrackerTabContent({
  actionItems,
  leaseStatusFilter,
  onClickActionItem,
  onBuildingDataChange,
}: TrackerTabContentProps) {
  const navigate = useNavigate();
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  const [priorityFilter, setPriorityFilter] = useState<string>();
  const [unitFilter, setUnitFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();

  const handleClickRow = useCallback(
    (id: string) => {
      const action = actionItems.find((item) => item.id === id);

      if (action) {
        navigate(
          `/projects/${action.projectId}/${action.buildingId}/units/${action.unitId}`
        );
      }
    },
    [actionItems, navigate]
  );

  const handleClickGroup = useCallback(
    (unitId: string, category: ActionItemCategory) => {
      navigate(
        `/projects/${projectId}/${buildingId}/units/${unitId}/group/${category}`
      );
    },
    [buildingId, navigate, projectId]
  );

  const unitItems = useMemo(() => {
    const unitIdsObj: Record<string, string> = {};

    actionItems.forEach((actionitem) => {
      unitIdsObj[actionitem.unitId] = actionitem.unitId;
    });

    return Object.keys(unitIdsObj)
      .sort()
      .map((item) => ({ label: item, value: item }));
  }, [actionItems]);

  const filteredActionItems = useMemo(() => {    
    return actionItems.filter((actionItem) => {
      return true;
      if (!actionItem.completedOn || actionItem.status == 'active') {
        return true;
      }

      // check if action Item completed more than 1 minute ago and return false
      const today = new Date();
      const completedAt: Date = (actionItem.completedOn as any).toDate();

      if (today.getTime() - completedAt.getTime() > 60000) {
        return false;
      }
      return true;
    });
  }, [
    actionItems,
    leaseStatusFilter,
    priorityFilter,
    statusFilter,
    unitFilter,
  ]);

  return (
    <Stack className="flex-1  pr-[8px]" gap="16px">
      {/* <Stack direction="row" alignItems="center" gap="26px">
        <Select
          label="Priority"
          items={priorityItems}
          selected={priorityFilter}
          onSelect={setPriorityFilter}
        />
        <Select
          label="Unit"
          items={unitItems}
          selected={unitFilter}
          onSelect={setUnitFilter}
        />
        <Select
          label="Status"
          items={statusItems}
          selected={statusFilter}
          onSelect={setStatusFilter}
        />
      </Stack> */}

      <ActionGroupList
        actionItems={filteredActionItems}
        onClickActionItem={onClickActionItem}
        onBuildingDataChange={onBuildingDataChange}
      />
    </Stack>
  );
}
