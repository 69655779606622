import { FC, SVGProps } from 'react';
import * as React from 'react';

import IconLoop from '@/assets/images/icons/icon-loop.svg?react';
import IconChecklist from '@/assets/images/icons/icon-checklist.svg?react';
import IconProject from '@/assets/images/icons/icon-project.svg?react';
import IconTeam from '@/assets/images/icons/icon-team.svg?react';
import IconCalendar from '@/assets/images/icons/icon-calendar.svg?react';
import IconActivity from '@/assets/images/icons/icon-activity.svg?react';
import IconDocument from '@/assets/images/icons/icon-document.svg?react';
import IconFolder from '@/assets/images/icons/icon-folder.svg?react';
import IconMapPin from '@/assets/images/icons/icon-map-pin.svg?react';
import IconNavArrowLeft from '@/assets/images/icons/icon-nav-arrow-left.svg?react';
import IconNavArrowRight from '@/assets/images/icons/icon-nav-arrow-right.svg?react';
import IconVacancy from '@/assets/images/icons/icon-vacancy.svg?react';
import IconNet from '@/assets/images/icons/icon-net.svg?react';
import IconSearch from '@/assets/images/icons/icon-search.svg?react';
import IconArrowDown from '@/assets/images/icons/icon-arrow-down.svg?react';
import IconArrowUp from '@/assets/images/icons/icon-arrow-up.svg?react';
import IconAvatar from '@/assets/images/icons/icon-avatar.svg?react';
import IconPortfolio from '@/assets/images/icons/icon-portfolio.svg?react';
import IconFile from '@/assets/images/icons/icon-file-text.svg?react';
import IconEye from '@/assets/images/icons/icon-eye.svg?react';
import IconTool from '@/assets/images/icons/icon-tool.svg?react';
import IconGlobe from '@/assets/images/icons/icon-globe.svg?react';
import IconBookMark from '@/assets/images/icons/icon-bookmark.svg?react';
import IconGrid from '@/assets/images/icons/icon-grid.svg?react';
import IconMenu from '@/assets/images/icons/icon-menu.svg?react';
import IconInfo from '@/assets/images/icons/icon-info.svg?react';
import IconLayer from '@/assets/images/icons/icon-layers.svg?react';
import IconTrending from '@/assets/images/icons/icon-trending.svg?react';
import IconSort from '@/assets/images/icons/icon-sort.svg?react';
import IconSortAsc from '@/assets/images/icons/icon-sort-asc.svg?react';
import IconSortDesc from '@/assets/images/icons/icon-sort-desc.svg?react';
import IconDropArrow from '@/assets/images/icons/icon-droparrow.svg?react';
import IconClose from '@/assets/images/icons/icon-close.svg?react';
import IconGoodCondition from '@/assets/images/icons/icon-contdition-good.svg?react';
import IconBadCondition from '@/assets/images/icons/icon-contdition-bad.svg?react';
import IconNoCondition from '@/assets/images/icons/icon-contdition-normal.svg?react';
import IconChecked from '@/assets/images/icons/icon-checkbox-checked.svg?react';
import IconCheck from '@/assets/images/icons/icon-checkbox.svg?react';
import IconMoreVertical from '@/assets/images/icons/icon-more-vertical.svg?react';
import IconExclamationMark from '@/assets/images/icons/icon-exclamation-mark.svg?react';
import IconActions from '@/assets/images/icons/icon-actions.svg?react';
import IconThe3dApp from '@/assets/images/icons/icon-the3dapp.svg?react';
import IconSuccess from '@/assets/images/icons/icon-success.svg?react';
import IconWarning from '@/assets/images/icons/icon-warning.svg?react';
import IconFloorPlan from '@/assets/images/icons/icon-floorplan.svg?react';
import IconSortVertical from '@/assets/images/icons/icon-sort-vertical.svg?react';
import IconEmailMarkAsUnread from '@/assets/images/icons/icon-email-mark-as-unread.svg?react';
import IconKey from '@/assets/images/icons/icon-key.svg?react';
import IconForbbiden from '@/assets/images/icons/icon-forbbiden.svg?react';

export const iconTypes = {
  loop: IconLoop,
  checklist: IconChecklist,
  project: IconProject,
  team: IconTeam,
  calendar: IconCalendar,
  activity: IconActivity,
  document: IconDocument,
  folder: IconFolder,
  mapPin: IconMapPin,
  navArrowLeft: IconNavArrowLeft,
  navArrowRight: IconNavArrowRight,
  vacancy: IconVacancy,
  net: IconNet,
  search: IconSearch,
  arrowDown: IconArrowDown,
  arrowUp: IconArrowUp,
  avatar: IconAvatar,
  portfolio: IconPortfolio,
  file: IconFile,
  eye: IconEye,
  tool: IconTool,
  globe: IconGlobe,
  bookMark: IconBookMark,
  menu: IconMenu,
  grid: IconGrid,
  info: IconInfo,
  layer: IconLayer,
  trending: IconTrending,
  sort: IconSort,
  sortAsc: IconSortAsc,
  sortDesc: IconSortDesc,
  dropArrow: IconDropArrow,
  close: IconClose,
  good: IconGoodCondition,
  bad: IconBadCondition,
  noCond: IconNoCondition,
  checked: IconChecked,
  check: IconCheck,
  moreVertical: IconMoreVertical,
  exclamationMark: IconExclamationMark,
  actions: IconActions,
  the3dapp: IconThe3dApp,
  success: IconSuccess,
  warning: IconWarning,
  floorplan: IconFloorPlan,
  sortvertical: IconSortVertical,
  key: IconKey,
  emailMarkAsUnread: IconEmailMarkAsUnread,
  forbbiden: IconForbbiden,
};

type Props = {
  name: keyof typeof iconTypes;
} & SVGProps<SVGSVGElement>;

const AppIcon: FC<Props> = ({ name, ...props }) => {
  if (!name) {
    const Icon = iconTypes['floorplan'];
    return  <Icon {...props}/>;
  }
  const Icon = iconTypes[name];

  if (!Icon) {
    return <p>Error Loading Icon</p>;
  }
  return <Icon {...props} />;
};

export default AppIcon;
