import { FC, PropsWithChildren } from 'react';
import logo from '@/assets/images/logo/logo.svg';
import Lottie from 'lottie-react';
import animationData from '../assets/city.json';
import { GoogleOAuthProvider } from '@react-oauth/google';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId='151405658407-t55ev8u98m6hgr4hhfid4juqegenivdi.apps.googleusercontent.com'>
      <div className="relative flex h-screen w-full">
        <Lottie
          style={{
            height: '70vh',
            width: '70%',
            cursor: 'auto',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateY(-50%) translateX(-50%)',
            opacity: '0.8',
            filter: 'grayscale(60%)',
            borderRadius: '20px',
            overflow: 'hidden',
          }}
          animationData={animationData}
          autoPlay
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          loop
        />
        <div className="relative z-10 m-auto flex w-full flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center rounded-3xl bg-black bg-opacity-70 p-20">
            <img
              src={logo}
              alt="Logo"
              className="mb-32 h-40 w-[144px] md:h-auto md:w-auto"
            />
            {children}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default AuthLayout;
