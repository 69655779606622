import { InputBase } from '@mui/material';
import type { InputBaseProps } from '@mui/material';

import { twMerge } from 'tailwind-merge';

export type InputProps = InputBaseProps;

export function Input({ className, sx, ...rest }: InputProps) {
  return (
    <InputBase
      className={twMerge(
        'flex h-36 items-center gap-16 rounded-lg px-12 leading-[14px] tracking-tight',
        className
      )}
      sx={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0A0E15 !important`,
        '& input, & input::placeholder': {
          fontSize: '14px',
          color: '#6C6E73',
          fontWeight: 400,
          lineHeight: '14px',
          letterSpacing: '0.35px',
        },
        '& input': {
          color: 'rgba(255,255,255,0.6)',
        },
        '& ::-webkit-calendar-picker-indicator': {
          filter: 'invert(1)',
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
