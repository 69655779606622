import { SvgIcon, type SvgIconProps } from '@mui/material';

export function Picture(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="image 1">
        <path
          id="Vector"
          d="M16.3978 3.01562H4.73112C3.81065 3.01562 3.06445 3.76182 3.06445 4.68229V16.349C3.06445 17.2694 3.81065 18.0156 4.73112 18.0156H16.3978C17.3183 18.0156 18.0645 17.2694 18.0645 16.349V4.68229C18.0645 3.76182 17.3183 3.01562 16.3978 3.01562Z"
          stroke="#E6E3E3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Vector_2"
          d="M7.64771 8.84766C8.33806 8.84766 8.89771 8.28801 8.89771 7.59766C8.89771 6.9073 8.33806 6.34766 7.64771 6.34766C6.95735 6.34766 6.39771 6.9073 6.39771 7.59766C6.39771 8.28801 6.95735 8.84766 7.64771 8.84766Z"
          stroke="#E6E3E3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Vector_3"
          d="M18.0645 13.0143L13.8979 8.84766L4.7312 18.0143"
          stroke="#E6E3E3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
}
