import { useState, ChangeEvent } from 'react';
import { InputBase } from '@mui/material';

type InputProps = {
  value: string;
  onChange(newValue: string | null, failCb: () => void): void;
};

export function Input({ value, onChange }: InputProps) {
  const [innerValue, setInnerValue] = useState(value);

  const handleSave = () => {
    if (value === innerValue) {
      return;
    }

    onChange(innerValue.trim() !== '' ? innerValue : null, () => {
      setInnerValue(value);
    });
  };

  return (
    <InputBase
      value={innerValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setInnerValue(event.target.value);
      }}
      onBlur={handleSave}
      sx={{
        background: 'transparent',
        '& input': { background: 'transparent', color: 'white' },
      }}
    />
  );
}
