import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Loader } from '@/components/Loader/Loader';

import { useAuth } from '@/shared/context/AuthProvider';
import { PrivateLayout } from '@/layouts/PrivateLayout';

export default function PrivateRoutes() {
  const { user, userData, allowedProjectIds, permissionsLoaded } = useAuth();
  const { projectId } = useParams();
  const location = useLocation();

  if (isMobile) {
    return <Navigate to="/block-mobile-usage" />;
  }

  if (user === null) {
    return <Navigate to="welcome" state={{ from: location }} />;
  }

  if (user === undefined || !userData || !permissionsLoaded) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader title="Launching Your Workspace..." center />
      </div>
    );
  }

  if (
    projectId &&
    !userData.isDev &&
    allowedProjectIds !== 'all' &&
    !allowedProjectIds.includes(projectId)
  ) {
    return <Navigate to="/access-denied" />;
  }

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
}
